<ng-container *ngIf="linkedVehicle; else no_vehicle_link">
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="col_heading mb-0">Linked Vehicle</div>
    <div *appHasPermission="['used_edit_rv','new_edit_rv']">
      <button mat-stroked-button color="primary" *ngIf="vehicle?.NewLink"
        (click)="openNewAdminLinking(vehicle?.NewLink)">
        New Link
      </button>
      <button class="ms-2" mat-stroked-button color="primary"
        *ngIf="roleCheckService.isWriteAccess() && vehicle?.isRemoveLinking==true"
        [disabled]="processingLinkedData || isRemoving" (click)="removeLink()">
        {{ isRemoving ? 'Removing..':'Remove Linking' }}
      </button>
      <button class="ms-2" mat-stroked-button color="primary" *ngIf="roleCheckService.isWriteAccess()"
        [disabled]="processingLinkedData" (click)="openLinkedVehicle()">Change Vehicle
      </button>
    </div>
  </div>
  <link-vehicle-detail class="d-block mb-4" [linkedVehicle]="linkedVehicle" [isAdmin]="roleCheckService.isWriteAccess()"
    [vehicleId]="vehicleId" [linkedVehicleId]="linkedVehicleId" (printChart)="printChartData($event)"
    [isDataLoaded]="false"></link-vehicle-detail>
  <ng-container *ngIf="!processingLinkedData">
    <vehicle-chart [vehicle]="processedLinkedData" [vehicleId]="linkedVehicleId"
      [isLinkedVehicleTabOpened]="isLinkedVehicleTabOpened" [isLinkedVehicleChartView]="true"></vehicle-chart>
  </ng-container>
</ng-container>
<ng-template #no_vehicle_link>
  <div class="d-flex justify-content-between align-items-center h-100 p-4">
    <div>No {{roleCheckService.isSuperAdmin() ? 'DI' : 'RV'}} available</div>
  </div>
</ng-template>