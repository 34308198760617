import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-add-rv-values',
  templateUrl: './add-rv-values.component.html',
  styleUrls: ['./add-rv-values.component.scss']
})
export class AddRvValuesComponent implements OnInit {

  @Input() isLinked: boolean;
  @Input() linkedId: string;
  @Input() checked: boolean;

  public isPercentage: string;
  public vehicle;
  public isProcessing: boolean = true;
  public isSaving: boolean = false;
  public saveValuesModel: any = {
    dollar: {
      dollar_12: undefined,
      dollar_24: undefined,
      dollar_36: undefined,
      dollar_48: undefined,
      dollar_60: undefined,
    },
    percent: {
      per_12: undefined,
      per_24: undefined,
      per_36: undefined,
      per_48: undefined,
      per_60: undefined,
    },
    comments: undefined,
  };
  public newVehiclePrice: number = undefined;

  constructor(
    public dialogRef: MatDialogRef<AddRvValuesComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public roleCheckService: RoleCheckerService,
    public vehicleService: VehicleService,
    public commonService: CommonService,
    public dataStore: DataStoreService,
    private jwtService: JwtService
  ) {
    this.isPercentage = 'percentage';
    if (data.vehicle) {
      this.vehicle = Object.assign({}, data.vehicle)
    }
  }

  ngOnInit() {
    this.getVehicleNewPrice();
  }

  getVehicleNewPrice() {
    this.isProcessing = true;
    this.vehicleService.getBaseData(this.vehicle.DatiumAssetKey).subscribe((res: any) => {
      try {
        const response = res;
        this.newVehiclePrice = response.data.NewPrice;
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      if (response.errors) {
        this.formatErrors(response.errors);
      }
      else {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
      this.closeModal();
    })
  }

  validateForm(form) {
    if (this.saveValuesModel.comments) {
      if (!form.invalid) {
        const percentObjKeys = Object.keys(this.saveValuesModel.percent);
        let count = 0, inValid = false;
        for (let i = 0; i < percentObjKeys.length; i++) {
          const value = parseFloat(this.saveValuesModel.percent[percentObjKeys[i]]);
          if (value && value >= 0 && value <= 100) {
            count++;
          }
          else if (value) {
            this.saveValuesModel.percent[percentObjKeys[i]] = undefined;
            this.saveValuesModel.dollar["dollar_" + percentObjKeys[i].split("_")[1]] = undefined;
            this.commonService.showSnakeBar("Invalid Input");
            inValid = true;
          }
        }
        if (count == 0 && !inValid) {
          this.commonService.showSnakeBar("At least one input is required");
        }
        else if (!inValid) {
          this.saveForm();
        }
      }
      else {
        this.validateFormFields(form.form);
      }
    }
    else {
      this.commonService.showSnakeBar("Comments are required");
    }
  }

  saveForm() {
    this.isSaving = true;
    const reqModel = {
      dollor_12: parseFloat(this.saveValuesModel.dollar.dollar_12),
      dollor_24: parseFloat(this.saveValuesModel.dollar.dollar_24),
      dollor_36: parseFloat(this.saveValuesModel.dollar.dollar_36),
      dollor_48: parseFloat(this.saveValuesModel.dollar.dollar_48),
      dollor_60: parseFloat(this.saveValuesModel.dollar.dollar_60),
      per_12: parseFloat(this.saveValuesModel.percent.per_12),
      per_24: parseFloat(this.saveValuesModel.percent.per_24),
      per_36: parseFloat(this.saveValuesModel.percent.per_36),
      per_48: parseFloat(this.saveValuesModel.percent.per_48),
      per_60: parseFloat(this.saveValuesModel.percent.per_60),
      companyId: this.jwtService.getCompanyId(),
      comments: this.saveValuesModel.comments,
      isAdmin: this.roleCheckService.isSuperAdmin()
    }
    console.log(reqModel, '-----prin the req modal here');
    this.vehicleService.saveRvDiValues(reqModel, this.vehicle.DatiumAssetKey).subscribe((res: any) => {
      try {
        const response = res;
        if (response.data.Status) {
          this.commonService.showSnakeBar("Successfully Added");
          this.dataStore.resetVehicleDetailsReload(this.vehicle.DatiumAssetKey, true, true)
          this.closeModal();
        }
        else {
          this.commonService.showSnakeBar(response.data.Message);
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isSaving = false;
    }, (err) => {
      let response = err;
      if (response.errors) {
        this.formatErrors(response.errors);
      }

      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }

      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  validateFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateFormFields(control);
      }
    });
  }

  getAndSetDollar(prnct, d_key, p_key) {
    if (prnct > -100 && prnct <= 100 || prnct == '-') {
      this.saveValuesModel.dollar[d_key] = this.commonService.roundToXDigits((this.newVehiclePrice / 100) * prnct);
    } else {
      this.saveValuesModel.percent[p_key] = 0
      this.saveValuesModel.dollar[p_key] = 0
      this.commonService.showSnakeBar("Value must be between -100 and 100");
    }
  }

  getAndSetPercent(dollar, p_key, d_key) {
    let per = (dollar / this.newVehiclePrice) * 100;
    if (per > -100 && per <= 100) {
      this.saveValuesModel.percent[p_key] = this.commonService.roundToXDigits(per);
    } else {
      this.saveValuesModel.dollar[d_key] = 0
      this.saveValuesModel.percent[p_key] = 0
      this.commonService.showSnakeBar("Percentage must be between -100 and 100");
    }
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  getDisableStatus() {
    if (!this.isProcessing && this.newVehiclePrice && !this.isSaving) {
      return false;
    }
    else {
      return true;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
