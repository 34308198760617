<div class="support_headings">
  <h2>Contact Us</h2>
</div>

<div class="row">
  <div class="col-md-5">
    <div class="contact_us">
      <form [formGroup]="contactUsForm" (ngSubmit)="handleContactForm(contactUsForm)" autocomplete="false" novalidate>
        <mat-form-field [floatLabel]="'never'" class="bordered shadow-input mt-5">
          <label>Your Name</label>
          <input type="text" matInput maxlength="30" formControlName="name" required>
          <mat-error
            *ngIf="contactUsForm.controls.name.invalid && (contactUsForm.controls.name.dirty || contactUsForm.controls.name.touched)">
            <span *ngIf="contactUsForm.controls.name.errors.required">
              Please enter name.
            </span>
            <span *ngIf="contactUsForm.controls.name.errors.minlength">
              Name should not be less than 3 characters.
            </span>
            <span *ngIf="contactUsForm.controls.name.errors.maxlength">
              Name should not be greater than 30 characters.
            </span>
            <span *ngIf="formError['name']">{{formError.name}}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'never'" class="bordered shadow-input mt-5">
          <label>Your Email</label>
          <input type="email" matInput maxlength="50" formControlName="email" required>
          <mat-error
            *ngIf="contactUsForm.controls.email.invalid && (contactUsForm.controls.email.dirty || contactUsForm.controls.email.touched)">
            <span *ngIf="contactUsForm.controls.email.errors.required || contactUsForm.controls.email.errors.email">
              Please enter email.
            </span>
            <span *ngIf="contactUsForm.controls.email.errors.maxlength">
              Email should not be greater than 30 characters.
            </span>
            <span *ngIf="contactUsForm.controls.email.errors.pattern">
              Please enter a valid email.
            </span>
            <span *ngIf="formError['email']">{{formError.email}}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'never'" class="bordered shadow-input mt-5">
          <label>Your Phone Number</label>
          <input matInput formControlName="phone_no"  required>
          <mat-error
            *ngIf="contactUsForm.controls.phone_no.invalid && (contactUsForm.controls.phone_no.dirty || contactUsForm.controls.phone_no.touched)">
            <span *ngIf="contactUsForm.controls.phone_no.errors.required">
              Please enter phone no.
            </span>
            <span *ngIf="contactUsForm.controls.phone_no.errors.maxlength">
              Phone no should not be greater than 12 numbers.
            </span>
            <span *ngIf="contactUsForm.controls.phone_no.errors.pattern">
              Please enter a valid phone no.
            </span>
            <span *ngIf="formError['phone_no']">{{formError.phone_no}}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'never'" class="bordered shadow-input mt-5">
          <label>Subject</label>
          <input type="text" matInput formControlName="subject" minlength="3" required>
          <mat-error
            *ngIf="contactUsForm.controls.subject.invalid && (contactUsForm.controls.subject.dirty || contactUsForm.controls.subject.touched)">
            <span *ngIf="contactUsForm.controls.subject.errors.required">
              Please enter your subject.
            </span>
            <span *ngIf="contactUsForm.controls.subject.errors.minlength">
              Subject should not be less than 3 characters.
            </span>
            <span *ngIf="formError['subject']">{{formError.subject}}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'never'" class="bordered shadow-input mt-5">
          <label>Your Message</label>
          <textarea matInput name="comment" formControlName="comment" required></textarea>
        </mat-form-field>
        <mat-error
          *ngIf="contactUsForm.controls.comment.invalid && (contactUsForm.controls.comment.dirty || contactUsForm.controls.comment.touched)">
          <span *ngIf="contactUsForm.controls.comment.errors.required">
            Please enter message.
          </span>
          <span *ngIf="formError['comment']">{{formError.comment}}</span>
        </mat-error>

        <button class="btn btn-dark btn-block btn-lg text-uppercase mt-5" mat-button [disabled]="loadingForm">
          {{loadingForm ? 'Sending' : 'Send Message'}}
        </button>
      </form>
    </div>
  </div>
</div>