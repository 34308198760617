import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'dashboard-actions',
  templateUrl: './dashboard-actions.component.html',
  styleUrls: ['./dashboard-actions.component.scss']
})

export class DashboardActionsComponent implements OnInit {

  hasNewVehiclesAccess: boolean = false;
  hasAutoPredictNewVehAccess: boolean = false;
  hasLinkedVehicleAccess: boolean = false;
  hasPriceTrackerAccess: boolean = false;
  hasRiskAdjustment: boolean = false;
  hasReports: boolean = false;
  hasAutoPredictUsed: boolean = false;
  isLoadingList: boolean = false;
  vSubscription: Subscription;

  //count variables
  public newVehiclesCount: number = 0;
  public autoPnewVehiclesCount: number = 0;
  public autoPusedVehiclesCount: number = 0;
  public reportsCount: number = 0;
  constructor(
    public roleCheckService: RoleCheckerService,
    private jwtService: JwtService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
  ) {
    let permissions: Array<any> = this.jwtService.getUserPermissions()
    let checkPerm = ['new_edit_rv', 'used_edit_rv', 'calculator'];
    this.hasReports = checkPerm.some(r => permissions.indexOf(r) >= 0);
    let checkPerm1 = ['new_edit_rv', 'used_edit_rv'];
    this.hasRiskAdjustment = checkPerm1.some(r => permissions.indexOf(r) >= 0);
    let checkPerm2 = ['used_view_rv', 'used_edit_rv'];
    this.hasAutoPredictUsed = checkPerm2.some(r => permissions.indexOf(r) >= 0);
    this.hasNewVehiclesAccess = (this.roleCheckService.isSuperAdmin() || (this.roleCheckService.isCompanyOwner() && !this.roleCheckService.checkAutoAccept()));
    let checkPerm3 = ['new_view_rv', 'new_edit_rv'];
    this.hasAutoPredictNewVehAccess = checkPerm3.some(r => permissions.indexOf(r) >= 0);
    let checkPerm4 = ['used_view_rv', 'used_edit_rv', 'new_view_rv', 'new_edit_rv'];
    this.hasLinkedVehicleAccess = checkPerm4.some(r => permissions.indexOf(r) >= 0);
    let checkPerm5 = ['price_tracker'];
    this.hasPriceTrackerAccess = checkPerm5.some(r => permissions.indexOf(r) >= 0);
  }

  ngOnInit() {
    this.getTilesCount();
  }

  getTilesCount() {
    this.isLoadingList = true;
    this.vSubscription = this.dashboardService.getDTilesCount().subscribe(
      (result: any) => {
        let response = result;
        if (response.status == 200) {
          let tilesCount = response.data;
          this.newVehiclesCount = tilesCount.newVehiclesCount;
          this.autoPnewVehiclesCount = tilesCount.autoPredictNewVehiclesCount;
          this.autoPusedVehiclesCount = tilesCount.autoPredictUsedVehiclesCount;
          this.reportsCount = tilesCount.reportsCount;
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isLoadingList = false;
      },
      (error) => {
        let response = error;
        this.toggleLoader(false)
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.toggleLoader(false)
      }
    )
  }

  /*
  * @name toggleLoader
  * @param None
  * @description toggle listing loaders
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    this.isLoadingList = show
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
  }
}
