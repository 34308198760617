<div>
  <page-title [title]="'Products'"></page-title>


  <div *ngIf="roleCheckService.isSuperAdmin()">
    <div class="mt-2 mb-4">

    </div>
    <div class="">
      <app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'100px'}"></app-loader>
      <div class="example-container">

        <div class="example-table-container" *ngIf="!isLoading">
          <table mat-table [dataSource]="listData">

            <!-- Position Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting">Name <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="OfferType">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting des">OfferType <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.OfferType}} </td>
            </ng-container>
            <ng-container matColumnDef="TotalDays">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting des">Total Days <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.TotalDays ? element.TotalDays : 'N/A'}} </td>
            </ng-container>
            <ng-container matColumnDef="TotalUsers">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting des">Total Users <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.TotalUsers ? element.TotalUsers : 'N/A'}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="action">
              <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-right" mat-cell *matCellDef="let element">
                <button (click)="updateProducts(element)" class="btn btn-primary ml-3" mat-button>Edit
                </button>

              </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
