<div class="support_headings">
  <h2>Current Releases</h2>
  <h5>Screen for setting/viewing RV's of individual vehicles through adjustments, linking’s and visualisations.</h5>
</div>

<div class="help_steps">

  <h2 class="mb-4">RV Adjustments and Linking</h2>

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">1</span>
      <div class="step_count">If you want to adjust the RV percentages for a vehicle directly you can do this via the
        'Change RV values' section within the vehicle record. For example, if
        you would like to increase the RV percentages by 5% across all the terms you would input 5 into each of the 5
        boxes, then add in a reason for the change,
        then finally click the ‘Accept & Save Changes’ button (example screenshot below).</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom autonew_step_zoom1">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/autopredict_new/auto_new_step1.jpg" alt="Step1">
      </div>
    </div>
  </div>


  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">2</span>
      <div class="step_count">If you want to link the vehicle's RV values to another vehicle's RV's you can do this via
        the <strong>Linked Vehicle</strong> section within the vehicle record. Click on the <strong>Link
          Vehicle</strong>
        button, either choose from
        the vehicles proposed by Datium in the <strong>Recommended</strong> section or search for the vehicle in the
        <strong>Search Vehicles</strong>
        section. When you have settled on your choice of vehicle click on the <strong>Link</strong> button and the
        vehicles will now
        be linked.
      </div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom6">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step4.jpg" alt="Step4">
      </div>
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom7">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom newvehicle_step_zoom7_2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step5.jpg" alt="Step5">
      </div>
    </div>

  </div>


  <h2 class="mb-4">Adjusting Charts</h2>


  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">3</span>
      <div class="step_count">Within the vehicle record a chart will display showing the RV (blue line), the Datium RV
        (pink line), aggregated used car sales records (orange dots) and the number of units that go into each sales
        record (grey bars). You can adjust the data that flows into this chart – on initialization the chart will
        automatically show the most recent 12 months of used car sales data. To change the period of sales data you can
        do this by adjusting the dates under <strong>Select Start Date</strong> and <strong>Select End Date</strong>.
        Furthermore, if you would like
        to restrict the data to a specific region you can do this via the <strong>Region</strong> dropdown menu.</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom autonew_step_zoom2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/autopredict_new/auto_new_step3.jpg" alt="Step1">
      </div>
    </div>
  </div>

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">4</span>
      <div class="step_count">If you would like to compare RV curves for multiple vehicles you can do this via
        selecting the vehicles you would like to display on the chart and then clicking on the button <strong>Compare
          Vehicles</strong> near the top of the screen (this button will only appear after selecting more than one
        vehicle).</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot top_margin">
        <div class="step_zoom autonew_step_zoom3">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom autonew_step_zoom3_2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/autopredict_new/auto_new_step4.jpg" alt="Step1">
      </div>
    </div>
  </div>

</div>