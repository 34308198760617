<!-- <page-title [title]="'Dashboard'"></page-title> -->
<div class="dashboard_container">
  <div class="row">
    <div class="col col-sm-12 col-lg-7">
      <dashboard-actions></dashboard-actions>
      <app-vehicles-list class="mt-4" listType="newVehicles"></app-vehicles-list>
      <history-wrap></history-wrap>
    </div>
    <div class="col col-sm-12 col-lg-5">
      <app-vehicles-list listType="recentlySearched"></app-vehicles-list>
      <app-graph-widget widgetType="vehicleSearched"></app-graph-widget>
      <app-graph-widget widgetType="newVehiclesAdded"></app-graph-widget>
      <!-- <dashboard-sidebar [sidebarClass]="'d-flex justify-content-end dashboard_sidebar_wrapper'" [for]="'dashboard'"></dashboard-sidebar> -->
    </div>
  </div>
</div>