<div class="support_headings">
  <h2>New Vehicles</h2>
  <h5>Screen for accepting/rejecting vehicles into the Current Releases area.</h5>
</div>

<div class="help_steps">

  <h2 class="mb-4">Accepting or rejecting new vehicles</h2>

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">1</span>
      <div class="step_count">To <strong>reject</strong> vehicles you can either click on the reject
        button next to the vehicle record or you can select multiple vehicles in one go and click on the reject button
        on the top right of the screen. Note – you can only select 10 vehicles at a time for rejection.</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom1">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step1.jpg" alt="Step1">
      </div>
      <div class="step_screenshot top_margin">
        <div class="step_zoom newvehicle_step_zoom2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom newvehicle_step_zoom2_2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step2.jpg" alt="Step2">
      </div>
    </div>
  </div>

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">2</span>
      <div class="step_count">To <strong>accept</strong> vehicles with the existing Datium values you
        can either click on the accept button next to the vehicle record or you can select multiple vehicles in one go
        and click on the accept button on the top left of the screen. Note – you can only select 10 vehicles at a time
        for acceptance.</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom3">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step1.jpg" alt="Step3">
      </div>
      <div class="step_screenshot top_margin">
        <div class="step_zoom newvehicle_step_zoom4">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom newvehicle_step_zoom4_2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step2.jpg" alt="Step2">
      </div>
    </div>
  </div>

  <div class="single_step top_margin">

    <h2 class="mb-4">Accept & Save Changes and Accept & Link</h2>

    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">3</span>
      <div class="step_count">If you want to make some changes to a vehicle before accepting it you can do this via the
        <strong>Change RV values</strong> section within the vehicle record. For example, if you would like to increase
        the RV percentages by 5% across all the terms you would input 5 into each of the 5 boxes, then add in a reason
        for the change, then finally click the <strong>Accept & Save Changes</strong> button (example screenshot
        below).
      </div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom5">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step3.jpg" alt="Step3">
      </div>
    </div>
  </div>

  <div class="single_step top_margin">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">4</span>
      <div class="step_count">If you want to link the vehicle's RV values to another vehicle’s RV’s before accepting it
        you can do this via the <strong>Linked Vehicle</strong> section within the vehicle record. Click on the
        <strong>Accept
          & Link</strong>
        button, either choose from the vehicles proposed by Datium in the <strong>Recommended</strong> section or
        search for the
        vehicle in the <strong>Search Vehicles</strong> section. When you have settled on your choice of vehicle click
        on the <strong>Link</strong>
        button and the vehicles will now be linked.
      </div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom6">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step4.jpg" alt="Step4">
      </div>
      <div class="step_screenshot">
        <div class="step_zoom newvehicle_step_zoom7">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom newvehicle_step_zoom7_2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)"
              d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/new_vehicles/new_vehicle_step5.jpg" alt="Step5">
      </div>
    </div>
  </div>

</div>