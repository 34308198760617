<h2 mat-dialog-title>
  <button mat-dialog-close [disabled]="isProcessing">
    <span class="material-symbols-outlined">close</span>
  </button> Update {{productData['PlanName']}} Offer
</h2>

<mat-dialog-content class="mat-typography add_product">
  <form [formGroup]="productForm" autocomplete="false" novalidate>
    <div class="row mt-2 mb-4">
      <div class="col col-md-12">
        <input type="hidden" formControlName="Plan_Id">
        <mat-form-field class="dropdown" [floatLabel]="'never'">
          <mat-select name="ProductOffer_Id" (selectionChange)="selectedValue($event)" formControlName="ProductOffer_Id"
            placeholder="Product Offers" [multiple]="isMultiple">
            <mat-option *ngFor="let offers of ProductOffers" [value]="offers.ProductOffer_Id">{{offers.Name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="checkControlError('ProductOffer_Id',productForm)" class="mb-3">
            <span>
              Please choose offer type.
            </span>
          </mat-error>
        </mat-form-field>

      </div>
      <ng-container *ngIf="getControl('ProductOffer_Id').value==2">
        <ng-container formGroupName="Products">
          <ng-container *ngIf="isProductExists('AutoPredict')" formGroupName="AutoPredict">
            <div class="col-md-12">
              <h3>AutoPredict</h3>
            </div>
            <input type="hidden" formControlName="Product_Id">
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Days</label>
                <input matInput formControlName="TotalDays" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('Products.AutoPredict.TotalDays',productForm)" class="mb-3">
                  <span>
                    Please enter total number of days.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('Products.AutoPredict.TotalUsers',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="button_type mb-2" [floatLabel]="'never'">
                <label for="Makes">Choose Makes</label>
                <mat-select multiple formControlName="Makes">
                  <mat-option *ngFor="let make of makArray" [value]="make">
                    {{make}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkControlError('Products.AutoPredict.Makes',productForm)" class="mb-3">
                  <span>
                    Please choose at least one make.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-12 pb-3">
              <label class="pt-3 pb-2">Chosen Makes</label>
              <div class="pb-3">
                <span class="text-primary">{{getMakesList()}}</span>
              </div>
            </div>
            <div class="col-md-12 mb-3">
              <div class="d-block company_roles">
                <label class="col-md-12 pl-0 pb-3">Choose Permission</label>
                <div class="row">
                  <label formArrayName="Permission_Id"
                    *ngFor="let genre of getControl('Products.AutoPredict.Permission_Id').controls; let i = index"
                    class="col-md-6 pb-2">
                    <mat-checkbox [formControlName]="i">{{permArray[i]['label']}}</mat-checkbox>
                  </label>
                  <div class="col-md-12 pt-3 pb-3"
                    *ngIf="checkControlError('Products.AutoPredict.Permission_Id',productForm)">
                    <mat-error>
                      <span>Please choose at least one permission.</span>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="isProductExists('InstantValuation')" formGroupName="InstantValuation">
            <div class="col-md-12">
              <h3>InstantValuation</h3>
            </div>
            <input type="hidden" formControlName="Product_Id">
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Days</label>
                <input matInput formControlName="TotalDays" name="TotalDays" type="number">
                <mat-error *ngIf="checkControlError('Products.InstantValuation.TotalDays',productForm)" class="mb-3">
                  <span>
                    Please enter total number of days.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('Products.InstantValuation.TotalUsers',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Requests</label>
                <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                <mat-error *ngIf="checkControlError('Products.InstantValuation.TotalRequests',productForm)"
                  class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *ngIf="isProductExists('Rego')" formGroupName="Rego">
            <div class="col-md-12">
              <h3>Rego</h3>
            </div>
            <input type="hidden" formControlName="Product_Id">
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Days</label>
                <input matInput formControlName="TotalDays" name="TotalDays" type="number">
                <mat-error *ngIf="checkControlError('Products.Rego.TotalDays',productForm)" class="mb-3">
                  <span>
                    Please enter total number of days.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('Products.Rego.TotalUsers',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Requests</label>
                <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                <mat-error *ngIf="checkControlError('Products.Rego.TotalRequests',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <ng-container *ngIf="isProductExists('Vin')" formGroupName="Vin">
            <div class="col-md-12">
              <h3>Vin</h3>
            </div>
            <input type="hidden" formControlName="Product_Id">
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Days</label>
                <input matInput formControlName="TotalDays" name="TotalDays" type="number">
                <mat-error *ngIf="checkControlError('Products.Vin.TotalDays',productForm)" class="mb-3">
                  <span>
                    Please enter total number of days.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('Products.Vin.TotalUsers',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalDays">Total Requests</label>
                <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                <mat-error *ngIf="checkControlError('Products.Vin.TotalRequests',productForm)" class="mb-3">
                  <span>
                    Please enter total number of users.
                  </span>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div class="form_btns ">
      <button *ngIf="isEdit " class="btn btn-primary btn-lg " mat-button [disabled]="isProcessing "
        (click)="updateProduct(productForm) " type="submit ">{{isProcessing ? 'Please Wait...' : 'Update
        Product'}}</button>
      <button class="btn btn-outline-primary ml-3 btn-lg " mat-stroked-button (click)="closeModal() "
        [disabled]="isProcessing " type="button ">Cancel</button>
    </div>
  </form>
</mat-dialog-content>