<ng-container [ngSwitch]="type" *ngIf="ShowLoader">
  <ng-container *ngSwitchCase="'listings-small'">
    <div class="loading_div d-flex" [ngStyle]="CStyle">
      <div class="thumb me-3">
        <div class="animatingLayer"></div>
      </div>
      <div class="rows">
        <div class="animatingLayer first"></div>
        <div class="animatingLayer"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'vehicle-list'">
    <div class="new-vehicle-tab bg-white shadow-sm p-3" [ngStyle]="CStyle">
      <div class="row">
        <div class="col-md-8">
          <div class="animatingLayer"></div>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <div class="animatingLayer"></div>
        </div>
        <div class="col-md-12">
          <div class="animatingLayer"></div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'thumbnail'">
    <div class="animatingLayer thumbnail_loader" [ngStyle]="CStyle"></div>
  </ng-container>
  <ng-container *ngSwitchCase="'filters'">
    <div class="loading_div d-flex" [ngStyle]="CStyle">
      <div class="rows">
        <div class="animatingLayer"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'normal'">
    <div class="data_loading" *ngIf="ShowLoader" [ngStyle]="CStyle">
      <mat-spinner></mat-spinner> {{text}}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'dark'">
    <div class="data_loading bg-dark mt-4 justify-content-start" [ngStyle]="CStyle">
      <mat-spinner></mat-spinner> {{text}}
    </div>
  </ng-container>
</ng-container>