<div>
  <page-title [title]="'Risk Adjustments'"></page-title>
  <mat-tab-group>
    <mat-tab label="Business Risk" *ngIf="!roleCheckService.isSuperAdmin()">
      <div class="mt-2 mb-4" *appHasPermission="['used_edit_rv','new_edit_rv']">
        <button color="primary" (click)="addNewRiskAdjustment('BusinessRisk')" mat-flat-button
          *ngIf="roleCheckService.isWriteAccess()">Add Business
          Risk</button>
      </div>
      <div class="">
        <no-record-found *ngIf="!loadBusinessRisk && vFactData.length <= 0" [notFoundHeading]="'No record(s) found'"
          [cStyle]="{'height':'300px'}"></no-record-found>
        <app-loader [ShowLoader]="loadBusinessRisk" [CStyle]="{'min-height':'100px'}"></app-loader>
        <div class="example-container" *ngIf="vFactData.length > 0">

          <div class="example-table-container" *ngIf="!loadBusinessRisk">
            <table class="custom-table" mat-table [dataSource]="vFactData" matSort>

              <!-- Position Column -->
              <ng-container matColumnDef="VFactsGroups">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> VFactsGroups </th>
                <td mat-cell *matCellDef="let element"> {{element.VFactsGroups}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                <td mat-cell *matCellDef="let element"> {{element.value}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="action" *appHasPermission="['used_edit_rv','new_edit_rv']">
                <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
                <td class="text-right" mat-cell *matCellDef="let element">
                  <ng-container>
                    <ng-container *ngIf="roleCheckService.isWriteAccess()">
                      <button (click)="updateRiskAdjustment(element,'BusinessRisk')" mat-flat-button
                        color="primary">Edit
                      </button>
                      <button class="ms-3" (click)="confirmDeletion(element?.Id,'BusinessRisk')"
                        [disabled]="delBRLoader==element.Id" mat-flat-button color="warn">
                        {{delBRLoader==element.Id ? 'Deleting..':'Delete'}}
                      </button>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <mat-paginator *ngIf="!loadBusinessRisk && vFactData.length > 0" [length]="vFactData.length" [pageSize]="30"
          [pageSizeOptions]="[10, 20,30]"></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Deflation" *ngIf="roleCheckService.isSuperAdmin()">
      <div class="mt-2 mb-4">
        <button *ngIf="roleCheckService.isWriteAccess()" (click)="addNewRiskAdjustment('Deflation')" mat-flat-button
          color="primary">Add Deflation</button>
      </div>
      <div class="">
        <no-record-found *ngIf="!loadDeflation && deflationData.length <= 0" [notFoundHeading]="'No record(s) found'"
          [cStyle]="{'height':'300px'}"></no-record-found>
        <app-loader [ShowLoader]="loadDeflation" [CStyle]="{'min-height':'100px'}"></app-loader>
        <div class="example-container" *ngIf="deflationData.length > 0">

          <div class="example-table-container" *ngIf="!loadDeflation">
            <table class="custom-table" mat-table [dataSource]="deflationData">

              <!-- Position Column -->
              <ng-container matColumnDef="VFactsGroups">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="sorting">VFactsGroups <i class="mat-select-arrow"></i> </div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.VFactsGroups}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="sorting des">Value <i class="mat-select-arrow"></i> </div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.value}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="action">
                <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
                <td class="text-right" mat-cell *matCellDef="let element">
                  <button (click)="updateRiskAdjustment(element,'Deflation')" mat-flat-button color="primary">Edit
                  </button>
                  <button (click)="confirmDeletion(element?.Id,'Deflation')" [disabled]="delDLoader==element.Id"
                    class="ms-3" mat-stroked-button color="warn">
                    {{delDLoader==element.Id ? 'Deleting..':'Delete'}}
                  </button>
                </td>

              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <mat-paginator *ngIf="!loadDeflation && deflationData.length > 0" [length]="deflationData.length"
          [pageSize]="30" [pageSizeOptions]="[10, 20,30]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-tab>
    <mat-tab label="Remarketing Efficiency" *ngIf="!roleCheckService.isSuperAdmin()">
      <div class="mt-2 mb-4" *appHasPermission="['used_edit_rv','new_edit_rv']">
        <button color="primary" *ngIf="roleCheckService.isWriteAccess()"
          (click)="addNewRiskAdjustment('RemarketingEfficiency')" mat-flat-button>Add
          Remarketing Efficiency</button>
      </div>
      <div class="">
        <no-record-found *ngIf="!loadRemarketingEfficiency && remarketingData.length <= 0"
          [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'300px'}"></no-record-found>
        <app-loader [ShowLoader]="loadRemarketingEfficiency" [CStyle]="{'min-height':'100px'}"></app-loader>
        <div class="example-container" *ngIf="remarketingData.length > 0">
          <div class="example-table-container" *ngIf="!loadDeflation">
            <table class="custom-table" mat-table [dataSource]="remarketingData">

              <!-- Position Column -->
              <ng-container matColumnDef="VFactsGroups">
                <th mat-header-cell *matHeaderCellDef> VFactsGroups </th>
                <td mat-cell *matCellDef="let element"> {{element.VFactsGroups}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef> Value </th>
                <td mat-cell *matCellDef="let element"> {{element.value}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="action">
                <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
                <td class="text-right" mat-cell *matCellDef="let element">
                  <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
                    <ng-container *ngIf="roleCheckService.isWriteAccess()">

                      <button (click)="updateRiskAdjustment(element,'RemarketingEfficiency')" class="ms-3"
                        mat-flat-button color="primary">Edit
                      </button>
                      <button (click)="confirmDeletion(element?.Id,'RemarketingEfficiency')" class="ms-3"
                        mat-flat-button color="warn" [disabled]="delRELoader==element.Id">
                        {{delRELoader==element.Id ? 'Deleting..':'Delete'}}
                      </button>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <mat-paginator *ngIf="!loadRemarketingEfficiency && remarketingData.length > 0"
          [length]="remarketingData.length" [pageSize]="30" [pageSizeOptions]="[10, 20,30]"></mat-paginator>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>