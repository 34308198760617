import { Injectable } from '@angular/core';
import { AUTP_USER } from './common.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class JwtService {

  constructor(
    private router: Router,
    private dialogRef: MatDialog
  ) { }

  IsAuthUser(): boolean {
    if (window.localStorage[AUTP_USER] && JSON.parse(window.localStorage[AUTP_USER]).authToken) {
      return true;
    }
    else {
      return false;
    }
  }

  setAccessDetail(obj: any) {
    window.localStorage[AUTP_USER] = JSON.stringify(obj);

    this.router.navigate(["/dashboard"]);
    // window.localStorage[USER_ROLE_STORAGE_KEY] = obj.roleId == 1 ? 'admin' : obj.roleId == 2 ? 'company_admin' : 'company_user';
    // window.localStorage[USER_WRITE_PERMISSION] = obj.permissionId == 1 ? true : false;
    // window.localStorage[DM_PREFIX + TOKEN_NAME] = obj.token;
    // window.localStorage[USER_STORAGE_KEY] = obj.userName;
    // this.router.navigate(["/dashboard"]);
  }

  getAccessToken() {
    return JSON.parse(window.localStorage[AUTP_USER]).authToken
  }

  deleteLocalStorage(redirect: boolean = true, unauthorized: boolean = false) {
    window.localStorage.clear();
    this.dialogRef.closeAll()
    if (redirect === true) {
      window.location.href = environment.portalUrl + "/logout";
    } else if(unauthorized === true) {
      setTimeout(() => {
        window.location.href = environment.portalUrl;
      }, 1000);
    }
  }

  hasWriteAccess(): boolean {
    if (window.localStorage[AUTP_USER]) {
      const result = JSON.parse(window.localStorage[AUTP_USER]).Permission_Id == 1 ? true : false;
      return result;
    }
  }

  getUserPermissions(): Array<any> {
    if (window.localStorage[AUTP_USER]) {
      if (JSON.parse(window.localStorage[AUTP_USER]).permissions) {
        let permissions = JSON.parse(window.localStorage[AUTP_USER]).permissions;
        if (permissions) {
          return permissions
        } else {
          return []
        }
      }
      else {
        return [];
      }
    }
  }

  getAutoAccept(autoCheck: boolean = false) {
    if (window.localStorage[AUTP_USER]) {
      if (JSON.parse(window.localStorage[AUTP_USER]).auto_accept) {
        return JSON.parse(window.localStorage[AUTP_USER]).auto_accept;
      }
    }
    return false;
  }

  public checkAutoAccept(per: string = "") {
    let isAutoAccept = this.getAutoAccept()
    if (isAutoAccept == 1) {
      return true
    }
    return false;
  }

  getUserRole() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Role_Id;
    }
  }

  getUserId() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).User_Id;
    }
  }

  getCompanyId() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Company_Id;
    }
  }

  getUserEmail() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Email;
    }
  }

  getUserName() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Name;
    }
  }

  getUserCompanyName() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Company_Name;
    }
  }

  getNameLetter() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).Name.substring(0, 1);
    }
  }

  getUserPhone() {
    if (window.localStorage[AUTP_USER]) {
      return JSON.parse(window.localStorage[AUTP_USER]).PhoneNo;
    }
  }

  getProfileImage() {
    if (window.localStorage[AUTP_USER]) {
      if (JSON.parse(window.localStorage[AUTP_USER]).Profile_Photo) {
        let photo = JSON.parse(window.localStorage[AUTP_USER]).Profile_Photo;
        if (photo) {
          return photo
        } else {
          return ''
        }
      }
      else {
        return '';
      }
    }
  }

  getCompanyImage() {
    if (window.localStorage[AUTP_USER]) {
      if (JSON.parse(window.localStorage[AUTP_USER]).Company_Photo) {
        let photo = JSON.parse(window.localStorage[AUTP_USER]).Company_Photo;
        if (photo) {
          return photo
        } else {
          return ''
        }
      }
      else {
        return '';
      }
    }
  }

  getProfileThumbImage() {
    if (window.localStorage[AUTP_USER]) {
      if (JSON.parse(window.localStorage[AUTP_USER]).thumbnailUrl) {
        return JSON.parse(window.localStorage[AUTP_USER]).thumbnailUrl;
      }
      else {
        return '';
      }
    }
  }

  updateUserDetails(name, email, phone, photo, thumb) {
    if (window.localStorage[AUTP_USER]) {
      let authObject = JSON.parse(window.localStorage[AUTP_USER]);
      authObject['Name'] = name;
      authObject['Email'] = email;
      authObject['PhoneNo'] = phone;
      authObject['Profile_Photo'] = photo;
      authObject['thumbnailUrl'] = thumb
      window.localStorage[AUTP_USER] = JSON.stringify(authObject);
    }
  }
}
