import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { HighChartService } from 'src/app/services/highcharts.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'app-compare-vehicles',
  templateUrl: './compare-vehicles.component.html',
  styleUrls: ['./compare-vehicles.component.scss']
})

export class CompareVehiclesComponent implements OnInit {

  @ViewChild('compareChart', { static: true }) public compareChart: ElementRef;
  public recordLength: number = 0;
  elData = []
  toggleType = 'dollar'
  constructor(
    public bottomSheetRef: MatBottomSheetRef,
    private highChartService: HighChartService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private roleCheckService: RoleCheckerService
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.elData = this.data
    this.recordLength = this.data.length;
    //this.prepareChart(this.elData); 
    this.toggleDollarPer()
  }

  toggleDollarPer(type = 'dollar') {
    this.toggleType = type
    this.prepareChart(this.elData, type)
  }

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  prepareChart(data, type = 'dollar') {
    //console.log(data, 'data')
    let self = this;
    let chartOptions = {
      chart: {
        width: 850, // Set the width in pixels
      },
      xAxis: {
        categories: ['12 Months', '24 Months', '36 Months', '48 Months', '60 Months'],
      },
      title: {
        text: '',
      },
      subtitle: {
        text: ''
      },
      yAxis: {
        title: {
          text: 'RV Values'
        },
        labels: {
          formatter: function () {
            if (type == 'dollar') {
              return self.formatter.format(this.value);
            } else {
              return `${this.value}%`;
            }
          },
        },
        // min: 0,
        // max: 70000,
        // floor: 0,
        // ceiling: 70000,
        // step: 10000
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },
      credits: {
        enabled: false
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: '100%'
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }
        ]
      },
      tooltip: {
        formatter: function () {
          if (type == 'dollar') {
            return '"' + this.series.name + '"' + '<br>' + '(' + self.formatter.format(this.y) + ')';
          } else {
            return '"' + this.series.name + '"' + '<br>' + '(' + (this.y) + '%)';
          }
          // return 'Series ' + '"' + this.series.name + '"' + ' Point ' + '"' + self.formatMonth(this.x) + '"' + '<br>' + '(' + self.formatMonth(this.x) + ', ' + self.formatter.format(this.y) + ')';
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      series: [],
    };

    if (this.roleCheckService.isSuperAdmin()) {
      for (let i = 0; i < data.length; i++) {
        const dollarKeys = Object.keys(data[i].diValues.dollarValue);
        let carRvValues = [];
        for (let j = 0; j < dollarKeys.length; j++) {
          if (type == 'dollar') {
            carRvValues.push(parseInt(data[i].diValues.dollarValue[dollarKeys[j]].formatted.replace(",", "")));
          } else {
            carRvValues.push(data[i].diValues.percentageValue[dollarKeys[j]].formatted);
          }
        }
        //console.log(carRvValues, 'carRvValues')
        chartOptions.series.push({
          name: data[i].vehicleDetails.make + ' ' + data[i].vehicleDetails.model + ' ' + data[i].vehicleDetails.description,
          data: carRvValues
        });
      }
      //console.log(chartOptions.series, 'chartOptions.series2')
      if (chartOptions.series.length > 0) {
        if (chartOptions.series[0].data && chartOptions.series[0].data.length) {
          this.highChartService.createChart(this.compareChart.nativeElement, chartOptions);
        }
      }
    }
    else {
      for (let i = 0; i < data.length; i++) {
        const dollarKeys = Object.keys(data[i].rvValues.dollarValue);
        let carRvValues = [];
        for (let j = 0; j < dollarKeys.length; j++) {
          if (type == 'dollar') {
            carRvValues.push(parseInt(data[i].rvValues.dollarValue[dollarKeys[j]].formatted.replace(",", "")));
          } else {
            carRvValues.push(data[i].rvValues.percentageValue[dollarKeys[j]].formatted);
          }
        }
        //console.log(carRvValues, 'carRvValues')
        chartOptions.series.push({
          name: data[i].vehicleDetails.make + ' ' + data[i].vehicleDetails.model + ' ' + data[i].vehicleDetails.description,
          data: carRvValues
        });
      }
      //console.log(chartOptions.series, 'chartOptions.series1')
      if (chartOptions.series.length > 0) {
        if (chartOptions.series[0].data && chartOptions.series[0].data.length) {
          this.highChartService.createChart(this.compareChart.nativeElement, chartOptions);
        }
      }
    }
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })

  formatMonth(data) {
    return parseInt(data.split(" ")[0]);
  }
}
