import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { Subscription } from 'rxjs';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vehicle-linkage',
  templateUrl: './vehicle-linkage.component.html',
  styleUrls: ['./vehicle-linkage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleLinkageComponent implements OnInit {

  childV
  parentV
  vSubscription: Subscription;
  treeDetails;
  isLoading: boolean = false
  constructor(
    public dialogObjRef: MatDialogRef<VehicleLinkageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public roleCheckService: RoleCheckerService,
    private vehicleService: VehicleService,
    private commonService: CommonService
  ) {
    this.childV = data['childV']
    this.parentV = data['parentV']
    //console.log(this.childV, this.parentV)
  }

  ngOnInit() {
    this.getLinkedTreeVehicleDetail()
  }

  getLinkedTreeVehicleDetail() {
    this.isLoading = true;
    this.vSubscription = this.vehicleService.getLinkedTreeVehicleDetail(this.childV['DatiumAssetKey'], this.parentV['DatiumAssetKey']).subscribe((result: any) => {
      try {
        const response = result;
        this.treeDetails = response.data;
      } catch (e) {

      }
    },
      (error) => {
        this.isLoading = false
        let response = error;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isLoading = false
      })
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: UntypedFormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
  }

}
