/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.SelCompareVehicleComponent
* @description
* This component is used to handling the compare vehicle chart
* */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataStoreService } from '../../../services/data-store.service';
import { Subscription } from 'rxjs';
import { CompareVehiclesComponent } from '../compare-vehicles/compare-vehicles.component';
import { CommonService } from '../../../services/common.service';
import { GaEventsService } from '../../../services/ga-events.service';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-sel-compare-vehicle',
  templateUrl: './sel-compare-vehicle.component.html',
  styleUrls: ['./sel-compare-vehicle.component.scss']
})
export class SelCompareVehicleComponent implements OnInit, OnDestroy {

  selectCarForCompare: Array<any> = [];
  getSelectedCarDataSignal: Subscription;
  isSuperAdmin: boolean = false;
  isCompanyUser: boolean = false;
  constructor(public dialog: MatDialog,
    public dataStoreService: DataStoreService,
    public commonService: CommonService,
    private bottomSheet: MatBottomSheet,
    private gaEvent: GaEventsService,
    private roleCheckService: RoleCheckerService,
    private vehicleService: VehicleService
  ) {
    this.getSelectedCarDataSignal = this.dataStoreService.getSelectedCarData().subscribe(data => {
      //console.log(data, 'data')
      if (data['vehicle']) {
        this.toggleCarSelection(data.vehicle);
      } else if (data['clear']) {
        this.selectCarForCompare = [];
      }
    })
  }

  ngOnInit() {
    //this.commonService.clearCarSelectionLength()
    if (this.roleCheckService.isLegalese() || this.roleCheckService.isSuperAdmin()) {
      this.isSuperAdmin = true;
    } else if (this.roleCheckService.isCompanyOwner() || this.roleCheckService.isCompanyAdmin() || this.roleCheckService.isCompanyUser()) {
      this.isCompanyUser = true;
    }
  }

  toggleCarSelection(vehicle): void {
    if (vehicle) {
      //console.log(this.selectCarForCompare.length)
      /*if (this.selectCarForCompare.length >= 10) {
        this.commonService.showSnakeBar("Maximum limit of selection reached");
      }
      else {*/
      const index = this.selectCarForCompare.findIndex(obj => obj.DatiumAssetKey == vehicle.DatiumAssetKey);
      if (index != -1) {
        this.selectCarForCompare.splice(index, 1);
      }
      else {
        this.selectCarForCompare.push(vehicle);
      }
      this.commonService.setCarSelectionLength(this.selectCarForCompare.length)
      //}
    }
  }

  /*
  * @name compareVehicles
  * @param
  * @description Open compare vehicle popup
  * @return none
  */
  compareVehicles(): void {
    let dtKeys = []
    this.selectCarForCompare.forEach(el => {
      dtKeys.push(el.DatiumAssetKey)
    })
    let gaData = {
      'event': 'compareVehicle',
      'value': dtKeys.join(', ')
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    this.bottomSheet.open(CompareVehiclesComponent, { data: this.selectCarForCompare });
  }

  isExporting: boolean = false;
  exportVehicles() {
    this.isExporting = true;
    this.vehicleService.exportIndex({ vehicles: this.selectCarForCompare }).subscribe(
      (response: any) => {
        this.isExporting = false;
        try {
          //console.log(response, 'response', response._body);
          console.log("response", response);
          let filename = this.isSuperAdmin ? 'Datium.xlsx' : 'RV.xlsx';
          var blob = new Blob([response], { type: 'application/xlsx' });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, filename);
          }
          else {
            var link = document.createElement("a");
            // Browsers that support HTML5 download attribute
            if (link.download !== undefined) {
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        } catch (e) {
          console.log(e, 'error');
        }
      },
      (error) => {
        this.isExporting = false;
        if (error.message) {
          this.commonService.showSnakeBar(error.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => { }
    );
  }

  ngOnDestroy() {
    if (this.getSelectedCarDataSignal) {
      this.getSelectedCarDataSignal.unsubscribe()
    }
  }
}
