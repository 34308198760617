<app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'100px'}"></app-loader>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="products.length > 0;else noOffer">
    <mat-card *ngFor="let product of products" class="mb-3">
      <div class="row">
        <div class="col-md-10">
          <mat-card-title>{{product.PlanName}}</mat-card-title>
        </div>
        <div class="col-md-2">
          <button mat-button type="button" (click)="offerAction(product)" class="btn btn-primary"
            [disabled]="loadingSub">
            Add Offer</button>
        </div>
      </div>
    </mat-card>
  </ng-container>
</ng-container>
<ng-template #noOffer>
  <div>No offer found for this company</div>
</ng-template>
