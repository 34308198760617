<div class="dashboard-widget vehicles-list-wrapper">

  <div class="widget-header">
    <h2 *ngIf="listType == 'recentlySearched' && hasQuickSearchAccess">Vehicles recently searched</h2>
    <h2 *ngIf="listType == 'newVehicles' && hasNewVehicleListAccess">New vehicles added</h2>
    <mat-radio-group *ngIf="listType == 'recentlySearched' &&  hasQuickSearchAccess">
      <mat-radio-button value="today" checked="true" [attr.checked]="vehicleSearchType=='today' ? true: null"
        (click)="getRecentSeachList('today')">Today</mat-radio-button>
      <mat-radio-button value="week" [attr.checked]="vehicleSearchType=='week' ? true: null"
        (click)="getRecentSeachList('week')">This Week</mat-radio-button>
      <mat-radio-button value="month" [attr.checked]="vehicleSearchType=='month' ? true: null"
        (click)="getRecentSeachList('month')">This Month</mat-radio-button>
    </mat-radio-group>
  </div>

  <ng-container *ngFor="let number of [0,1,2,3,4,5]">
    <app-loader *ngIf="listType == 'newVehicles' && hasNewVehicleListAccess" [ShowLoader]="isNewVehLoading"
      [type]="'vehicle-list'" [CStyle]="{'min-height':'52px'}"></app-loader>
  </ng-container>
  <div *ngFor="let vehicle of newVehiclesData" class="new-vehicle-tab bg-white shadow-sm p-3">
    <div class="row">
      <div class="col-md-8">
        <div class="d-flex align-items-center">
          <mat-chip-list class="me-3">
            <mat-chip color="primary" class="pointer" selected matTooltip="Click to copy Datium Asset Key" ngxClipboard
              [cbContent]="vehicle.DatiumAssetKey" *ngxClipboardIfSupported
              (cbOnSuccess)="copiedMsg('Copied to clipboard')">{{vehicle.DatiumAssetKey}}</mat-chip>
          </mat-chip-list>
          <div class="brand_modal">
            {{vehicle.vehicleDetails.make}} <div class="dot"></div> {{vehicle.vehicleDetails.model}} <div class="dot">
            </div>
            {{vehicle.vehicleDetails.year_group}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="added-on">Added on {{vehicle.vehicleDetails.created_at | date:appSettings.COMMON_DATE_FORMAT}}</div>
      </div>
      <div class="col-md-12">
        <p class="mt-2" *ngIf="vehicle.Description">{{vehicle.description}}</p>
      </div>
    </div>

  </div>
  <div class="mt-4"
    *ngIf="listType == 'newVehicles' && !isNewVehLoading && newVehiclesData.length && hasNewVehicleListAccess">
    <button mat-flat-button color="primary" routerLink="/new-vehicles">View All</button>
  </div>
  <no-record-found
    *ngIf="listType == 'newVehicles' && !isNewVehLoading && !newVehiclesData.length && hasNewVehicleListAccess"
    [notFoundHeading]="'No record(s) found'" [showImg]="false" [cStyle]="{'height':'100px'}"></no-record-found>

  <ng-container *ngFor="let number of [0,1,2,3,4]">
    <app-loader *ngIf="listType == 'recentlySearched'" [ShowLoader]="isSearchVehLoading" [type]="'vehicle-list'"
      [CStyle]="{'min-height':'60px'}"></app-loader>
  </ng-container>
  <div *ngIf="searchedVehiclesData.length && !isSearchVehLoading && hasQuickSearchAccess">
    <div *ngFor="let vehicle of searchedVehiclesData" class="new-vehicle-tab bg-white shadow-sm p-3">
      <div class="row">
        <div class="col-md-8">
          <div class="d-flex align-items-center">
            <mat-chip-list class="me-3">
              <mat-chip color="primary" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                [cbContent]="vehicle.DatiumAssetKey" *ngxClipboardIfSupported
                (cbOnSuccess)="copiedMsg('Copied to clipboard')" class="pointer"
                selected>{{vehicle.DatiumAssetKey}}</mat-chip>
            </mat-chip-list>
            <div class="brand_modal">
              {{vehicle.Make}} <div class="dot"></div> {{vehicle.Family}} <div class="dot"></div>
              {{vehicle.Year_Group}}
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="added-on">Added on {{vehicle.created_at | date : 'dd-MM-yyyy'}}</div>
        </div>
        <div class="col-md-12">
          <p class="mt-2" *ngIf="vehicle.Description">{{vehicle.Description}}</p>
        </div>
      </div>



    </div>
  </div>

  <no-record-found
    *ngIf="listType == 'recentlySearched' && !isSearchVehLoading && !searchedVehiclesData.length && hasQuickSearchAccess"
    [notFoundHeading]="'No record(s) found'" [showImg]="false" [cStyle]="{'height':'100px'}"></no-record-found>

</div>