<div class="vehicle_listing_table new-vehicles">
  <ul class="table_heading" *ngIf="!isLoadingList && vehicleList.length > 0">
    <li>
      <div class="row align-items-center">
        <div class="col col-sm-3 col-lg-4 table_left_info">
          <div class="d-flex align-items-center">
            <div class="select_col thbox">
              <!-- <mat-checkbox></mat-checkbox> -->
            </div>
            <div class="thbox vehicle_details">Vehicle Details</div>
            <div class="thbox units pointer" (click)="orderBy('units',(sort == 'asc' ? 'desc':'asc'))">Units
              <svg width="7px" height="11px" class="ml-2">
                <path fill-rule="evenodd" fill="rgb(143, 143, 151)"
                  d="M3.500,0.500 L6.500,4.500 L0.500,4.500 L3.500,0.500 ZM3.500,10.500 L0.500,6.500 L6.500,6.500 L3.500,10.500 Z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="col col-sm-9 col-lg-8 table_right_info">
          <div class="d-flex align-items-center">
            <div class="thbox">&nbsp;</div>
            <div class="thbox">
              <div class="thbox units pointer" (click)="orderBy('new_price',(sort == 'asc' ? 'desc':'asc'))">New Price
                <svg width="7px" height="11px" class="ml-2">
                  <path fill-rule="evenodd" fill="rgb(143, 143, 151)"
                    d="M3.500,0.500 L6.500,4.500 L0.500,4.500 L3.500,0.500 ZM3.500,10.500 L0.500,6.500 L6.500,6.500 L3.500,10.500 Z" />
                </svg>
              </div>
            </div>
            <div class="thbox">12M <br> (30,000KM)</div>
            <div class="thbox">24M <br> (60,000KM)</div>
            <div class="thbox">36M <br> (90,000KM)</div>
            <div class="thbox">48M <br> (120,000KM)</div>
            <div class="thbox">60M <br> (150,000KM)</div>
            <div class="thbox actions_heading">&nbsp;</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0" [notFoundHeading]="notFoundHeading"
    [cStyle]="{'height':'300px'}" [notFoundDescription]="notFoundDescription"></no-record-found>
  <ul class="table_body" *ngIf="isLoadingList">
    <vehicle-list-loading></vehicle-list-loading>
  </ul>
  <ul class="table_body" *ngIf="vehicleList.length > 0 && !isLoadingList">
    <ng-container *ngFor="let vehicleObj of vehicleList">
      <new-vehicle-detail-wrapper [vehicle]="vehicleObj" [vehicleId]="vehicleObj?.DatiumAssetKey"
        [ngStyle]="{'display': vehicleObj.DatiumAssetKey == loaderDat ? 'none':'block'}"></new-vehicle-detail-wrapper>
      <ul class="table_body" *ngIf="vehicleObj.DatiumAssetKey == loaderDat">
        <vehicle-list-loading></vehicle-list-loading>
      </ul>
    </ng-container>
    <div class="d-flex justify-content-center p-4" *ngIf="!noMoreRec && !isPaging && total > 10 && !isLoadingList">
      <button class="btn btn-primary" (click)="onScroll()" mat-button>Load More</button>
    </div>
    <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
    <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
      [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  </ul>
</div>