<div class="mb-4">
  <page-title [title]="pageTitle"></page-title>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="vehicle_list_filter d-flex align-items-center flex-wrap">
      <ng-container>
        <div class="di-input-wrapper">
          <label class="custom-label">Choose Basket</label>
          <mat-form-field appearance="fill" floatLabel="never">
            <mat-select [placeholder]="'Select'" [(ngModel)]="basketId"
              (selectionChange)="basketSelection($event.value)">
              <mat-option *ngFor="let basket of baskets" [value]="basket.BasketId">{{basket.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="filter_component">
          <mat-form-field class="button_type">
            <mat-select [placeholder]="'Choose Type'" [(ngModel)]="type"
              (selectionChange)="basketSelection($event.value)">
              <mat-option *ngFor="let type of types" [value]="type.key">{{type.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </ng-container>
    </div>
  </div>
  <div class="col-md-6 d-flex justify-content-end">
    <div class="di-input-wrapper">
      <label class="custom-label">&nbsp;</label>
      <button (click)="addNewBasket()" [disabled]="basketLoader" mat-flat-button color="primary" class="ms-3">Add New
        Basket</button>
    </div>
  </div>
  <div class="col-md-12" *ngIf="selectedBasket">
    <div class="di-step-wrapper p-4">
      <div class="d-flex basket-name align-items-center mb-2">
        <h2 class="me-3 mb-0">{{selectedBasket.name}}</h2>
        <a href="javascript:;" (click)="updateBasket()">
          <span class="material-symbols-outlined">edit</span>
        </a>
        <a href="javascript:;" (click)="deleteBasket()" class="ms-2">
          <span class="material-symbols-outlined red">cancel</span>
        </a>
      </div>
      <p>{{selectedBasket.description}}</p>
    </div>
  </div>
</div>
<div class="row" *ngIf="!basketLoader && baskets.length <= 0">
  <div class="col-md-12">
    <div class="pt-5">
      <no-record-found [notFoundHeading]="'No Basket has been added.'" [cStyle]="{'height':'100px'}"></no-record-found>
    </div>
  </div>
</div>
<app-loader [ShowLoader]="basketListLoader || basketLoader" [CStyle]="{'min-height':'100px'}"></app-loader>
<app-bi-vehicle-list [routeName]="'/basket'" [vList]="basketVehicleList"
  *ngIf="!basketListLoader && !basketLoader && baskets.length > 0" [isBasket]="true">
</app-bi-vehicle-list>