import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { JwtService } from 'src/app/services/jwt.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AppSettings } from 'src/app/configs/app-settings';

@Component({
  selector: 'app-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehiclesListComponent implements OnInit {
  public isNewVehLoading: boolean = false;
  public isSearchVehLoading: boolean = false;
  @Input() listType: string;
  public newVehiclesData: Array<any> = [];
  public searchedVehiclesData: Array<any> = [];
  public vehicleSearchType: string = "today";

  public hasNewVehicleListAccess: boolean = false;
  public hasQuickSearchAccess: boolean = false;
  constructor(
    private vehicleService: VehicleService,
    private roleCheckService: RoleCheckerService,
    private jwtService: JwtService,
    private commonService: CommonService,
    private appSettings: AppSettings
  ) {
    this.hasNewVehicleListAccess = (this.roleCheckService.isSuperAdmin() || (this.roleCheckService.isCompanyOwner() && this.roleCheckService.checkAutoAccept() == false ? true : false));
    let permissions: Array<any> = this.jwtService.getUserPermissions();
    if (permissions.includes('quick-search')) {
      this.hasQuickSearchAccess = true;
    }
  }

  ngOnInit() {
    if (this.listType == "newVehicles") {
      if (this.hasNewVehicleListAccess)
        this.getNewVehiclesList();
    } else {
      if (this.hasQuickSearchAccess)
        this.getRecentSeachList();
    }
  }

  getNewVehiclesList() {
    let apiParams = {
      page: 1,
      limit: 10,
      sort: "asc",
      sortBy: "new_price"
    }
    this.isNewVehLoading = true;
    this.vehicleService.getNewVehicleListing(apiParams).subscribe((result: any) => {
      let response = result;
      if (response.status == 200) {
        let vehicleData = response.data;
        this.newVehiclesData = vehicleData.records;
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isNewVehLoading = false;
    },
      (error) => {
        this.isSearchVehLoading = false;
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      });
  }


  getRecentSeachList(searchType: string = "today") {
    this.vehicleSearchType = searchType;
    let type = 0; //type=0 means latest searches, 1 means popular searches
    let params = { type: type, searchType: searchType }
    this.isSearchVehLoading = true;

    this.vehicleService.getVehicleSearches(params).subscribe(
      (result: any) => {
        let response = result;
        if (response.status == 200) {
          if (response.data.length) {
            this.searchedVehiclesData = response.data;
          } else {
            this.searchedVehiclesData = [];
          }
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isSearchVehLoading = false;
      },
      (error) => {
        this.isSearchVehLoading = false;
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    );

  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
