<h2 mat-dialog-title>
  <button mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>Api Key
</h2>
<mat-dialog-content class="mat-typography insta_api_key">
  <div class="row">
    <div class="col col-lg-12 mb-3">
      <h2>{{companyDetails.CompanyName}}</h2>
    </div>
    <div class="col col-lg-12">
      <div class="d-flex align-items-center">
        <mat-form-field [floatLabel]="'never'" class="bordered">
          <label>API Key</label>
          <input matInput [value]="companyDetails.ApiKey" #inAPKey readonly>
        </mat-form-field>
        <button style="padding: 8px 20px !important; position: relative; top: 2px;" [ngxClipboard]="inAPKey"
          class="btn btn-primary" mat-stroked-button *ngxClipboardIfSupported
          (cbOnSuccess)="copiedMsg('API Key')">Copy</button>
      </div>
    </div>
    <div class="col col-lg-12">
      <div class="d-flex align-items-center">
        <mat-form-field [floatLabel]="'never'" class="bordered">
          <label>API Secret</label>
          <input matInput [value]="companyDetails.InstantValV2ApiSecret" #inAPSecret readonly>
        </mat-form-field>
        <button style="padding: 8px 20px !important; position: relative; top: 2px;" [ngxClipboard]="inAPSecret"
          class="btn btn-primary" mat-stroked-button *ngxClipboardIfSupported
          (cbOnSuccess)="copiedMsg('API Secret')">Copy</button>
      </div>
    </div>
  </div>
</mat-dialog-content>