<div class="mb-4">
  <page-title [title]="'Help & Support'"></page-title>
</div>

<div class="row">
  <div class="col col-12 col-sm-12 col-md-4 col-xl-3">
    <!-- <help-sidebar></help-sidebar> -->

    <div class="help-support-sidebar">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('administration')"
            [ngClass]="{'active':visibleTab == 'administration'}">
            Administration
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('newVehicles')"
            [ngClass]="{'active':visibleTab == 'newVehicles'}">
            New Vehicles
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('autopredictNew')"
            [ngClass]="{'active':visibleTab == 'autopredictNew'}">
            Current Releases
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('riskAdjustments')"
            [ngClass]="{'active':visibleTab == 'riskAdjustments'}">
            Risk Adjustments
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('linkingTree')"
            [ngClass]="{'active':visibleTab == 'linkingTree'}">
            Linking Tree
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('rvCalculator')"
            [ngClass]="{'active':visibleTab == 'rvCalculator'}">
            RV Calculator
          </a>
        </li>
        <li class="nav-item" *ngIf="!isSuperAdmin">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('faq')"
            [ngClass]="{'active':visibleTab == 'faq'}">
            FAQ
          </a>
        </li>
        <li class="nav-item" *ngIf="!isSuperAdmin">
          <a class="nav-link d-flex align-items-center" (click)="toggleTopics('contactUs')"
            [ngClass]="{'active':visibleTab == 'contactUs'}">
            Contact Us
          </a>
        </li>
      </ul>
    </div>

  </div>
  <div class="col col-12 col-sm-12 col-md-8 col-xl-9">

    <administration *ngIf="visibleTab == 'administration'"></administration>

    <new-vehicles *ngIf="visibleTab == 'newVehicles'"></new-vehicles>

    <autopredict-new *ngIf="visibleTab == 'autopredictNew'"></autopredict-new>

    <risk-adjustments *ngIf="visibleTab == 'riskAdjustments'"></risk-adjustments>

    <linking-tree *ngIf="visibleTab == 'linkingTree'"></linking-tree>

    <rv-calculator *ngIf="visibleTab == 'rvCalculator'"></rv-calculator>

    <faq *ngIf="visibleTab == 'faq' && !isSuperAdmin"></faq>

    <contact-us *ngIf="visibleTab == 'contactUs' && !isSuperAdmin"></contact-us>

  </div>
</div>