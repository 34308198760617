import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RvValuesComponent } from '../calculator/rv-values/rv-values.component';
import { RoleCheckerService } from '../../services/role-checker.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { CommonService } from '../../services/common.service';
import { GaEventsService } from '../../services/ga-events.service';

@Component({
  selector: 'vehicle-brief',
  templateUrl: './vehicle-brief.component.html',
  styleUrls: ['./vehicle-brief.component.scss']
})
export class VehicleBriefComponent implements OnInit {

  @Input() name: string;
  @Input() isLinked: boolean;
  @Input() linkedId: string;
  @Input() linkVehicle: boolean;
  @Input() rvIncluded: boolean;
  @Input() isRecommended: boolean;
  @Input() isSuperAdmin: boolean;
  @Input() type: string;
  @Input('vehicleDetails') vehicle;
  @Input('parentVehicle') parentVehicle;
  @Input() rvHidden: boolean;
  @Input() removingThis: string;
  @Input() addingThis: string;
  @Input() addedThese: any[];
  @Output() addRecom: EventEmitter<any> = new EventEmitter();
  @Output() removeRecom: EventEmitter<any> = new EventEmitter();

  @Output() changeVehicle = new EventEmitter();
  @Output() captureValues = new EventEmitter();

  datiumLoader = null

  confirmDialogRef: MatDialog
  constructor(
    public dialog: MatDialog,
    //public dialogObjRef: MatDialogRef<LinkVehicleComponent>,
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    private gaEvent: GaEventsService
  ) { }

  ngOnInit() {
    //console.log(this.linkedId, this.linkVehicle)
  }


  captureCalValues(DatiumAssetKey) {
    this.captureValues.emit(DatiumAssetKey)
  }

  openRVCal(vehicle): void {
    let gaData = {
      'event': 'valueCalculator',
      'value': vehicle.DatiumAssetKey
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(RvValuesComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        vehicle: vehicle
      }
    });

    // dialogRef.afterClosed().subscribe((result : any) => {
    //   console.log('The dialog was closed');
    // });
  }

  showRvDi() {
    if (this.type == 'list' || this.type == 'linking' || this.type == 'recommended') {
      return false;
    } else {
      if (this.rvIncluded) {
        return true;
      } else {
        return false;
      }
    }
  }

  selectedLink(DatiumAssetKey) {
    //console.log(this.parentVehicle, 'this.parentVehicle')
    let confirmTitle = 'Are you sure you want to add the linking?';
    if (typeof (this.parentVehicle.linkedVehicle) != null && typeof (this.parentVehicle.linkedVehicle) != "undefined") {
      confirmTitle = 'Are you sure you want to change the linking?';
    }
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: confirmTitle
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      //console.log('result', result, DatiumAssetKey, 'parent vehicle', this.parentVehicle)
      if (result == 'yes') {
        //this.datiumLoader = DatiumAssetKey
        let parentKey = null;
        if (this.parentVehicle['DatiumAssetKey'] != undefined) {
          parentKey = this.parentVehicle['DatiumAssetKey']
        }
        else if (this.parentVehicle['vehicleId'] != undefined) {
          parentKey = this.parentVehicle['vehicleId']
        } else if (this.parentVehicle['vehicle'] != undefined) {
          if (this.parentVehicle['vehicle']['DatiumAssetKey'] != undefined) {
            parentKey = this.parentVehicle['vehicle']['DatiumAssetKey']
          }
        }
        if (parentKey != null) {
          this.addUpdateLinking(parentKey, DatiumAssetKey)
        } else {
          this.commonService.showSnakeBar('Parent vehicle is not found. Please try again later.')
        }
      } else {
        // do nothing
      }
    });
  }

  // emit signal to parent
  // close current popup and send signal to update the vehicle
  addUpdateLinking(child, parentKey) {
    this.changeVehicle.emit({
      child: child, parent: parentKey
    })
  }

  removeRecommendation(vehicle, event) {
    if (!this.removingThis) {
      this.removeRecom.emit(vehicle);
    }
    else {
      event.preventDefault();
      this.commonService.showSnakeBar("Removing in progress");
    }
  }

  addRecommendation(vehicle, event) {
    if (!this.addingThis) {
      this.addRecom.emit(vehicle);
    }
    else {
      event.preventDefault();
      this.commonService.showSnakeBar("Adding in progress");
    }
  }

  getDisabledStatus(allKeys) {
    for (let i = 0; i < allKeys.length; i++) {
      if (allKeys[i] == this.vehicle.DatiumAssetKey) {
        return true;
      }
    }
    return false;
  }

  copiedMsg(param) {
    this.commonService.showSnakeBar(param);
  }

}
