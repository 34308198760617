<div class="di-mobile-menu-wrapper">

    <div class="mobile-menu-data">

        <div class="profile-header-wrapper">

            <div class="user-profile">
                <div class="profile-image">
                    <div class="name-tag">SH</div>
                </div>
                <div class="user-details">
                    <h4>Sean Ho</h4>
                    <!-- <p>sean.ho&#64;@datiuminsights.com</p> -->
                    <div class="edit-logout-links">
                        <a href="">Edit Profile</a>
                        <a href="">Log Out</a>
                    </div>
                </div><!-- .user-details ends here -->
                <button class="closeMenu" (click)="closeMobileMenu()"><span
                        class="material-symbols-outlined">close</span></button>
            </div>

            <products-dropdown></products-dropdown>

        </div>

        <app-menu (click)="closeMobileMenu()"></app-menu>

    </div>

    <div class="mobile-menu-bg" (click)="closeMobileMenu()"></div>


</div>