<ng-container *ngIf="isError">
  {{errorMsg}}
</ng-container>
<ng-container *ngIf="!isError">
  <form [formGroup]="companyProductForm" autocomplete="false" novalidate #formCont *ngIf="!loadingPer">
    <h2 class="mb-3" *ngIf="!fromRenew">Add Subscription</h2>
    <input type="hidden" formControlName="Plan_Id">
    <input type="hidden" formControlName="companyId">
    <ng-container formGroupName="Products">
      <mat-accordion [multi]="true">
        <ng-container *ngFor="let Product of offerDetails['Products']">
          <mat-expansion-panel [formGroupName]="Product.ProductName" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="row" (click)="$event.stopPropagation();">
                  <div class="col-md-12">
                    {{Product.ProductName}}
                    <input type="hidden" formControlName="isChecked">
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="Product.ProductName=='AutoPredict'" class="row">
              <input type="hidden" formControlName="Product_Id">
              <input type="hidden" formControlName="CompanySubscription_Id">
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalUsers">Total Users</label>
                  <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                  <mat-error *ngIf="checkControlError('Products.AutoPredict.TotalUsers',productForm)" class="mb-3">
                    <span>
                      Please enter total number of users.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6" *ngIf="getControl('Products.AutoPredict.CompanySubscription_Id').value ==null">
                <label for="Makes" class="pt-3 pb-2">Choose Makes</label>
                <mat-form-field class="button_type mb-2" [floatLabel]="'never'">
                  <mat-select multiple formControlName="Makes">
                    <mat-option *ngFor="let make of makArray" [value]="make">
                      {{make}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="checkControlError('Products.AutoPredict.Makes',productForm)" class="mb-3">
                    <span>
                      Please choose at least one make.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 pb-3">
                <label class="pt-3 pb-2">Chosen Makes</label>
                <div class="pb-3">
                  <span class="text-primary">{{getMakesList()}}</span>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="d-block company_roles">
                  <label class="col-md-12 pl-0 pb-3">Choose Permission</label>
                  <div class="row">
                    <label formArrayName="Permission_Id"
                      *ngFor="let genre of getControl('Products.AutoPredict.Permission_Id').controls; let i = index"
                      class="col-md-6 pb-2 mt-0">
                      <mat-checkbox [formControlName]="i" class="mt-0">{{permArray[i]['label']}}</mat-checkbox>
                    </label>
                    <div class="col-md-12 pt-3 pb-3"
                      *ngIf="checkControlError('Products.AutoPredict.Permission_Id',productForm)">
                      <mat-error>
                        <span>Please choose at least one permission.</span>
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.AutoPredict.StartDate').value"
                    [matDatepicker]="autp_StartDate" placeholder="Start Date" formControlName="StartDate">
                  <mat-datepicker-toggle matSuffix [for]="autp_StartDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #autp_StartDate [startAt]="startDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.AutoPredict.StartDate',companyProductForm)"
                    class="mb-3">
                    <span>
                      Please choose start date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.AutoPredict.StartDate').value"
                    [matDatepicker]="autp_EndDate" placeholder="End Date" formControlName="EndDate">
                  <mat-datepicker-toggle matSuffix [for]="autp_EndDate"></mat-datepicker-toggle>
                  <mat-datepicker #autp_EndDate [startAt]="endDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.AutoPredict.EndDate',companyProductForm)" class="mb-3">
                    <span>
                      Please choose end date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="Product.ProductName=='InstantValuation'" class="row">
              <input type="hidden" formControlName="Product_Id">
              <input type="hidden" formControlName="CompanySubscription_Id">
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalDays">Total Users</label>
                  <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                  <mat-error *ngIf="checkControlError('Products.InstantValuation.TotalUsers',productForm)" class="mb-3">
                    <span>
                      Please enter total number of users.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalRequests">Total Requests</label>
                  <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                  <mat-error *ngIf="checkControlError('Products.InstantValuation.TotalRequests',productForm)"
                    class="mb-3">
                    <span>
                      Please enter total number of requests.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.InstantValuation.StartDate').value"
                    [matDatepicker]="iv_StartDate" placeholder="Start Date" formControlName="StartDate">
                  <mat-datepicker-toggle matSuffix [for]="iv_StartDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #iv_StartDate [startAt]="startDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.InstantValuation.StartDate',companyProductForm)"
                    class="mb-3">
                    <span>
                      Please choose start date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.InstantValuation.StartDate').value"
                    [matDatepicker]="iv_EndDate" placeholder="End Date" formControlName="EndDate">
                  <mat-datepicker-toggle matSuffix [for]="iv_EndDate"></mat-datepicker-toggle>
                  <mat-datepicker #iv_EndDate [startAt]="endDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.InstantValuation.EndDate',companyProductForm)"
                    class="mb-3">
                    <span>
                      Please choose end date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="Product.ProductName=='Rego'" class="row">
              <input type="hidden" formControlName="Product_Id">
              <input type="hidden" formControlName="CompanySubscription_Id">
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalUsers">Total Users</label>
                  <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                  <mat-error *ngIf="checkControlError('Products.Rego.TotalUsers',productForm)" class="mb-3">
                    <span>
                      Please enter total number of users.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalRequests">Total Requests</label>
                  <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                  <mat-error *ngIf="checkControlError('Products.Rego.TotalRequests',productForm)" class="mb-3">
                    <span>
                      Please enter total number of requests.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.Rego.StartDate').value" [matDatepicker]="Rego_StartDate"
                    placeholder="Start Date" formControlName="StartDate">
                  <mat-datepicker-toggle matSuffix [for]="Rego_StartDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #Rego_StartDate [startAt]="startDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.Rego.StartDate',companyProductForm)" class="mb-3">
                    <span>
                      Please choose start date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.Rego.StartDate').value" [matDatepicker]="Rego_EndDate"
                    placeholder="End Date" formControlName="EndDate">
                  <mat-datepicker-toggle matSuffix [for]="Rego_EndDate"></mat-datepicker-toggle>
                  <mat-datepicker #Rego_EndDate [startAt]="endDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.Rego.EndDate',companyProductForm)" class="mb-3">
                    <span>
                      Please choose end date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="Product.ProductName=='Vin'" class="row">
              <input type="hidden" formControlName="Product_Id">
              <input type="hidden" formControlName="CompanySubscription_Id">
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalUsers">Total Users</label>
                  <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                  <mat-error *ngIf="checkControlError('Products.Vin.TotalUsers',productForm)" class="mb-3">
                    <span>
                      Please enter total number of users.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field class="mb-2" [floatLabel]="'never'">
                  <label for="TotalRequests">Total Requests</label>
                  <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                  <mat-error *ngIf="checkControlError('Products.Vin.TotalRequests',productForm)" class="mb-3">
                    <span>
                      Please enter total number of requests.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.Vin.StartDate').value" [matDatepicker]="Vin_StartDate"
                    placeholder="Start Date" formControlName="StartDate">
                  <mat-datepicker-toggle matSuffix [for]="Vin_StartDate">
                  </mat-datepicker-toggle>
                  <mat-datepicker #Vin_StartDate [startAt]="startDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.Vin.StartDate',companyProductForm)" class="mb-3">
                    <span>
                      Please choose start date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field>
                  <input matInput [min]="getControl('Products.Vin.StartDate').value" [matDatepicker]="Vin_EndDate"
                    placeholder="End Date" formControlName="EndDate">
                  <mat-datepicker-toggle matSuffix [for]="Vin_EndDate"></mat-datepicker-toggle>
                  <mat-datepicker #Vin_EndDate [startAt]="endDate" disabled="false"></mat-datepicker>
                  <mat-error *ngIf="checkControlError('Products.Vin.EndDate',companyProductForm)" class="mb-3">
                    <span>
                      Please choose end date.
                    </span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </ng-container>
    <div class="row row mt-4">
      <div class="col-md-12">
        <div class="form_btns" *ngIf="!loadingPer">
          <!-- <button class="btn btn-primary btn-lg" mat-button [disabled]="isProcessing || !companyProductForm.valid"
            (click)="saveProducts(companyProductForm)" *ngIf="isEdit"
            type="submit">{{isProcessing ? 'Please Wait...' : 'Update Product'}}</button> -->
          <button class="btn btn-primary btn-lg" mat-button [disabled]="isProcessing || !companyProductForm.valid"
            (click)="saveProducts(companyProductForm)" type="submit">{{isProcessing ? 'Please Wait...' :
            'Add'}}</button>
          <button class="btn btn-outline-primary ml-3 btn-lg" mat-stroked-button (click)="closeModal()"
            [disabled]="isProcessing" type="button">Cancel</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>