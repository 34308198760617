<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [attr.disabled]="isLoading" name="Make" [formControlName]="'Make'" placeholder="Make" [multiple]="isMultiple"
    (selectionChange)="selectionChange($event.value)">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Make" [values]="MakeFilterList" (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngFor="let MakeObj of MakeFilterList" [value]="MakeObj.filter">{{MakeObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Make.invalid && (parentForm.controls.Make.dirty || parentForm.controls.Make.touched)">
    <span *ngIf="parentForm.controls.Make.errors.required || parentForm.controls.Make.errors.Make">
      Please choose Make
    </span>
    <span *ngIf="formError?.Make">{{formError.Make}}</span>
  </mat-error>
</mat-form-field>
