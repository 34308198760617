<div class="rv_values_container">
  <form #vehicleValue="ngForm">
    <div class="row">
      <div class="col-2">
        <div class="di-input-wrapper mb-4">
          <label class="custom-label">Value</label>
          <mat-form-field appearance="fill" floatLabel="never">
            <input matInput [disabled]="getDisableStatus()" required [(ngModel)]="vehicleValueModel.term" name="term">
            <span matSuffix>(%)</span>
          </mat-form-field>
        </div>
      </div>
      <div class="col-2">
        <div class="di-input-wrapper">
          <label class="custom-label">Add reason for changes</label>
          <mat-form-field appearance="fill" floatLabel="never">
            <input matInput [disabled]="getDisableStatus()" required [(ngModel)]="vehicleValueModel.comment"
              name="comments">
          </mat-form-field>
        </div>
      </div>
      <div class="col">
        <div class="di-input-wrapper">
          <label class="custom-label">&nbsp;</label>
          <button mat-flat-button color="primary" [disabled]="getDisableStatus()"
            (click)="validateFormAndSave(vehicleValue)">
            {{getBtnText()}}
          </button>
        </div>

      </div>
    </div>




  </form>
</div>