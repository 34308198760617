<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  Linking Recommendations
  <button mat-dialog-close
    [disabled]="(removingThis!=undefined && removingThis!= null && removingThis!='')?true:(addingThis!=undefined && addingThis!= null && addingThis!='')?true:false">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography rv_calculator">
  <vehicle-brief [isLinked]="vehicle?.linkedVehicle" [linkVehicle]="vehicle?.linkedVehicle"
    [rvIncluded]="vehicle?.rvValues || vehicle?.dmValues" [linkedId]="vehicle?.linkedVehicle?.DatiumAssetKey"
    [vehicleDetails]="vehicle" [type]="'recommended'" [rvHidden]="false" class="bg-primary text-white"
    [isSuperAdmin]="roleCheckService.isSuperAdmin()" [parentVehicle]="formData"></vehicle-brief>
  <div class="popup_content">
    <div class="search_results mt-0">
      <mat-tab-group>
        <mat-tab label="Recommendations">
          <app-loader [ShowLoader]="isGettingRecommendVehicles" [CStyle]="{'min-height':'200px'}"></app-loader>
          <no-record-found *ngIf="recommendedVehicles.length==0 && !isGettingRecommendVehicles"
            [notFoundHeading]="'No recommendations found'" [cStyle]="{'height':'300px'}"></no-record-found>
          <ng-container *ngFor="let vehicleObj of recommendedVehicles">
            <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
              [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
              [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj"
              [type]="'recommended'" [rvHidden]="false" [parentVehicle]="formData"
              (changeVehicle)="changeVehicle($event)" [isRecommended]="true"
              [isSuperAdmin]="roleCheckService.isSuperAdmin()" (removeRecom)="removeRecommendation($event)"
              [removingThis]="removingThis">
            </vehicle-brief>
          </ng-container>
        </mat-tab>
        <mat-tab label="Search Recommendations">
          <div class="search_filters mt-3">
            <app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'popup'"
              (searchResponse)="searchResponse($event)" [filterClass]="'col-md-4 mt-2'"></app-vehicle-filter-search>
            <!-- <form [formGroup]="searchForm" class="row m-0 d-flex align-items-center flex-wrap" (ngSubmit)="handleSearchForm(searchForm)">
              <vfacts-group-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></vfacts-group-filter>

              <vehicle-make-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></vehicle-make-filter>

              <vehicle-family-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></vehicle-family-filter>

              <fuel-type-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></fuel-type-filter>

              <vehicle-transmission-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3"
                [form]="searchForm" [formError]="formError"></vehicle-transmission-filter>

              <vehicle-cylinders-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></vehicle-cylinders-filter>
              <button mat-button class="btn btn-primary mat-button  ml-3" type="submit">Search</button>
              <button mat-button class="btn btn-dark mat-button  ml-3" type="button" (click)="resetFilters()">Clear
                Filters</button>
            </form> -->

          </div>

          <ng-container>
            <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0"
              [notFoundHeading]="notFoundHeading" [notFoundDescription]="notFoundDescription"
              [cStyle]="{'height':'300px'}"></no-record-found>
            <app-loader [ShowLoader]="isLoadingList" [CStyle]="{'min-height':'200px'}"></app-loader>
            <!-- <no-record-found *ngIf="!searchForm.value.VFactsGroups" [notFoundHeading]="'Please choose any filter.'"
              [cStyle]="{'height':'300px'}"></no-record-found> -->
            <ng-container *ngFor="let vehicleObj of vehicleList">
              <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
                [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
                [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj"
                [type]="'recommended'" [rvHidden]="false" [parentVehicle]="formData"
                (changeVehicle)="changeVehicle($event)" [isRecommended]="false"
                [isSuperAdmin]="roleCheckService.isSuperAdmin()" (addRecom)="addRecommendation($event)"
                [addingThis]="addingThis" [addedThese]="addedThese"></vehicle-brief>
            </ng-container>
            <div class="d-flex justify-content-center p-4"
              *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
              <button (click)="onScroll()" mat-flat-button color="primary">Load More</button>
            </div>
            <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
            <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging"
              [notFoundHeading]="'No more record(s) found'" [noRecord]="true"
              [cStyle]="{'height':'100px'}"></no-record-found>
          </ng-container>

        </mat-tab>
      </mat-tab-group>

    </div>

  </div>

</mat-dialog-content>