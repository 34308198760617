<h4>History</h4>
<div class="history_details">
  <app-loader [ShowLoader]="isLoadingList" [CStyle]="{'min-height':'auto'}" [type]="'listings-small'"></app-loader>
  <ng-container *ngIf="rvHistoryList.length > 0 && !isLoadingList">
    <div class="date_history" *ngFor="let rvHistoryListObj of rvHistoryList">
      <div class="changes_date" *ngIf="rvHistoryListObj?.date">
        <strong>
          {{rvHistoryListObj.date | date:timeLineDateFormat}}
        </strong>
      </div>
      <ng-container *ngIf="rvHistoryListObj?.history">
        <div class="single_change" *ngFor="let rvHistory of rvHistoryListObj.history">

          <div class="d-flex align-items-center">
            <div class="changes_info me-2">
              {{rvHistory.createdDateTime | date:Htime}} by <span class="text-primary">
                <app-text-format [data]="rvHistory?.user?.name"></app-text-format>
              </span>
            </div>
            <span class="dot"></span>
            <div class="changes_comments">
              <app-text-format [data]="rvHistory?.comments"></app-text-format>
            </div>
          </div>

          <div class="changes_values">
            <div class="d-flex justify-content-between">
              <div class="age">
                <div class="ageBar">12M (30k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['12_30000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['12_30000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">24M (60k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['24_60000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['24_60000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">36M (90k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['36_90000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['36_90000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">48M (120k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['48_120000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['48_120000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">60M (150k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['60_150000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['60_150000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center p-4" *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
    <button class="btn btn-primary" (click)="onScroll()" mat-button>Load More</button>
  </div>
  <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
  <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
    [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  <no-record-found *ngIf="page==1 && !isLoadingList && !isPaging && rvHistoryList.length <= 0"
    [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'100px'}" [noRecord]="true"></no-record-found>
</div>