<div class="section_loader" *ngIf="ShowLoader">
  <!-- <mat-spinner></mat-spinner> -->
  <div class="screen_ve_loader">
    <div class="msg_loader_data">
      <div>
        <mat-spinner></mat-spinner>
      </div>
      <div class="loading_messages">
        <ul>
          <li>{{text}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
