<div class="compare_vehicles">

  <div class="d-flex justify-content-between align-items-start">
    <div class="selection_count">
      <h3>Comparison Chart</h3>
      <p>{{recordLength}} vehicles selected</p>
      <mat-button-toggle-group name="toggleDollarPer" aria-label="Toggle Dollar Per" [value]="toggleType" class="mt-3"
        appearance="legacy">
        <mat-button-toggle value="dollar" (click)="toggleDollarPer()" class="btn btn-sm mat-button"
          [ngClass]="toggleType=='dollar'? 'btn-primary':'btn-light'">$
        </mat-button-toggle>
        <mat-button-toggle value="per" (click)="toggleDollarPer('per')" class="btn btn-sm mat-button"
          [ngClass]="toggleType!='dollar'? 'btn-primary':'btn-light'">%
        </mat-button-toggle>
      </mat-button-toggle-group>
      <!-- <button class="btn btn-primary" mat-button (click)="toggleDollarPer()" type="button">$</button>
        <button class="btn btn-primary" mat-button (click)="toggleDollarPer('per')" type="button">%</button> -->
    </div>

    <div class="selection_graph" style="width:80%">
      <div #compareChart></div>
    </div>

    <button class="btn btn-outline-primary d-flex align-items-center" mat-stroked-button (click)="openLink($event)">
      <span class="material-symbols-outlined">close</span> Close
    </button>

  </div>

</div>