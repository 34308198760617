<div class="vehicle_container">
  <button *appHasPermission="['used_edit_rv','new_edit_rv']" class="change_linking position-absolute"
    (click)="changeLinking()" color="primary" mat-flat-button>Change
    Linking</button>
  <div class="d-flex align-items-start vehicle_info_wrapper">
    <div class="vehicle_brand d-flex align-items-center">
      <mat-chip-list>
        <mat-chip color="primary" selected matTooltip="Click to copy Datium Asset Key" ngxClipboard
          [cbContent]="vehicle?.DatiumAssetKey" *ngxClipboardIfSupported (cbOnSuccess)="copiedMsg('Copied to clipboard')"
          class="pointer">
          <app-text-format [data]="vehicle?.DatiumAssetKey"></app-text-format>
        </mat-chip>
      </mat-chip-list>
      <div class="brand_modal">
        <app-text-format [data]="vehicle?.vehicleDetails?.make"></app-text-format>
        <span class="dot"></span>
        <app-text-format [data]="vehicle?.vehicleDetails?.model"></app-text-format>
        <span class="dot"></span>
        <app-text-format [data]="vehicle?.vehicleDetails?.year_group"></app-text-format>
      </div>
    </div>
    <p class="vehicle_desc d-flex"><span class="dot mt-2"></span>
      <app-text-format [data]="vehicle?.vehicleDetails?.description"></app-text-format>
    </p>
  </div>
  <div class="rv_datium_data">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>INDEX</th>
          <th>12M (30,000KM)</th>
          <th>24M (60,000KM)</th>
          <th>36M (90,000KM)</th>
          <th>48M (120,000KM)</th>
          <th>60M (150,000KM)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span
              [ngClass]="roleCheckService.isSuperAdmin() ? 'text-danger':'text-primary'">{{roleCheckService.isSuperAdmin()
              ? 'D.MODEL' : 'RV'}}</span></td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.rvValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.dmValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.rvValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.dmValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.rvValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.dmValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.rvValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.dmValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.rvValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.dmValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
        </tr>
        <tr>
          <td><span class="text-uppercase"
              [ngClass]="roleCheckService.isSuperAdmin() ? 'text-primary':'text-danger'">{{roleCheckService.isSuperAdmin()
              ? 'D.INDEX' : 'DATIUM'}}</span></td>
          <td>
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.diValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.diValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.diValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.diValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <strong class="font-weight-bold">
              <app-text-format [data]="vehicle?.diValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
            </strong>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>