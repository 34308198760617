import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-single-sign-in',
  templateUrl: './single-sign-in.component.html',
  styleUrls: ['./single-sign-in.component.scss']
})
export class SingleSignInComponent implements OnInit {
  token: string;
  public isProcessing: boolean = false;
  public isError: boolean = false;
  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private commonService: CommonService,
    private jwtService: JwtService
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.singleSignIn()
    });
  }

  ngOnInit() {
    // this.jwtService.deleteLocalStorage();
    //this.singleSignIn()
  }

  singleSignIn() {
    this.isProcessing = true;
    let params = {
      token: this.token
    }
    //Delete old user session
    this.jwtService.deleteLocalStorage(false);
    this.userService.singleSignIn(params).subscribe((res: any) => {
      try {
        const response = res;
        if (response.status == 200) {
          response.data['authToken'] = params.token
          this.jwtService.setAccessDetail(response.data);
        }
        else {
          this.isError = true
          //this.router.navigate(
        }
      }
      catch (e) {
        this.isError = true
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      this.isError = true
      this.isProcessing = false;
    }, () => {
      this.isProcessing = false;
    })
  }

}
