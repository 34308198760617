<div class="add_rv_link" *ngIf="!commonService.toggleIndex(vehicle, 'toggle');else otherLnkCase">
  <div class="col_heading">Linked Vehicle</div>
  <p>No vehicle is linked with this vehicle, you can link vehicle here.</p>
  <div class="add_btns d-flex">
    <button mat-flat-button color="primary" (click)="openLinkedVehicle()">
      <span class="material-symbols-outlined">link</span>
      Accept & Link
    </button>
  </div>
</div>
<ng-template #otherLnkCase>
  <div class="d-flex align-items-center justify-content-between mb-3">
    <div class="col_heading mb-0">Link Vehicle</div>
    <div>
      <button mat-stroked-button color="primary" (click)="openLinkedVehicle()">Link Vehicle</button>
      <button class="ms-2" color="primary" mat-stroked-button (click)="linkingRecommendations()">Manage
        Recommendations</button>
    </div>
  </div>

  <div class="vehicle_link_suggestions">

    <p class="mb-3">Recommended Vehicles ({{recommendedVehicles.length}})</p>

    <app-loader [ShowLoader]="isGettingRecommendVehicles" [CStyle]="{'min-height':'200px'}"></app-loader>
    <no-record-found *ngIf="recommendedVehicles.length==0 && !isGettingRecommendVehicles"
      [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'300px'}" [noRecord]="true"></no-record-found>
    <div class="vehicle_recommendations">
      <ng-container *ngFor="let vehicleObj of recommendedVehicles">
        <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
          [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
          [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj" [type]="'linking'"
          [rvHidden]="false" [parentVehicle]="vehicle" (changeVehicle)="changeVehicle($event)">
        </vehicle-brief>
      </ng-container>
    </div>

    <!-- <button class="btn btn-primary" mat-button (click)="changeLinking()">Add another vehicle</button> -->

  </div>
</ng-template>