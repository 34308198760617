<div class="no_record_found mt-5" [ngStyle]="cStyle">

  <div *ngIf="!noRecord; else no_record">

    <img *ngIf="showImg" src="assets/images/no_result.svg" height="220" alt="No Result" class="mb-4">

    <h3 *ngIf="notFoundHeading">{{notFoundHeading}}</h3>
    <p *ngIf="notFoundDescription">{{notFoundDescription}}</p>

  </div>
  <ng-template #no_record>
    <div>
      <p>{{notFoundHeading}}</p>
    </div>
  </ng-template>
</div>