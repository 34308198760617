<app-loader [ShowLoader]="loadingPer" [CStyle]="{'min-height':'150px'}" [text]="loadingText"></app-loader>
<form [formGroup]="companyProductForm" autocomplete="false" novalidate #formCont *ngIf="!loadingPer">
  <h2 class="mb-3">Choose Product</h2>
  <mat-accordion [multi]="true">
    <!-- <mat-expansion-panel formGroupName="AutoPredict" hideToggle (opened)="setProduct({checked:true},autoProduct_Id)"
      (closed)="setProduct({checked:false},autoProduct_Id)"> -->
    <mat-expansion-panel formGroupName="AutoPredict" hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="row" (click)="$event.stopPropagation();">
            <div class="col-md-12">
              <!-- <mat-checkbox [value]="autoProduct_Id" formControlName="isChecked"
                (change)="setProduct($event,autoProduct_Id)">
                {{productsArray[0]['title']}}</mat-checkbox> -->
              <mat-checkbox [value]="autoProduct_Id" formControlName="isChecked"
                (change)="setProduct($event,autoProduct_Id)">{{productsArray[0]['title']}}
              </mat-checkbox>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div class="row">
          <!-- <div class="col-md-12 mb-3">
            <mat-checkbox [value]="autoProduct_Id" formControlName="isChecked"
              (change)="setProduct($event,autoProduct_Id)">
              {{productsArray[0]['title']}}</mat-checkbox>
          </div> -->
          <div class="col-md-12 mb-3">
            <div class="d-block company_roles">
              <div class="row">
                <label class="col-md-12">Choose Permission</label>
                <label formArrayName="Permission_Id"
                  *ngFor="let genre of companyProductForm.controls.AutoPredict['controls']['Permission_Id']['controls']; let i = index"
                  class="col-md-6">
                  <mat-checkbox [formControlName]="i">{{validPermission[i]['label']}}</mat-checkbox>
                </label>
                <div class="col-md-12 pt-3 pb-3"
                  *ngIf="checkControlError('AutoPredict.Permission_Id',companyProductForm)">
                  <mat-error>
                    <span>Please choose at least one permission.</span>
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input matInput [min]="startDate" [matDatepicker]="autp_start_date" placeholder="Start Date"
                formControlName="start_date">
              <mat-datepicker-toggle matSuffix [for]="autp_start_date" *ngIf="isEdit"></mat-datepicker-toggle>
              <mat-datepicker #autp_start_date [startAt]="startDate" disabled="false"></mat-datepicker>
              <mat-error *ngIf="checkControlError('AutoPredict.start_date',companyProductForm)" class="mb-3">
                <span>
                  Please choose start date.
                </span>
                <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input matInput [min]="startDate" [matDatepicker]="autp_end_date" placeholder="End Date"
                formControlName="end_date">
              <mat-datepicker-toggle matSuffix [for]="autp_end_date"></mat-datepicker-toggle>
              <mat-datepicker #autp_end_date [startAt]="endDate" disabled="false"></mat-datepicker>
              <mat-error *ngIf="checkControlError('AutoPredict.end_date',companyProductForm)" class="mb-3">
                <span>
                  Please choose end date.
                </span>
                <!-- <span *ngIf="formError.end_date">{{formError.end_date}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="mb-2" [floatLabel]="'never'">
              <label for="TotalUsers">No. of Users</label>
              <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
              <mat-error *ngIf="checkControlError('AutoPredict.TotalUsers',companyProductForm)" class="mb-3">
                <span>
                  Please enter a number of users.
                </span>
                <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
              </mat-error>
            </mat-form-field>

          </div>
          <div class="col-md-6">
            <ng-container *ngIf="checkSubMake();else formMake">
              <div class="pt-4">
                <label>Makes</label>
                <div>
                  <span class="text-primary">{{checkSubMake(true)}}</span>
                </div>
              </div>
            </ng-container>
            <ng-template #formMake>
              <mat-form-field class="button_type mb-2" [floatLabel]="'never'">
                <label for="Make">Choose Makes</label>
                <mat-select multiple formControlName="Make">
                  <mat-option *ngFor="let make of makArray" [value]="make">
                    {{make}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkControlError('AutoPredict.Make',companyProductForm)" class="mb-3">
                  <span>
                    Please choose at least one make.
                  </span>
                  <!-- <span *ngIf="formError.makes">{{formError.makes}}</span> -->
                </mat-error>
              </mat-form-field>
            </ng-template>
          </div>
        </div>
        <div class="row" *ngIf="isEdit && checkSubExists(autoProduct_Id)">
          <div class="col-md-12 mb-3" (click)="addNewSub(autoProduct_Id)">
            <label class="pointer">
              <span class="material-symbols-outlined blue"
                *ngIf="toggleNewSub.indexOf(autoProduct_Id) ==-1">add_circle</span>
              <span class="material-symbols-outlined blue"
                *ngIf="toggleNewSub.indexOf(autoProduct_Id) !=-1">do_not_disturb_on</span>
              Add New Subscription
            </label>
          </div>
          <ng-container formGroupName="newSubscription" *ngIf="toggleNewSub.indexOf(autoProduct_Id) !=-1">
            <input type="hidden" formControlName="isNew" />
            <div class="col-md-12 mb-3">
              <div class="d-block company_roles">
                <div class="row">
                  <label class="col-md-12">Choose Permission</label>
                  <label formArrayName="Permission_Id" *ngFor="let genre of getNewSubPermissionControl(); let i = index"
                    class="col-md-6">
                    <mat-checkbox [formControlName]="i">{{validPermission[i]['label']}}</mat-checkbox>
                  </label>
                  <div class="col-md-12 pt-3 pb-3"
                    *ngIf="checkControlError('AutoPredict.newSubscription.Permission_Id',companyProductForm)">
                    <mat-error>
                      <span>Please choose at least one permission.</span>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput [min]="getSubInputDate(autoProduct_Id)" [matDatepicker]="autp_start_date"
                  placeholder="Start Date" formControlName="start_date">
                <mat-datepicker-toggle matSuffix [for]="autp_start_date" *ngIf="isEdit"></mat-datepicker-toggle>
                <mat-datepicker #autp_start_date [startAt]="getSubInputDate(autoProduct_Id)" disabled="false">
                </mat-datepicker>
                <mat-error *ngIf="checkControlError('AutoPredict.newSubscription.start_date',companyProductForm)"
                  class="mb-3">
                  <span>
                    Please choose start date.
                  </span>
                  <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput [min]="getSubInputDate(autoProduct_Id)" [matDatepicker]="autp_end_date"
                  placeholder="End Date" formControlName="end_date">
                <mat-datepicker-toggle matSuffix [for]="autp_end_date"></mat-datepicker-toggle>
                <mat-datepicker #autp_end_date [startAt]="endDate" disabled="false"></mat-datepicker>
                <mat-error *ngIf="checkControlError('AutoPredict.newSubscription.end_date',companyProductForm)"
                  class="mb-3">
                  <span>
                    Please choose end date.
                  </span>
                  <!-- <span *ngIf="formError.end_date">{{formError.end_date}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field class="mb-2" [floatLabel]="'never'">
                <label for="TotalUsers">No. of Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('AutoPredict.newSubscription.TotalUsers',companyProductForm)"
                  class="mb-3">
                  <span>
                    Please enter a number of users.
                  </span>
                  <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
                </mat-error>
              </mat-form-field>

            </div>
            <div class="col-md-6">
              <mat-form-field class="button_type mb-2" [floatLabel]="'never'">
                <label for="Make">Choose Makes</label>
                <mat-select multiple formControlName="Make">
                  <mat-option *ngFor="let make of makArray" [value]="make">
                    {{make}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="checkControlError('AutoPredict.newSubscription.Make',companyProductForm)"
                  class="mb-3">
                  <span>
                    Please choose at least one make.
                  </span>
                  <!-- <span *ngIf="formError.makes">{{formError.makes}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- <mat-expansion-panel formGroupName="InstantValuation" hideToggle (opened)="setProduct({checked:true},ivProduct_Id)"
      (closed)="setProduct({checked:false},ivProduct_Id)"> -->
    <mat-expansion-panel formGroupName="InstantValuation" hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="row" (click)="$event.stopPropagation();">
            <div class="col-md-12">
              <!-- <mat-checkbox [value]="ivProduct_Id" formControlName="isChecked"
                (change)="setProduct($event,ivProduct_Id)">
                {{productsArray[1]['title']}}</mat-checkbox> -->
              <mat-checkbox [value]="ivProduct_Id" formControlName="isChecked"
                (change)="setProduct($event,ivProduct_Id)">{{productsArray[1]['title']}}
              </mat-checkbox>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div class="row">
          <!-- <div class="col-md-12">
            <mat-checkbox [value]="ivProduct_Id" formControlName="isChecked"
              (change)="setProduct($event,ivProduct_Id);false">
              {{productsArray[1]['title']}}</mat-checkbox>
          </div> -->
          <div class="col-md-6">
            <mat-form-field>
              <input matInput [min]="startDate" [matDatepicker]="iv_start_date" placeholder="Start Date"
                formControlName="start_date">
              <mat-datepicker-toggle matSuffix [for]="iv_start_date" *ngIf="isEdit"></mat-datepicker-toggle>
              <mat-datepicker #iv_start_date [startAt]="startDate" disabled="false"></mat-datepicker>
              <mat-error *ngIf="checkControlError('InstantValuation.start_date',companyProductForm)">
                <span>
                  Please choose start date.
                </span>
                <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field>
              <input matInput [min]="startDate" [matDatepicker]="iv_end_date" placeholder="End Date"
                formControlName="end_date">
              <mat-datepicker-toggle matSuffix [for]="iv_end_date"></mat-datepicker-toggle>
              <mat-datepicker #iv_end_date [startAt]="endDate" disabled="false"></mat-datepicker>
              <mat-error *ngIf="checkControlError('InstantValuation.end_date',companyProductForm)">
                <span>
                  Please choose start date.
                </span>
                <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'">
              <label for="TotalUsers">No. of Users</label>
              <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
              <mat-error *ngIf="checkControlError('InstantValuation.TotalUsers',companyProductForm)">
                <span>
                  Please enter a number of users.
                </span>
                <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field [floatLabel]="'never'">
              <label for="TotalRequests">No. of Request</label>
              <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
              <mat-error *ngIf="checkControlError('InstantValuation.TotalRequests',companyProductForm)">
                <span>
                  Please enter a number of request.
                </span>
                <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="isEdit && checkSubExists(ivProduct_Id)">
          <div class="col-md-12 mb-3" (click)="addNewSub(ivProduct_Id)">
            <label class="pointer">
              <span class="material-symbols-outlined blue"
                *ngIf="toggleNewSub.indexOf(ivProduct_Id) ==-1">add_circle</span>
              <span class="material-symbols-outlined blue"
                *ngIf="toggleNewSub.indexOf(ivProduct_Id) !=-1">do_not_disturb_on</span>
              Add New Subscription
            </label>
          </div>
          <ng-container formGroupName="newSubscription" *ngIf="toggleNewSub.indexOf(ivProduct_Id) !=-1">
            <input type="hidden" formControlName="isNew" />
            <div class="col-md-6">
              <mat-form-field>
                <input matInput [min]="getSubInputDate(ivProduct_Id)" [matDatepicker]="iv_start_date"
                  placeholder="Start Date" formControlName="start_date">
                <mat-datepicker-toggle matSuffix [for]="iv_start_date" *ngIf="isEdit"></mat-datepicker-toggle>
                <mat-datepicker #iv_start_date [startAt]="getSubInputDate(ivProduct_Id)" disabled="false">
                </mat-datepicker>
                <mat-error *ngIf="checkControlError('InstantValuation.newSubscription.start_date',companyProductForm)">
                  <span>
                    Please choose start date.
                  </span>
                  <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field>
                <input matInput [min]="getSubInputDate(ivProduct_Id)" [matDatepicker]="iv_end_date"
                  placeholder="End Date" formControlName="end_date">
                <mat-datepicker-toggle matSuffix [for]="iv_end_date"></mat-datepicker-toggle>
                <mat-datepicker #iv_end_date [startAt]="endDate" disabled="false"></mat-datepicker>
                <mat-error *ngIf="checkControlError('InstantValuation.newSubscription.end_date',companyProductForm)">
                  <span>
                    Please choose start date.
                  </span>
                  <!-- <span *ngIf="formError.start_date">{{formError.start_date}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field [floatLabel]="'never'">
                <label for="TotalUsers">No. of Users</label>
                <input matInput formControlName="TotalUsers" name="TotalUsers" type="number">
                <mat-error *ngIf="checkControlError('InstantValuation.newSubscription.TotalUsers',companyProductForm)">
                  <span>
                    Please enter a number of users.
                  </span>
                  <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field [floatLabel]="'never'">
                <label for="TotalRequests">No. of Request</label>
                <input matInput formControlName="TotalRequests" name="TotalRequests" type="number">
                <mat-error
                  *ngIf="checkControlError('InstantValuation.newSubscription.TotalRequests',companyProductForm)">
                  <span>
                    Please enter a number of request.
                  </span>
                  <!-- <span *ngIf="formError.noUsers">{{formError.noUsers}}</span> -->
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="row row mt-4">
    <div class="col-md-12">
      <div class="form_btns" *ngIf="!loadingPer">
        <button class="btn btn-primary btn-lg" mat-button [disabled]="isProcessing"
          (click)="saveProducts(companyProductForm)" *ngIf="isEdit" type="submit">{{isProcessing ? 'Please Wait...' :
          'Update Product'}}</button>
        <button class="btn btn-primary btn-lg" mat-button [disabled]="isProcessing"
          (click)="saveProducts(companyProductForm)" *ngIf="!isEdit" type="submit">{{isProcessing ? 'Please Wait...' :
          'Add Product'}}</button>
        <button class="btn btn-outline-primary ml-3 btn-lg" mat-stroked-button (click)="closeModal()"
          [disabled]="isProcessing" type="button">Cancel</button>
      </div>
    </div>
  </div>
</form>