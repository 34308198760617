<nav class="di-nav">
  <ul>
    <ng-container *ngIf="menu_items && menu_items.items">
      <li *ngFor="let item of menu_items.items">
        <a class="nav-item" [routerLink]="item.menu_link ? [item.menu_link] : null"
          *ngIf="(item.menu_link=='/vehicle-search-new' || item.menu_link=='/vehicle-search-used' || item.menu_link=='/vehicle-list-new' || item.menu_link=='/vehicle-list-used'); else other_routes"
          [class.active]="(((vListNew.isActive || vSearchListNew.isActive) && (item.menu_link=='/vehicle-search-new' || item.menu_link=='/vehicle-list-new')) || ((vListOld.isActive || vSearchListOld.isActive) && (item.menu_link=='/vehicle-search-used' || item.menu_link=='/vehicle-list-used')))">
          {{ item.menu_name }}
          <ng-container *ngIf="!item.menu_link">
            <span class="material-symbols-outlined  size-18">arrow_drop_down</span>
          </ng-container>
        </a>
        <ng-template #other_routes>
          <a class="nav-item" *ngIf="item.menu_link" [routerLink]="item.menu_link ? [item.menu_link] : null"
            [routerLinkActive]="['active']">
            {{ item.menu_name }}
          </a>
          <a class="nav-item" *ngIf="!item.menu_link">
            {{ item.menu_name }}
            <span class="material-symbols-outlined size-18">arrow_drop_down</span>
          </a>
          <div class="sub-menu" *ngIf="item.sub_menu">
            <ul>
              <li *ngFor="let submenu of item.sub_menu">
                <ng-container *ngIf="submenu.url">
                  <a [routerLink]="submenu.url">{{submenu.name}}</a>
                </ng-container>
                <ng-container
                  *ngIf="!submenu.url && (submenu.name == 'RV Calculator' || submenu.name == 'Datium Calculator')">
                  <a (click)="openCalculator()">{{submenu.name}}</a>
                </ng-container>
                <ng-container *ngIf="!submenu.url && submenu.name == 'Manage Users'">
                  <a (click)="manageUsers()">{{submenu.name}}</a>
                </ng-container>
              </li>
            </ul>
          </div>
        </ng-template>
      </li>
    </ng-container>
    <li style="display: none"><a class="nav-item" routerLink="/vehicle-list-new" routerLinkActive
        #vListNew="routerLinkActive"></a></li>
    <li style="display: none"><a class="nav-item" routerLink="/vehicle-list-used" routerLinkActive
        #vListOld="routerLinkActive"></a></li>
    <li style="display: none"><a class="nav-item" routerLink="/vehicle-search-new" routerLinkActive
        #vSearchListNew="routerLinkActive"></a></li>
    <li style="display: none"><a class="nav-item" routerLink="/vehicle-search-used" routerLinkActive
        #vSearchListOld="routerLinkActive"></a></li>
  </ul>
</nav>