import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AppHeadings } from 'src/app/configs/app-headings';
import { FilterName } from 'src/app/configs/filter-names';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs';
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
  selector: 'manage-recommendations',
  templateUrl: './manage-recommendations.component.html',
  styleUrls: ['./manage-recommendations.component.scss']
})
export class ManageRecommendationsComponent implements OnInit {


  searchForm: UntypedFormGroup;
  formError = {}
  formData: any = {};
  vehicle: any;
  public processedLinkedData: any = {};
  public processingLinkedData: boolean = true;
  isDataReceived = false;
  isGettingRecommendVehicles: boolean = false;
  recommendedVehicles: any[] = [];
  isLoadingList: boolean = false;
  isPaging: boolean = false;
  vehicleList = [];
  noMoreRec = false;
  vSubscription: Subscription
  vRecSubscription: Subscription
  total = 0
  page: any = 1
  limit = 10;
  selectedFilter = {}
  sort = 'asc'
  currentTotal = 0
  sortBy = 'new_price';
  alreadyAddedIds = [];
  removingThis: string = undefined;
  addingThis: string = undefined;
  addedThese: any[] = [];
  notFoundHeading = 'Please choose a filter.'
  notFoundDescription = ''
  filterState = {
    submit: false,
    reset: false,
    initialState: false,
    data: {},
    error: false,
    loader: true
  }
  type = ""
  constructor(private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private vehicleService: VehicleService,
    private filterName: FilterName,
    private dataStore: DataStoreService,
    public dialogObjRef: MatDialogRef<ManageRecommendationsComponent>,
    public roleCheckService: RoleCheckerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formData = data;
    //console.log('data', data)
    this.vehicle = data.vehicle ? data.vehicle : {};
    this.formData = data;
    this.searchForm = this.fb.group(
      {
        VFactsGroups: [''],
        Make: [''],
        Family: [''],
        Body_Type: [''],
        Fuel_Type: [''],
        Transmission: [''],
        Cylinders: [''],
        Vehicle_Level: ['']
      }
    )
  }

  ngOnInit() {
    ///this.getLinkedIds();
    this.fetchVehicleRecommendations();
  }
  /*
  * @name toggleLoader
  * @param None
  * @description toggle loader(pagination and other loader)
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }
  searchResponse(response) {
    //console.log(response)
    //console.log(this.filterState, '---------------filter state')
    this.filterState = response
    if (this.filterState) {
      let filterStateChanges = this.filterState;
      //console.log(filterStateChanges, '-------------filterStateChanges')
      if (filterStateChanges) {//value == false
        let filterVal = filterStateChanges//.currentValue
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          //this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.resetPaginationRecords()
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please correct the errors.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
        }
      } else {
        let filterVal = filterStateChanges//.currentValue
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.isLoadingList = false;
        }
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          //this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.resetPaginationRecords()
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
      }
    }
    //console.log(this.filterState)
    //this.commonService.setLocalStorage('vehicle_filters', JSON.stringify(response.data))
    //this.commonService.sendUserTo('vehicle-list-bi')
  }
  /*
 * @name onScroll
 * @param newList:None
 * @description paginate on scroll
 * @return none
 */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    // console.log(typeof (this.page), 'this.page')
    let page = parseInt(this.page);
    this.page = page + 1;
    // console.log(typeof (this.page), 'this.page')
    if (this.currentTotal > 10) {
      //  console.log('onScroll request')
      this.getVehicleList(true)
    }
  }

  /*
 * @name handleSearchForm
 * @param searchFormObj:FormGroup
 * @description handle the search form
 * @return none
 */
  handleSearchForm(searchFormObj: UntypedFormGroup) {
    //console.log(searchFormObj, searchFormObj.valid)
    if (searchFormObj.valid) {
      let VFactsGroups = searchFormObj.value.VFactsGroups
      let Make = searchFormObj.value.Make
      if (!VFactsGroups && !Make) {
        if (!VFactsGroups) {
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (!Make) {
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }
      this.resetPaginationRecords()
      this.getVehicleList();
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
  * @name resetPaginationRecords
  * @param None
  * @description reset pagination, records and selected filter
  * @return none
  */
  resetPaginationRecords() {
    this.page = 1
    this.limit = 10
    this.total = 0
    this.currentTotal = 0
    this.vehicleList = [];
    this.sort = 'asc'
    this.sortBy = 'new_price'
    //this.disableScroll = true;
    this.noMoreRec = false;
    this.isLoadingList = false;
    this.isPaging = false;
  }

  /*
  * @name resetFilters
  * @param none
  * @description reset the vehicle filter form
  * @return none
  */
  resetFilters() {
    this.searchForm.setValue(this.filterName.filter)
    this.formError = {}
    this.vehicleList = [];
    this.dataStore.setSelectedFilters(this.filterName.filter)
    this.dataStore.resetVehicleFilterList()
  }

  /*
  * @name getLinkedIds
  * @param None
  * @description get all linked vehicle ids(Admin and company user)
  * @return none
  */
  getLinkedIds() {
    this.vehicleService.getLinkedVehicleIds().subscribe(
      (result: any) => {
        try {
          let response = result;
          let vehicleData = response.data;
          this.alreadyAddedIds = vehicleData.ids
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error;
        if (response.message) {
          //this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
      }
    )
  }

  /*
  * @name changeVehicle
  * @param None
  * @description receive signal for adding/updating the linking
  * @return none
  */
  changeVehicle(data) {
    this.commonService.showSnakeBar('Please wait while we are updating the linking.');
    this.vehicleService.changeVehicleLinking(data['child'], data['parent']).subscribe(
      (result: any) => {
        try {
          let response = result;
          this.commonService.showSnakeBar(response.message);
          this.dataStore.resetVehicleDetailsReload(data['child'], true, true)
          this.dialogObjRef.close()
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }

  /*
  * @name getVehicleList
  * @param None
  * @description Api call for fetching the vehicle list
  * @return none
  */
  getVehicleList(paging = false) {
    this.toggleLoader(true, paging)
    let apiParams = {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
      sortBy: this.sortBy,
      type: 'bi',
      exId: this.formData['vehicleId'] ? this.formData['vehicleId'] : ''
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    apiParams['list_type'] = 'rec_list'
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.otherVehicleListing(apiParams, 'post').subscribe(
      (result: any) => {
        try {
          let response = result;
          let vehicleData = response.data;
          if (this.page == 1) {
            this.total = vehicleData.total
          }
          //this.total = vehicleData.total
          this.currentTotal = vehicleData.total
          this.limit = vehicleData.limit
          this.page = vehicleData.page
          if (this.currentTotal == 0 && paging === true) {
            this.noMoreRec = true;
          }
          else {
            this.noMoreRec = false;
          }
          if (vehicleData.records.length > 0) {
            this.appendVehicleListing(vehicleData.records)
          } else {
            if (this.page == 1) {
              this.notFoundHeading = 'Hum.. Looks like no record(s) found for this filter'
              this.notFoundDescription = 'Please update your filter above.'
            }
          }
        } catch (e) {

        }
      },
      (error) => {
        let response = error;
        this.toggleLoader(false, paging)
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        if (this.page != 1) {
          this.page -= 1
        }
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }


  /*
  * @name appendVehicleListing
  * @param newList:Array
  * @description paginate over vehicle listing                   
  * @return none
  */
  appendVehicleListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.vehicleList.length > 0) {
        newState = this.vehicleList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.vehicleList = newState
    }

  }

  /*
    * @name formValidate
    * @param  Form
    * @description validate form                       
    * @return none
    */
  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          console.log(this.formError, control)
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.vRecSubscription) {
      this.vRecSubscription.unsubscribe()
    }
  }

  fetchVehicleRecommendations() {
    this.isGettingRecommendVehicles = true;
    //console.log(typeof (this.formData['linkedVehicle']))
    let exId = '';
    if (this.formData['linkedVehicleId'] != undefined) {
      if ('vehicleId' in this.formData['linkedVehicleId']) {
        exId = this.formData['linkedVehicleId']['vehicleId']
      }
    }
    this.vRecSubscription = this.vehicleService.getVehicleRecommendations(this.formData.vehicleId, exId).subscribe((result: any) => {
      try {
        this.isGettingRecommendVehicles = false;
        const response = result;
        this.recommendedVehicles = response.data;
        for (let i = 0; i < this.recommendedVehicles.length; i++) {
          this.addedThese.push(this.recommendedVehicles[i].DatiumAssetKey);
        }
      } catch (e) {

      }

    },
      (error) => {
        let response = error;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isGettingRecommendVehicles = false;
      },
      () => {
        this.isGettingRecommendVehicles = false
      })
  }

  addRecommendation(vehicle) {
    this.addingThis = vehicle.DatiumAssetKey;
    this.vehicleService.addVehicleRecommendations(this.vehicle.DatiumAssetKey, vehicle.DatiumAssetKey).subscribe((result: any) => {
      try {
        const response = result;
        if (response.data.Status) {
          this.addedThese.push(vehicle.DatiumAssetKey);
          this.recommendedVehicles.push(vehicle);
          this.commonService.showSnakeBar(response.message);
          // Send signal to refresh the listing screen
          this.dataStore.setVehicleRec({
            'action': 'add',
            'DatiumAssetKey': this.vehicle.DatiumAssetKey,
            'record': vehicle
          })
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      } catch (e) {
        this.commonService.showSnakeBar("Request Failed");
      }
      this.addingThis = undefined;
    },
      (error) => {
        let response = error;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.addingThis = undefined;
      },
      () => {
        this.addingThis = undefined;
      })
  }

  removeRecommendation(vehicle) {
    this.removingThis = vehicle.DatiumAssetKey;
    this.vehicleService.removeVehicleRecommendations(this.vehicle.DatiumAssetKey, vehicle.DatiumAssetKey).subscribe((result: any) => {
      try {
        const response = result;
        if (response.data.Status) {
          const index = this.addedThese.indexOf(vehicle.DatiumAssetKey);
          if (index != -1) {
            this.addedThese.splice(index, 1);
          }
          this.spliceObject(vehicle.DatiumAssetKey, false);
          this.commonService.showSnakeBar(response.message);
          // Send signal to refresh the listing screen
          this.dataStore.setVehicleRec({
            'action': 'remove',
            'DatiumAssetKey': this.vehicle.DatiumAssetKey,
            'removeKey': vehicle.DatiumAssetKey
          })
        }
        else {
          this.commonService.showSnakeBar(response.message);
        }
      } catch (e) {
        this.commonService.showSnakeBar("Request Failed");
      }
      this.removingThis = undefined;
    },
      (error) => {
        let response = error;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.removingThis = undefined;
      },
      () => {
        this.removingThis = undefined;
      })
  }

  spliceObject(DatiumAssetKey, isAdding) {
    if (isAdding) {
      const index = this.vehicleList.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
      if (index != -1) {
        this.vehicleList.splice(index, 1);
      }
    }
    else {
      const index = this.recommendedVehicles.findIndex(obj => obj.DatiumAssetKey == DatiumAssetKey);
      if (index != -1) {
        this.recommendedVehicles.splice(index, 1);
      }
    }
  }
}
