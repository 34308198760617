<div>
  <page-title [title]="'Product Offers'"></page-title>


  <div *ngIf="roleCheckService.isSuperAdmin()">
    <div class="mt-2 mb-4">

    </div>
    <div class="">
      <app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'100px'}"></app-loader>
      <div class="example-container">

        <div class="example-table-container" *ngIf="!isLoading">
          <table mat-table [dataSource]="listData">

            <!-- Position Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting">Name <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.PlanName}} </td>
            </ng-container>

            <ng-container matColumnDef="Products">
              <th mat-header-cell *matHeaderCellDef>
                <div>Products <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element">

                <mat-chip-list class="me-2" *ngFor="let productName of element.Products">
                  <mat-chip color="primary" selected>
                    {{productName.ProductName}}
                  </mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="OfferType">
              <th mat-header-cell *matHeaderCellDef>
                <div class="sorting des">OfferType <i class="mat-select-arrow"></i> </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element.OfferTypeName}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="action">
              <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
              <td class="text-right" mat-cell *matCellDef="let element">
                <button (click)="updateProducts(element)" class="btn btn-primary ml-3" mat-button>Edit
                </button>

              </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>