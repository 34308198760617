<div class="dashboard_changes_history">

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <h3 class="mb-1">{{roleCheckService.isSuperAdmin() ? 'Datium Changes History' : 'Changes History'}}</h3>
    </mat-expansion-panel-header>

    <ng-container *ngIf="!roleCheckService.isSuperAdmin()">
      <rv-changes></rv-changes>
    </ng-container>
    <ng-container *ngIf="roleCheckService.isSuperAdmin()">
      <datium-changes></datium-changes>
    </ng-container>

  </mat-expansion-panel>

</div>