<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [formControlName]="'Family'" name="Family" placeholder="Family" [multiple]="isMultiple" [attr.disabled]="isLoading"
    (selectionChange)="selectionChange($event.value)" [(value)]="selectedFamily">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Family" [values]="FamilyFilterList" (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngIf="!isMultiple" [value]="'All'">All</mat-option>
    <mat-option *ngFor="let FamilyObj of FamilyFilterList" [value]="FamilyObj.filter">{{FamilyObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Family.invalid && (parentForm.controls.Family.dirty || parentForm.controls.Family.touched)">
    <span *ngIf="parentForm.controls.Family.errors.required || parentForm.controls.Family.errors.Family">
      Please choose Family
    </span>
    <span *ngIf="formError?.Family">{{formError.Family}}</span>
  </mat-error>
</mat-form-field>
