<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <ng-container *ngIf="showBack;else calCase">
      Value Calculator
    </ng-container>
    <ng-template #calCase>
      {{roleCheckService.isSuperAdmin() ? 'Datium' : 'RV'}} Calculator
    </ng-template>
    <a href="javascript:void(0)" class="text-primary dialog-title-link d-flex align-items-center" matLine
      mat-dialog-close (click)="closeDialog()" *ngIf="showBack">
      <span class="material-symbols-outlined">keyboard_double_arrow_left</span> Change Vehicle
    </a>
  </div>
  <button mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>
<mat-dialog-content class="mat-typography rv_calculator rv_value_popup position-relative">
  <vehicle-brief [isLinked]="vehicle?.linkedVehicle" [linkVehicle]="vehicle?.linkedVehicle"
    [rvIncluded]="vehicle?.rvValues || vehicle?.dmValues" [linkedId]="vehicle?.linkedVehicle?.DatiumAssetKey"
    [vehicleDetails]="vehicle" [type]="'in_calculator'" [rvHidden]="true"
    [isSuperAdmin]="roleCheckService.isSuperAdmin()" class="bg-primary text-white"
    (captureValues)="captureCalculatorVal($event)" *ngIf="!isProcessing"></vehicle-brief>
  <app-loader [ShowLoader]="isProcessing" [CStyle]="{'min-height':'300px'}"></app-loader>
  <div class="change_values" *ngIf="!isProcessing">
    <form #RvCalForm="ngForm">

      <div class="row mt-4">
        <div class="col col-md-6">
          <div class="di-input-wrapper">
            <label class="custom-label">New Price</label>
            <mat-form-field [floatLabel]="'never'" appearance="fill" floatLabel="never">
              <input matInput [(ngModel)]="RvCalFormModel.new_price" name="new_price" type="number"
                (input)="changeDollarValues()">
            </mat-form-field>
          </div>
        </div>
        <div class="col col-md-6">
          <div class="di-input-wrapper">
            <label class="custom-label">Manual Adj</label>
            <mat-form-field [floatLabel]="'never'" appearance="fill" floatLabel="never">
              <input matInput [(ngModel)]="RvCalFormModel.mAdj_12" max="100" name="mAdj_12" type="number"
                (input)="calculateNewManualADJ(RvCalFormModel.mAdj_12,12)">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-md-6">
          <div class="di-input-wrapper">
            <label class="custom-label">Age</label>
            <mat-form-field appearance="fill" floatLabel="never">
              <input matInput [(ngModel)]="RvCalFormModel.age_12" name="age_12" type="number" min="0" max="60"
                (input)="calculateNewValue(RvCalFormModel.age_12,12,'age')">
            </mat-form-field>
          </div>
        </div>
        <div class="col col-md-6">
          <div class="di-input-wrapper">
            <label class="custom-label">KM</label>
            <mat-form-field appearance="fill" floatLabel="never">
              <input matInput [(ngModel)]="RvCalFormModel.km_12" name="km_12" type="number" min="0"
                (input)="calculateNewValue(RvCalFormModel.km_12,12,'km')">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col col-md-6">
          <div class="di-input-wrapper">
            <label class="custom-label">{{roleCheckService.isSuperAdmin() ? 'Datium' : 'RV'}} inc GST</label>
            <div>
              <app-text-format [data]="RvCalFormModel.rvGST_12" [defaultNoFormat]="0" [formatType]="'new_price'">
              </app-text-format>
              <app-text-format [data]="perDollar(RvCalFormModel.rvGST_12,'per')" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
              <input [(ngModel)]="RvCalFormModel.rvGST_12" name="rvGST_12" type="hidden">
            </div>
          </div>
        </div>

        <div *ngIf="baseData.Ind_NewVehicle === true" class="col col-md-6 text-right">
          <button mat-flat-button [disabled]="(age == RvCalFormModel.age_12) && (km == RvCalFormModel.km_12)"
            color="primary" type="button" (click)="calculateValue()">Recalculate</button>
        </div>
      </div>

      <div class="border-top border-blue mt-4 pt-4">

        <div class="row">

          <div class="col col-md-4">
            <div class="field_wrapper">
              <label class="text-gray mb-2">Valuation Adj</label>
              <div class="text-primary font-weight-bold">
                <app-text-format [data]="RvCalFormModel.rvmAdj_12" [defaultNoFormat]="0" [formatType]="'new_price'">
                </app-text-format>
                <app-text-format [data]="perDollar(RvCalFormModel.rvmAdj_12,'per')" [defaultNoFormat]="0"
                  [formatType]="'percentage'"></app-text-format>
              </div>
              <input [(ngModel)]="RvCalFormModel.rvmAdj_12" name="rvmAdj_12" type="hidden">
            </div>
          </div>

          <div class="col col-md-4">
            <div class="field_wrapper">
              <label class="text-gray mb-2">{{roleCheckService.isSuperAdmin() ? 'DI Model' : 'DI'}} inc GST</label>
              <div class="text-danger font-weight-bold">
                <app-text-format [data]="RvCalFormModel.di_rvGST_12" [defaultNoFormat]="0" [formatType]="'new_price'">
                </app-text-format>
                <app-text-format [data]="perDollar(RvCalFormModel.di_rvGST_12,'per')" [defaultNoFormat]="0"
                  [formatType]="'percentage'"></app-text-format>
              </div>
              <input [(ngModel)]="RvCalFormModel.di_rvGST_12" name="di_rvGST_12" type="hidden">
            </div>
          </div>

          <div class="col col-md-4">
            <div class="field_wrapper">
              <label class="text-gray mb-2">Ex GST</label>
              <div class="text-danger font-weight-bold">
                <app-text-format [data]="RvCalFormModel.rv_12" [defaultNoFormat]="0" [formatType]="'new_price'">
                </app-text-format>
                <app-text-format [data]="perDollar(RvCalFormModel.rv_12,'per')" [defaultNoFormat]="0"
                  [formatType]="'percentage'"></app-text-format>
              </div>
              <input [(ngModel)]="RvCalFormModel.rv_12" name="rv_12" type="hidden">
            </div>
          </div>


        </div>

      </div>

    </form>
  </div>
  <ve-loader [ShowLoader]="loaderData.loading" [text]="loaderData.text"></ve-loader>
</mat-dialog-content>