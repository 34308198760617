<div class="new-vehicles-added-wrapper">

  <h2>New vehicles added</h2>

  <div class="new-vehicle-tab bg-white shadow-sm p-3">
    <div class="d-flex align-items-center mb-2">
      <mat-chip-list class="me-2">
        <mat-chip color="primary" selected>DI2015ACABAWAD</mat-chip>
      </mat-chip-list>
      <div class="brand_modal">
        Toyota <div class="dot"></div> Kluger <div class="dot"></div> 2019
      </div>
    </div>
    <p>GSU50R Grande Wagon 7st 5dr Spts Auto 8sp 2WD 3.5i</p>

    <div class="added-on">Added on 10-08-2021</div>
  </div>

  <div class="new-vehicle-tab bg-white shadow-sm p-3">
    <div class="d-flex align-items-center mb-2">
      <mat-chip-list class="me-2">
        <mat-chip color="primary" selected>DI2015ACABAWAD</mat-chip>
      </mat-chip-list>
      <div class="brand_modal">
        Toyota <div class="dot"></div> Kluger <div class="dot"></div> 2019
      </div>
    </div>
    <p>GSU50R Grande Wagon 7st 5dr Spts Auto 8sp 2WD 3.5i</p>

    <div class="added-on">Added on 10-08-2021</div>
  </div>

  <div class="new-vehicle-tab bg-white shadow-sm p-3">
    <div class="d-flex align-items-center mb-2">
      <mat-chip-list class="me-2">
        <mat-chip color="primary" selected>DI2015ACABAWAD</mat-chip>
      </mat-chip-list>
      <div class="brand_modal">
        Toyota <div class="dot"></div> Kluger <div class="dot"></div> 2019
      </div>
    </div>
    <p>GSU50R Grande Wagon 7st 5dr Spts Auto 8sp 2WD 3.5i</p>

    <div class="added-on">Added on 10-08-2021</div>
  </div>

</div>