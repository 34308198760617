import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";
import { CommonService } from "../../../services/common.service";
import { DataStoreService } from "../../../services/data-store.service";
import { SubscriptionService } from "../../../services/subscription.service";
import { PermissionsService } from '../../../services/permissions.service'
import { ActivatedRoute, Router } from "@angular/router";
import { AddOfferComponent } from "../add-offer/add-offer.component";
import { AppSettings } from "src/app/configs/app-settings";
import { ConfirmationPopupComponent } from "src/app/shared/confirmation-popup/confirmation-popup.component";
import { PopupLoaderComponent } from "src/app/shared/popup-loader/popup-loader.component";
import { RenewSubscriptionComponent } from "../renew-subscription/renew-subscription.component";

@Component({
  selector: "app-subscription-list",
  templateUrl: "./subscription-list.component.html",
  styleUrls: ["./subscription-list.component.scss"]
})
export class SubscriptionListComponent implements OnInit {
  displayedColumns: string[] = [
    "PlanName",
    "ProductName",
    "StartDate",
    "EndDate",
    "Status",
    "Action"
  ];
  isLoading: boolean = false;
  isSubLoading: boolean = false;
  listData = [];

  vSubscription: Subscription;
  vFactListing: Subscription;
  makArray: Array<string> = [];
  permArray: Array<any> = [];
  CompanyDetails = {};


  title = "Manage Subscriptions";
  companyId = null;
  timeLineDateFormat = null
  constructor(
    public commonService: CommonService,
    public dialog: MatDialog,
    public dataStore: DataStoreService,
    public subscriptionService: SubscriptionService,
    public permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appSettings: AppSettings
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params["companyId"]) {
        this.companyId = params["companyId"];
        this.timeLineDateFormat = this.appSettings.COMMON_DATE_FORMAT
      } else {
        this.sendToCompany()
      }
    });
  }

  ngOnInit() {
    this.commonService.setTitle(this.title);
    this.getCompanyDetails()
  }

  sendToCompany() {
    this.commonService.showSnakeBar(
      "Unable to find the requested company."
    );
    this.router.navigate(["/companies"]);
  }


  getCompanyDetails() {
    this.isLoading = true;
    this.vFactListing = this.subscriptionService
      .getCompanyDetails({ companyId: this.companyId })
      .subscribe(
        (result: any) => {
          this.isLoading = false;
          try {
            let response = result;
            if (!response.data) {
              this.sendToCompany()
            }
            this.CompanyDetails = response.data
            this.getSubscriptionListing()

          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.isLoading = false;
          let response = error;

          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isLoading = false;
        },
        () => { }
      );
  }

  /*
  * @name getSubscriptionListing
  * @param none
  * @description get the subscription of products
  * @return none
  */
  getSubscriptionListing() {
    this.isSubLoading = true;
    this.vFactListing = this.subscriptionService
      .getSubscriptionList({ companyId: this.companyId })
      .subscribe(
        (result: any) => {
          this.isSubLoading = false;
          try {
            let response = result;
            this.listData = response.data.subscription;
            this.makArray = response.data.Makes;
            this.permArray = response.data.Permissions;
          } catch (e) { }
        },
        error => {
          //console.log(error)
          this.isSubLoading = false;
          let response = error;

          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isSubLoading = false;
        },
        () => { }
      );
  }

  subscriptionAction(action, data) {
    console.log(action, data);
  }

  addSubscription() {
    const dialogRef = this.dialog.open(AddOfferComponent, {
      width: "auto",
      height: "auto",
      data: {
        companyId: this.companyId
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyOfferAdded.subscribe((res: any) => {
      console.log('here...')
      this.getSubscriptionListing();
    })
  }


  getOfferSubscriptionData(offer) {
    let ProductSubscription = {
      TotalUsers: offer['TotalUsers']
    }
    if (offer['TotalRequests']) {
      ProductSubscription['TotalRequests'] = offer['TotalRequests']
    }
    if (offer['Makes']) {
      ProductSubscription['Makes'] = offer['Makes']
    }
    if (offer['Permissions']) {
      ProductSubscription['Permission_Id'] = offer['Permissions'].map(Number)
    }
    let temp = {
      Product_Id: offer['Product_Id'],
      ProductSubscription: ProductSubscription
    }
    console.log(temp, 'temp')
    return [temp]
  }


  renewSubscription(offer) {
    const popupLoader = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Getting details...'
      }
    });
    this.permissionsService
      .getOfferDetails(offer['Plan_Id'])
      .subscribe(
        (result: any) => {
          this.isSubLoading = false;
          try {
            popupLoader.close()
            let response = result;
            if (response.data) {
              let offerData = response.data
              if (offerData['products']) {
                let makes = offerData.makes
                delete offerData.makes
                let permissions = offerData.permissions
                delete offerData.permissions
                let pData = {
                  offerDetails: offerData['products'],
                  makes: makes,
                  permissions: permissions,
                  offerSubscription: this.getOfferSubscriptionData(offer),
                  companyId: this.companyId,
                  fromRenew: true
                }
                this.openRenewPopup(pData)
              }


            } else {

            }

          } catch (e) {
            popupLoader.close()
          }
        },
        error => {
          //console.log(error)
          popupLoader.close()
          this.isSubLoading = false;
          let response = error;

          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          this.isSubLoading = false;
        },
        () => { }
      );
  }


  openRenewPopup(data) {
    console.log(data)
    const dialogRef = this.dialog.open(RenewSubscriptionComponent, {
      width: "auto",
      height: "auto",
      data: {
        offer: data
      },
      disableClose: true
    });
    dialogRef.componentInstance.onCompanyOfferAdded.subscribe((res: any) => {
      console.log('here...')
      this.getSubscriptionListing();
    })
  }

  confirmDeactivation(subData) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to update the deactivate this subscription?'
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.deactivateSubscription(subData);
      }
    });
  }


  deactivateSubscription(subData) {
    let ttData = {
      'Company_Id': this.companyId,
      'Product_Id': subData['Product_Id'],
      'Plan_Id': subData['Plan_Id'],
      'CompanySubscription_Id': subData['CompanySubscription_Id']
    }
    const popupLoader = this.dialog.open(PopupLoaderComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Deactivating...'
      }
    });
    let subId = subData['CompanySubscription_Id']
    this.subscriptionService
      .deactivateSubscription(ttData)
      .subscribe(
        (result: any) => {
          popupLoader.close()
          try {
            var index = this.listData.findIndex(p => p.CompanySubscription_Id == subId)
            if (index != -1) {
              this.listData[index]['IsActive'] = false
            }
          } catch (e) { }
        },
        error => {
          popupLoader.close()
          //console.log(error)
          let response = error;
          if (response.message) {
            this.handleServerFormError(response.errors);
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          popupLoader.close()
        }
      );
  }

  /*
   * @name updateProducts
   * @param none
   * @description Update new (Deflation, BusinessRisk and Remarketing Efficiency)
   * @return none
   */
  /*updateProducts(object) {
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: true,
        editProduct: object,
        makes: this.makArray,
        permissions: this.permArray,
        ProductOffers: this.ProductOffers
      },
      disableClose: true
    });


    dialogRef.componentInstance.onUpdateProduct.subscribe((res: any) => {
      this.listData = res
      this.listData = this.listData.slice()
    })
  }*/

  /*
   * @name handleServerFormError
   * @param Form
   * @description handle server side errors
   * @return none
   */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe();
    }
    if (this.vFactListing) {
      this.vFactListing.unsubscribe();
    }
  }
}
