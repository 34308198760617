<div class="quick-search-modal-wrapper full-screen-modal">
  <div class="container" style="position: relative;">
    <app-quick-search-vehicle-details onOutsideClick [offset]="detailOffset" (outsideClick)="selectedVehicle=undefined"
      [vehicle]="selectedVehicle" [elClass]="'right-aligned'">
    </app-quick-search-vehicle-details>
    <div class="row justify-content-between">
      <div class="col-md-6">
        <h1>Search vehicle</h1>
        <p>You will be able to search for vehicle with make, model, year group and other filters here.</p>
        <div class="search-form mt-4" *ngIf="filterModel['Make']">
          <mat-chip-list class="selected-chips">
            <mat-chip *ngIf="filterModel['Make']" [removable]="true" (removed)="clearFilter('Make')" selected>
              {{filterModel['Make']}}
              {{filterModel['Family']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Year_Group']" (removed)="clearFilter('Year_Group')"
              selected>
              {{filterModel['Year_Group']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Body_Type']" (removed)="clearFilter('Body_Type')" selected>
              {{filterModel['Body_Type']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Fuel_Type']" (removed)="clearFilter('Fuel_Type')" selected>
              {{filterModel['Fuel_Type']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Transmission']" (removed)="clearFilter('Transmission')"
              selected>
              {{filterModel['Transmission']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Cylinders']" (removed)="clearFilter('Cylinders')" selected>
              {{filterModel['Cylinders']}} - Cylinders
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
            <mat-chip [removable]="true" *ngIf="filterModel['Vehicle_Level']" (removed)="clearFilter('Vehicle_Level')"
              selected>
              {{filterModel['Vehicle_Level']}}
              <span class="material-symbols-outlined size-18" matChipRemove>close</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="search-form mt-4" *ngIf="curFilters == 'Make'">
          <label for="searchByMakeModal">Start by searching for make, model:</label>
          <div class="di-input-wrapper mt-3">
            <!-- <input matInput placeholder="Type car make model here" [autofocus]="true"> -->
            <!--TODO commented by Ravi -22-01-25-->
            <!-- <ng-autocomplete [data]="makeList" [minQueryLength]="3" [debounceTime]="300" [isLoading]="loadingFilters"
                placeholder="Type car make model here" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete> -->

            <mat-form-field class="full-width" [floatLabel]="'never'" appearance="fill" class="mb-0">
              <span matPrefix class="material-symbols-outlined">search</span>
              <input type="text" matInput [formControl]="autoCompleteControl" [matAutocomplete]="auto"
                placeholder="Type car make model here" class="ps-4">
              <mat-spinner class="input-loader" *ngIf="loadingFilters" matSuffix></mat-spinner>
              <span class="material-symbols-outlined cursor-pointer" *ngIf="!loadingFilters && makeList.length"
                (click)="clearInput()" matSuffix>
                close
              </span>

              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectEvent($event)">
                <mat-option *ngFor="let item of makeList" [value]="item">
                  {{ item.MakeFamily }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>


            <ng-template #itemTemplate let-item>
              <a [innerHTML]="item.MakeFamily"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
        </div>
        <div class="search-form mt-4" *ngIf="loadingFilters && curFilters != 'Make'">
          <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'100px'}"></app-loader>
        </div>
        <div class="search-form mt-4" *ngIf="!loadingFilters">
          <div class="row">
            <div class="col-md-12" *ngIf="curFilters=='Year_Group'">
              <h3 class="info-title">Select build year of car...</h3>
              <div class="years-selection-list">
                <ul>
                  <li class="year-selection" *ngFor="let fl of filterList.slice(0,9)"
                    (click)="handleSelection('Year_Group',fl.filter)">
                    {{fl.filter}}
                  </li>
                  <li class="last">
                    <div class="di-input-wrapper">
                      <mat-form-field appearance="fill" floatLabel="never" *ngIf="filterList.length > 9">
                        <mat-select (selectionChange)="handleSelection('Year_Group',$event.value)"
                          placeholder="Select Year" multiple="false">
                          <mat-option *ngFor="let year_group of filterList.slice(9,filterList.length)"
                            [value]="year_group.filter">
                            {{year_group.filter}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
            <div class="col-md-12" *ngIf="curFilters=='Body_Type'">
              <h3 class="info-title">Select car's body type...</h3>
              <mat-chip-list class="select-chips">
                <mat-chip *ngFor="let fl of filterList" (click)="handleSelection('Body_Type',fl.filter)" selected>
                  {{fl.filter}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="col-md-12" *ngIf="curFilters=='Fuel_Type'">
              <h3 class="info-title">Select car's fuel type...</h3>
              <mat-chip-list class="select-chips">
                <mat-chip *ngFor="let fl of filterList" (click)="handleSelection('Fuel_Type',fl.filter)" selected>
                  {{fl.filter}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="col-md-12" *ngIf="curFilters=='Transmission'">
              <h3 class="info-title">Select car's transmission type...</h3>
              <mat-chip-list class="select-chips">
                <mat-chip *ngFor="let fl of filterList" (click)="handleSelection('Transmission',fl.filter)" selected>
                  {{fl.filter}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="col-md-12" *ngIf="curFilters=='Cylinders'">
              <h3 class="info-title">Select car cylinders...</h3>
              <mat-chip-list class="select-chips">
                <mat-chip *ngFor="let fl of filterList" (click)="handleSelection('Cylinders',fl.filter)" selected>
                  {{fl.filter}}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="col-md-12" *ngIf="curFilters=='Vehicle_Level' && !filterModel['Vehicle_Level']">
              <h3 class="info-title">Select vehicle level...</h3>
              <mat-chip-list class="select-chips">
                <mat-chip *ngFor="let fl of filterList" (click)="handleSelection('Vehicle_Level',fl.filter)" selected>
                  {{fl.filter}}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>

        <div class="vehicles-list-wrapper mt-4" *ngIf="vehicleLoader">
          <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'100px'}"></app-loader>
        </div>
        <div class="vehicles-list-wrapper mt-5 suggested-vehicles-wrapper" *ngIf="!vehicleLoader">
          <ng-container *ngIf="vehicleList.length > 0">
            <h2 class="info-title mb-3">Found {{total}} matched vehicles</h2>

            <div class="new-vehicle-tab bg-white shadow-sm p-3 mb-3 cursor-pointer" *ngFor="let vehicle of vehicleList"
              (click)="openDetails($event,vehicle)"
              [ngClass]="selectedVehicle && selectedVehicle.DatiumAssetKey == vehicle.DatiumAssetKey ? 'right-active' : ''">


              <div class="vehicle-wrapper">
                <div class="d-flex align-items-center mb-2">
                  <mat-chip selected class="pt-2" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                    [cbContent]="vehicle?.DatiumAssetKey" *ngxClipboardIfSupported
                    (cbOnSuccess)="copiedMsg('Copied to clipboard')">
                    {{vehicle?.DatiumAssetKey}}
                  </mat-chip>
                  <div class="brand_modal ms-3">
                    <span class="font-weight-bold">
                      <app-text-format [data]="vehicle?.vehicleDetails.make"></app-text-format>
                    </span>
                    <span class="dot"></span>
                    <app-text-format [data]="vehicle?.vehicleDetails.model"></app-text-format>
                    <span class="dot"></span>
                    <app-text-format [data]="vehicle?.vehicleDetails.year_group"></app-text-format>
                  </div>
                </div>
                <p>{{vehicle?.vehicleDetails.description}}</p>
              </div>
              <span class="material-symbols-outlined open-arrow">arrow_forward</span>
            </div>
            <div class="d-flex justify-content-center p-4" *ngIf="!noMore && !vehicleLoader && total>10">
              <button [disabled]="isPaging" (click)="onScroll()" mat-flat-button color="primary">
                {{isPaging?'Loading...':'Load More'}}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <img width="320" src="/assets/images/search-page-graphic.svg" alt="search-page-graphic">
      </div>
    </div>

    <div class="suggested-vehicles-wrapper">
      <div class="row mt-4" *ngIf="!filterModel['Year_Group']">
        <div class="col-md-6">
          <div class="vehicles-list-wrapper mt-4" *ngIf="recentLoader">
            <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'100px'}"></app-loader>
          </div>
          <div class="vehicles-list-wrapper mt-4" *ngIf="!recentLoader">
            <h2 class="info-title mb-3">Latest Searches</h2>
            <ng-container *ngIf="recentVehicles.length > 0;else noRecent">
              <div class="clickable bg-white shadow-sm p-3 mb-3" *ngFor="let vehicle of recentVehicles"
                (click)="setupSearch(vehicle)">
                <div class="vehicle-wrapper">
                  <div class="d-flex align-items-center mb-2">
                    <mat-chip selected class="d-flex align-items-center" ngxClipboard
                      [cbContent]="vehicle['DatiumAssetKey']" *ngxClipboardIfSupported
                      (cbOnSuccess)="copiedMsg('Copied to clipboard')">
                      {{vehicle['DatiumAssetKey']}}
                    </mat-chip>
                    <div class="brand_modal ms-3">
                      <span class="font-weight-bold">
                        <app-text-format [data]="vehicle.Make"></app-text-format>
                      </span>
                      <span class="dot"></span>
                      <app-text-format [data]="vehicle.Family"></app-text-format>
                      <span class="dot"></span>
                      <app-text-format [data]="vehicle.Year_Group"></app-text-format>
                    </div>
                  </div>
                  <p>{{vehicle.Description}}</p>
                </div>
              </div>
            </ng-container>
            <ng-template #noRecent>
              <no-record-found [notFoundHeading]="'No recent searches'" [cStyle]="{'height':'300px'}">
              </no-record-found>
            </ng-template>
          </div>
        </div>
        <div class="col-md-6">
          <div class="vehicles-list-wrapper mt-4" *ngIf="popularLoader">
            <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'100px'}"></app-loader>
          </div>
          <div class="vehicles-list-wrapper mt-4" *ngIf="!popularLoader">
            <h2 class="info-title mb-3">Popular Searches</h2>
            <ng-container *ngIf="popularVehicles.length > 0;else noPopular">
              <div class="clickable bg-white shadow-sm p-3 mb-3" *ngFor="let vehicle of popularVehicles"
                (click)="setupSearch(vehicle)">
                <div class="vehicle-wrapper">
                  <div class="d-flex align-items-center mb-2">
                    <mat-chip class="pointer" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                      [cbContent]="vehicle['DatiumAssetKey']" *ngxClipboardIfSupported
                      (cbOnSuccess)="copiedMsg('Copied to clipboard')" selected class="d-flex align-items-center">
                      {{vehicle['DatiumAssetKey']}}
                    </mat-chip>
                    <div class="brand_modal ms-3">
                      <span class="font-weight-bold">
                        <app-text-format [data]="vehicle.Make"></app-text-format>
                      </span>
                      <span class="dot"></span>
                      <app-text-format [data]="vehicle.Family"></app-text-format>
                      <span class="dot"></span>
                      <app-text-format [data]="vehicle.Year_Group"></app-text-format>
                    </div>
                  </div>
                  <p>{{vehicle.Description}}</p>
                </div>
              </div>
            </ng-container>
            <ng-template #noPopular>
              <no-record-found [notFoundHeading]="'No recent searches'" [cStyle]="{'height':'300px'}">
              </no-record-found>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>