<page-title [title]="'System Configuration'"></page-title>

<div class="row">

  <div class="col col-4 col-xl-3">

    <dashboard-sidebar [sidebarClass]="'d-flex dashboard_sidebar_wrapper myprofile'" [for]="'profile'"></dashboard-sidebar>

  </div>

  <div class="col col-8 col-xl-9">



  </div>

</div>