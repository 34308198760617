<div class="row table_row">
    <div class="col col-sm-3 col-lg-4 table_left_info">
        <div class="d-flex h-100">
            <div class="select_col tbox">
                <mat-checkbox (click)="toggleAcceptReject($event)" [disabled]="vehicleObj.accepted!=0"></mat-checkbox>
            </div>
            <div class="tbox vehicle_details">
                <div class="vehicle_breif">
                    <div class="d-flex align-items-center">
                        <mat-chip-list>
                            <mat-chip color="primary" selected>{{vehicleId}}</mat-chip>
                        </mat-chip-list>
                        <div class="brand_modal ml-3">
                            <span class="font-weight-bold">
                                <app-text-format [data]="vehicleObj?.vehicleDetails?.make"></app-text-format>
                            </span>
                            <span class="dot"></span>
                            <app-text-format [data]="vehicleObj?.vehicleDetails?.model"></app-text-format>
                            <span class="dot"></span>
                            <app-text-format [data]="vehicleObj?.vehicleDetails?.year_group"></app-text-format>
                        </div>
                        <!--div class="ml-3 text-primary d-flex tooltip_parent" *ngIf="linkedId">
              <svg width="18px" height="10px" class="mr-1">
                <path fill-rule="evenodd" fill="#2ca9e1" d="M5.400,5.833 L12.600,5.833 L12.600,4.167 L5.400,4.167 L5.400,5.833 ZM1.710,5.000 C1.710,3.083 3.330,1.583 5.400,1.583 L8.100,1.583 L8.100,-0.000 L5.400,-0.000 C2.430,-0.000 -0.000,2.250 -0.000,5.000 C-0.000,7.750 2.430,10.000 5.400,10.000 L8.100,10.000 L8.100,8.416 L5.400,8.416 C3.330,8.416 1.710,6.916 1.710,5.000 ZM12.600,-0.000 L9.900,-0.000 L9.900,1.583 L12.600,1.583 C14.670,1.583 16.290,3.083 16.290,5.000 C16.290,6.916 14.670,8.416 12.600,8.416 L9.900,8.416 L9.900,10.000 L12.600,10.000 C15.570,10.000 18.000,7.750 18.000,5.000 C18.000,2.250 15.570,-0.000 12.600,-0.000 Z" />
              </svg> {{linkedId}}
              <div class="tooltip_container">
                <div class="d-flex align-items-center">
                  <mat-chip-list>
                    <mat-chip color="primary" selected>{{linkedId}}</mat-chip>
                  </mat-chip-list>
                  <p class="font-weight-bold ml-3">Ford <span class="dot"></span> Escape</p>
                </div>
                <p class="linked_info">ZG MY 18 Ambiente Wagon 5dr Spts Auto 6sp 2WD 1.5T</p>
              </div>
            </div-->
                    </div>
                    <p class="mt-3">
                        <app-text-format [data]="vehicleObj?.vehicleDetails?.description"></app-text-format>
                    </p>
                </div>
            </div>
            <div class="tbox units">
                <app-text-format [data]="vehicleObj?.vehicleDetails?.units" [defaultNoFormat]="0"></app-text-format>
            </div>
        </div>
    </div>
    <!-- .col-lg-5 ends here -->
    <div class="col col-sm-9 col-lg-8 table_right_info">
        <div class="d-flex h-100">
            <div class="tbox border-left">
                <div class="two_vals">
                    <!-- <div class="text-primary text-uppercase" *ngIf="datiumIndex">Datium Index</div> -->
                    <div class="text-danger text-uppercase">Datium Model</div>
                </div>
            </div>
            <div class="tbox align-items-center">
                <div class="two_vals">
                    <!-- <div *ngIf="datiumIndex">$29,150</div> -->
                    <div>
                        <app-text-format [data]="vehicleObj?.vehicleDetails?.new_price" [defaultNoFormat]="0"
                            [formatType]="'new_price'"></app-text-format>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="vehicleObj?.dmValues">
                <div class="tbox">
                    <div class="two_vals">
                        <!-- <div *ngIf="datiumIndex">$29,150 (64%)</div> -->
                        <div>
                            <app-text-format [data]="vehicleObj?.dmValues?.dollarValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format [data]="vehicleObj?.dmValues?.percentageValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </div>
                    </div>
                </div>
                <div class="tbox">
                    <div class="two_vals">
                        <div>
                            <app-text-format [data]="vehicleObj?.dmValues?.dollarValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format [data]="vehicleObj?.dmValues?.percentageValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </div>
                    </div>
                </div>
                <div class="tbox">
                    <div class="two_vals">
                        <div>
                            <app-text-format [data]="vehicleObj?.dmValues?.dollarValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format [data]="vehicleObj?.dmValues?.percentageValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </div>
                    </div>
                </div>
                <div class="tbox">
                    <div class="two_vals">
                        <div>
                            <app-text-format [data]="vehicleObj?.dmValues?.dollarValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format [data]="vehicleObj?.dmValues?.percentageValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </div>
                    </div>
                </div>
                <div class="tbox">
                    <div class="two_vals">
                        <div>
                            <app-text-format [data]="vehicleObj?.dmValues?.dollarValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format [data]="vehicleObj?.dmValues?.percentageValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!vehicleObj?.dmValues">
                <div class="tbox no_data_available">
                    <div class="two_vals">
                        <div>
                            <span>No data available</span>
                        </div>
                    </div>
                </div>
                <span></span>
            </ng-container>
            <div class="tbox actions">
                <button (click)="acceptValues(vehicleId)" *ngIf="vehicleObj.accepted==0" class="btn btn-primary me-3"
                    mat-button><span class="material-symbols-outlined white">check_circle</span> {{ vehicleId ==
                    selectedVehicle ? 'Please wait..' : 'Accept'}}
                </button>
                <button (click)="rejectValues(vehicleId)" *ngIf="vehicleObj.accepted==0" class="btn btn-primary me-3"
                    mat-button><span class="material-symbols-outlined white">check_circle</span> {{ vehicleId ==
                    selectedVehicle ? 'Please wait..' : 'Reject'}}
                </button>
                <button *ngIf="vehicleObj.accepted==1" class="btn btn-primary me-3" mat-button><span
                        class="material-symbols-outlined white">check_circle</span> Accepted
                </button>
                <button *ngIf="vehicleObj.accepted==2" class="btn btn-primary me-3" mat-button><span
                        class="material-symbols-outlined white">check_circle</span> Rejected
                </button>
                <!--button class="btn btn-outline-primary" mat-stroked-button><svg width="18px" height="10px">
            <path fill-rule="evenodd" fill="#2ca9e1" d="M5.400,5.833 L12.600,5.833 L12.600,4.167 L5.400,4.167 L5.400,5.833 ZM1.710,5.000 C1.710,3.083 3.330,1.583 5.400,1.583 L8.100,1.583 L8.100,-0.000 L5.400,-0.000 C2.430,-0.000 -0.000,2.250 -0.000,5.000 C-0.000,7.750 2.430,10.000 5.400,10.000 L8.100,10.000 L8.100,8.416 L5.400,8.416 C3.330,8.416 1.710,6.916 1.710,5.000 ZM12.600,-0.000 L9.900,-0.000 L9.900,1.583 L12.600,1.583 C14.670,1.583 16.290,3.083 16.290,5.000 C16.290,6.916 14.670,8.416 12.600,8.416 L9.900,8.416 L9.900,10.000 L12.600,10.000 C15.570,10.000 18.000,7.750 18.000,5.000 C18.000,2.250 15.570,-0.000 12.600,-0.000 Z" />
          </svg> Link Vehicle</button-->
            </div>

        </div>
    </div>
    <!-- .col-lg-7 ends here -->
</div>