<h2 mat-dialog-title>
  <button mat-dialog-close [disabled]="isProcessing">
    <span class="material-symbols-outlined">close</span>
  </button>{{ isEdit ? 'Update User':'Invite New User' }}
</h2>
<mat-dialog-content class="mat-typography invite_user">
  <div class="popup_content">
    <form [formGroup]="userInvite" autocomplete="false" novalidate>
      <mat-form-field [floatLabel]="'never'" class="bordered">
        <label for="name">Full Name</label>
        <input matInput formControlName="name" name="name">
        <mat-error
          *ngIf="userInvite.controls.name.invalid && (userInvite.controls.name.dirty || userInvite.controls.name.touched)">
          <span *ngIf="userInvite.controls.name.errors.required">
            Name field should not be empty.
          </span>
          <span *ngIf="userInvite.controls.name.errors.maxlength">
            Company Field not be greater than 40 characters.
          </span>
          <span *ngIf="userInvite.controls.name.errors.pattern">
            Please enter a valid name.
          </span>
          <span *ngIf="formError.name">{{formError.name}}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field [floatLabel]="'never'" class="bordered">
        <label for="email">Email</label>
        <input type="text" matInput formControlName="email" name="email">
        <mat-error
          *ngIf="userInvite.controls.email.invalid && (userInvite.controls.email.dirty || userInvite.controls.email.touched)">
          <span *ngIf="userInvite.controls.email.errors.required">
            Email field should not be empty
          </span>
          <span *ngIf="userInvite.controls.email.errors.email">
            Please enter a valid email.
          </span>
          <span *ngIf="userInvite.controls.email.errors.maxlength">
            Email field should not be greater than 40 characters.
          </span>
          <span *ngIf="formError.email">{{formError.email}}</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field [floatLabel]="'never'" class="bordered">
        <label for="phone_no">Phone</label>
        <input matInput formControlName="phone_no" >
        <mat-error
          *ngIf="userInvite.controls.phone_no.invalid && (userInvite.controls.phone_no.dirty || userInvite.controls.phone_no.touched)">
          <span *ngIf="userInvite.controls.phone_no.errors.required">
            Phone field should not be empty.
          </span>
          <span *ngIf="userInvite.controls.phone_no.errors.minlength">
            Phone number should not be less than 8 digits.
          </span>
          <span *ngIf="formError.email">{{formError.phone_no}}</span>
        </mat-error>
      </mat-form-field>
      <div class="action_btns mt-3">
        <button *ngIf="!this.isEdit" class="btn btn-primary btn-lg mr-4" (click)="sendInvite(userInvite)" type="submit"
          [disabled]="isProcessing || isFetchingFrom" mat-button>{{(isProcessing) ? 'Sending...' : 'Send
          Invite'}}</button>
        <button *ngIf="this.isEdit" class="btn btn-primary btn-lg mr-4" (click)="updateUser(userInvite)" type="submit"
          [disabled]="isProcessing || isFetchingFrom" mat-button>{{(isProcessing) ? 'Updating...' : 'Update
          User'}}</button>
        <button class="btn btn-outline-primary btn-lg" (click)="closeModal()" type="button" [disabled]="isProcessing"
          mat-stroked-button>Cancel</button>
      </div>
    </form>
  </div>
</mat-dialog-content>