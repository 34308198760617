<h2 mat-dialog-title>
  <button class="m-0" (click)="setStep(1)">Products</button>
  <ng-container *ngIf="step == 2">
    <span class="material-symbols-outlined">keyboard_arrow_right</span>
    <button [disabled]="isSubProcessing" class="m-0">
      {{ offer["offerDetails"]["PlanName"] }}
    </button>
  </ng-container>
  <button mat-dialog-close [disabled]="isSubProcessing">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>
<mat-dialog-content class="mat-typography add_company_popup">
  <ng-container *ngIf="step == 1">
    <app-offer-listing (offerSelect)="setOffer($event)" [companyId]="companyId"></app-offer-listing>
  </ng-container>
  <ng-container *ngIf="step == 2">
    <app-add-product-v2 [companyId]="companyId" [offer]="offer" [isEdit]="true"
      (onCompanySubAdd)="onCompanySubAdd($event)" (inProgress)="subInProgress($event)">
    </app-add-product-v2>
  </ng-container>
</mat-dialog-content>