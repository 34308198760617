<header class="page-header mb-5">
  <div class="d-flex align-items-center title-with-dropdown mb-5">
    <h1 class="page-title">{{title}} <span *ngIf="totalRecord > 0">&nbsp;({{totalRecord}})</span>
    </h1>

    <div *ngIf="isSuperAdmin" class="di-input-wrapper ms-4">
      <mat-form-field *ngIf="exportDataOptions.length" appearance="fill" floatLabel="never" class="mb-0">
        <mat-select [(ngModel)]="selectedExportPage" placeholder="Select">
          <mat-option *ngFor="let record of exportDataOptions" value="{{record.value}}">{{record.key}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button *ngIf="totalRecord !== 0 && isSuperAdmin" (click)="exportRecords()"
      [disabled]="isExporting || isSearchFinished || (exportDataOptions.length > 0 && selectedExportPage==='')"
      class="ms-4" mat-flat-button color="primary">{{isExporting ? 'Exporting' : 'Export'}}
      {{isExporting?'...':''}}</button>

  </div>
</header>
<div class="selected_vehicles mt-5" *ngIf="selectedVehicles.length>1 || selectCarForCompare.length > 1">
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <h2 class="font-weight-bold mb-0 me-3">{{getSelectionLen()}} Vehicles Selected</h2>
      <button (click)="compareVehicles()" mat-stroked-button color="primary"
        *ngIf="selectCarForCompare.length > 1">Compare
        Selected</button>
      <button (click)="acceptValues()" class="ms-3" mat-flat-button color="primary" [disabled]="isLoader"
        *ngIf="selectedVehicles.length > 1">
        <span class="material-symbols-outlined">check_circle</span>
        Accept Selected
      </button>
      <app-loader [ShowLoader]="isLoader" [CStyle]="{'min-height':'30px'}" class="ml-3"
        [text]="'Processing...'"></app-loader>
    </div>
    <div>
      <button (click)="rejectValues()" class="ms-3" mat-stroked-button color="warn" [disabled]="isLoader"
        *ngIf="selectedVehicles.length > 1">
        <span class="material-symbols-outlined">cancel</span>
        Reject Selected
      </button>
    </div>
  </div>
</div>
<app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'listing'"
  (searchResponse)="searchResponse($event)" [filterClass]="'col-md-2 mt-3'"></app-vehicle-filter-search>
<app-bi-vehicle-list [type]="newType" [routeName]="routeName" [filterState]="filterState"
  (listState)="listLoaded($event)" (resetSelection)="resetSelection($event)"
  (resetTotalSelection)="resetTotalSelection($event)" (exportFinishEvent)="exportFinishEvent($event)"
  (searchFinishEvent)="searchFinshedEvent($event)" (selectAllEvent)="selectAllEvent($event)"
  [selectAllCheckbox]="isSelectAllEvent"></app-bi-vehicle-list>
<!-- <new-vehicle-list-wrapper [filterState]="filterState" (resetSelection)="resetSelection($event)" (resetTotalSelection)="resetTotalSelection($event)"
  (listLoaded)="listLoaded($event)"></new-vehicle-list-wrapper> -->