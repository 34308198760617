<div class="mb-4">
  <page-title [title]="'Snapshots'"></page-title>
</div>

<div class="row">
  <div class="col col-12">

    <div *ngIf="reportList.data.length > 0" class="mb-3">
      <div class="row">
        <div class="col-md-3">
          <div class="di-input-wrapper">
            <mat-form-field flaotLabel="never" appearance="fill">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div class="vreports_table_container">
      <table *ngIf="reportList.data.length > 0" mat-table [dataSource]="reportList" matSort class="custom-table">
        <!-- Position Column -->
        <ng-container matColumnDef="selectBox">
          <th mat-header-cell *matHeaderCellDef> <mat-checkbox class="me-3" matTooltip="Click to Select All"
              [checked]="isAllSelected" (change)="toggleSelectAll()">
            </mat-checkbox> <span *ngIf="!isAllSelected">Select </span><span *ngIf="isAllSelected">Unselect </span>All
            &nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox matTooltip="Click to Select" [(ngModel)]="element.checked"
              (change)="onChange($event, element)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="DatiumAssetKey">
          <th mat-header-cell *matHeaderCellDef> Datium Asset Key </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip color="primary" class="pointer" selected matTooltip="Click to copy Datium Asset Key" ngxClipboard
                [cbContent]="element?.DatiumAssetKey" *ngxClipboardIfSupported
                (cbOnSuccess)="copiedMsg('Copied to clipboard')">{{element?.DatiumAssetKey}}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef> Vehicle Description </th>
          <td mat-cell *matCellDef="let element">
            <div class="brand_modal">
              <app-text-format [data]="element?.Make"></app-text-format>
              &nbsp;
              <app-text-format [data]="element?.Family"></app-text-format>
              <br />
              <app-text-format [data]="element?.Description"></app-text-format>
            </div>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="NewPrice">
          <th mat-header-cell *matHeaderCellDef> New Price </th>
          <td mat-cell *matCellDef="let element">
            <app-text-format [data]="element.NewPrice" [defaultNoFormat]="0" [formatType]="'new_price'">
            </app-text-format>
          </td>
        </ng-container>

        <ng-container matColumnDef="age_12">
          <th mat-header-cell *matHeaderCellDef> Term </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-stats">
              <div>
                <app-text-format [data]="element.age_12"></app-text-format>
              </div>
              <div *ngIf="element.age_24">
                <app-text-format [data]="element.age_24"></app-text-format>
              </div>
              <div *ngIf="element.age_36">
                <app-text-format [data]="element.age_36"></app-text-format>
              </div>
              <div *ngIf="element.age_48">
                <app-text-format [data]="element.age_48"></app-text-format>
              </div>
              <div *ngIf="element.age_60">
                <app-text-format [data]="element.age_60"></app-text-format>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="km_12">
          <th mat-header-cell *matHeaderCellDef> KM </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-stats">
              <div>
                <app-text-format [data]="element.km_12"></app-text-format>
              </div>
              <div *ngIf="element.age_24">
                <app-text-format [data]="element.km_24"></app-text-format>
              </div>
              <div *ngIf="element.age_36">
                <app-text-format [data]="element.km_36"></app-text-format>
              </div>
              <div *ngIf="element.age_48">
                <app-text-format [data]="element.km_48"></app-text-format>
              </div>
              <div *ngIf="element.age_60">
                <app-text-format [data]="element.km_60"></app-text-format>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="rv_per_12">
          <th mat-header-cell *matHeaderCellDef> RV </th>
          <td mat-cell *matCellDef="let element">
            <div class="table-stats">
              <div>
                <app-text-format [data]="element.rv_dollar_12" [defaultNoFormat]="0" [formatType]="'dollar'">
                </app-text-format>
                <app-text-format [data]="element.rv_per_12" [defaultNoFormat]="0" [formatType]="'percentage'">
                </app-text-format>
              </div>
              <div *ngIf="element.age_24">
                <app-text-format [data]="element.rv_dollar_24" [defaultNoFormat]="0" [formatType]="'dollar'">
                </app-text-format>
                <app-text-format [data]="element.rv_per_24" [defaultNoFormat]="0" [formatType]="'percentage'">
                </app-text-format>
              </div>
              <div *ngIf="element.age_36">
                <app-text-format [data]="element.rv_dollar_36" [defaultNoFormat]="0" [formatType]="'dollar'">
                </app-text-format>
                <app-text-format [data]="element.rv_per_36" [defaultNoFormat]="0" [formatType]="'percentage'">
                </app-text-format>
              </div>
              <div *ngIf="element.age_48">
                <app-text-format [data]="element.rv_dollar_48" [defaultNoFormat]="0" [formatType]="'dollar'">
                </app-text-format>
                <app-text-format [data]="element.rv_per_48" [defaultNoFormat]="0" [formatType]="'percentage'">
                </app-text-format>
              </div>
              <div *ngIf="element.age_60">
                <app-text-format [data]="element.rv_dollar_60" [defaultNoFormat]="0" [formatType]="'dollar'">
                </app-text-format>
                <app-text-format [data]="element.rv_per_60" [defaultNoFormat]="0" [formatType]="'percentage'">
                </app-text-format>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let element">
            <app-text-format [data]="element.type" [formatType]="element.type"></app-text-format>
          </td>
        </ng-container>

        <ng-container matColumnDef="formatCreatedDate">
          <th mat-header-cell *matHeaderCellDef> Created</th>
          <td mat-cell *matCellDef="let element">
            <app-text-format [data]="element.formatCreatedDate" [defaultNoFormat]="0">
            </app-text-format>
          </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div *ngIf="isLoading" class="vreports_loader">
        <app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'100px'}"></app-loader>
      </div>
      <no-record-found class="vreports_loader" *ngIf="!isLoading && reportList.data.length <= 0"
        [notFoundHeading]="'No Snapshots found'" [cStyle]="{'height':'300px'}"></no-record-found>
    </div>
    <mat-paginator *ngIf="reportList.data.length > 0" class="mt-4 no_bg" [pageSize]="pageSize"
      [pageSizeOptions]="[20,50,75,100]" (page)="getPaginatorOptions($event)"></mat-paginator>
  </div>
</div>
<app-export-lists [selectedItems]="selectedItems" [selectedFor]="'Reports'"
  (exportingFinishEvent)="exportingFinishEvent($event)"></app-export-lists>