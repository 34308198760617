<mat-dialog-content class="mat-typography confirmation_popup">

  <button class="close_btn" mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>

  <div class="popup_content p-3">

    <div class="text-center">

      <svg _ngcontent-c35="" fill="none" height="76" viewBox="0 0 76 76" width="76" xmlns="http://www.w3.org/2000/svg">
        <path _ngcontent-c35=""
          d="M16.7305 59.2695C22.707 65.1289 29.7969 68.0586 38 68.0586C46.2031 68.0586 53.2344 65.1289 59.0938 59.2695C65.0703 53.293 68.0586 46.2031 68.0586 38C68.0586 29.7969 65.0703 22.7656 59.0938 16.9062C53.2344 10.9297 46.2031 7.94141 38 7.94141C29.7969 7.94141 22.707 10.9297 16.7305 16.9062C10.8711 22.7656 7.94141 29.7969 7.94141 38C7.94141 46.2031 10.8711 53.293 16.7305 59.2695ZM11.457 11.6328C18.8398 4.25 27.6875 0.558594 38 0.558594C48.3125 0.558594 57.1016 4.25 64.3672 11.6328C71.75 18.8984 75.4414 27.6875 75.4414 38C75.4414 48.3125 71.75 57.1602 64.3672 64.543C57.1016 71.8086 48.3125 75.4414 38 75.4414C27.6875 75.4414 18.8398 71.8086 11.457 64.543C4.19141 57.1602 0.558594 48.3125 0.558594 38C0.558594 27.6875 4.19141 18.8984 11.457 11.6328ZM34.3086 19.1914H41.6914V41.6914H34.3086V19.1914ZM34.3086 49.25H41.6914V56.8086H34.3086V49.25Z"
          fill="#ED4850" opacity="0.5"></path>
      </svg>

      <h2>{{title}}</h2>

      <div class="confirm_btns" *ngIf="nrEmail === 'notRegisteredEmail'">
        <button mat-stroked-button (click)="triggerAction('no')">Ok</button>
      </div>

      <div class="confirm_btns" *ngIf="nrEmail !== 'notRegisteredEmail'">
        <button class="me-3" mat-flat-button color="primary" (click)="triggerAction('yes')">Yes</button>
        <button mat-stroked-button (click)="triggerAction('no')">No</button>
      </div>

    </div>

  </div>

</mat-dialog-content>