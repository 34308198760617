<div class="support_headings">
  <h2>Frequently Asked Questions</h2>
</div>
<mat-accordion>
  <mat-expansion-panel class="mb-3 shadow-sm" *ngFor="let helpT of helpTopics; let i=index">
    <mat-expansion-panel-header>
      <mat-panel-title><span class="question_number">{{i+1}}</span> {{helpT.ques}}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="faq_answer">
      <p>{{helpT.ans}}</p>
    </div>
  </mat-expansion-panel>
</mat-accordion>