<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select name="VFactsGroups" [formControlName]="'VFactsGroups'" placeholder="VFacts Group" [multiple]="isMultiple"
    [attr.disabled]="isLoading" (selectionChange)="selectionChange($event.value)">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.VFactsGroups" [values]="vFactGroups"
      (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngFor="let vfGroup of vFactGroups" [value]="vfGroup.filter">{{vfGroup.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.VFactsGroups.invalid && (parentForm.controls.VFactsGroups.dirty || parentForm.controls.VFactsGroups.touched)">
    <span *ngIf="parentForm.controls.VFactsGroups.errors.required || parentForm.controls.VFactsGroups.errors.VFactsGroups">
      Please choose VFacts Groups
    </span>
    <span *ngIf="formError?.VFactsGroups">{{formError.VFactsGroups}}</span>
  </mat-error>
</mat-form-field>
