/*
* @ngdoc Component
* @name app.shared.filters.VfactsGroupFilterComponent
* @description
* This component is used to manage the vfacts group filter
* */

import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { VehicleService } from "../../../services/vehicle.service";
import { CommonService } from '../../../services/common.service';
import { DataStoreService } from '../../../services/data-store.service';
import { FilterName } from '../../../configs/filter-names';
import { Subscription } from 'rxjs';
@Component({
  selector: 'vfacts-group-filter',
  templateUrl: './vfacts-group-filter.component.html',
  styleUrls: ['./vfacts-group-filter.component.scss']
})
export class VfactsGroupFilterComponent implements OnInit, OnDestroy {

  @Input() field_type: string;
  @Input() field_class: string;
  @Input() isMultiple: boolean = false;
  @Input('form') parentForm: UntypedFormGroup;
  @Input() formError: any
  @Input() filterList: Array<any> = []

  isLoading: boolean = false;
  vSubscription: Subscription
  checkResetVehicleFilterList: Subscription
  getSelectedFilters: Subscription
  checkInitResetVehicleFilterList: Subscription
  vFGroups = new UntypedFormControl();
  vFactGroups = []
  selectedVFactGroups = "";
  selectedFilter: any = {};
  @Output() sendFilterSelect = new EventEmitter();
  constructor(private vehicleService: VehicleService, private commonService: CommonService, private dataStore: DataStoreService, private filterName: FilterName) {
    this.selectedFilter = filterName.filter

    this.initSub();
  }

  ngOnInit() {
    if (this.isMultiple == false) {
      this.getVFactsGroups()
    } else {
      this.vFactGroups = this.filterList
    }
  }

  ngOnChanges(changes) {
    if (changes['filterList'] && this.isMultiple == true) {
      this.vFactGroups = changes.filterList.currentValue
    }
  }


  initSub() {
    this.getSelectedFilters = this.dataStore.getSelectedFilters().subscribe(data => {
      this.selectedFilter = data.filters;
    })
    /*this.dataStore.getFilterSelectSignal().subscribe(data => {
      if (data.filter == 'Make' && (this.selectedFilter.VFactsGroups == "" || this.selectedFilter.VFactsGroups == null)) {
        this.getVFactsGroups()
      }
    })*/
    //reset filter
    this.checkResetVehicleFilterList = this.dataStore.checkResetVehicleFilterList().subscribe(data => {
      this.vFactGroups = [];
      this.getVFactsGroups()
    })
    if (this.isMultiple == false) {
      this.checkInitResetVehicleFilterList = this.dataStore.getInitFilterSelectSignal().subscribe(data => {
        this.vFactGroups = [];
        this.getVFactsGroups()
      })
    }
  }

  /*
  * @name getVFactsGroups
  * @param none
  * @description Get VFactsGroups filter list                  
  * @return none
  */
  getVFactsGroups() {
    this.isLoading = true
    let apiParams = {
      filter: 'VFactsGroups'
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    delete apiParams['VFactsGroups']
    this.vSubscription = this.vehicleService.getVehicleFilters(apiParams).subscribe(
      (result: any) => {
        try {
          let response = result;
          //console.log(response, 'response')
          this.vFactGroups = response.data;
          //this.commonService.showSnakeBar(response.message);
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error;
        this.isLoading = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isLoading = false;
      }
    )
  }

  /*
  * @name selectionChange
  * @param  Array(Selected values)
  * @description Get selected values                    
  * @return none
  */
  selectionChange(value) {
    console.log('v_fact change', value)
    if (this.isMultiple) {
      this.sendFilterSelect.emit({ filter: 'VFactsGroups', filterVal: value, 'all': false, toggle: false });
    } else {
      this.dataStore.sendFilterSelectSignal('VFactsGroups', value)
    }
  }

  /*
  * @name checkAll
  * @param  Array(response)
  * @description Hangle when user select All options in a filter
  * @return none
  */
  checkAll(response) {
    this.sendFilterSelect.emit({ filter: 'VFactsGroups', filterVal: response.data, 'all': true, toggle: response.toggle });
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: UntypedFormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.checkResetVehicleFilterList) {
      this.checkResetVehicleFilterList.unsubscribe()
    }
    if (this.getSelectedFilters) {
      this.getSelectedFilters.unsubscribe()
    }
    if (this.checkInitResetVehicleFilterList) {
      this.checkInitResetVehicleFilterList.unsubscribe()
    }
  }

}
