/*
* @ngdoc Module
* @name app.dashboard
* @description
* This module is used to handle all the features related to dashboard.
* */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { ApCommonModule } from '../ap-common/ap-common.module';
import { MaterialComponentsModule } from '../material-components.module';

import { CommonService } from '../services/common.service';

import { DashboardComponent } from './dashboard-wrapper/dashboard.component';
import { DashboardSidebarComponent } from './dashboard-sidebar/dashboard-sidebar.component';
import { HistoryWrapComponent } from './history-wrap/history-wrap.component';
import { RvChangesComponent } from './rv-changes/rv-changes.component';
import { DatiumChangesComponent } from './datium-changes/datium-changes.component';
import { DashboardActionsComponent } from './dashboard-actions/dashboard-actions.component';
import { DashboardService } from '../services/dashboard.service';
import { SingleSignInComponent } from './single-sign-in/single-sign-in.component';
import { NewVehiclesAddedComponent } from './new-vehicles-added/new-vehicles-added.component';
import { VehiclesRecentlySearchedComponent } from './vehicles-recently-searched/vehicles-recently-searched.component';
import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { GraphWidgetComponent } from './graph-widget/graph-widget.component';
import { SafeHtml } from '../pipes/safe-html';
import { ClipboardModule } from 'ngx-clipboard';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    SharedModule,
    RouterModule,
    ApCommonModule,
    NgChartsModule,
    ClipboardModule
  ],
  declarations: [
    DashboardComponent,
    DashboardSidebarComponent,
    HistoryWrapComponent,
    RvChangesComponent,
    DatiumChangesComponent,
    DashboardActionsComponent,
    SingleSignInComponent,
    NewVehiclesAddedComponent,
    VehiclesRecentlySearchedComponent,
    VehiclesListComponent,
    GraphWidgetComponent,
    SafeHtml
  ],
  exports: [
    DashboardSidebarComponent
  ],
  providers: [
    CommonService, DashboardService
  ]
})
export class DashboardModule { }
