<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [formControlName]="'Vehicle_Level'" name="Vehicle_Level" placeholder="Vehicle Level" [multiple]="isMultiple"
    [attr.disabled]="isLoading" (selectionChange)="selectionChange($event.value)" [(value)]="selectedVLevel">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Vehicle_Level" [values]="vLevelList"
      (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngIf="!isMultiple" [value]="'All'">All</mat-option>
    <mat-option *ngFor="let vLevelObj of vLevelList" [value]="vLevelObj.filter">{{vLevelObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Vehicle_Level.invalid && (parentForm.controls.Vehicle_Level.dirty || parentForm.controls.Vehicle_Level.touched)">
    <span *ngIf="parentForm.controls.Vehicle_Level.errors.required || parentForm.controls.Vehicle_Level.errors.Vehicle_Level">
      Please choose Vehicle Level
    </span>
    <span *ngIf="formError?.Vehicle_Level">{{formError.Vehicle_Level}}</span>
  </mat-error>
</mat-form-field>
