<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'profile'">
    <img *ngIf="jwtService.getProfileImage() == ''" src="assets/images/no_user.png" alt="{{jwtService.getUserName()}}">
    <img *ngIf="jwtService.getProfileImage() != ''" src="{{commonService.azureURL + jwtService.getProfileImage()}}" alt="{{jwtService.getUserName()}}">
  </ng-container>
  <ng-container *ngSwitchCase="'company'">
    <app-svg-icon [type]="'cmp_profile'" *ngIf="jwtService.getCompanyImage() == ''"></app-svg-icon>
    <img *ngIf="jwtService.getCompanyImage() != ''" src="{{commonService.azureURL + jwtService.getCompanyImage()}}" alt="{{jwtService.getUserName()}}">
  </ng-container>
</ng-container>
