<div class="linking_tree_wrapper mt-4" *ngIf="(page==1 && !isLoadingList && vehicleList.length <= 0) || isLoadingList">
  <no-record-found [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'300px'}"
    *ngIf="(page==1 && !isLoadingList && vehicleList.length <= 0)"></no-record-found>
  <div class="vehicle_listing_table" *ngIf="isLoadingList">
    <ul class="table_body">
      <vehicle-list-loading></vehicle-list-loading>
    </ul>
  </div>
</div>
<div class="linking_tree_wrapper mt-4" *ngIf="vehicleList.length > 0 && !isLoadingList">
  <div class="row mb-2 linking_tree_heading">
    <div class="col col-lg-6">
      <h3>List</h3>
    </div>
    <div class="col col-lg-6">
      <h3>Linked Vehicle</h3>
    </div>
  </div>
  <div class="linking_row" *ngFor="let vehicleObj of vehicleList">
    <ve-loader [ShowLoader]="fetchingDetails == vehicleObj?.vehicle?.DatiumAssetKey"
      [text]="'Please wait while we are fetching the latest changes.'"></ve-loader>
    <a href="javascript:void(0)" (click)="vehicleLinkage(vehicleObj?.vehicle,vehicleObj?.linkedVehicle)"
      class="link_circle" *ngIf=" vehicleObj?.vehicle && vehicleObj?.linkedVehicle && !fetchingDetails">
      <span class="material-symbols-outlined blue">link</span>
    </a>
    <linking-tree-vehicle [vehicle]="vehicleObj?.vehicle" *ngIf="vehicleObj?.vehicle"></linking-tree-vehicle>
    <vehicle-linked [vehicle]="vehicleObj?.linkedVehicle" *ngIf="vehicleObj?.linkedVehicle"></vehicle-linked>
  </div>
  <div class="d-flex justify-content-center p-4" *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
    <button color="primary" (click)="onScroll()" mat-flat-button>Load More</button>
  </div>
  <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
  <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
    [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
</div>