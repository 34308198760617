<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [formControlName]="'Body_Type'" name="Body_Type" placeholder="Body Type" [multiple]="isMultiple"
    [attr.disabled]="isLoading" (selectionChange)="selectionChange($event.value)" [(value)]="selectedBodyType">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Body_Type" [values]="bodyTypeList" (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option [value]="'All'" *ngIf="!isMultiple">All</mat-option>
    <mat-option *ngFor="let bodyTypeObj of bodyTypeList" [value]="bodyTypeObj.filter">{{bodyTypeObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Body_Type.invalid && (parentForm.controls.Body_Type.dirty || parentForm.controls.Body_Type.touched)">
    <span *ngIf="parentForm.controls.Body_Type.errors.required || parentForm.controls.Body_Type.errors.Body_Type">
      Please choose Body Type
    </span>
    <span *ngIf="formError?.Body_Type">{{formError.Body_Type}}</span>
  </mat-error>
</mat-form-field>
