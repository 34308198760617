<h2 mat-dialog-title>
    {{getTitle()}}
    <button mat-dialog-close [disabled]="isProcessing">
        <span class="material-symbols-outlined">close</span>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <form [formGroup]="riskAdjustmentForm" autocomplete="false" novalidate>
        <div class="row mb-4">
            <div class="col col-lg-12 mb-2">
                <div class="di-input-wrapper">
                    <label class="custom-label">VFacts Group</label>
                    <mat-form-field appearance="fill" floatLabel="never">
                        <mat-select name="VFactsGroups" formControlName="VFactsGroups" placeholder="Select"
                            [multiple]="isMultiple">
                            <mat-option *ngFor="let vfGroup of vFactGroups"
                                [value]="vfGroup.filter">{{vfGroup.filter}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col col-lg-12 mb-2">
                <div class="di-input-wrapper">
                    <label class="custom-label">Value</label>
                    <mat-form-field appearance="fill" floatLabel="never" class="mb-0">
                        <input matInput formControlName="value" type="number" placeholder="Value">
                    </mat-form-field>
                    <mat-error class="mt-2"
                        *ngIf="riskAdjustmentForm.controls.value.invalid && (riskAdjustmentForm.controls.value.dirty || riskAdjustmentForm.controls.value.touched)">
                        <span *ngIf="riskAdjustmentForm.controls.value.errors.required">
                            Value field should not be empty.
                        </span>
                        <span *ngIf="formError.value">{{formError.value}}</span>
                    </mat-error>
                </div>
            </div>
        </div>
        <div class="form_btns">
            <button *ngIf="!isEdit" color="primary" mat-flat-button [disabled]="isProcessing"
                (click)="addNewRiskAdjustment(riskAdjustmentForm)" type="submit">{{isProcessing ? 'Please Wait...' :
                'Add Adjustment'}}</button>
            <button *ngIf="isEdit" color="primary" mat-flat-button [disabled]="isProcessing"
                (click)="updateNewAdjustment(riskAdjustmentForm)" type="submit">{{isProcessing ? 'Please Wait...' :
                'Update Adjustment'}}</button>
            <button class="ms-3" mat-stroked-button color="primary" (click)="closeModal()" [disabled]="isProcessing"
                type="button">Cancel</button>
        </div>
    </form>
</mat-dialog-content>