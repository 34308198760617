<div class="selected_vehicles" *ngIf="selectCarForCompare && selectCarForCompare.length>0">
  <div class="d-flex align-items-center">
    <h2 class="font-weight-bold mb-0 me-3">{{selectCarForCompare.length}} Vehicles Selected</h2>
    <button *ngIf="selectCarForCompare && selectCarForCompare.length>1" (click)="compareVehicles()" mat-flat-button
      color="primary">Compare Vehicles</button>
    <button (click)="exportVehicles()" *appHasPermission="['v_export']" [disabled]="isExporting" mat-stroked-button
      color="primary" class="ms-3">
      {{isExporting?'Exporting':'Export'}} {{isSuperAdmin ? 'Datium' : ''}}{{isCompanyUser ? 'RV' : ''}}
      {{isExporting?'...':''}}
    </button>
  </div>
</div>