<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  Add {{roleCheckService.isSuperAdmin() ? 'Datium' : 'RV'}} Values
  <button mat-dialog-close [disabled]="isSaving">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography rv_calculator rv_value_popup">
  <vehicle-brief [isLinked]="vehicle?.linkedVehicle" [rvIncluded]="vehicle?.rvValues || vehicle?.dmValues"
    [linkedId]="vehicle?.linkedVehicle" [vehicleDetails]="vehicle" class="bg-primary text-white"></vehicle-brief>
  <app-loader [ShowLoader]="isProcessing" [CStyle]="{'min-height':'300px'}"></app-loader>
  <div class="add_rv_value" *ngIf="!isProcessing">
    <mat-radio-group [(ngModel)]="isPercentage" class="mb-4 d-block">
      <mat-radio-button value="percentage" class="me-4" checked>% Value</mat-radio-button>
      <mat-radio-button value="dollar">$ Value</mat-radio-button>
    </mat-radio-group>
    <form #carValueForm="ngForm">
      <div *ngIf="isPercentage == 'percentage'">
        <div class="row">
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">12m <br />(30,000km)</label>
              <mat-form-field class="mw-auto mb-1 suffix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.percent.per_12" name="per_12"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetDollar(saveValuesModel.percent.per_12, 'dollar_12','per_12')">
                <span matSuffix><span class="material-symbols-outlined">percent</span></span>
              </mat-form-field>
              <strong>
                ${{saveValuesModel.dollar.dollar_12 | number : '1.2-2'}}
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">24m <br />(60,000km)</label>
              <mat-form-field class="mw-auto mb-1 suffix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.percent.per_24" name="per_24"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetDollar(saveValuesModel.percent.per_24, 'dollar_24','per_24')">
                <span matSuffix><span class="material-symbols-outlined">percent</span></span>
              </mat-form-field>
              <strong>
                ${{saveValuesModel.dollar.dollar_24 | number : '1.2-2'}}
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">36m <br />(90,000km)</label>
              <mat-form-field class="mw-auto mb-1 suffix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.percent.per_36" name="per_36"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetDollar(saveValuesModel.percent.per_36, 'dollar_36','per_36')">
                <span matSuffix><span class="material-symbols-outlined">percent</span></span>
              </mat-form-field>
              <strong>
                ${{saveValuesModel.dollar.dollar_36 | number : '1.2-2'}}
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">48m <br />(120,000km)</label>
              <mat-form-field class="mw-auto mb-1 suffix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.percent.per_48" name="per_48"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetDollar(saveValuesModel.percent.per_48, 'dollar_48','per_48')">
                <span matSuffix><span class="material-symbols-outlined">percent</span></span>
              </mat-form-field>
              <strong>
                ${{saveValuesModel.dollar.dollar_48 | number : '1.2-2'}}
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">60m <br />(150,000km)</label>
              <mat-form-field class="mw-auto mb-1 suffix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.percent.per_60" name="per_60"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetDollar(saveValuesModel.percent.per_60, 'dollar_60','per_60')">
                <span matSuffix><span class="material-symbols-outlined">percent</span></span>
              </mat-form-field>
              <strong>
                ${{saveValuesModel.dollar.dollar_60 | number : '1.2-2'}}
              </strong>
            </div>
          </div>
        </div>
        <!-- <div class="di-input-wrapper add_comments">
          <label class="custom-label">Add Comments</label>
          <mat-form-field class="mw-auto mb-1" [floatLabel]="'never'" appearance="fill">
            <textarea matInput placeholder="Add some comments here" [(ngModel)]="saveValuesModel.comments" name="comments"
              required></textarea>
          </mat-form-field>
        </div>
        <div class="popup_btns mt-4 mb-4">
          <button class="btn btn-primary me-3" mat-button (click)="validateForm(carValueForm)">Save Value</button>
          <button class="btn btn-outline-primary" mat-dialog-close mat-stroked-button>Cancel</button>
        </div> -->
      </div>

      <div *ngIf="isPercentage == 'dollar'">
        <div class="row">
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">12m <br />(30,000km)</label>
              <mat-form-field class="mw-auto mb-1 prefix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.dollar.dollar_12" name="dollar_12"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetPercent(saveValuesModel.dollar.dollar_12, 'per_12','dollar_12')">
                <span matPrefix><span class="material-symbols-outlined">attach_money</span></span>
              </mat-form-field>
              <strong>
                {{saveValuesModel.percent.per_12 | number : '1.2-2'}}%
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">24m <br />(60,000km)</label>
              <mat-form-field class="mw-auto mb-1 prefix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.dollar.dollar_24" name="dollar_24"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetPercent(saveValuesModel.dollar.dollar_24, 'per_24','dollar_24')">
                <span matPrefix><span class="material-symbols-outlined">attach_money</span></span>
              </mat-form-field>
              <strong>
                {{saveValuesModel.percent.per_24 | number : '1.2-2'}}%
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">36m <br />(90,000km)</label>
              <mat-form-field class="mw-auto mb-1 prefix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.dollar.dollar_36" name="dollar_36"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetPercent(saveValuesModel.dollar.dollar_36, 'per_36','dollar_36')">
                <span matPrefix><span class="material-symbols-outlined">attach_money</span></span>
              </mat-form-field>
              <strong>
                {{saveValuesModel.percent.per_36 | number : '1.2-2'}}%
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">48m <br />(120,000km)</label>
              <mat-form-field class="mw-auto mb-1 prefix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.dollar.dollar_48" name="dollar_48"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetPercent(saveValuesModel.dollar.dollar_48, 'per_48','dollar_48')">
                <span matPrefix><span class="material-symbols-outlined">attach_money</span></span>
              </mat-form-field>
              <strong>
                {{saveValuesModel.percent.per_48 | number : '1.2-2'}}%
              </strong>
            </div>
          </div>
          <div class="col">
            <div class="di-input-wrapper">
              <label class="custom-label">60m <br />(150,000km)</label>
              <mat-form-field class="mw-auto mb-1 prefix" [floatLabel]="'never'" appearance="fill">
                <input matInput [(ngModel)]="saveValuesModel.dollar.dollar_60" name="dollar_60"
                  [disabled]="getDisableStatus()"
                  (input)="getAndSetPercent(saveValuesModel.dollar.dollar_60, 'per_60','dollar_60')">
                <span matPrefix><span class="material-symbols-outlined">attach_money</span></span>
              </mat-form-field>
              <strong>
                {{saveValuesModel.percent.per_60 | number : '1.2-2'}}%
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="di-input-wrapper mt-3">
        <label class="custom-label">Add Comments</label>
        <mat-form-field class="mw-auto mb-1 textarea" [floatLabel]="'never'" appearance="fill">
          <textarea matInput placeholder="Add some comments here" [(ngModel)]="saveValuesModel.comments" name="comments"
            required [disabled]="getDisableStatus()">
          </textarea>
        </mat-form-field>
      </div>
      <div class="popup_btns mt-4 mb-4">
        <button class="me-3" mat-flat-button color="primary" (click)="validateForm(carValueForm)"
          [disabled]="getDisableStatus()">
          {{isSaving ? 'Saving' : 'Save'}} Changes
        </button>
        <button color="primary" mat-dialog-close mat-stroked-button [disabled]="isSaving">Cancel</button>
      </div>
    </form>
  </div>
</mat-dialog-content>