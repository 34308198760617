<div class="support_headings">
  <h2>RV Calculator</h2>
  <h5>Screen for retrieving RV’s for non-standard term and kilometre combinations.</h5>
</div>

<div class="help_steps">

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">1</span>
      <div class="step_count">If you would like to retrieve an RV outside of the standard terms provided then you can
        do this via the RV Calculator feature. Click on the <strong>RV Calculator</strong> button near the top right of
        the screen, search for the vehicle you would like to retrieve the RV for and click the <strong>Calculate RV
          button</strong>. Or you can click on the calculator icon next to the vehicle's Datium Asset Key.</div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot top_margin">
        <div class="step_zoom rvcalc_step_zoom1">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)" d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <div class="step_zoom rvcalc_step_zoom2">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)" d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/rv_calculator/rvcalc_step1.jpg" alt="Step1">
      </div>
    </div>
    <div class="step_graphics">
      <div class="step_screenshot top_margin">
        <div class="step_zoom rvcalc_step_zoom3">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)" d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/rv_calculator/rvcalc_step2.jpg" alt="Step2">
      </div>
    </div>
  </div>

  <div class="single_step">
    <div class="step_title d-flex align-items-center mb-5">
      <span class="count">2</span>
      <div class="step_count">In the <strong>Age</strong> and <strong>KM</strong> sections input whichever term and/or
        km combination that you are after and the values in the section <strong>RV inc GST</strong> will automatically
        adjust to the new values. There are 5 spots for you to input into and retrieve values to help you compare and
        contrast RV's.</div>
    </div>

    <div class="step_graphics">
      <div class="step_screenshot">
        <div class="step_zoom rvcalc_step_zoom4">
          <svg width="19px" height="28px">
            <path fill-rule="evenodd" fill="rgb(51, 51, 51)" d="M17.694,24.342 L13.327,16.850 L18.242,15.544 C18.626,15.442 18.917,15.132 18.992,14.745 C19.067,14.359 18.912,13.963 18.594,13.728 L1.642,1.193 C1.310,0.947 0.861,0.921 0.502,1.126 C0.143,1.332 -0.058,1.729 -0.010,2.137 L2.475,22.945 C2.521,23.336 2.790,23.666 3.165,23.795 C3.541,23.925 3.958,23.830 4.239,23.551 L7.838,19.988 L12.205,27.480 C12.343,27.716 12.570,27.889 12.835,27.959 C13.101,28.030 13.385,27.993 13.623,27.857 L17.314,25.746 C17.811,25.462 17.981,24.833 17.694,24.342 Z" />
          </svg>
        </div>
        <img src="assets/images/help_support/rv_calculator/rvcalc_step3.jpg" alt="Step2">
      </div>
    </div>
  </div>

</div>