<h2 mat-dialog-title>
  <button mat-dialog-close [disabled]="isProcessing">
    <span class="material-symbols-outlined">close</span>
  </button>{{ isEdit ? 'Update User':'Invite New User' }}
</h2>
<mat-dialog-content class="mat-typography invite_user">
  <div class="popup_content">
    <form [formGroup]="userInvite" autocomplete="false" novalidate>
      <mat-form-field>
        <span class="material-symbols-outlined" matPrefix>
          <span class="material-symbols-outlined gray">person</span>
        </span>
        <input type="text" matInput placeholder="Enter full name" formControlName="name" name="name">
        <mat-error
          *ngIf="userInvite.controls.name.invalid && (userInvite.controls.name.dirty || userInvite.controls.name.touched)">
          <span *ngIf="userInvite.controls.name.errors.required">
            User Name field should not be empty
          </span>
          <span *ngIf="userInvite.controls.name.errors.maxlength">
            User Name should not be greater than 40 characters.
          </span>
          <span *ngIf="userInvite.controls.name.errors.pattern">
            Please enter a valid name.
          </span>
          <span *ngIf="formError.name">{{formError.name}}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <span class="material-symbols-outlined" matPrefix>
          <span class="material-symbols-outlined">mail</span>
        </span>
        <input type="text" matInput placeholder="Enter email address" formControlName="email" name="email">
        <mat-error
          *ngIf="userInvite.controls.email.invalid && (userInvite.controls.email.dirty || userInvite.controls.email.touched)">
          <span *ngIf="userInvite.controls.email.errors.required">
            Email field should not be empty
          </span>
          <span *ngIf="userInvite.controls.email.errors.email">
            Please enter a valid email.
          </span>
          <span *ngIf="userInvite.controls.email.errors.maxlength">
            Email field should not be greater than 40 characters.
          </span>
          <span *ngIf="formError.email">{{formError.email}}</span>
        </mat-error>
      </mat-form-field>
      <app-loader [ShowLoader]="isFetchingFrom" [CStyle]="{'min-height':'50px'}" [text]="'Loading products..'">
      </app-loader>
      <ng-container *ngIf="!isFetchingFrom">
        <ng-container *ngFor="let products of productListing">
          <mat-checkbox [checked]="oldProducts.indexOf(products.Product_Id) != -1 ? true: false"
            (change)="productsCheck($event,products)" class="me-2">
            {{products.Name}}
          </mat-checkbox>
          <mat-form-field *ngIf="products.Product_Id==1" class="mt-3 mb-2">
            <span class="material-symbols-outlined" matPrefix>
              <span class="material-symbols-outlined">settings_account_box</span>
            </span>
            <mat-select placeholder="Select Permission" formControlName="permission_id" name="permission_id">
              <mat-option *ngFor="let role of userRoles" [value]="role.value">
                {{role.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="userInvite.controls.permission_id.invalid && (userInvite.controls.permission_id.dirty || userInvite.controls.permission_id.touched)">
              <span *ngIf="userInvite.controls.permission_id.errors.required">
                Permission field should not be empty
              </span>
              <span *ngIf="formError.permission_id">{{formError.permission_id}}</span>
            </mat-error>
          </mat-form-field>
        </ng-container>

      </ng-container>
      <div class="action_btns mt-3">
        <button *ngIf="!this.isEdit" class="btn btn-primary btn-lg me-4" (click)="sendInvite(userInvite)" type="submit"
          [disabled]="isProcessing || isFetchingFrom" mat-button>{{(isProcessing) ? 'Sending...' : 'Send
          Invite'}}</button>
        <button *ngIf="this.isEdit" class="btn btn-primary btn-lg me-4" (click)="updateUser(userInvite)" type="submit"
          [disabled]="isProcessing || isFetchingFrom" mat-button>{{(isProcessing) ? 'Updating...' : 'Update
          User'}}</button>
        <button class="btn btn-outline-primary btn-lg" (click)="closeModal()" type="button" [disabled]="isProcessing"
          mat-stroked-button>Cancel</button>
      </div>
    </form>
  </div>
</mat-dialog-content>