<!-- <div class="col col-lg-12" *ngIf="isFilterLoad">
      <mat-card class="shadow-sm mat-card">
        <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'auto'}" [type]="'listings-small'">
        </app-loader>
      </mat-card>
    </div> -->
<div class="row">
  <ng-container>

    <div class="col col-9">

      <h1 class="mb-4 page-title">{{pageTitle}}</h1>

      <div class="row" *ngIf="!isFilterLoad">
        <div class="col-md-3" *ngFor="let otherControl of priceFilterOtherControls; let i=index">
          <div class="di-input-wrapper">
            <label class="custom-label">{{otherControl['title']}}</label>

            <mat-form-field appearance="fill" floatLabel="never">
              <mat-select [placeholder]="otherControl['title']"
                [multiple]="otherControl['name']=='Year_Group' ? true:false"
                (selectionChange)="emitFilterSelection($event.value,otherControl['name'])"
                *ngIf="(otherControl['name']=='Km_Col' || otherControl['name']=='Year_Group');else otherFilterFields"
                [attr.name]="otherControl['name']" [(ngModel)]="priceFilterOtherModel[otherControl['name']]"
                [disabled]="isFilterLoad">

                <mat-option *ngFor="let otherFilterList of OtherFilterList[otherControl['name']]"
                  [value]="otherControl['name']=='Year_Group' ? otherFilterList.filter : otherFilterList.key">
                  {{otherControl['name']=='Year_Group' ? otherFilterList.filter : otherFilterList.label}}
                </mat-option>
              </mat-select>
              <ng-template #otherFilterFields>
                <input matInput [min]="StartDate" [max]="EndDate" [matDatepicker]="i"
                  [placeholder]="otherControl['title']" [(ngModel)]="priceFilterOtherModel[otherControl['name']]"
                  (dateChange)="emitFilterSelection($event.value,otherControl['name'])">
                <div class="mat-form-field-suffix">
                  <mat-datepicker-toggle matSuffix [for]="i"></mat-datepicker-toggle>
                </div>
                <mat-datepicker #i [disabled]="checkDatePickerDisable(otherControl['name']) || isFilterLoad">
                </mat-datepicker>
              </ng-template>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-3">
          <form [formGroup]="otherSearchForm" autocomplete="false" novalidate style="display: inline;">
            <ng-container *ngFor="let filterObj of priceFilterEtcControls">
              <app-vehicle-filter (sendFilterSelect)="detectOtherFilterSelection($event)" [isMultiple]="true"
                [filterList]="OtherFilterList[filterObj['name']]" [field_class]="'col col-lg-2 button_type'"
                [form]="otherSearchForm" [formError]="formError" [controlName]="filterObj['name']"
                [frmControl]="otherSearchForm.controls[filterObj['name']]" [controlPlaceholder]="filterObj['title']"
                [toggleLoader]="isFilterLoad || isLoading" [allFilterList]="OtherFilterList"></app-vehicle-filter>
            </ng-container>
          </form>
        </div>
      </div>

      <app-loader [ShowLoader]="isFilterLoad" [CStyle]="{'min-height':'300px'}" [type]="'normal'"></app-loader>

      <div class="di-step-wrapper mt-4" *ngIf="isFirstTime && !isFilterLoad">
        <no-record-found [notFoundHeading]="'Please choose a filter first'" [cStyle]="{'height':'300px'}">
        </no-record-found>
      </div>

      <ng-container *ngIf="!isFirstTime">
        <div class="di-step-wrapper mt-4">
          <div class="step-heading"><span class="material-symbols-outlined">calendar_month</span> Price by Age
            (Months)</div>
          <app-loader [CStyle]="{'min-height':'300px'}" [ShowLoader]="ageChartLoader" [text]="'Loading data...'">
          </app-loader>
          <div class="chart-container text-center" *ngIf="!ageChartLoader">
            <app-loader [CStyle]="{'min-height':'auto'}" [ShowLoader]="isAgeChartImgLoad" [text]="'Creating chart...'">
            </app-loader>
            <img [hidden]="isAgeChartImgLoad" [src]="ageChartHtml" (load)="imgPreLoader('age')"
              (error)="imgLoadingError('age')" style="max-width: 100%;">
            <div class="row" *ngIf="regressionPoints.length>0">
              <table>
                <tbody>
                  <tr>
                    <td style="width: 10%;
                          text-align: left;">
                      <div class="di-input-wrapper" style=" max-width:100px;">
                        <label class="custom-label" for="regressionPrice">Price</label>
                        <mat-form-field [floatLabel]="'never'" appearance="outline"
                          [ngClass]="{'col-md-2': regressionPrice<=0}">
                          <input matInput [(ngModel)]="regressionPrice" numbersOnly type="number" inputmode="numeric"
                            (ngModelChange)="reCalculateRegressionPoint($event)" name="regressionPrice"
                            [disabled]="isAgeChartImgLoad" />
                        </mat-form-field>
                      </div>
                    </td>
                    <td *ngFor="let line of calRegressionValue;let i=index;" style="vertical-align: middle;">
                      <div class="di-input-wrapper ">
                        <label class="custom-label" for="regressionPrice_{{ageList[i]}}">{{ageList[i]}}</label>
                        <mat-form-field class="no_border" [floatLabel]="'never'" appearance="outline">
                          <input matInput name="regressionPrice_{{ageList[i]}}" readonly class="no_border"
                            value="{{line | currency:'AUD':'symbol-narrow':'1.0-0'}}" />
                        </mat-form-field>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="di-step-wrapper mt-5">
          <div class="step-heading"><span class="material-symbols-outlined">schedule</span> Price Over Time</div>
          <app-loader [CStyle]="{'min-height':'300px'}" [ShowLoader]="timeChartLoader" [text]="'Loading data...'">
          </app-loader>
          <div class="chart-container text-center" *ngIf="!timeChartLoader">
            <app-loader [CStyle]="{'min-height':'auto'}" [ShowLoader]="isTimeChartImgLoad" [text]="'Creating chart...'">
            </app-loader>
            <img [hidden]="isTimeChartImgLoad" [src]="timeChartHtml" (load)="imgPreLoader('time')"
              (error)="imgLoadingError('time')" style="max-width: 100%;">
          </div>
        </div>
      </ng-container>

    </div>

    <div class="col col-3" *ngIf="!isFilterLoad">
      <div class="di-step-wrapper p-4">

        <div class="step-heading"><span class="material-symbols-outlined">search</span> Filter By</div>

        <div class="overlay-loader" *ngIf="isLoading">
          <app-loader [type]="'normal'" [ShowLoader]="true" [CStyle]="{'min-height':'400px'}"></app-loader>
        </div>

        <form [formGroup]="searchForm" (ngSubmit)="handleSearchForm(searchForm)" autocomplete="false" novalidate
          [ngClass]="cssClass" *ngIf="!isFilterLoad">
          <div class="row">
            <div class="col-lg-12 mb-2" *ngFor="let filterObj of priceFilterControls">
              <app-vehicle-filter (sendFilterSelect)="detectFilterSelection($event)"
                [isMultiple]="filterObj['name']=='VFacts_Class'?false:true"
                [filterList]="allFilterList[filterObj['name']]" [field_class]="'button_type'" [form]="searchForm"
                [formError]="formError" [controlName]="filterObj['name']"
                [frmControl]="searchForm.controls[filterObj['name']]" [controlPlaceholder]="filterObj['title']"
                [toggleLoader]="isFilterLoad || isLoading" [allFilterList]="allFilterList"></app-vehicle-filter>
            </div>
          </div>
          <div class="action-btns mt-3">
            <button mat-flat-button color="primary" class="me-3" [ngClass]="btnClass" type="submit"
              [disabled]="isFilterLoad || isLoading || this.checkObjEmpty(searchForm.value)">Apply Filters</button>
            <button mat-stroked-button color="primary" [ngClass]="btnClass" type="button" (click)="resetFilters()"
              [disabled]="isFilterLoad || isLoading">Clear All
            </button>
          </div>
        </form>

      </div>
    </div>
  </ng-container>
</div>