<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  New Link
  <button (click)="closeModel()">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>
<mat-dialog-content class="mat-typography new_admin_link_popup">
  <div class="popup_content">
    <vehicle-brief [isLinked]="formData?.linkedVehicle" [linkVehicle]="formData?.linkedVehicle"
      [linkedId]="formData?.linkedVehicleId ? formData['linkedVehicleId']:null" [vehicleDetails]="formData?.vehicle"
      [type]="'l-tree'" [rvHidden]="false" class="bg-primary text-white"
      [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>
    <app-loader [ShowLoader]="loadingRv" [CStyle]="{'min-height':'200px'}" [text]="'Fetching index..'"></app-loader>
    <div class="vehicleBriefRv p-4" *ngIf="!loadingRv && (linkData?.di_p12 || linkData?.rv_p12)">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th>INDEX</th>
            <th>12M (30,000KM)</th>
            <th>24M (60,000KM)</th>
            <th>36M (90,000KM)</th>
            <th>48M (120,000KM)</th>
            <th>60M (150,000KM)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span class="text-primary text-uppercase">RV</span></td>
            <td>
              <app-text-format [data]="linkData?.rv_d12" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.rv_p12" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.rv_d24" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.rv_p24" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.rv_d36" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.rv_p36" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.rv_d48" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.rv_p48" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.rv_d60" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.rv_p60" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
          </tr>
          <tr>
            <td><span class="text-uppercase text-warning">DATIUM</span></td>
            <td>
              <app-text-format [data]="linkData?.di_d12" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.di_p12" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.di_d24" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.di_p24" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.di_d36" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.di_p36" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.di_d48" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.di_p48" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
            <td>
              <app-text-format [data]="linkData?.di_d60" [defaultNoFormat]="0"
                [formatType]="'dollar'"></app-text-format>
              <app-text-format [data]="linkData?.di_p60" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format>
            </td>
          </tr>
        </tbody>
      </table>
      <ng-container *ngIf="!loadingRv && !acceptingIndex">
        <div class="mt-4">
          <button class="me-3" mat-flat-button color="primary" [disabled]="acceptingIndex"
            (click)="acceptDatiumValues(formData['vehicleId'],formData['NewLink'])">
            Accept with Datium Index
          </button>
          <button class="me-3" mat-stroked-button color="primary" [disabled]="acceptingIndex"
            (click)="acceptRVValues(formData['vehicleId'],formData['NewLink'])">
            Accept with RV Index
          </button>
          <button mat-stroked-button color="warn" [disabled]="acceptingIndex"
            (click)="rejectValues(formData['vehicleId'],formData['NewLink'])">
            Decline Changes
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="loadingRv || acceptingIndex">
        <app-loader [ShowLoader]="acceptingIndex" [CStyle]="{'min-height':'100px'}"
          [text]="'Updating linking...'"></app-loader>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>