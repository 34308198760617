<app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'auto'}" [type]="'listings-small'"></app-loader>
<div class="linked_vehicle" *ngIf="!isLoading">
    <div class="vehicle_breif d-flex justify-content-between">
        <div class="left_info">
            <div class="d-flex align-items-center">
                <mat-chip-list>
                    <mat-chip class="pointer" color="primary" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                        [cbContent]="linkedVehicle?.DatiumAssetKey" *ngxClipboardIfSupported
                        (cbOnSuccess)="copiedMsg('Copied to clipboard')" selected>
                        <span class="material-symbols-outlined me-2 dark-blue">link</span>
                        {{linkedVehicle?.DatiumAssetKey}}</mat-chip>
                </mat-chip-list>
                <div class="brand_modal ms-3">
                    <span class="font-weight-bold">
                        <app-text-format [data]="linkedVehicleData?.vehicleDetails?.make"></app-text-format>
                    </span>
                    <span class="dot"></span>
                    <app-text-format [data]="linkedVehicleData?.vehicleDetails?.model"></app-text-format>
                    <span class="dot"></span>
                    <app-text-format [data]="linkedVehicleData?.vehicleDetails?.year_group"></app-text-format>
                </div>
            </div>
            <p class="mt-2">
                <app-text-format [data]="linkedVehicleData?.vehicleDetails?.description"></app-text-format>
            </p>
        </div>
        <div class="right_info">
            <div class="bg-primary text-white py-1 px-2 rounded d-flex align-items-center">
                <span class="material-symbols-outlined white me-1">check_circle</span>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center h-100 p-4"
            *ngIf="!linkedVehicleData?.rvValues && !linkedVehicleData?.dmValues">
            <div>No {{roleCheckService.isSuperAdmin() ? 'DI' : 'RV'}} available</div>
        </div>

        <div *ngIf="linkedVehicleData?.rvValues || linkedVehicleData?.dmValues" class="mt-2">
            <a href="javascript:void(0)" (click)="rvHidden=!rvHidden">{{rvHidden ? 'Hide' : 'Show'}}
                {{roleCheckService.isSuperAdmin() ? 'DI' : 'RV'}}</a>
        </div>

        <div class="rv_datium_data bg-light"
            *ngIf="rvHidden && (linkedVehicleData?.rvValues || linkedVehicleData?.dmValues)">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th class="text-right">12M<br>30,000KM</th>
                        <th class="text-right">24M<br>60,000KM</th>
                        <th class="text-right">36M<br>90,000KM</th>
                        <th class="text-right">48M<br>120,000KM</th>
                        <th class="text-right">60M<br>150,000KM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span class="" [ngClass]="roleCheckService.isSuperAdmin() ? 'text-danger':'text-primary'">
                                {{roleCheckService.isSuperAdmin()? 'D.MODEL' : 'RV'}}
                            </span>
                        </td>
                        <td class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.rvValues?.dollarValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.rvValues?.percentageValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.dmValues?.dollarValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.dmValues?.percentageValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.rvValues?.dollarValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.rvValues?.percentageValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.dmValues?.dollarValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.dmValues?.percentageValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.rvValues?.dollarValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.rvValues?.percentageValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.dmValues?.dollarValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.dmValues?.percentageValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.rvValues?.dollarValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.rvValues?.percentageValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.dmValues?.dollarValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.dmValues?.percentageValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.rvValues?.dollarValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.rvValues?.percentageValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
                            <app-text-format [data]="linkedVehicleData?.dmValues?.dollarValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.dmValues?.percentageValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                    </tr>
                    <tr>
                        <td><span class="text-uppercase"
                                [ngClass]="roleCheckService.isSuperAdmin() ? 'text-primary':'text-danger'">{{roleCheckService.isSuperAdmin()
                                ? 'D.INDEX' : 'DATIUM'}}</span></td>
                        <td class="text-right">
                            <app-text-format [data]="linkedVehicleData?.diValues?.dollarValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.diValues?.percentageValue['12_30000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right">
                            <app-text-format [data]="linkedVehicleData?.diValues?.dollarValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.diValues?.percentageValue['24_60000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right">
                            <app-text-format [data]="linkedVehicleData?.diValues?.dollarValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.diValues?.percentageValue['36_90000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right">
                            <app-text-format [data]="linkedVehicleData?.diValues?.dollarValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.diValues?.percentageValue['48_120000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                        <td class="text-right">
                            <app-text-format [data]="linkedVehicleData?.diValues?.dollarValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
                            <app-text-format
                                [data]="linkedVehicleData?.diValues?.percentageValue['60_150000']['formatted']"
                                [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>