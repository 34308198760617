import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-popup-loader',
  templateUrl: './popup-loader.component.html',
  styleUrls: ['./popup-loader.component.scss']
})
export class PopupLoaderComponent implements OnInit {

  text: string;
  constructor(public dialogRef: MatDialogRef<PopupLoaderComponent>, @Inject(MAT_DIALOG_DATA) public data: PopupLoaderComponent) {
    this.text = this.data['title'] ? this.data['title'] : 'Loading...';
  }
  ngOnInit() {
  }

}
