<mat-dialog-content class="mat-typography confirmation_popup">

  <div class="popup_content p-3">

    <div class="text-center">

      <app-loader [ShowLoader]="true" [text]="text" [CStyle]="{'min-height':'150px'}">
      </app-loader>

    </div>

  </div>

</mat-dialog-content>
