<div *ngIf="!isError">
  <app-loader [ShowLoader]="isProcessing" [CStyle]="{'min-height':'100px'}"></app-loader>
</div>
<div *ngIf="isError">
  <div class="wrapper row2">
    <div id="container" class="clear">
      <section id="fof" class="clear">
        <div class="hgroup">
          <h1><span><strong>4</strong></span><span><strong>0</strong></span><span><strong>1</strong></span></h1>
          <h2><span style="margin-top:50px;">Error !</span><span class="margin-top">Unauthorized Access</span></h2>
        </div>
        <p>You have attempted to access a page for which you are not authorized.</p>
      </section>
    </div>
  </div>
</div>
