import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { JwtService } from '../../services/jwt.service';

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUsForm: UntypedFormGroup;
  loadingForm = false;
  public phoneNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  formError = {}
  constructor(private commonService: CommonService, private userService: UserService, public jwtService: JwtService,) { }

  ngOnInit() {
    this.commonService.setTitle('Contact Us')
    this.initContactForm();
  }


  /*
  * @name initContactForm
  * @param  
  * @description Initialize the contact form
  * @return none
  */
  initContactForm() {
    this.contactUsForm = new UntypedFormGroup({
      'name': new UntypedFormControl(this.jwtService.getUserName(), [Validators.required]),
      'subject': new UntypedFormControl(null, [Validators.required]),
      'email': new UntypedFormControl(this.jwtService.getUserEmail(), [
        Validators.required, Validators.email, //Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ]),
      'phone_no': new UntypedFormControl(this.jwtService.getUserPhone(), [
        Validators.required, Validators.minLength(8)
      ]),
      'comment': new UntypedFormControl(null, [Validators.required]),
    });
  }

  /*
 * @name checkKey
 * @param  Event(Keyboard)
 * @description Allowed only numeric values
 * @return none
 */
  checkKey(event) {
    if (event.which < 48 || event.which > 57) {
      event.preventDefault();
    }
  }

  /*
  * @name handleContactForm
  * @param  Form
  * @description handle contact us form                       
  * @return none
  */
  handleContactForm(contactUsForm) {
    if (contactUsForm.valid) {
      //console.log(contactUsForm.value);

      this.loadingForm = true;
      this.userService.sendContactUsRequest(contactUsForm.value).subscribe(
        (result: any) => {
          this.loadingForm = false;
          try {
            //this.gaEvent.sendGaEvent('ContactRequest', contactUsForm.value.enquiry_topic, contactUsForm.value.email, 100);
            let response = result;
            this.commonService.showSnakeBar(response.message);
            this.contactUsForm.reset();
          } catch (e) {

          }
        },
        (err) => {
          this.loadingForm = false;
          let response = err;
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => { }
      );
    } else {
      this.contactUsFormValidate(contactUsForm);
    }
  }

  /*
  * @name contactUsFormValidate
  * @param  Form
  * @description validate contact us form                       
  * @return none
  */
  contactUsFormValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.contactUsFormValidate(control);
      }
    });
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.contactUsForm.contains(key)) {
          let control = this.contactUsForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

}
