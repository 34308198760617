import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataStoreService } from 'src/app/services/data-store.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { EditProductComponent } from '../edit-product/edit-product.component';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  displayedColumns: string[] = ['Name', 'OfferType', 'TotalDays', 'TotalUsers', 'action'];
  isLoading: boolean = false;
  listData = []

  vSubscription: Subscription;
  vFactListing: Subscription;
  delBRLoader: number = null
  delDLoader: number = null
  delRELoader: number = null
  makArray: Array<string> = []
  permArray: Array<any> = []
  ProductOffers: Array<any> = []
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    public dataStore: DataStoreService,
    public roleCheckService: RoleCheckerService,
    public permissionService: PermissionsService) {

  }

  ngOnInit() {
    this.commonService.setTitle('Products');
    this.getProductListing()
  }

  /*
  * @name getProductListing
  * @param none
  * @description get the listing of products
  * @return none
  */
  getProductListing() {
    this.isLoading = true
    //let type = 'BusinessRisk';
    this.vFactListing = this.permissionService.getProductList().subscribe(
      (result: any) => {
        this.isLoading = false
        try {
          let response = result;
          this.listData = response.data.products;
          this.makArray = response.data.makes;
          this.permArray = response.data.permissions;
          this.ProductOffers = response.data.ProductOffers
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        this.isLoading = false;
        let response = error;

        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isLoading = false
      },
      () => {

      }
    )
  }

  /*
 * @name updateProducts
 * @param none
 * @description Update new (Deflation, BusinessRisk and Remarketing Efficiency)
 * @return none
 */
  updateProducts(object) {
    const dialogRef = this.dialog.open(EditProductComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: true,
        editProduct: object,
        makes: this.makArray,
        permissions: this.permArray,
        ProductOffers: this.ProductOffers
      },
      disableClose: true
    });
    /*dialogRef.afterClosed().subscribe((result : any) => {
      //console.log(result, 'result')
      //this.getProductListing();

    })*/

    dialogRef.componentInstance.onUpdateProduct.subscribe((res: any) => {
      this.listData = res
      this.listData = this.listData.slice()
    })
  }


  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors                  
* @return none
*/
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }
  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.vFactListing) {
      this.vFactListing.unsubscribe()
    }
  }
}
export interface PeriodicElement {
  Name: string;
  OfferType: string;
  TotalDays: number;
  TotalUsers: number;
  action: string;
}

