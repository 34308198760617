<div class="table_row_wrapper position-relative"
  [ngClass]="{'opened_wrapper':( show_vehicle && !checkVehicleRejected()),'not-allowed':checkVehicleRejected()}">
  <vehicle-short-detail [vehicle]="vehicle" [vehicleId]="vehicleId"
    [isRvValues]="vehicle?.rvValues || vehicle?.dmValues" [linkedVehicle]="vehicle?.linkedVehicle"
    (click)="showVehicleDetail()" matTooltip="{{clickText}}"
    (vehicleActionEvent)="vehicleActionEvent($event)"></vehicle-short-detail>
  <vehicle-detail [vehicle]="vehicle" [vehicleId]="vehicleId" [isRvValues]="vehicle?.rvValues || vehicle?.dmValues"
    [linkedVehicle]="vehicle?.linkedVehicle" *ngIf="show_vehicle && !checkVehicleRejected()" [isAdmin]="isAdmin"
    [showVehicle]="show_vehicle" [preventClose]="preventClose"></vehicle-detail>
  <ve-loader [ShowLoader]="loaderData.loading" [text]="loaderData.text"></ve-loader>
</div>