<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchDefault="">
    <div class="d-flex h-100" [ngClass]="{'acpt-vehicle': commonService.toggleIndex(vehicle,'toggle')}">
      <div class="tbox border-left" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-primary text-center" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            {{roleCheckService.isSuperAdmin() ? 'DATIUM INDEX' : 'RV'}}
          </div>
          <div class="text-seconday text-center">
            {{roleCheckService.isSuperAdmin() ? 'DATIUM MODEL' : 'DATIUM'}}
          </div>
        </div>
      </div>
      <div class="tbox border-left d-flex justify-content-end" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.rvValues?.dollarValue['12_30000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.rvValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.diValues?.dollarValue['12_30000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.diValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.dmValues?.dollarValue['12_30000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.dmValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
        </div>
      </div>
      <div class="tbox d-flex justify-content-end" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.rvValues?.dollarValue['24_60000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.rvValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.diValues?.dollarValue['24_60000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.diValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.dmValues?.dollarValue['24_60000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.dmValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
        </div>
      </div>
      <div class="tbox d-flex justify-content-end" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.rvValues?.dollarValue['36_90000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.rvValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.diValues?.dollarValue['36_90000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.diValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.dmValues?.dollarValue['36_90000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.dmValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
        </div>
      </div>
      <div class="tbox d-flex justify-content-end" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.rvValues?.dollarValue['48_120000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.rvValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.diValues?.dollarValue['48_120000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.diValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.dmValues?.dollarValue['48_120000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.dmValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
        </div>
      </div>
      <div class="tbox d-flex justify-content-end" [ngClass]="commonService.toggleIndex(vehicle)">
        <div class="two_vals">
          <div class="text-right" *ngIf="!roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.rvValues?.dollarValue['60_150000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.rvValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="commonService.toggleIndex(vehicle,'accepted')">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.diValues?.dollarValue['60_150000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.diValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
          <div class="text-right" *ngIf="roleCheckService.isSuperAdmin()">
            <p class="dollar-amount"><app-text-format [data]="vehicle?.dmValues?.dollarValue['60_150000']['formatted']"
                [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format></p>
            <p class="percentage-amount"><app-text-format
                [data]="vehicle?.dmValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
                [formatType]="'percentage'"></app-text-format></p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'adjustments'">

  </ng-container>
</ng-container>