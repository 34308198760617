<div class="history_details dashboard">
  <app-loader [ShowLoader]="isLoadingList" [CStyle]="{'min-height':'300px'}"></app-loader>
  <ng-container *ngIf="rvHistoryList.length > 0 && !isLoadingList">
    <div class="date_history" *ngFor="let rvHistoryListObj of rvHistoryList">
      <div class="changes_date" *ngIf="rvHistoryListObj?.date">
        <strong>
          {{rvHistoryListObj.date | date:timeLineDateFormat}}
        </strong>
      </div>
      <ng-container *ngIf="rvHistoryListObj?.history">
        <div class="single_change" *ngFor="let rvHistory of rvHistoryListObj.history">

          <div class="changes_info d-flex justify-content-between">
            <div>
              <div class="d-flex align-items-center">
                <mat-chip-list class="me-2">
                  <mat-chip color="primary" selected class="pointer" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                    [cbContent]="rvHistory?.DatiumAssetKey" *ngxClipboardIfSupported
                    (cbOnSuccess)="copiedMsg('Copied to clipboard')">
                    <app-text-format [data]="rvHistory?.DatiumAssetKey"></app-text-format>
                  </mat-chip>
                </mat-chip-list>
                <div class="brand_modal">
                  <app-text-format [data]="rvHistory?.vehicleDetails?.make"></app-text-format>
                  <span class="dot"></span>
                  <app-text-format [data]="rvHistory?.vehicleDetails?.model"></app-text-format>
                  <span class="dot"></span>
                  <app-text-format [data]="rvHistory?.vehicleDetails?.year_group"></app-text-format>
                </div>
              </div>
              <p class="mt-2">
                <app-text-format [data]="rvHistory?.vehicleDetails?.description"></app-text-format>
              </p>
            </div>
            <div>at {{rvHistory.createdDateTime | date:Htime}} by <span class="text-primary">
                <app-text-format [data]="rvHistory?.user?.name"></app-text-format>
              </span></div>
          </div>

          <div class="changes_values">
            <div class="d-flex justify-content-between">
              <div class="age">
                <div class="ageBar">12M (30k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['12_30000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['12_30000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">24M (60k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['24_60000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['24_60000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">36M (90k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['36_90000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['36_90000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">48M (120k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['48_120000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['48_120000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
              <div class="age">
                <div class="ageBar">60M (150k)</div>
                <div class="changeValue" [ngClass]="getClass(rvHistory?.adjustmentPercentage['60_150000'])">
                  <app-text-format [data]="rvHistory?.adjustmentPercentage['60_150000']" [defaultNoFormat]="'--'"
                    [formatType]="'history'"></app-text-format>
                </div>
              </div>
            </div>
          </div>
          <div class="changes_comments">
            <app-text-format [data]="rvHistory?.comments"></app-text-format>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center p-4" *ngIf="!isPaging && currentTotal > 10 && !isLoadingList">
    <button mat-flat-button color="primary" (click)="onScroll()" mat-button>Load More</button>
  </div>
  <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
  <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
    [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  <no-record-found *ngIf="page==1 && !isLoadingList && !isPaging && rvHistoryList.length <= 0"
    [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'100px'}" [noRecord]="true"></no-record-found>
</div>