<div class="mb-4">
    <page-title [title]="pageTitle"></page-title>
</div>

<!-- <vehicle-list-filter (searchFormSubmit)="handleFilterSearchForm($event)" (searchFormReset)="handleSearchFormReset($event)" [serverError]="serverError" [checkParams]="'false'"></vehicle-list-filter> -->

<app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'listing'"
    (searchResponse)="searchResponse($event)" [filterClass]="'col-md-2 mt-3'"></app-vehicle-filter-search>

<linking-wrapper [routeName]="'/linking-tree'" [filterState]="filterState" [resetAndSearch]="resetAndSearch"
    (listLoaded)="vehicleListLoaded($event)" (sendError)="handleFormErrors($event)"></linking-wrapper>