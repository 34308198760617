<h2 mat-dialog-title>
  Add to Basket
  <button mat-dialog-close [disabled]="isProcessing">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography basket-list-wrapper">
  <div class="row mt-2 mb-4 text-center" *ngIf="basketLoader">
    <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'200px'}"></app-loader>
  </div>
  <ng-container *ngIf="!basketLoader">
    <ng-container *ngIf="baskets.length <=0; else addVehicle">
      <button mat-flat-button color="primary" (click)="addNewBasket()">
        <span class="material-symbols-outlined">add_circle</span>
        Create New Basket
      </button>
    </ng-container>
    <ng-template #addVehicle>
      <div class="row mt-2 mb-4">
        <mat-selection-list class="bucket-list-selection" [(ngModel)]="basketIds"
          (ngModelChange)="handleBasketSelection($event)">
          <mat-list-option color="primary" *ngFor="let basket of baskets" [value]="basket.BasketId"
            [checkboxPosition]="'after'">
            <h3 matLine> {{basket.name}} <span>{{basket.TotalVehicles}} Vehicle</span> </h3>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="form_btns">
        <button color="primary" mat-flat-button [disabled]="isProcessing || basketId.length <= 0"
          (click)="addVehicleBasket()" type="submit">{{isProcessing?'Adding...':'Done'}}</button>
        <button class="ms-3" mat-stroked-button (click)="addNewBasket()" [disabled]="isProcessing" type="button">Create
          New Basket</button>
      </div>
    </ng-template>
  </ng-container>
</mat-dialog-content>