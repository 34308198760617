/*
* @ngdoc Component
* @name app.vehicle.new-vehicles.NewVehicleListComponent
* @description
* This component is used to manage the new vehicle list
* */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppHeadings } from 'src/app/configs/app-headings';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { DataStoreService } from 'src/app/services/data-store.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationPopupComponent } from '../../../shared/confirmation-popup/confirmation-popup.component';
import { VehicleService } from '../../../services/vehicle.service';
import { CompareVehiclesComponent } from '../../vehicle-list/compare-vehicles/compare-vehicles.component';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-new-vehicle-list',
  templateUrl: './new-vehicle-list.component.html',
  styleUrls: ['./new-vehicle-list.component.scss']
})
export class NewVehicleListComponent implements OnInit, OnDestroy {

  title: string
  getSelectedVehicleSignal: Subscription
  getSelectedCarDataSignal: Subscription
  getAcceptedVehicle: Subscription
  vSubscription: Subscription
  selectedVehicles: Array<any> = [];
  selectCarForCompare: Array<any> = [];
  isLoader = false;
  totalRecord = 0;
  type = "listing"
  filterState = {
    submit: false,
    reset: false,
    initialState: false,
    data: {},
    error: false,
    loader: true,
    reqType: 'search',
    exportCsvPageNo: null
  }
  routeName = "/new-vehicles"
  newType = "new_vehicles"

  public exportDataOptions: Array<{
    key: string,
    value: number
  }> = [];
  public exportRecordsLimit: number = 200;
  public selectedExportPage: string = '';
  public isExporting: boolean = false;
  public isSearchFinished: boolean = true;
  public isSuperAdmin: boolean = false;
  public isSelectAllEvent: boolean = false;
  constructor(private bottomSheet: MatBottomSheet, public dialog: MatDialog, private appHeading: AppHeadings, private commonService: CommonService, private dataStore: DataStoreService, private vehicleService: VehicleService, private roleCheckService: RoleCheckerService,) {
    this.getSelectedVehicleSignal = this.dataStore.getSelectedNewVehicle().subscribe(data => {
      //console.log(data, 'data')
      this.isSelectAllEvent = false;
      this.toggleSelectedVehicle(data)
    })
    this.getSelectedCarDataSignal = this.dataStore.getSelectedCarData().subscribe(data => {
      //console.log(data, 'data')
      if (data['vehicle']) {
        this.toggleCarSelection(data.vehicle);
      } else if (data['clear']) {
        this.selectCarForCompare = [];
      }
    })
  }

  ngOnInit() {
    this.title = this.appHeading.vehicle.heading.new_vadmin;
    this.commonService.setTitle(this.title)
    if (this.roleCheckService.isSuperAdmin()) {
      this.isSuperAdmin = true;
    }
  }

  toggleCarSelection(vehicle): void {
    if (vehicle) {
      //console.log(this.selectCarForCompare.length)
      if (this.selectCarForCompare.length >= 10) {
        this.commonService.showSnakeBar("Maximum limit of selection reached");
      }
      else {
        const index = this.selectCarForCompare.findIndex(obj => obj.DatiumAssetKey == vehicle.DatiumAssetKey);
        //console.log(index)
        if (index != -1) {
          this.selectCarForCompare.splice(index, 1);
        }
        else {
          this.selectCarForCompare.push(vehicle);
        }
      }
    }
  }

  vehicleListState(event) {
    //console.log(event)
  }

  /*
  * @name resetSelection
  * @param None
  * @description Reset selection for accepting or rejecting vehicle
  * @return none
  */
  resetTotalSelection(response) {
    let DatiumAssetKey = response.DatiumAssetKey, accepted = response.accepted
    const index = this.selectedVehicles.findIndex(element => element.DatiumAssetKey == DatiumAssetKey);
    if (index != -1) {
      this.selectedVehicles.splice(index, 1);
    }
    //Remove vehicle that was rejected by user from compare list
    if (accepted == 2) {
      const cmIndex = this.selectCarForCompare.findIndex(element => element.DatiumAssetKey == DatiumAssetKey);
      if (cmIndex != -1) {
        this.selectCarForCompare.splice(cmIndex, 1);
      }
    }
    this.decTotalCounter(1)
  }

  decTotalCounter(len) {
    this.totalRecord = this.totalRecord != 0 ? this.totalRecord - len : this.totalRecord
    this.listLoaded(this.totalRecord)
  }

  /*
  * @name resetSelection
  * @param None
  * @description Reset selection for accepting or rejecting vehicle
  * @return none
  */
  resetSelection(event = null) {
    //console.log('reset selections')
    this.selectedVehicles = []
    this.selectCarForCompare = []
  }

  /*
  * @name compareVehicles
  * @param 
  * @description Open compare vehicle popup
  * @return none
  */
  compareVehicles(): void {
    let graphData = []
    this.selectCarForCompare.forEach(element => {
      if (!('diValues' in element)) {
        element['diValues'] = element['dmValues']
      }
      graphData.push(element)
    });
    //console.log(graphData, 'graphData')
    this.bottomSheet.open(CompareVehiclesComponent, { data: graphData });
  }

  getSelectionLen() {
    let keys = []
    this.selectedVehicles.forEach(element => {
      keys.push(element['DatiumAssetKey'])
    });
    this.selectCarForCompare.forEach(element => {
      keys.push(element['DatiumAssetKey'])
    });
    return this.commonService.uniqueArray(keys)
  }

  toggleSelectedVehicle(vehicle) {
    if (vehicle) {
      /*if (this.selectedVehicles.length >= 10) {
        this.selectedVehicles.pop();
        event.preventDefault();
        this.commonService.showSnakeBar("Maximum limit of selection reached");
      }
      else {*/
      //const index = this.selectedVehicles.findIndex(vehicleId => vehicleId == vehicle);
      const index = this.selectedVehicles.findIndex(element => element.DatiumAssetKey == vehicle.DatiumAssetKey);
      //console.log('index', index)
      if (index != -1) {
        this.selectedVehicles.splice(index, 1);
      }
      else {
        //console.log('push', vehicle)
        this.selectedVehicles.push(vehicle);
      }
      this.commonService.setNewVehicleSelectionLength(this.selectedVehicles.length)
      /*const veIndex = this.selectCarForCompare.findIndex(element => element.DatiumAssetKey == vehicle.DatiumAssetKey);
      //console.log('veIndex', veIndex)
      if (veIndex != -1) {
        this.selectCarForCompare.splice(veIndex, 1);
      }
      else {
        //console.log('push', vehicle)
        this.selectCarForCompare.push(vehicle);
      }*/
      //this.selectCarForCompare
      //console.log('length', this.selectedVehicles.length)
      //console.log('selectedVehicles', this.selectedVehicles)
      //}
    }
  }


  acceptValues(): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: `Are you sure you want to accept(${this.selectedVehicles.length}) vehicle(s)?`
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.acceptRejectSelVehicle(1)
      }
    });
  }

  rejectValues(): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: `Are you sure you want to reject(${this.selectedVehicles.length}) vehicle(s)?`
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.acceptRejectSelVehicle(0)
      }
    });
  }

  removeRejectedVehicle(DatiumAssetKeys: Array<any> = []) {
    DatiumAssetKeys.forEach(DatiumAssetKey => {
      const cmIndex = this.selectCarForCompare.findIndex(element => element.DatiumAssetKey == DatiumAssetKey);
      if (cmIndex != -1) {
        this.selectCarForCompare.splice(cmIndex, 1);
      }
    });

  }

  getDatiumAssetKeys(): Array<any> {
    let DatiumAssetKey = []
    this.selectedVehicles.forEach(element => {
      DatiumAssetKey.push(element.DatiumAssetKey)
    });
    return DatiumAssetKey
  }

  acceptRejectSelVehicle(flag) {
    if (this.selectedVehicles.length > 0) {
      this.isLoader = true;
      let DatiumAssetKeys = this.getDatiumAssetKeys()
      let totalV = DatiumAssetKeys.length;
      let vehicles = DatiumAssetKeys.join(',');
      let apiBody = {
        datiumAssetKeys: vehicles,
        action: flag
      }
      this.vSubscription = this.vehicleService.acceptRejectVehicles(apiBody).subscribe(
        (result: any) => {
          try {
            let response = result;
            this.dataStore.setSelectedNewVehicles({ data: DatiumAssetKeys, flag: flag })
            // Remove rejected vehicles
            if (flag == 0) {
              this.removeRejectedVehicle(DatiumAssetKeys)
            } else {
              this.selectCarForCompare = []
            }
            this.selectedVehicles = [];

            this.commonService.showSnakeBar(response.message);
            this.decTotalCounter(totalV)

            //Reload accepted vehicles
            if (flag == 1) {
              DatiumAssetKeys.forEach(DatiumAssetKey => {
                setTimeout(() => {
                  this.dataStore.resetVehicleDetailsReload(DatiumAssetKey, true, true, true)
                }, 1);
              });
            }
            this.commonService.clearCarSelectionLength()
            this.commonService.clearNewVehicleSelectionLength()
          } catch (e) {

          }
          this.isLoader = false;
        },
        (error) => {
          //console.log(error)
          try {
            let response = error;
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          } catch (e) {
            this.commonService.commonSnakeBar();
          }
          this.isLoader = false;
          //this.listLoaded.emit(false)
        },
        () => {
          this.isLoader = false;
        }
      )
    }
  }

  listLoaded(event) {

    this.totalRecord = event

    this.selectedExportPage = "";
    this.exportDataOptions = [];
    if (this.totalRecord > this.exportRecordsLimit) {
      const division = Math.ceil((this.totalRecord / this.exportRecordsLimit));
      for (let i = 1; i <= division; i++) {
        this.exportDataOptions.push({
          value: i,
          key: i < division ? `${((i - 1) * this.exportRecordsLimit) + 1} to ${i * this.exportRecordsLimit}` : `${((i - 1) * this.exportRecordsLimit) + 1} to ${this.totalRecord}`
        })
      }
    }
  }

  searchResponse(response) {
    //console.log(response)
    this.filterState = response
    this.isSearchFinished = true;
    if (response.submit == true || response.reset == true) {
      this.listLoaded(0)
    }
  }

  ngOnDestroy() {
    if (this.getSelectedVehicleSignal) {
      this.getSelectedVehicleSignal.unsubscribe()
    }
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.getAcceptedVehicle) {
      this.getAcceptedVehicle.unsubscribe()
    }
    if (this.getSelectedCarDataSignal) {
      this.getSelectedCarDataSignal.unsubscribe()
    }
  }

  exportRecords() {
    if (this.exportDataOptions.length > 0 && this.selectedExportPage === '') {
      return false;
    }
    this.isExporting = true;
    if (this.totalRecord > this.exportRecordsLimit) {
      this.filterState.exportCsvPageNo = this.selectedExportPage;
    } else {
      this.filterState.exportCsvPageNo = 1;
    }
    this.filterState = { ...this.filterState, reqType: 'download' };
  }

  exportFinishEvent(event: string) {
    if (event === 'done') {
      this.isExporting = false;
    }
  }

  searchFinshedEvent(event: string) {
    if (event === 'done') {
      this.isSearchFinished = false;
    }
  }

  selectAllEvent(event) {
    this.selectedVehicles = [];
    if (event.selectAll === false) {
      this.selectedVehicles = [];
    } else {
      this.isSelectAllEvent = true;
      //allow only 25 vehicles
      if (event.vehicles.length > 25) {
        this.commonService.showSnakeBar("Maximum 25 vehicle could be selected at a time");
      } else {
        for (let i = 0; i < event.vehicles.length; i++) {
          if (event.vehicles[i].accepted == 0) {
            this.selectedVehicles.push(event.vehicles[i]);
          } else {
            this.selectCarForCompare.push(event.vehicles[i]);
          }
        }
      }
    }
  }
}
