import { Injectable } from "@angular/core";
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JwtService } from "./jwt.service";
import { Router } from "@angular/router";
import { GaEventsService } from './ga-events.service';
import { httpStatusCodes } from "../configs/http-status-codes";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
    private router: Router,
    private dialogRef: MatDialog,
    private gaEvent: GaEventsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private handleError(response: any): Observable<never> {
    let error = response;
    let httpErrorCode = error.status;
    let errorMessage = 'An unknown error occurred!';

    if (response instanceof HttpErrorResponse) {
        httpErrorCode = response.status;
        
        if (response.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            errorMessage = `Client-side error: ${response.error.message}`;
        } else {
            // A server-side error occurred.
            errorMessage = response.error?.message || response.statusText || `Error Code: ${httpErrorCode}`;
        }
    } else {
        errorMessage = response.message || response.toString();
    }
    switch (httpErrorCode) {
      case httpStatusCodes.Unauthorized:
        let gaData = {
          'event': 'userSesExpire',
          'value': `${this.jwtService.getUserName()}~${new Date()}`
        };
        this.gaEvent.sendGaTagConfigData(gaData);
        this.jwtService.deleteLocalStorage(false, true);
        this.snackBar.open(errorMessage, 'Done', {
          duration: 3000
        });
        break;
      case httpStatusCodes.Forbidden:
        this.snackBar.open(errorMessage, 'Done', {
          duration: 3000
        });
        this.dialogRef.closeAll()
        this.router.navigate(["/dashboard"]);
        break;
      default:
        this.snackBar.open('An error occurred', 'Close', {
          duration: 3000
        });
        break;

    }

    return throwError(error);
  }
}
