<div class="chart_wrapper">
  <div class="chart_filter mb-3">
    <div class="row">
      <div class="col-3">
        <div class="di-input-wrapper">
          <label class="custom-label">Region</label>
          <mat-form-field class="mw-auto" appearance="fill" floatLabel="never">
            <mat-select (selectionChange)="onRegionChange()" [(ngModel)]="chartModel.Region"
              [disabled]="disableFieldStatus()">
              <mat-option [value]="'All'">All</mat-option>
              <mat-option *ngFor="let obj of carRegions" [value]="obj.Region">{{obj.Region}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-3">
        <div class="di-input-wrapper">
          <label class="custom-label">Year Group</label>
          <mat-form-field class="mw-auto" appearance="fill" floatLabel="never">
            <mat-select (selectionChange)="onYearChange()" [(ngModel)]="chartModel.Year_Group"
              [disabled]="disableFieldStatus()">
              <mat-option [value]="'All'">All</mat-option>
              <mat-option *ngFor="let obj of carYearGroup" [value]="obj.Year_Group">{{obj.Year_Group}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-3">
        <div class="di-input-wrapper">
          <label class="custom-label">Start Date</label>
          <mat-form-field class="mw-auto" appearance="fill" floatLabel="never">
            <input matInput [max]="maxDate" [matDatepicker]="picker1" [(ngModel)]="chartModel.StartDate"
              (ngModelChange)="onDateChange()" [disabled]="disableFieldStatus()">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
      <div class="col-3">
        <div class="di-input-wrapper">
          <label class="custom-label">End Date</label>
          <mat-form-field class="mw-auto" appearance="fill" floatLabel="never">
            <input matInput [max]="maxDate" [matDatepicker]="picker2" [(ngModel)]="chartModel.EndDate"
              (ngModelChange)="onDateChange()" [disabled]="disableFieldStatus()">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
    </div>
  </div>
  <ng-container *ngIf="!chartDataLoading && !isChartEmpty()">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
  </ng-container>
  <app-loader [ShowLoader]="chartDataLoading" [CStyle]="{'min-height':'300px'}"></app-loader>
  <no-record-found *ngIf="!chartDataLoading && isChartEmpty()" [noRecord]="true"
    [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'100px'}">
  </no-record-found>
</div>