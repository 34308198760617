<app-sel-compare-vehicle></app-sel-compare-vehicle>

<div>
  <form [formGroup]="searchForm" (ngSubmit)="handleSearchForm(searchForm)" autocomplete="false" novalidate>
    <div class="row">
      <vfacts-group-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vfacts-group-filter>

      <vehicle-make-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vehicle-make-filter>

      <vehicle-family-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vehicle-family-filter>

      <body-type-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></body-type-filter>

      <fuel-type-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></fuel-type-filter>

      <vehicle-transmission-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vehicle-transmission-filter>

      <vehicle-cylinders-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vehicle-cylinders-filter>

      <vehicle-level-filter class="col" [isMultiple]="false" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError"></vehicle-level-filter>
    </div>

    <button mat-button class="btn btn-primary mat-button" type="submit">Search</button>
    <button mat-button class="btn btn-dark mat-button" type="button" (click)="resetFilters()">Clear</button>
  </form>
</div>