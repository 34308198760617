/*
* @ngdoc Component
* @name app.vehicle.RiskAdjustmentsComponent
* @description
* This component is manage the Risk adjustment listing(Deflation, BusinessRisk and Remarketing Efficiency)
* */

import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from "../../services/vehicle.service";
import { Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddRiskAdjustmentComponent } from '../add-risk-adjustment/add-risk-adjustment.component'
import { DataStoreService } from 'src/app/services/data-store.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-risk-adjustments',
  templateUrl: './risk-adjustments.component.html',
  styleUrls: ['./risk-adjustments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RiskAdjustmentsComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['VFactsGroups', 'value', 'action'];
  isLoading: boolean = false;
  vFactGroups = [];
  vFactData = [];
  deflationData = [];
  remarketingData = [];

  loadBusinessRisk = false;
  loadDeflation = false;
  loadRemarketingEfficiency = false;


  vSubscription: Subscription;
  vFactListing: Subscription;
  delBRLoader: number = null
  delDLoader: number = null
  delRELoader: number = null
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(private commonService: CommonService, private vehicleService: VehicleService, public dialog: MatDialog, public dataStore: DataStoreService, public roleCheckService: RoleCheckerService) {

  }

  ngOnInit() {
    this.commonService.setTitle('Risk Adjustments');
    //this.getVFactsGroups();
    if (this.roleCheckService.isSuperAdmin()) {
      this.getRiskAdjustmentListing('Deflation');
    } else {
      this.getRiskAdjustmentListing('BusinessRisk');
      this.getRiskAdjustmentListing('RemarketingEfficiency');
    }
    //this.vFactGroups.paginator = this.paginator;
  }

  onLoader(type, toggle) {
    if (type == 'BusinessRisk') {
      this.loadBusinessRisk = toggle
    } else if (type == 'Deflation') {
      this.loadDeflation = toggle
    } else if (type == 'RemarketingEfficiency') {
      this.loadRemarketingEfficiency = toggle
    }
  }

  /*
  * @name getRiskAdjustmentListing
  * @param none
  * @description get the listing of (Deflation, BusinessRisk and Remarketing Efficiency)
  * @return none
  */
  getRiskAdjustmentListing(type) {
    this.onLoader(type, true)
    //let type = 'BusinessRisk';
    this.vFactListing = this.vehicleService.riskAdjustmentListing(type).subscribe(
      (result: any) => {
        try {
          let response = result;
          let listdata = response.data;
          if (type == 'BusinessRisk') {
            this.vFactData = listdata
          } else if (type == 'Deflation') {
            this.deflationData = listdata
          } else if (type == 'RemarketingEfficiency') {
            this.remarketingData = listdata
          }
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error;
        this.isLoading = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.onLoader(type, false)
      },
      () => {
        this.onLoader(type, false)
      }
    )
  }

  /*
  * @name addNewRiskAdjustment
  * @param none
  * @description Add new (Deflation, BusinessRisk and Remarketing Efficiency)
  * @return none
  */
  addNewRiskAdjustment(type): void {
    let panelClass = 'deflation-plan'
    if (type == 'Deflation') {
      panelClass = 'deflation-plan'
    }
    const dialogRef = this.dialog.open(AddRiskAdjustmentComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: false,
        type: type
      },
      panelClass: panelClass,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // console.log(result, 'result')
      if (result.id && result.VfactGroup && result.value) {
        let newData = {
          statusId: 1,
          Id: result.id,
          VFactsGroups: result.VfactGroup,
          value: result.value
        }
        if (result.type == 'BusinessRisk') {
          this.vFactData.unshift(newData);
          //console.log(this.vFactData, 'this.vFactData')
          this.vFactData = this.vFactData.slice()
          this.commonService.showSnakeBar('BusinessRisk has been added successfully.')
        } else if (result.type == 'Deflation') {
          this.deflationData.unshift(newData);
          this.deflationData = this.deflationData.slice()
          this.commonService.showSnakeBar('Deflation has been added successfully.')
        } else if (type == 'RemarketingEfficiency') {
          this.remarketingData.unshift(newData);
          this.remarketingData = this.remarketingData.slice()
          this.commonService.showSnakeBar('Remarketing efficiency has been added successfully.')
        }
      }
    })
  }

  /*
  * @name updateRiskAdjustment
  * @param none
  * @description Update new (Deflation, BusinessRisk and Remarketing Efficiency)
  * @return none
  */
  updateRiskAdjustment(object, type) {
    let panelClass = 'deflation-plan'
    if (type == 'Deflation') {
      panelClass = 'deflation-plan'
    }
    const dialogRef = this.dialog.open(AddRiskAdjustmentComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        isEdit: true,
        riskAdjustment: object,
        type: type
      },
      panelClass: panelClass,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      //console.log(result, 'result')
      if (result.id && result.VfactGroup) {
        if (result.type == 'BusinessRisk') {
          const index = this.vFactData.findIndex(obj => obj.Id == result.id);
          //console.log(index, 'index')
          if (index != -1) {
            // console.log(this.vFactData[index], 'index')
            this.vFactData[index].VFactsGroups = result.VfactGroup
            this.vFactData[index].value = result.value
            //console.log(this.vFactData[index], 'index')
            this.vFactData = this.vFactData.slice()
            this.commonService.showSnakeBar('BusinessRisk has been updated successfully.')
          }
        } else if (result.type == 'Deflation') {
          const index = this.deflationData.findIndex(obj => obj.Id == result.id);
          if (index != -1) {
            this.deflationData[index].VFactsGroups = result.VfactGroup
            this.deflationData[index].value = result.value
            this.deflationData = this.deflationData.slice()
            this.commonService.showSnakeBar('Deflation has been updated successfully.')
          }
        } else if (type == 'RemarketingEfficiency') {
          const index = this.remarketingData.findIndex(obj => obj.Id == result.id);
          if (index != -1) {
            this.remarketingData[index].VFactsGroups = result.VfactGroup
            this.remarketingData[index].value = result.value
            this.remarketingData = this.remarketingData.slice()
            this.commonService.showSnakeBar('Remarketing efficiency has been updated successfully.')
          }
        }
      }
    })
  }

  confirmDeletion(id, type) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to delete ?'
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.deleteRiskAdjustment(id, type);
      }
    });
  }

  toggleLoader(id: number = null, type: string = "", toggle: boolean = false) {
    if (type == 'BusinessRisk') {
      this.delBRLoader = toggle == true ? id : null;
    } else if (type == 'Deflation') {
      this.delDLoader = toggle == true ? id : null;
    } else if (type == 'RemarketingEfficiency') {
      this.delRELoader = toggle == true ? id : null;
    }
  }

  /*
  * @name deleteRiskAdjustment
  * @param none
  * @description delete new (Deflation, BusinessRisk and Remarketing Efficiency)
  * @return none
  */
  deleteRiskAdjustment(id, type) {
    this.isLoading = true
    let params = { id: id, type: type }
    this.toggleLoader(id, type, true)
    this.vFactListing = this.vehicleService.deleteRiskAdjustment(params).subscribe(
      (result: any) => {
        this.toggleLoader(id, type, false)
        try {
          let response = result;
          //let resultData = response.data;
          if (type == 'BusinessRisk') {
            const index = this.vFactData.findIndex(obj => obj.Id == id);
            if (index != -1) {
              this.vFactData.splice(index, 1)
              this.vFactData = this.vFactData.slice()
              this.commonService.showSnakeBar('Business Risk has been deleted successfully.')
            }
          } else if (type == 'Deflation') {
            const index = this.deflationData.findIndex(obj => obj.Id == id);
            if (index != -1) {
              this.deflationData.splice(index, 1)
              this.deflationData = this.deflationData.slice()
              this.commonService.showSnakeBar('Deflation has been deleted successfully.')
            }
          } else if (type == 'RemarketingEfficiency') {
            const index = this.remarketingData.findIndex(obj => obj.Id == id);
            if (index != -1) {
              this.remarketingData.splice(index, 1)
              this.remarketingData = this.remarketingData.slice()
              this.commonService.showSnakeBar('Remarketing efficiency has been deleted successfully.')
            }
          }
        } catch (e) {

        }
      },
      (error) => {
        this.toggleLoader(id, type, false)
        //console.log(error)
        let response = error;
        this.isLoading = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isLoading = false;
      }
    )
  }

  /*
* @name handleServerFormError
* @param Form
* @description handle server side errors                  
* @return none
*/
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }
  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.vFactListing) {
      this.vFactListing.unsubscribe()
    }
  }
}
export interface PeriodicElement {
  vFactGroups: string;
  value: number;
  action: string;
}

