<h2 mat-dialog-title>
  {{roleCheckService.isSuperAdmin() ? 'Datium Calculator' : 'RV Calculator'}}
  <button mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography rv_calculator">
  <div class="popup_content">
    <div class="heading_bar d-flex justify-content-between align-items-center mb-3">
      <h3>Select vehicle to calculate {{roleCheckService.isSuperAdmin() ? 'DI' : 'RV'}}</h3>
      <!-- <div class="vehicle_search_container d-flex align-items-center">
        <div class="search_vehicle" *ngIf="show_search != true" (click)="show_search = true">
          <span>
            <svg class="search_icon" width="19px" height="18px">
              <path fill-rule="evenodd" fill="rgb(151, 151, 159)" d="M17.844,17.082 L13.469,12.708 C14.642,11.359 15.353,9.599 15.353,7.675 C15.353,3.440 11.908,-0.000 7.676,-0.000 C3.441,-0.000 -0.000,3.444 -0.000,7.675 C-0.000,11.906 3.445,15.350 7.676,15.350 C9.601,15.350 11.361,14.639 12.710,13.466 L17.085,17.840 C17.189,17.944 17.329,18.000 17.465,18.000 C17.600,18.000 17.740,17.948 17.844,17.840 C18.051,17.633 18.051,17.290 17.844,17.082 ZM1.074,7.675 C1.074,4.035 4.036,1.078 7.672,1.078 C11.313,1.078 14.271,4.039 14.271,7.675 C14.271,11.311 11.313,14.276 7.672,14.276 C4.036,14.276 1.074,11.315 1.074,7.675 Z" />
            </svg>
          </span>
          Search vehicles
        </div>
        <mat-form-field [floatLabel]="'never'" *ngIf="show_search == true">
          <input matInput placeholder="Search AutoPredict" [autofocus]="true">
        </mat-form-field>
      </div> -->
    </div><!-- .heading_bar ends here -->

    <!-- <form [formGroup]="searchForm" class="search_filters row">
      <vfacts-group-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></vfacts-group-filter>

      <vehicle-make-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></vehicle-make-filter>

      <vehicle-family-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></vehicle-family-filter>

      <fuel-type-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></fuel-type-filter>

      <vehicle-transmission-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></vehicle-transmission-filter>

      <vehicle-cylinders-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
        [formError]="formError"></vehicle-cylinders-filter>
    </form> -->

    <div class="search_filters">
      <app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'popup'"
        (searchResponse)="searchResponse($event)" [filterClass]="'col-md-4 mt-2'"></app-vehicle-filter-search>
    </div>

    <div class="search_results">
      <ng-container>
        <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0"
          [notFoundHeading]="notFoundHeading" [notFoundDescription]="notFoundDescription"
          [cStyle]="{'height':'300px'}"></no-record-found>
        <app-loader [ShowLoader]="isLoadingList" [CStyle]="{'min-height':'200px'}"></app-loader>
        <!-- <no-record-found *ngIf="!searchForm.value.VFactsGroups" [notFoundHeading]="'Please choose any filter.'"
          [cStyle]="{'height':'300px'}"></no-record-found> -->
        <ng-container *ngFor="let vehicleObj of vehicleList">
          <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
            [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
            [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj" [type]="'calculator'"
            [rvHidden]="true" [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>
        </ng-container>
        <div class="d-flex justify-content-center p-4"
          *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
          <button color="primary" (click)="onScroll()" mat-flat-button>Load More</button>
        </div>
        <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
        <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
          [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>