<div class="single_user_row d-flex align-items-center justify-content-between">
  <div class="user_info d-flex align-items-center">
    <div class="user_thumb">
      <img
        src="{{ User && User.thumbnailUrl && User.thumbnailUrl!='' ? commonService.azureURL + User.thumbnailUrl : 'assets/images/no_user.png'}}"
        alt="" width="50" height="50">
    </div>
    <div class="user_name_email">
      <strong>{{User.Name}}</strong>
      <p>{{User.Email}}</p>
      <br />
      <p>{{User.PhoneNo}}</p>
    </div>
  </div>
  <div class="user_info d-flex align-items-center">
    <div class="role">
      <span>Invited By: <strong>{{User.Invite_User}}</strong></span>
    </div>
  </div>
  <div class="user_actions d-flex align-items-center">
    <div class="role">
      <span *ngIf="User.User_Status==0">Status: <strong class="text-warning">Invited</strong></span>
      <span *ngIf="User.User_Status==1">Status: <strong class="text-success">Active</strong></span>
    </div>
    <div class="actions">
      <button class="btn btn-outline-primary" mat-stroked-button (click)="editUser()">Edit</button>
      <button [disabled]="isProcessing" class="btn btn-outline-primary" mat-stroked-button (click)="openConfirmPopUp()">
        {{isProcessing ? 'Please Wait...' : 'Delete'}}
      </button>
    </div>
  </div>
</div>
