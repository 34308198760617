<ng-container *ngIf="vehicle">
  <div class="vehicle-details-expanded" [ngStyle]="{'top': offset+'px'}" [ngClass]="elClass">
    <div class="row labels">
      <div class="col">Index</div>
      <div class="col">12M (30,000KM)</div>
      <div class="col">24M (60,000KM)</div>
      <div class="col">36M (90,000KM)</div>
      <div class="col">48M (120,000KM)</div>
      <div class="col">60M (150,000KM)</div>
    </div>
    <div class="row value">
      <div class="col blue">
        {{roleCheckService.isSuperAdmin() ? 'DATIUM INDEX' : 'RV'}}
      </div>
      <ng-container *ngIf="dValues.length > 0;else noV">
        <div class="col" *ngFor="let d of dValues;let i=index">
          
          <app-text-format [data]="pValues[i]" [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
          <app-text-format [data]="d" [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
        </div>
      </ng-container>
      <ng-template #noV>
        <div class="col">
          No {{roleCheckService.isSuperAdmin() ? 'Datium values' : 'RV'}} values available
        </div>
      </ng-template>
    </div>
    <div class="row value">
      <div class="col red">
        {{roleCheckService.isSuperAdmin() ? 'DATIUM MODEL' : 'DATIUM'}}
      </div>
      <ng-container *ngIf="dValues.length > 0;else noD">
        <div class="col" *ngFor="let d of d1Values;let i=index">
          
          <app-text-format [data]="p1Values[i]" [defaultNoFormat]="0" [formatType]="'dollar'"></app-text-format>
          <app-text-format [data]="d" [defaultNoFormat]="0" [formatType]="'percentage'"></app-text-format>
        </div>
      </ng-container>
      <ng-template #noD>
        <div class="col">
          No Datium values available
        </div>
      </ng-template>
    </div>
    <div class="mt-3">
      <vehicle-chart [vehicle]="vehicle" [vehicleId]="vehicle.DatiumAssetKey"></vehicle-chart>
    </div>
  </div>
</ng-container>
