<ng-container [ngSwitch]="formatType">
  <ng-container *ngSwitchCase="'new_price'">
    {{(data) ? (data | currency:'AUD':'symbol-narrow':'1.0-0') : 'N/A'}}
  </ng-container>
  <ng-container *ngSwitchCase="'dollar'">
    ${{ data ? data : defaultNoFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'percentage'">
    ({{ data ? data : defaultNoFormat }}%)
  </ng-container>
  <ng-container *ngSwitchCase="'history'">
    {{ data ? data +'%' : defaultNoFormat }}
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    {{ data ? (data|date:Htime) : 'N/A' }}
  </ng-container>
  <ng-container *ngSwitchCase="'rv'">
    RV
  </ng-container>
  <ng-container *ngSwitchCase="'calculator'">
    Calculator
  </ng-container>
  <ng-container *ngSwitchDefault="">
    {{ data ? data : defaultNoFormat }}
  </ng-container>
</ng-container>
