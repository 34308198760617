<div class="row table_row nw-table-row-hover">
  <div class="col col-sm-5 col-md-5 col-lg-5">
    <div class="d-flex h-100">
      <div class="select_col tbox" (click)="$event.stopPropagation();">
        <ng-container *ngIf="!isBasket">
          <mat-checkbox (click)="toggleCarSelection($event)" (change)="OnChange($event)"
            [disabled]="!isRvValues && !commonService.toggleIndex(vehicle, 'active')" [checked]="vehicle.checked"
            *ngIf="!commonService.toggleIndex(this.vehicle, 'rejected')" matTooltip="Click to Select">
          </mat-checkbox>
        </ng-container>
      </div>
      <div class="tbox vehicle_details">
        <div class="vehicle_breif">
          <div class="vehicle_id_brand align-items-center">
            <div class="d-flex align-items-center">
              <mat-chip-list>
                <mat-chip class="pointer" matTooltip="Click to copy Datium Asset Key" ngxClipboard
                  [cbContent]="vehicleId" *ngxClipboardIfSupported (cbOnSuccess)="copiedMsg('Copied to clipboard')"
                  [disableRipple]="true" color="primary" selected>{{vehicleId}}
                </mat-chip>
              </mat-chip-list>
              <ng-container *appHasPermission="'calculator'">
                <div class="ms-2" (click)="$event.stopPropagation();"
                  *ngIf="commonService.toggleIndex(vehicle, 'accepted') && isRvValues">
                  <a [routerLink]="[]" matTooltip="{{roleCheckService.isSuperAdmin() ? 'Datium' : 'RV'}} Calculator"
                    (click)="openRVCal(vehicle)">
                    <span class="material-symbols-outlined">calculate</span>
                  </a>
                </div>
              </ng-container>
              <ng-container *appHasPermission="['basket']">
                <div class="ms-2" (click)="$event.stopPropagation();" *ngIf="vehicle['BasketVId'] && !isNewVehicle">
                  <a matTooltip="Remove from Basket" href="javascript:;" (click)="deleteBasket()">
                    <span class="material-symbols-outlined red">shopping_basket</span>
                  </a>
                </div>
                <div class="ms-2" (click)="$event.stopPropagation();" *ngIf="!vehicle['BasketVId'] && !isNewVehicle">
                  <a matTooltip="Add to Basket" href="javascript:;" (click)="toggleBasketModel()">
                    <span class="material-symbols-outlined green">shopping_basket</span>
                  </a>
                </div>
              </ng-container>
              <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
                <div class="ms-2 d-flex"
                  *ngIf="isRvValues && !roleCheckService.isSuperAdmin() && commonService.toggleIndex(vehicle, 'accepted')"
                  (click)="$event.stopPropagation();">
                  <a matTooltip="Capture RV" [routerLink]="[]" (click)="captureValues(vehicle)">
                    <span class="material-symbols-outlined">photo_camera</span>
                  </a>
                </div>
              </ng-container>
            </div>
            <div class="brand_modal_linked mb-2 mt-2 d-flex flex-column">
              <div class="brand_modal">
                <span class="font-weight-bold">
                  <app-text-format [data]="vehicle?.vehicleDetails?.make"></app-text-format>
                </span>
                <span class="dot"></span>
                <app-text-format [data]="vehicle?.vehicleDetails?.model"></app-text-format>
                <span class="dot"></span>
                <app-text-format [data]="vehicle?.vehicleDetails?.year_group"></app-text-format>
              </div>
              <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
                <div matTooltip="Click to copy Datium Asset Key" ngxClipboard [cbContent]="vehicle?.NewLink"
                  (cbOnSuccess)="copiedMsg('Copied to clipboard')" class="d-flex text-warning tooltip_parent"
                  *ngIf="vehicle?.NewLink">
                  <span class="material-symbols-outlined me-2 orange">link</span>

                  <app-text-format [data]="vehicle?.NewLink"></app-text-format>
                </div>
              </ng-container>
              <div class="mt-2 text-primary d-flex tooltip_parent"
                *ngIf="vehicle?.linkedVehicle?.DatiumAssetKey && !vehicle?.NewLink">
                <span class="material-symbols-outlined me-2 blue">link</span>
                <app-text-format [data]="vehicle?.linkedVehicle?.DatiumAssetKey"></app-text-format>
                <div class="tooltip_container" *ngIf="vehicle?.linkedVehicle?.vehicleDetails">
                  <div class="d-flex align-items-center">
                    <mat-chip-list>
                      <mat-chip [disableRipple]="true" color="primary"
                        selected>{{vehicle?.linkedVehicle?.DatiumAssetKey}}
                      </mat-chip>
                    </mat-chip-list>
                    <p class="font-weight-bold ms-3">
                      <app-text-format [data]="vehicle?.linkedVehicle?.vehicleDetails?.make"></app-text-format>
                      <span class="dot"></span>
                      <app-text-format [data]="vehicle?.linkedVehicle?.vehicleDetails?.model"></app-text-format>
                      <!-- <span class="dot"></span>
                      <app-text-format [data]="vehicle?.linkedVehicle?.vehicleDetails?.year_group"></app-text-format> -->
                    </p>
                  </div>
                  <p class="linked_info">
                    <app-text-format [data]="vehicle?.linkedVehicle?.vehicleDetails?.description"></app-text-format>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="mt-1">
            <app-text-format [data]="vehicle?.vehicleDetails?.description"></app-text-format>
          </p>
        </div>
      </div>
      <div class="tbox justify-content-center">
        <p><app-text-format [data]="vehicle?.vehicleDetails?.units" [defaultNoFormat]="0"></app-text-format></p>
      </div>
      <div class="tbox align-items-center justify-content-end">
        <p><app-text-format [data]="vehicle?.vehicleDetails?.new_price" [defaultNoFormat]="0"
            [formatType]="'new_price'">
          </app-text-format></p>
      </div>
    </div>
  </div>
  <!-- .col-lg-5 ends here -->
  <div class="col col-sm-7 col-md-7 col-lg-7 nw-rv-value d-flex align-items-center justify-content-between"
    *ngIf="isRvValues" [ngClass]="{'new_vehicles_list':!commonService.toggleIndex(vehicle,'toggle')}">
    <app-vehicle-dt-indexes class="w-100 h-100" [vehicle]="vehicle"></app-vehicle-dt-indexes>
    <div *ngIf="!commonService.toggleIndex(vehicle,'toggle')" class="action-btn-area d-flex flex-column"
      (click)="$event.stopPropagation();">
      <button (click)="acceptValues()" color="primary" mat-flat-button [disabled]="isLoader">
        Accept
      </button>
      <button (click)="rejectValues()" color="warn" class="mt-2" mat-stroked-button [disabled]="isLoader">
        Reject
      </button>
    </div>
  </div>


  <!-- .col-lg-7 ends here -->
  <div class="col col-sm-7 col-md-7 col-lg-7" *ngIf="!isRvValues"
    [ngClass]="{'new_vehicles_list':!commonService.toggleIndex(vehicle,'toggle')}">
    <div class="d-flex justify-content-between align-items-center border-left h-100 p-4">
      <div>No {{roleCheckService.isSuperAdmin() ? 'Datium values' : 'RV'}} available</div>
      <ng-container *ngIf="commonService.toggleIndex(vehicle,'accepted');else acptRejectbtn">
        <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
          <div (click)="$event.stopPropagation();" *ngIf="roleCheckService.isWriteAccess()">
            <button class="me-3" (click)="addRvValues(vehicle)" mat-stroked-button color="primary">
              {{roleCheckService.isSuperAdmin() ? 'Add Datium Index' : 'Add RV Values'}}
            </button>
            <button (click)="openLinkedVehicle()" *ngIf="!linkedVehicle" color="primary" mat-flat-button>Link
              Vehicle</button>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #acptRejectbtn>
        <div (click)="$event.stopPropagation();" class="action-btn-area">
          <button *ngIf="commonService.toggleIndex(vehicle,'accepted')" mat-flat-button color="primary">
            <span class="material-symbols-outlined">check_circle</span> Accepted
          </button>
          <button *ngIf="commonService.toggleIndex(vehicle,'rejected')" class="ms-3" mat-stroked-button color="warn">
            <span class="material-symbols-outlined">cancel</span> Rejected
          </button>
          <button (click)="acceptValues()" *ngIf="commonService.toggleIndex(vehicle,'active')" mat-flat-button
            color="primary">
            <span class="material-symbols-outlined">check_circle</span> Accept
          </button>
          <button (click)="rejectValues()" *ngIf="commonService.toggleIndex(vehicle,'active')" class="ms-3"
            mat-stroked-button color="warn">
            <span class="material-symbols-outlined">cancel</span> Reject
          </button>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- <div class="col col-lg-7" *ngIf="isAdmin">
    <div class="d-flex justify-content-between align-items-center border-left h-100 p-4">
      <div>No RV available</div>
      <div (click)="$event.stopPropagation();">
        <button class="btn btn-outline-primary me-3" mat-stroked-button>Datium Calculator</button>
        <button class="btn btn-outline-primary me-3" (click)="addRvValues(vehicle)" mat-stroked-button>Add Datium Index</button>
        <button *ngIf="!linkedVehicle" class="btn btn-primary" mat-button>Link Vehicle</button>
      </div>
    </div>
  </div> -->
</div>