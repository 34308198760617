/*
* @ngdoc Component
* @name app.vehicle.AddRiskAdjustmentComponent
* @description
* This component is manage the Risk adjustment(Deflation, BusinessRisk and Remarketing Efficiency)
* */

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CompanyService } from 'src/app/services/company.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { VehicleService } from "../../services/vehicle.service";
import { RoleCheckerService } from 'src/app/services/role-checker.service';

@Component({
    selector: 'app-add-company',
    templateUrl: './add-risk-adjustment.component.html',
    styleUrls: ['./add-risk-adjustment.component.scss']
})

export class AddRiskAdjustmentComponent implements OnInit {
    public isProcessing: boolean = false;
    public riskAdjustmentForm: UntypedFormGroup;
    public isEdit: boolean;
    vFactGroups = [];
    //public numberValueMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/];
    isMultiple = false;
    formError = {};
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<AddRiskAdjustmentComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public companyService: CompanyService,
        public commonService: CommonService,
        private vehicleService: VehicleService,
        public roleCheckService: RoleCheckerService
    ) {
        this.isEdit = this.data.isEdit;
        if ((this.roleCheckService.isCompanyOwner() || this.roleCheckService.isCompanyAdmin() || this.roleCheckService.isCompanyUser()) && this.data.type == 'Deflation') {
            this.commonService.showSnakeBar('You are not authorize to perform this action.')
            this.closeModal();
        }
    }

    ngOnInit() {
        this.initForm();
        this.getVFactsGroups();
    }


    getTitle() {
        let s = this.isEdit ? 'Update ' : 'Add ';
        let type = this.data.type;
        if (type == 'Deflation') {
            s = `${s}Deflation`
        } else if (type == 'BusinessRisk') {
            s = `${s}Business Risk`
        } else if (type == 'RemarketingEfficiency') {
            s = `${s}Remarketing Efficiency`
        }
        return s;
    }

    /*
    * @name initForm
    * @param none
    * @description Initialize forms             
    * @return none
    */
    initForm() {
        if (this.isEdit) {
            //this.existingUsers = this.data.company.No_User;
            this.riskAdjustmentForm = new UntypedFormGroup({
                'VFactsGroups': new UntypedFormControl(this.data.riskAdjustment.VFactsGroups),
                'value': new UntypedFormControl(this.data.riskAdjustment.value, [
                    Validators.required
                ])
            });
            //this.setImageUrl(this.data.company.CompanyLogo, this.data.company.Thumbnail);
        }
        else {
            this.riskAdjustmentForm = new UntypedFormGroup({
                'VFactsGroups': new UntypedFormControl(null, Validators.required),
                'value': new UntypedFormControl(null, Validators.required)
            });
        }
    }

    /*
  * @name getVFactsGroups
  * @param none
  * @description Get VFactsGroups filter list                  
  * @return none
  */
    getVFactsGroups() {
        //this.isLoading = true
        let apiParams = {
            filter: 'VFactsGroups'
        }
        this.vehicleService.getVehicleFilters(apiParams).subscribe(
            (result: any) => {
                try {
                    let response = result;
                    this.vFactGroups = response.data;

                } catch (e) {

                }
            },
            (error) => {
                //console.log(error)
                let response = error;
                //this.isLoading = false;
                if (response.message) {
                    //this.handleServerFormError(response.errors)
                    this.commonService.showSnakeBar(response.message);
                } else {
                    this.commonService.commonSnakeBar();
                }
            },
            () => {
                //this.isLoading = false;
            }
        )
    }

    /*
    * @name addNewRiskAdjustment
    * @param none
    * @description Add new risk adjustments             
    * @return none
    */
    addNewRiskAdjustment(form) {
        if (form.valid) {
            //console.log(form.value, 'form.value')
            this.isProcessing = true;
            form.value.type = this.data.type;
            this.formError = {};
            this.vehicleService.addRiskAdjustment(form.value).subscribe((res: any) => {
                try {
                    const response = res;
                    //this.onCompanyAdd.emit(response.data[0]);
                    response.data[0]['type'] = this.data.type;
                    this.closeModal(response.data[0]);
                }
                catch (e) {
                    this.commonService.commonSnakeBar();
                }
                this.isProcessing = false;
            }, (err) => {
                let response = err;
                this.isProcessing = false;
                if (response.message) {
                    if ('errors' in response.errors) {
                        this.handleServerFormError(response.errors)
                    }
                    this.commonService.showSnakeBar(response.message)
                }
                else {
                    this.commonService.commonSnakeBar();
                }
                // this.closeModal(response.data[0]);
            }, () => {
                this.isProcessing = false;
                //this.closeModal();
            })
        }
        else {
            this.formValidate(form);
        }
    }

    /*
    * @name handleServerFormError
    * @param Form
    * @description handle server side errors                  
    * @return none
    */
    handleServerFormError(errors) {
        if (Object.keys(errors).length > 0) {
            Object.keys(errors).forEach((key) => {
                if (this.riskAdjustmentForm.contains(key)) {
                    let control = this.riskAdjustmentForm.get(key);
                    control.markAsTouched({ onlySelf: true });
                    control.setErrors({ 'incorrect': true })
                    this.formError[key] = errors[key];
                }
            });
        }
    }

    /*
    * @name updateNewAdjustment
    * @param none
    * @description update new risk adjustments             
    * @return none
    */
    updateNewAdjustment(form) {
        if (form.valid) {
            this.isProcessing = true;
            form.value.type = this.data.type;
            form.value.id = this.data.riskAdjustment.Id
            this.vehicleService.updateRiskAdjustment(form.value).subscribe((res: any) => {
                try {
                    const response = res;
                    //this.onCompanyUpdate.emit(response.data[0]);
                    response.data[0]['type'] = this.data.type;
                    this.closeModal(response.data[0]);
                }
                catch (e) {
                    this.commonService.commonSnakeBar();
                }
                this.isProcessing = false;
            }, (err) => {
                let response = err;
                this.isProcessing = false;
                if (response.message) {
                    this.commonService.showSnakeBar(response.message)
                }
                else if (response.errors) {
                    this.formatErrors(response.errors);
                } else {
                    this.commonService.commonSnakeBar();
                }
                //this.closeModal(response.data[0]);
            }, () => {
                this.isProcessing = false;
                //this.closeModal();
            })
        }
        else {
            this.formValidate(form);
        }
    }

    closeModal(data = {}) {
        this.dialogRef.close(data);
    }

    formValidate(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.formValidate(control);
            }
        });
    }

    formatErrors(errors) {
        const objKeys = Object.keys(errors);
        for (let i = 0; i < objKeys.length; i++) {
            if (errors[objKeys[i]]) {
                this.commonService.showSnakeBar(errors[objKeys[i]]);
            }
        }
    }

}