import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import { VehicleService } from '../../services/vehicle.service';
import { RoleCheckerService } from '../../services/role-checker.service';
import { DataStoreService } from '../../services/data-store.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-new-admin-link',
  templateUrl: './new-admin-link.component.html',
  styleUrls: ['./new-admin-link.component.scss']
})
export class NewAdminLinkComponent implements OnInit, OnDestroy {

  formData: any = {};
  linkData: any;
  loadingRv: boolean = false
  acceptingIndex: boolean = false
  vSubscription: Subscription
  constructor(public dialogObjRef: MatDialogRef<NewAdminLinkComponent>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private commonService: CommonService, private vehicleService: VehicleService, public roleCheckService: RoleCheckerService, private dataStore: DataStoreService) {
    this.formData = data;
    //console.log(data, 'data')
    //this.vehicle = this.formData['vehicle']
  }

  ngOnInit() {
    this.getNewLinkDetails()
  }

  getNewLinkDetails() {
    let newLink = this.formData['NewLink']
    let vehicleId = this.formData['vehicleId']
    if (newLink != "undefined" && vehicleId != "undefined") {
      this.loadingRv = true
      this.vSubscription = this.vehicleService.getNewVehicleLinkDetail(newLink, vehicleId).subscribe(
        (result: any) => {
          this.loadingRv = false
          try {
            let response = result;
            this.linkData = response['data']
          } catch (e) {

          }
        },
        (error) => {
          this.loadingRv = false
          //console.log(error)
          let response = error;
          if (response.message) {
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        },
        () => {
          this.loadingRv = false
        }
      )
    } else {
      this.commonService.showSnakeBar('Invalid Datium Asset Key')
      this.closeModel()
    }
  }

  closeModel() {
    //console.log('closing..')
    this.dialogObjRef.close()
  }


  acceptDatiumValues(vehicleId, NewLink) {
    let data = {
      datiumAssetKey: vehicleId,
      NewLink: NewLink,
      acceptRV: 0,
      acceptDI: 1,
      reject: 0
    }
    let title = "Are you sure you want to accept DI index?"
    this.acceptRejectValues(data, title)
  }

  acceptRVValues(vehicleId, NewLink) {
    let data = {
      datiumAssetKey: vehicleId,
      NewLink: NewLink,
      acceptRV: 0,
      acceptDI: 1,
      reject: 0
    }
    let title = "Are you sure you want to accept RV index?"
    this.acceptRejectValues(data, title)
  }

  rejectValues(vehicleId, NewLink) {
    let data = {
      datiumAssetKey: vehicleId,
      NewLink: NewLink,
      acceptRV: 0,
      acceptDI: 0,
      reject: 1
    }
    let title = "Are you sure you want to reject new link?"
    this.acceptRejectValues(data, title)
  }

  acceptRejectValues(data, title = "Are you sure you want to perform this action?") {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: title
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.acceptingIndex = true
        this.vSubscription = this.vehicleService.acceptRejectNewLinkDetail(data).subscribe(
          (result: any) => {
            this.acceptingIndex = false
            try {
              let response = result;
              this.linkData = response['data']
              this.dataStore.resetVehicleDetailsReload(data['datiumAssetKey'], true, true)
              this.dialogObjRef.close()
            } catch (e) {

            }
          },
          (error) => {
            this.acceptingIndex = false
            //console.log(error)
            let response = error;
            if (response.message) {
              this.commonService.showSnakeBar(response.message);
            } else {
              this.commonService.commonSnakeBar();
            }
          },
          () => {
            this.acceptingIndex = false
          }
        )
      }
    });
  }


  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
  }

}
