<div class="colors">

  <div class="color_boxes_container">
    <div class="container-xxl">
      <h2 class="mb-3">Colors</h2>

      <div class="row">

        <div class="col col-lg-4">
          <div class="color_box">
            <h3>On Hover</h3>
            <div class="color_thumbs d-flex">
              <div class="bg-primary color_thumb"></div>
              <div class="bg-black color_thumb"></div>
              <div class="bg-dark color_thumb"></div>
              <div class="bg-light color_thumb"></div>
              <div class="bg-danger color_thumb"></div>
              <div class="bg-success color_thumb"></div>
              <div class="bg-warning color_thumb"></div>
            </div>
          </div>
        </div>

        <div class="col col-lg-4">
          <div class="color_box">
            <h3>Gradients</h3>
            <div class="color_thumbs d-flex">
              <div class="blue-gradient color_thumb"></div>
              <div class="red-gradient color_thumb"></div>
              <div class="yellow-gradient color_thumb"></div>
              <div class="purple-gradient color_thumb"></div>
              <div class="cyan-gradient color_thumb"></div>
              <div class="skyblue-gradient color_thumb"></div>
            </div>
          </div>
        </div>

        <div class="col col-lg-4">
          <div class="color_box h-100">

          </div>
        </div>

      </div>
    </div>

  </div>

</div>

<div class="typography">

  <div class="container-xxl">
    <div class="row">

      <div class="col col-lg-6">
        <h2 class="mb-3 mt-5">Typography</h2>
        <div class="container_box">
          <p class="mb-3">Heading 1 (36px, Bold)</p>
          <h1>The quick brown fox jumps over the lazy dog</h1>

          <p class="mt-5 mb-3">Heading 2 (22px, Bold)</p>
          <h2>The quick brown fox jumps over the lazy dog</h2>

          <p class="mt-5 mb-3">Heading 3 (16px, Bold)</p>
          <h3>The quick brown fox jumps over the lazy dog</h3>

          <p class="mt-5 mb-3">Heading 4 (14px, Bold)</p>
          <h4>The quick brown fox jumps over the lazy dog</h4>

          <p class="mt-5 mb-3">Heading 5 (12px, Bold, Uppercase)</p>
          <h5>The quick brown fox jumps over the lazy dog</h5>

          <p class="mt-5 mb-3">Paragraph (14px, Regular)</p>
          <p>The quick brown fox jumps over the lazy dog</p>

        </div>
      </div>

      <div class="col col-lg-6">
        <h2 class="mb-3 mt-5">Input Elements</h2>
        <div class="container_box">

          <h3 class="mb-4">Text field</h3>
          <div class="row">
            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered">
                <label>Normal</label>
                <input matInput>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered">
                <label>Hover</label>
                <input matInput>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered">
                <label>Focus</label>
                <input matInput>
              </mat-form-field>
            </div>

          </div>

          <div class="row mt-4">

            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered">
                <label>Disable</label>
                <input matInput disabled>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered success">
                <label>Success</label>
                <input matInput>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <mat-form-field [floatLabel]="'never'" class="bordered error">
                <label>Error</label>
                <input matInput>
              </mat-form-field>
            </div>

          </div>

          <div class="row mt-5">

            <div class="col col-lg-4">
              <h3 class="mb-3">Select</h3>
              <mat-form-field>
                <mat-select placeholder="Select">
                  <mat-option *ngFor="let role of roles" [value]="role.value">
                    {{role.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col col-lg-4">
              <h3 class="mb-3">Textarea</h3>
              <mat-form-field [floatLabel]="'never'" class="bordered">
                <textarea matInput cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"></textarea>
              </mat-form-field>

            </div>

            <div class="col col-lg-4">
              <h3 class="mb-3">Check/Radio button</h3>

              <div class="d-flex">
                <ul class="mr-5">
                  <li class="mb-3">
                    <mat-checkbox>Option 1</mat-checkbox>
                  </li>
                  <li class="mb-3">
                    <mat-checkbox>Option 2</mat-checkbox>
                  </li>
                  <li class="mb-3">
                    <mat-checkbox>Option 3</mat-checkbox>
                  </li>
                </ul>

                <ul>
                  <li class="mb-3">
                    <mat-radio-button value="1">Option 1</mat-radio-button>
                  </li>
                  <li class="mb-3">
                    <mat-radio-button value="2">Option 2</mat-radio-button>
                  </li>
                  <li class="mb-3">
                    <mat-radio-button value="3">Option 3</mat-radio-button>
                  </li>
                </ul>

              </div>

            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

</div>

<div class="button_misc">

  <div class="container-xxl">

    <div class="row">
      <div class="col col-lg-8">
        <h2 class="mb-3 mt-5">Misc. Elements</h2>
        <div class="container_box">
          <div class="row">
            <div class="col col-lg-4">
              <h3 class="mb-2">Dropdown</h3>

              <mat-form-field class="button_type">
                <mat-select placeholder="Select">
                  <mat-option *ngFor="let dropdown of dropdowns" [value]="dropdown.value">
                    {{dropdown.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col col-lg-4">
              <h3 class="mb-2">Number Slider</h3>

              <mat-slider class="number_of_users" [disabled]="disabled" [invert]="invert" [max]="100" [min]="0"
                [step]="step" [thumbLabel]="thumbLabel" [tickInterval]="tickInterval" [(ngModel)]="value"
                [vertical]="vertical">
              </mat-slider>

              <h3 class="mt-3 mb-4">Company Avatar</h3>

              <div class="d-flex">

                <div class="upload_logo mr-4">
                  <div class="thumbPlaceholder bg-primary upload"
                    onclick="document.getElementById('fileToUpload').click()">
                    <svg width="64px" height="51px">
                      <path fill-rule="evenodd" fill="rgba(255,255,255, 0.3)"
                        d="M53.400,50.949 L53.400,40.599 L49.127,40.599 L49.127,4.610 L63.474,4.610 L63.474,50.949 L53.400,50.949 ZM58.266,10.071 L52.211,10.071 L52.211,16.155 L58.266,16.155 L58.266,10.071 ZM58.266,19.849 L52.211,19.849 L52.211,25.933 L58.266,25.933 L58.266,19.849 ZM58.266,29.627 L52.211,29.627 L52.211,35.710 L58.266,35.710 L58.266,29.627 ZM35.478,39.581 L28.827,39.581 L28.827,50.949 L17.761,50.949 L17.761,0.051 L46.544,0.051 L46.544,50.949 L35.478,50.949 L35.478,39.581 ZM30.133,6.049 L23.482,6.049 L23.482,12.732 L30.133,12.732 L30.133,6.049 ZM30.133,16.789 L23.482,16.789 L23.482,23.471 L30.133,23.471 L30.133,16.789 ZM30.133,27.528 L23.482,27.528 L23.482,34.211 L30.133,34.211 L30.133,27.528 ZM40.823,6.049 L34.172,6.049 L34.172,12.732 L40.823,12.732 L40.823,6.049 ZM40.823,16.789 L34.172,16.789 L34.172,23.471 L40.823,23.471 L40.823,16.789 ZM40.823,27.528 L34.172,27.528 L34.172,34.211 L40.823,34.211 L40.823,27.528 ZM10.905,50.949 L0.831,50.949 L0.831,4.610 L15.178,4.610 L15.178,40.599 L10.905,40.599 L10.905,50.949 ZM12.094,10.071 L6.039,10.071 L6.039,16.155 L12.094,16.155 L12.094,10.071 ZM12.094,19.849 L6.039,19.849 L6.039,25.933 L12.094,25.933 L12.094,19.849 ZM12.094,35.710 L12.094,29.627 L6.039,29.627 L6.039,35.710 L12.094,35.710 Z" />
                    </svg>
                    <span class="upload-icon position-absolute">
                      <span class="material-symbols-outlined">photo_camera</span>
                    </span>
                  </div>
                  <input id="fileToUpload" type="file" style="display:none;">
                </div>

                <div class="company_thumb mr-4">
                  <div class="thumbPlaceholder lg">
                    <svg width="64px" height="51px">
                      <path fill-rule="evenodd" opacity="0.302" fill="rgb(255, 255, 255)"
                        d="M53.400,50.949 L53.400,40.599 L49.127,40.599 L49.127,4.610 L63.474,4.610 L63.474,50.949 L53.400,50.949 ZM58.266,10.071 L52.211,10.071 L52.211,16.155 L58.266,16.155 L58.266,10.071 ZM58.266,19.849 L52.211,19.849 L52.211,25.933 L58.266,25.933 L58.266,19.849 ZM58.266,29.627 L52.211,29.627 L52.211,35.710 L58.266,35.710 L58.266,29.627 ZM35.478,39.581 L28.827,39.581 L28.827,50.949 L17.761,50.949 L17.761,0.051 L46.544,0.051 L46.544,50.949 L35.478,50.949 L35.478,39.581 ZM30.133,6.049 L23.482,6.049 L23.482,12.732 L30.133,12.732 L30.133,6.049 ZM30.133,16.789 L23.482,16.789 L23.482,23.471 L30.133,23.471 L30.133,16.789 ZM30.133,27.528 L23.482,27.528 L23.482,34.211 L30.133,34.211 L30.133,27.528 ZM40.823,6.049 L34.172,6.049 L34.172,12.732 L40.823,12.732 L40.823,6.049 ZM40.823,16.789 L34.172,16.789 L34.172,23.471 L40.823,23.471 L40.823,16.789 ZM40.823,27.528 L34.172,27.528 L34.172,34.211 L40.823,34.211 L40.823,27.528 ZM10.905,50.949 L0.830,50.949 L0.830,4.610 L15.178,4.610 L15.178,40.599 L10.905,40.599 L10.905,50.949 ZM12.094,10.071 L6.039,10.071 L6.039,16.155 L12.094,16.155 L12.094,10.071 ZM12.094,19.849 L6.039,19.849 L6.039,25.933 L12.094,25.933 L12.094,19.849 ZM12.094,35.710 L12.094,29.627 L6.039,29.627 L6.039,35.710 L12.094,35.710 Z" />
                    </svg>
                  </div>
                </div>

                <div class="user_thumb">
                  <div class="thumb_box">
                    <img src="assets/images/user_thumb.svg" alt="Ray M. Wallace">
                  </div>
                </div>

              </div>


            </div>
            <div class="col col-lg-4">

              <h3 class="mb-4">Loaders</h3>

              <div class="loading_div d-flex">
                <div class="thumb me-3">
                  <div class="animatingLayer"></div>
                </div>
                <div class="rows">
                  <div class="animatingLayer first"></div>
                  <div class="animatingLayer"></div>
                </div>
              </div>

              <div class="data_loading mt-4 justify-content-start" style="min-height:auto;">
                <mat-spinner></mat-spinner> Loading...
              </div>

              <div class="data_loading bg-dark mt-4 justify-content-start" style="min-height:auto;">
                <mat-spinner></mat-spinner> Loading...
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <h2 class="mb-3 mt-5">Buttons</h2>
        <div class="container_box">

        </div>
      </div>
    </div>

  </div>

</div>