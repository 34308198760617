import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'vehicle-detail-wrapper',
  templateUrl: './vehicle-detail-wrapper.component.html',
  styleUrls: ['./vehicle-detail-wrapper.component.scss']
})
export class VehicleDetailWrapperComponent implements OnInit, OnDestroy, OnChanges {

  @Input() isRvValues;
  @Input() linkedVehicle;
  @Input() vehicleId;
  @Input() vehicle;
  @Input() isAdmin: boolean;
  @Input() preventClose: string
  @Input() preventToggle: string = null;
  @Input() newScreen: string = null;

  resetRVHistorySubscription: Subscription
  veLoader: Subscription
  vSubscription: Subscription
  show_vehicle: boolean = false;

  loaderData = {
    loading: false,
    text: ""
  }
  clickText = 'Click to expand'
  constructor(private dataStore: DataStoreService, private commonService: CommonService, private vehicleService: VehicleService) {
    /*this.resetRVHistorySubscription = this.dataStore.checkVehicleDetailsReload().subscribe((data) => {
      //console.log('data', data, this.vehicleId, this.vehicle.DatiumAssetKey)
      if (data['DatiumAssetKey'] == this.vehicleId) {
        //console.log('matched')
        if (this.resetRVHistorySubscription) {
          this.resetRVHistorySubscription.unsubscribe()
        }
        this.getVehicleDetails();
      }
    });*/

    this.veLoader = this.dataStore.getVeLoader().subscribe((data) => {
      if (data['DatiumAssetKey'] == this.vehicleId) {
        this.loaderData.loading = data['toggle']
        this.loaderData.text = data['text']
      }
    });
  }

  ngOnChanges(changes) {
    // Reset vehicle listing array because user click on search button
    //console.log('changes.preventClose', changes.preventClose.currentValue, this.vehicleId)
    //console.log(changes)
    if (changes['preventClose']) {
      if (changes.preventClose.currentValue == this.vehicleId) {
        //console.log('mathc')
        if (this.commonService.toggleIndex(this.vehicle, 'active')) {
          if (changes['newScreen'].currentValue == this.vehicleId) {

          } else {
            this.show_vehicle = true;
            this.clickText = 'Click to close'
          }
        }
      } else {
        //console.log('no mathc')
      }
    }
    // Hide the rejected vehicle
    //console.log(changes, this.commonService.toggleIndex(this.vehicle, 'rejected'))
    if (this.commonService.toggleIndex(this.vehicle, 'rejected')) {
      this.show_vehicle = false;
      this.clickText = 'Click to expand'
      //console.log(this.show_vehicle, 'this.show_vehicle')
    }
  }

  ngOnInit() {
    //console.log('here')
    //console.log(this.preventClose)
    //console.log(this.preventToggle)
  }

  checkVehicleRejected() {
    return this.commonService.toggleIndex(this.vehicle, 'rejected')
  }

  showVehicleDetail() {
    //if (this.commonService.toggleIndex(this.vehicle, 'active')) {
    this.show_vehicle = !this.show_vehicle;
    if (this.show_vehicle == true) {
      this.clickText = 'Click to close'
    } else {
      this.clickText = 'Click to expand'
    }
    //}
  }


  getVehicleDetails() {
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    //console.log('getVehicleDetails', this.vehicleId)
    this.vSubscription = this.vehicleService.getVehicleDetail(this.vehicleId).subscribe(
      (result: any) => {
        try {
          let response = result;
          this.vehicle = response.data;
          //console.log('this.vehicle', this.vehicle)
        } catch (e) {

        }
      },
      (error) => {
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }

  vehicleActionEvent(event) {
    this.vehicle.checked = event.checked;
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe();
    }
    if (this.veLoader) {
      this.veLoader.unsubscribe();
    }
    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe();
    }
  }

}
