import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { httpStatusCodes } from 'src/app/configs/http-status-codes';
import { products } from 'src/app/configs/products';
import { CommonService } from 'src/app/services/common.service';
import { JwtService } from 'src/app/services/jwt.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'products-dropdown',
  templateUrl: './products-dropdown.component.html',
  styleUrls: ['./products-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsDropdownComponent implements OnInit {

  public urlWithToken: string = "";
  public portalUrl: string = environment.portalUrl;
  public isLoading: boolean = false;
  public companyId: number;
  public datiumProducts: Array<any> = [];
  constructor(
    private authService: JwtService,
    public router: Router,
    private userService: UserService,
    private commonService: CommonService,
  ) {
    this.urlWithToken = "login?token=" + this.authService.getAccessToken();
  }

  ngOnInit() {
    this.companyId = this.authService.getCompanyId();
    this.getCompanyProductsList();
  }

  getCompanyProductsList() {
    this.isLoading = true;
    let params = {
      companyId: this.companyId
    };
    let hasTruckValBikeValAccess: boolean = false;
    let hasInstantValAccess: boolean = false;
    this.userService.userProductsAccess(params).subscribe((response: any) => {
      try {
        if (response.status === httpStatusCodes.Success) {
          response.data.sort(function (x, y) { return x.Product_ID == products.instantval ? -1 : y.Product_ID == products.instantval ? 1 : 0; });
          if (response.data[0].hasTruckValBikeValAccess) {
            hasTruckValBikeValAccess = response.data[0].hasTruckValBikeValAccess;
          }
          let productsArray: Array<number> = [];
          response.data.forEach((product: any) => {
            if (product.hasNavigationAccess) {
              if (product.Product_ID === products.instantval) {
                hasInstantValAccess = true;
              }
              productsArray.push(product.Product_ID);
            } else {
              if (product.Product_ID === products.autopredict) {
                //if user is not allowed to see autopredict, redirect them to webportal
                this.commonService.notAuthorized();
                setTimeout(() => {
                  window.location.href = this.portalUrl;
                }, 500);
              }
            }
          });
          if (!hasInstantValAccess) { //if user don't have instantval then add salvageval at first index
            productsArray.splice(0, 0, products.salvageval)
          }
          this.datiumProducts = [];
          let instaProducts: Array<any> = [];
          productsArray.forEach((productId, index) => {
            if (index === 0 && (productId === products.instantval || productId === products.salvageval)) {
              if (productId === products.instantval) {
                instaProducts.push(
                  {
                    icon: 'directions_car',
                    name: 'Car',
                    url: environment.instantvalUrl + this.urlWithToken
                  }
                );
                if (hasTruckValBikeValAccess) {
                  instaProducts.push(
                    {
                      icon: 'local_shipping',
                      name: 'Truck',
                      url: environment.truckvalUrl + this.urlWithToken
                    },
                    {
                      icon: 'motorcycle',
                      name: 'Motorcycle',
                      url: environment.bikevalUrl + this.urlWithToken
                    }
                  );
                }
                if (productsArray.includes(products.salvageval)) {
                  instaProducts.push(
                    {
                      icon: 'auto_towing',
                      name: 'Salvage',
                      url: environment.salvagevalUrl + this.urlWithToken
                    }
                  );
                }
              } else {
                instaProducts.push(
                  {
                    icon: 'auto_towing',
                    name: 'Salvage',
                    url: environment.salvagevalUrl + this.urlWithToken
                  }
                );
              }

              this.datiumProducts.push(
                {
                  category: 'Valuation',
                  products: instaProducts
                }
              );
            }

            if (productId === products.autopredict) {

              this.datiumProducts.push(
                {
                  category: 'Residual Values',
                  products: [
                    {
                      icon: 'manage_search',
                      name: 'AutoPredict',
                      url: ''
                    }
                  ]
                }
              );

            }

            if (productId === products.pricemytruck) {
              this.datiumProducts.push(
                {
                  category: 'Sold Prices',
                  products: [
                    {
                      icon: 'sell',
                      name: 'PriceMyTruck',
                      url: environment.pricemytruckUrl + this.urlWithToken
                    }
                  ]
                }
              );
            }
          });
        }
      }
      catch (e) {
      }
      this.isLoading = false;
    }, (err) => {
      let error = err;
      if (error.message) {
        this.commonService.showSnakeBar(error.Message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.isLoading = false;
    });

  }

  redirect(url) {
    if (url === '/') {
      this.router.navigate(['/']);
    } else {
      window.location.href = url;
    }
  }

}
