/*
* @ngdoc Component
* @name app.shared.filters.VehicleFamilyFilterComponent
* @description
* This component is used to manage the family filter
* */

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs';
import { FilterName } from '../../../configs/filter-names';
@Component({
  selector: 'vehicle-family-filter',
  templateUrl: './vehicle-family-filter.component.html',
  styleUrls: ['./vehicle-family-filter.component.scss']
})
export class VehicleFamilyFilterComponent implements OnInit {

  @Input() field_type: string;
  @Input() field_class: string;
  @Input() isMultiple: boolean = false;
  @Input('form') parentForm: UntypedFormGroup;
  @Input() formError: any
  @Input() filterList: Array<any> = []

  isLoading: boolean = false;
  vSubscription: Subscription;
  getSelectedFilters: Subscription
  getFilterSelectSignal: Subscription
  checkResetVehicleFilterList: Subscription
  checkInitResetVehicleFilterList: Subscription
  FamilyForm = new UntypedFormControl();
  FamilyFilterList = []
  selectedFamily = "";
  selectedFilter = {};
  @Output() sendFilterSelect = new EventEmitter();
  constructor(private vehicleService: VehicleService, private commonService: CommonService, private dataStore: DataStoreService, private filterName: FilterName) {
    this.selectedFilter = filterName.filter

  }

  ngOnInit() {
    //this.getFamilyFilter()
    this.initSub()
  }
  initSub() {
    this.getSelectedFilters = this.dataStore.getSelectedFilters().subscribe(data => {
      this.selectedFilter = data.filters;
    })
    this.getFilterSelectSignal = this.dataStore.getFilterSelectSignal().subscribe(data => {
      if (data.filter == 'Make') {
        this.getFamilyFilter()
      }
    })
    this.checkResetVehicleFilterList = this.dataStore.checkResetVehicleFilterList().subscribe(data => {
      this.FamilyFilterList = [];
    })
    if (this.isMultiple == false) {
      this.checkInitResetVehicleFilterList = this.dataStore.getInitFilterSelectSignal().subscribe(data => {
        if (data.filter == 'Make') {
          this.FamilyFilterList = [];
          this.getFamilyFilter()
        }
      })
    }
  }
  ngOnChanges(changes) {
    //console.log(changes.)
    if (changes['filterList']) {
      this.FamilyFilterList = changes.filterList.currentValue
    }
    //console.log(this.vFactGroups, 'print the Vfacegroups here')
  }

  /*
  * @name getFamilyFilter
  * @param none
  * @description Get filter list                  
  * @return none
  */
  getFamilyFilter() {
    let apiParams = {
      filter: 'Family'
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    delete apiParams['Family']
    this.isLoading = true
    this.vSubscription = this.vehicleService.getVehicleFilters(apiParams).subscribe(
      (result: any) => {
        try {
          let response = result;
          //console.log(response, 'response')
          this.FamilyFilterList = response.data;
          //this.commonService.showSnakeBar(response.message);
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        let response = error;
        this.isLoading = false;
        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isLoading = false;
      }
    )
  }

  /*
  * @name selectionChange
  * @param  Array(Selected values)
  * @description Get selected values                    
  * @return none
  */
  selectionChange(value) {
    //console.log('Family change', value)
    if (this.isMultiple) {
      this.sendFilterSelect.emit({ filter: 'Family', filterVal: value, 'all': false, toggle: false });
    } else {
      this.dataStore.sendFilterSelectSignal('Family', value)
    }
  }

  /*
  * @name checkAll
  * @param  Array(response)
  * @description Hangle when user select All options in a filter
  * @return none
  */
  checkAll(response) {
    this.sendFilterSelect.emit({ filter: 'Family', filterVal: response.data, 'all': true, toggle: response.toggle });
  }

  /*
  * @name formValidate
  * @param  Form
  * @description validate form                       
  * @return none
  */
  formValidate(formGroup: UntypedFormGroup) {
    /*Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.formValidate(control);
      }
    });*/
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.getSelectedFilters) {
      this.getSelectedFilters.unsubscribe()
    }
    if (this.getFilterSelectSignal) {
      this.getFilterSelectSignal.unsubscribe()
    }
    if (this.checkResetVehicleFilterList) {
      this.checkResetVehicleFilterList.unsubscribe()
    }
    if (this.checkInitResetVehicleFilterList) {
      this.checkInitResetVehicleFilterList.unsubscribe()
    }
  }

}
