import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { JwtService } from 'src/app/services/jwt.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { UserService } from 'src/app/services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'single-user',
  templateUrl: './single-user.component.html',
  styleUrls: ['./single-user.component.scss']
})

export class SingleUserComponent implements OnInit {

  @Input() User: any;
  public isProcessing: boolean = false;
  constructor(
    public dialog: MatDialog,
    public jwtService: JwtService,
    public dataStore: DataStoreService,
    public userSerice: UserService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    //console.log(this.User);
  }

  editUser(obj) {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        companyId: this.jwtService.getCompanyId(),
        isEdit: true,
        user: obj
      }
    });
    dialogRef.componentInstance.onInviteUpdate.subscribe(() => {
      this.dataStore.sendUserListSignal();
    })
  }

  openConfirmPopUp(obj) {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Delete User'
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        this.deleteUser(obj)
      }
    });
  }

  deleteUser(obj) {
    this.isProcessing = true;
    this.userSerice.deleteUser({ userId: obj.User_Id }).subscribe((res: any) => {
      try {
        const response = res;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
          this.dataStore.sendUserListSignal();
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.isProcessing = false;
    }, (err) => {
      let response = err;
      this.isProcessing = false;
      if (response.errors && Object.keys(response.errors).length > 0) {
        this.formatErrors(response.errors)
      }
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
    }, () => {
      this.isProcessing = false;
    })
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  checkIfAUTP(p_ids) {
    if (p_ids) {
      let pArr = p_ids.split(',').map(Number)
      if (pArr.indexOf(1) != -1) return true;
    }
    return false
  }
}
