<div class="dashboard-widget vehicles-list-wrapper">

  <div class="widget-header">
    <h2 *ngIf="widgetType == 'vehicleSearched' && hasQuickSearchAccess">No. of Vehicles Searched</h2>
    <h2 *ngIf="widgetType == 'newVehiclesAdded' && hasNewVehicleListAccess">New vehicles added</h2>
    <mat-radio-group *ngIf="widgetType == 'vehicleSearched' && recentSeachesData.length && hasQuickSearchAccess">
      <mat-radio-button value="week" [attr.checked]="vehicleSearchType=='week' ? true : null" checked="true"
        (click)="getVehiclesSearched('week')">This Week
      </mat-radio-button>
      <mat-radio-button value="month" [attr.checked]="vehicleSearchType=='month' ? true: null"
        (click)="getVehiclesSearched('month')">This Month</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="widgetType == 'vehicleSearched' && hasQuickSearchAccess" class="widget-content bg-white shadow-sm p-3">
    <app-loader [ShowLoader]="isRecentSearchLoading" [type]="'normal'" [CStyle]="{'min-height':'150px'}"></app-loader>
    <div class="text-center" *ngIf="!isRecentSearchLoading" [innerHTML]="barChartHtml | safe"></div>

    <no-record-found *ngIf="!isRecentSearchLoading && !recentSeachesData.length"
      [notFoundHeading]="'No record(s) found'" [showImg]="false" [cStyle]="{'height':'100px'}"></no-record-found>
  </div>

  <div *ngIf="widgetType == 'newVehiclesAdded' && hasNewVehicleListAccess"
    class="widget-content bg-white shadow-sm p-3">
    <app-loader [ShowLoader]="isNewVehiclesLoading" [type]="'normal'" [CStyle]="{'min-height':'150px'}"></app-loader>
    <div *ngIf="newVehiclesData.length" class="pichart text-center">
      <canvas baseChart [type]="'pie'" [datasets]="pieChartData" [labels]="pieChartLabels" [chartType]="chartType"
      [options]="pieChartOptions" [plugins]="pieChartPlugins"></canvas>
    </div>
    <div class="graph-labels" *ngIf="newVehiclesData.length">
      <ul>
        <li *ngFor="let brand of newVehiclesData; let i=index;">
          <span [ngStyle]="{'background-color': colors[i]}" style="-webkit-print-color-adjust: exact"></span>
          <div class="text">
            <label>{{brand.y}} ({{brand.x}})</label>
            <strong>{{calculateBrandPercentage(brand.x)}}%</strong>
          </div>
        </li>
      </ul>
    </div>

    <no-record-found *ngIf="!isNewVehiclesLoading && !newVehiclesData.length" [notFoundHeading]="'No record(s) found'"
      [showImg]="false" [cStyle]="{'height':'100px'}"></no-record-found>
  </div>


</div>