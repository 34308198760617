<page-title [title]="pageHeading"></page-title>
<div class="container">
  <div class="row justify-content-center">
    <div class="col col-lg-10 col-12 search_card">
      <mat-card class="shadow-sm">
        <form [formGroup]="searchForm" (ngSubmit)="handleSearchForm(searchForm)" autocomplete="false" novalidate>
          <mat-card-header class="py-2 px-4">
            <mat-card-title>Search Vehicle</mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-4">

            <div class="row">

              <vfacts-group-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vfacts-group-filter>

              <vehicle-make-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vehicle-make-filter>

              <vehicle-family-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vehicle-family-filter>

              <body-type-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4" [form]="searchForm"
                [formError]="formError"></body-type-filter>

              <fuel-type-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4" [form]="searchForm"
                [formError]="formError"></fuel-type-filter>

              <vehicle-transmission-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vehicle-transmission-filter>

              <vehicle-cylinders-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vehicle-cylinders-filter>

              <vehicle-level-filter [isMultiple]="false" [field_class]="'dropdown'" class="col col-lg-4"
                [form]="searchForm" [formError]="formError"></vehicle-level-filter>

            </div>

          </mat-card-content>
          <mat-card-actions>
            <button mat-button class="btn btn-primary btn-lg mat-button" type="submit">Search</button>
            <button mat-button class="btn btn-dark btn-lg mat-button" type="button"
              (click)="resetFilters()">Clear</button>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
  </div>
</div>