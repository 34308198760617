<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [formControlName]="'Transmission'" name="Transmission" placeholder="Vehicle Transmission" [multiple]="isMultiple"
    [attr.disabled]="isLoading" (selectionChange)="selectionChange($event.value)" [(value)]="selectedTransmissionType">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Transmission" [values]="transmissionList"
      (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngIf="!isMultiple" [value]="'All'">All</mat-option>
    <mat-option *ngFor="let transmissionObj of transmissionList" [value]="transmissionObj.filter">{{transmissionObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Transmission.invalid && (parentForm.controls.Transmission.dirty || parentForm.controls.Transmission.touched)">
    <span *ngIf="parentForm.controls.Transmission.errors.required || parentForm.controls.Transmission.errors.Transmission">
      Please choose Transmission
    </span>
    <span *ngIf="formError?.Transmission">{{formError.Transmission}}</span>
  </mat-error>
</mat-form-field>
