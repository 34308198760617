import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { CommonService } from '../services/common.service';
import { RoleCheckerService } from '../services/role-checker.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard  {
  constructor(private jwtService: JwtService, private roleCheckerSer: RoleCheckerService, private router: Router, private commonService: CommonService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //Skip for super admin
    if (this.roleCheckerSer.isSuperAdmin()) {
      return true;
    }
    let permissions: Array<any> = this.jwtService.getUserPermissions()
    let pData = next.data['permissions']
    if (pData) {
      if (Array.isArray(pData)) {
        let isAllowed: boolean = false;
        pData.forEach(pKey => {
          if (pKey == 'new') {
            if (permissions.includes('new_view_rv') || permissions.includes('new_edit_rv')) {
              isAllowed = true
            }
          } else if (pKey == 'used') {
            if (permissions.includes('used_view_rv') || permissions.includes('used_edit_rv')) {
              isAllowed = true
            }
          }
        });
        return isAllowed
      } else {
        if (pData == 'new') {
          if (permissions.includes('new_view_rv') || permissions.includes('new_edit_rv')) {
          } else {
            this.navigateToDash()
          }
        } else if (pData == 'used') {
          if (permissions.includes('used_view_rv') || permissions.includes('used_edit_rv')) {
          } else {
            this.navigateToDash()
          }
        } else if (pData == 'price_tracker') {
          if (permissions.includes('price_tracker')) {
          } else {
            this.navigateToDash()
          }
        } else if (pData == 'basket') {
          if (permissions.includes('basket')) {
          } else {
            this.navigateToDash()
          }
        }
      }
    }
    let auto_accept = next.data['auto_accept']
    if (auto_accept == false) {
      if (!this.roleCheckerSer.checkAutoAccept() && this.roleCheckerSer.isCompanyOwner) {
      } else {
        this.navigateToDash()
      }
    }
    let risk = next.data['risk']
    if (risk == true) {
      if (permissions.includes('used_edit_rv') || permissions.includes('new_edit_rv')) {
      } else {
        this.navigateToDash()
      }
    }
    return true;
  }

  navigateToDash() {
    this.commonService.showSnakeBar("You are not authorize to view that page.")
    this.router.navigate(["/dashboard"]);
  }
}
