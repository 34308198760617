<div class="wrapper row2">
  <div id="container" class="clear">
    <section id="fof" class="clear">
      <div class="hgroup">
        <h1><span><strong>4</strong></span><span><strong>0</strong></span><span><strong>4</strong></span></h1>
        <h2>
          <div style="margin-top:50px">Error !</div>
          <div style="margin-top:20px"></div>
          <span>Page Not Found</span>
        </h2>
      </div>
      <p>For Some Reason The Page You Requested Could Not Be Found On Our Server</p>
      <p><a href="javascript:history.go(-1)">&laquo; Go Back</a> / <a href="/">Go Home &raquo;</a></p>
    </section>
  </div>
</div>