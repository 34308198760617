<div class="d-flex align-items-center justify-content-between mb-5">
  <page-title class="mb-0" [title]="'Admin Users'"></page-title>
  <div class="d-flex align-items-center">
    <button class="btn btn-primary btn-lg" (click)="addNewUser()" mat-button>Add New User</button>
  </div>
</div>
<div class="companies_list_rows vehicle_listing_table">
  <ul class="table_body" *ngIf="isProcessing">
    <vehicle-list-loading></vehicle-list-loading>
  </ul>

  <ng-container *ngFor="let user of userList">
    <admin-user-detail [User]="user" (processToggle)="handleActionEvents($event)"></admin-user-detail>
  </ng-container>

  <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>

  <no-record-found *ngIf="pagingParams.page==1 && !isProcessing && userList.length <= 0"
    [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'300px'}"></no-record-found>
  <no-record-found *ngIf="noMoreRec && !isProcessing && !isPaging" [notFoundHeading]="'No more record(s) found'"
    [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>

  <div class="d-flex justify-content-center p-4"
    *ngIf="!isProcessing && !isPaging && totalRecords > 10 && !listCompleted">
    <button class="btn btn-primary" (click)="loadMore()" mat-button>Load More</button>
  </div>
</div>
