import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { CommonService } from '../../services/common.service';
import { DataStoreService } from '../../services/data-store.service';
import { AdminUsersService } from '../../services/admin-users.service';
import { AdminUserDetailComponent } from '../admin-user-detail/admin-user-detail.component';
import { AddUpdateUserComponent } from '../add-update-user/add-update-user.component';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  public isProcessing: boolean = false;
  public userList: any[] = [];
  public isPaging: boolean = false;
  public pagingParams: any;
  public totalRecords: number = 0;
  public getUserListSignal: Subscription;
  public listCompleted: boolean = false;
  noMoreRec = false;

  constructor(
    public dialog: MatDialog,
    public adminUserService: AdminUsersService,
    public commonService: CommonService,
    public dataStore: DataStoreService
  ) {
    this.getUserListSignal = this.dataStore.getAdminUserListSignal().subscribe((res: any) => {
      if (res && res.companyStatusChange) {
        const index = this.userList.findIndex(obj => obj.userId == res.data.userId);
        if (index != -1) {
          this.userList[index].status = res.data.status;
        }
      }
      else {
        this.pagingParams = this.getPagingParams();
        this.userList = [];
        this.listCompleted = false;
        this.getUserList(false);
      }
    })
  }

  ngOnInit() {
    this.pagingParams = this.getPagingParams();
    this.getUserList(false);
  }

  getPagingParams() {
    return {
      page: 1,
      limit: 30,
      sort: 'asc',
    }
  }

  ngOnDestroy() {
    if (this.getUserListSignal) {
      this.getUserListSignal.unsubscribe();
    }
  }

  addNewUser(): void {
    const dialogRef = this.dialog.open(AddUpdateUserComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        user: {},
        isEdit: false
      },
      disableClose: true
    });
    dialogRef.componentInstance.onAddUser.subscribe((res: any) => {
      this.pagingParams = this.getPagingParams();
      this.userList = [];
      this.getUserList(false);
    })
  }

  getUserList(isForPaging) {
    this.toggleLoader(isForPaging);
    this.adminUserService.getAdminUserListing(this.pagingParams).subscribe((res: any) => {
      try {
        const response = res;
        this.appendUserListing(response.data.records);
        this.totalRecords = response.data.total;
        if (this.totalRecords == 0 && isForPaging === true) {
          this.noMoreRec = true;
        } else {
          this.noMoreRec = false;
        }
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    }, (err) => {
      try {
        let response = err;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      } catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    })
  }

  appendUserListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.userList.length > 0) {
        newState = this.userList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.userList = newState;
      if (this.userList.length == this.totalRecords) {
        this.listCompleted = true;
      }
    }
  }

  toggleLoader(isForPaging) {
    if (isForPaging) {
      this.isPaging = !this.isPaging;
    }
    else {
      this.isProcessing = !this.isProcessing;
    }
  }

  loadMore() {
    if (this.isProcessing === true || this.isPaging === true) {
      return false;
    }
    this.pagingParams.page++;
    if (this.totalRecords > 10) {
      this.getUserList(true)
    }
  }

  handleActionEvents(event) {
    //Update user details
    if (event['action'] == 'update') {
      let userData = event['data']
      const index = this.userList.findIndex(obj => obj.User_Id == userData.User_Id);
      if (index != -1) {
        this.userList[index] = userData;
        let newState = [];

        if (this.userList.length > 0) {
          newState = this.userList.slice();
          this.userList = newState;
        }
      }
    } else if (event['action'] == 'add' || event['action'] == 'delete') {
      this.pagingParams = this.getPagingParams();
      this.userList = [];
      this.getUserList(false);
    }
  }

}
