import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ManageRecommendationsComponent } from 'src/app/shared/manage-recommendations/manage-recommendations.component';
import { ChangeLinkingComponent } from 'src/app/shared/change-linking/change-linking.component';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { VehicleService } from 'src/app/services/vehicle.service';
import { CommonService } from 'src/app/services/common.service';
import { LinkVehicleComponent } from 'src/app/shared/link-vehicle/link-vehicle.component';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { AddRvValuesComponent } from '../../../shared/add-rv-values/add-rv-values.component';
@Component({
  selector: 'link-vehicle-super-admin',
  templateUrl: './link-vehicle-super-admin.component.html',
  styleUrls: ['./link-vehicle-super-admin.component.scss']
})

export class LinkVehicleSuperAdminComponent implements OnInit {
  @Input() isRvValues: string;
  @Input() linkedVehicle;
  @Input() vehicleId;
  @Input() vehicle;
  @Input() isAdmin: boolean;
  loadRVHistorySubscription: Subscription;
  resetRVHistorySubscription: Subscription;
  getVehicleRec: Subscription;
  vSubscription: Subscription;
  vRecSubscription: Subscription;
  isGettingRecommendVehicles: boolean = false;
  recommendedVehicles: any[] = [];

  constructor(
    public dialog: MatDialog,
    public dataStore: DataStoreService,
    public vehicleService: VehicleService,
    public commonService: CommonService,
    public roleCheckService: RoleCheckerService
  ) {

  }

  ngOnInit() {
    this.getSignalForGettingRecommendations();
  }

  getSignalForGettingRecommendations() {
    this.loadRVHistorySubscription = this.dataStore.checkRVHistoryListDatiumAssetKey().subscribe((data) => {
      if (data['DatiumAssetKey'] == this.vehicleId) {
        if (this.vSubscription) {
          this.vSubscription.unsubscribe()
        }
        this.getRecommendations();
      }
    });

    this.resetRVHistorySubscription = this.dataStore.checkResetRVHistoryListDatiumAssetKey().subscribe((data) => {
      if (data['DatiumAssetKey'] == this.vehicleId) {
        if (data['cancel'] == true && data['reset'] == false) {
          if (this.vSubscription) {
            this.vSubscription.unsubscribe()
          }
        }
        if (data['cancel'] == true && data['reset'] == true) {
          if (this.vSubscription) {
            this.vSubscription.unsubscribe()
          }
          this.getRecommendations();
        }
      }
    });

    // check for vehicle action
    this.getVehicleRec = this.dataStore.getVehicleRec().subscribe(
      (data) => {
        this.handleRecomSignal(data)
      }
    )
  }

  handleRecomSignal(data) {
    if (data['DatiumAssetKey'] == this.vehicleId) {
      if (data['action'] == 'add') {
        if (data['record']) {
          this.recommendedVehicles.push(data['record'])
        }
      } else if (data['action'] == 'remove') {
        if (data['removeKey']) {
          const index = this.recommendedVehicles.findIndex(obj => obj.DatiumAssetKey == data['removeKey']);
          if (index != -1) {
            this.recommendedVehicles.splice(index, 1);
          }
        }
      }
    }
  }

  addRvValues(vehicle = {}): void {
    const dialogRef = this.dialog.open(AddRvValuesComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        vehicle: vehicle
      }
    });
  }

  getRecommendations() {
    // If we are on Accept/reject screen, we first need to check if vehicle is accepted or not.
    // If accepted load the adjustments history
    if (!this.commonService.toggleIndex(this.vehicle, 'toggle')) {
      return false;
    }
    if (this.vRecSubscription != null) {
      this.vRecSubscription.unsubscribe();
    }
    let exId = '';
    if (this.linkedVehicle != undefined) {
      //console.log(this.linkedVehicle, 'this.linkedVehicle')
      if ('vehicleId' in this.linkedVehicle) {
        exId = this.linkedVehicle['vehicleId']
      }
    }
    this.isGettingRecommendVehicles = true
    this.vRecSubscription = this.vehicleService.getVehicleRecommendations(this.vehicleId, exId).subscribe((result: any) => {
      this.isGettingRecommendVehicles = false;
      try {
        const response = result;
        this.recommendedVehicles = response.data;
      } catch (e) {

      }

    },
      (error) => {
        this.isGettingRecommendVehicles = false
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {
        this.isGettingRecommendVehicles = false
      })
  }

  linkingRecommendations(): void {
    const dialogRef = this.dialog.open(ManageRecommendationsComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: this.linkedVehicle,
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      },
      disableClose: true
    });
  }

  changeLinking(): void {
    const dialogRef = this.dialog.open(ChangeLinkingComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: this.linkedVehicle,
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      }
    });
  }

  /*
  * @name changeVehicle
  * @param None
  * @description receive signal for adding/updating the linking
  * @return none
  */
  changeVehicle(data) {
    //console.log(data, 'data')
    //this.commonService.showSnakeBar('Please wait while we are updating the linking.');
    let child = data['child']
    this.dataStore.setVeLoader(child, true, "Please wait while we are updating the linking...")
    this.vehicleService.changeVehicleLinking(data['child'], data['parent']).subscribe(
      (result: any) => {
        this.dataStore.setVeLoader(child, false)
        try {
          let response = result;
          this.commonService.showSnakeBar(response.message);
          this.dataStore.resetVehicleDetailsReload(data['child'], true, true)
        } catch (e) {

        }
      },
      (error) => {
        this.dataStore.setVeLoader(child, false)
        //console.log(error)
        let response = error;
        if (response.message) {
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
      },
      () => {

      }
    )
  }

  openLinkedVehicle(): void {
    const dialogRef = this.dialog.open(LinkVehicleComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: {},
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      }
    });
  }


  ngOnDestroy() {
    if (this.vSubscription) {
      this.vSubscription.unsubscribe()
    }
    if (this.loadRVHistorySubscription) {
      this.loadRVHistorySubscription.unsubscribe()
    }
    if (this.resetRVHistorySubscription) {
      this.resetRVHistorySubscription.unsubscribe()
    }
    if (this.getVehicleRec) {
      this.getVehicleRec.unsubscribe()
    }
    if (this.vRecSubscription) {
      this.vRecSubscription.unsubscribe()
    }
  }

}
