<h2 mat-dialog-title>
  {{basketData ? 'Update ' : 'Add '}} Basket
  <button mat-dialog-close [disabled]="isProcessing">
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography">
  <form [formGroup]="basketForm" autocomplete="false" novalidate>
    <div class="row mt-2 mb-4">

      <div class="col col-lg-12">
        <div class="di-input-wrapper mb-4">
          <label class="custom-label">Name</label>
          <mat-form-field [floatLabel]="'never'" appearance="fill" class="mb-0">
            <input matInput formControlName="name" type="text">
          </mat-form-field>
          <mat-error class="mt-2"
            *ngIf="basketForm.controls.name.invalid && (basketForm.controls.name.dirty || basketForm.controls.name.touched)">
            <span *ngIf="basketForm.controls.name.errors.required">
              Please enter the name of basket.
            </span>
            <span *ngIf="formError.name">{{formError.name}}</span>
          </mat-error>
        </div>
      </div>
      <div class="col col-lg-12">
        <div class="di-input-wrapper">
          <label class="custom-label">Description(Optional)</label>
          <mat-form-field class="textarea" [floatLabel]="'never'" appearance="fill">
            <textarea matInput formControlName="description" placeholder=""></textarea>
            <mat-error
              *ngIf="basketForm.controls.description.invalid && (basketForm.controls.description.dirty || basketForm.controls.description.touched)">
              <span *ngIf="basketForm.controls.description.errors.required">
                Please enter the description of basket.
              </span>
              <span *ngIf="formError.description">{{formError.description}}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form_btns">
      <button mat-flat-button color="primary" [disabled]="isProcessing" (click)="AddUpdateBasket(basketForm)"
        type="submit">{{basketData ? 'Update ' : 'Add '}}Basket</button>
      <button class="ms-3" mat-stroked-button color="primary" (click)="closeModal()" [disabled]="isProcessing"
        type="button">Cancel</button>
    </div>
  </form>
</mat-dialog-content>