import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BasketService } from 'src/app/services/basket.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-add-basket',
  templateUrl: './add-basket.component.html',
  styleUrls: ['./add-basket.component.scss']
})
export class AddBasketComponent implements OnInit {

  basketForm = new UntypedFormGroup({
    'id': new UntypedFormControl(null),
    'name': new UntypedFormControl('', [
      Validators.required
    ]),
    'description': new UntypedFormControl('')
  });
  basketData = null;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddBasketComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private basketService: BasketService,
    public commonService: CommonService
  ) {
    if (this.data) {
      this.basketData = this.data;
      console.log(this.data, 'data');
      this.basketForm.get('id').setValue(this.data['BasketId']);
      this.basketForm.get('name').setValue(this.data.name);
      this.basketForm.get('description').setValue(this.data.description);
    }
  }

  ngOnInit() {
  }

  isProcessing = false;
  formError = {};
  AddUpdateBasket(form) {
    if (form.valid) {
      console.log(form.value, 'form.value')
      this.isProcessing = true;
      this.formError = {};
      this.basketService.addUpdateBasket(form.value).subscribe((res: any) => {
        try {
          const response = res;
          this.dialogRef.close(response.data);
        }
        catch (e) {
          this.commonService.commonSnakeBar();
        }
        this.isProcessing = false;
      }, (err) => {
        let response = err;
        this.isProcessing = false;
        if (response.message) {
          if ('errors' in response.errors) {
            this.handleServerFormError(response.errors)
          }
          this.commonService.showSnakeBar(response.message)
        }
        else {
          this.commonService.commonSnakeBar();
        }
        // this.closeModal(response.data[0]);
      }, () => {
        this.isProcessing = false;
        //this.closeModal();
      })
    }
    else {
      this.formValidate(form);
    }
  }

  /*
    * @name handleServerFormError
    * @param Form
    * @description handle server side errors
    * @return none
    */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.basketForm.contains(key)) {
          let control = this.basketForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }
  }

  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  formatErrors(errors) {
    const objKeys = Object.keys(errors);
    for (let i = 0; i < objKeys.length; i++) {
      if (errors[objKeys[i]]) {
        this.commonService.showSnakeBar(errors[objKeys[i]]);
      }
    }
  }


  closeModal() {
    this.dialogRef.close();
  }
}
