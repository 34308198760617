import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyService } from '../../../services/company.service';

@Component({
  selector: 'app-offer-listing',
  templateUrl: './offer-listing.component.html',
  styleUrls: ['./offer-listing.component.scss']
})
export class OfferListingComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  vFactListing: Subscription;
  products: Array<any> = []
  makes: Array<any> = []
  permissions: Array<any> = []
  subData = []
  loadingSub: boolean = false
  @Input('companyId') companyId: number;

  @Output() offerSelect: EventEmitter<any> = new EventEmitter();

  constructor(
    public permissionService: PermissionsService,
    private commonService: CommonService,
    public dialog: MatDialog,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    this.getProductOfferListing()
    //this.getSubscriptions()
  }

  getProductOfferListing() {
    this.isLoading = true
    this.vFactListing = this.permissionService.getCompanyProductOfferList({ companyId: this.companyId }).subscribe(
      (result: any) => {
        this.isLoading = false
        try {
          let response = result;
          if (response.data) {
            this.products = response.data.products ? response.data.products : []
            this.makes = response.data.makes
            this.permissions = response.data.permissions
          }
        } catch (e) {

        }
      },
      (error) => {
        //console.log(error)
        this.isLoading = false;
        let response = error;

        if (response.message) {
          this.handleServerFormError(response.errors)
          this.commonService.showSnakeBar(response.message);
        } else {
          this.commonService.commonSnakeBar();
        }
        this.isLoading = false
      },
      () => {

      }
    )
  }

  getSubscriptions() {
    if (this.companyId) {
      this.loadingSub = true
      this.companyService.getCompanySubscription(this.companyId).subscribe((res: any) => {
        this.loadingSub = false
        try {
          const response = res;
          let data = response['data']
          this.subData = data['subscriptions']
        }
        catch (e) {
          console.log(e)
          this.commonService.commonSnakeBar();
        }
      }, (err) => {
        this.loadingSub = false
        try {
          let response = err;
          if (response.message) {
            if ('errors' in response.errors) {
              this.handleServerFormError(response.errors)
            }
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
        }
        catch (e) {
          console.log(e)
          this.commonService.commonSnakeBar();
        }
      }, () => {

      })
    }
  }


  offerAction(offer) {
    //console.log(action, offer)
    let data = {
      offerDetails: offer,
      makes: this.makes,
      permissions: this.permissions
    }
    let planSubscription = []
    this.subData.forEach(el => {
      if ('ProductSubscription' in el) {
        let Plain_Id = Number(el['ProductSubscription']['Plan_Id'])
        if (Plain_Id == offer['Plan_Id']) {
          planSubscription.push(el)
        }
      }
    })
    data['offerSubscription'] = planSubscription
    console.log(data)
    this.offerSelect.emit(data)
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    /*if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        if (this.loginForm.contains(key)) {
          let control = this.loginForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
        }
      });
    }*/
  }

  closeModal() {
    this.dialog.closeAll();
  }

  ngOnDestroy() {
    if (this.vFactListing) {
      this.vFactListing.unsubscribe()
    }
  }
}
