import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommonService } from 'src/app/services/common.service';
import { QuickSearchModalComponent } from 'src/app/shared/quick-search-modal/quick-search-modal.component';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss']
})
export class QuickSearchComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    // const dialogRef = this.dialog.open(QuickSearchModalComponent, {
    //   // width: 'auto',
    //   // height: 'auto'
    // });
  }

}
