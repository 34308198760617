import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AppHeadings } from 'src/app/configs/app-headings';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { RvCalculatorService } from 'src/app/services/rv-calculator.service';
import { Subscription } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FilterName } from 'src/app/configs/filter-names';
import { DataStoreService } from 'src/app/services/data-store.service';

@Component({
  selector: 'app-rv-calculator',
  templateUrl: './rv-calculator.component.html',
  styleUrls: ['./rv-calculator.component.scss']
})
export class RvCalculatorComponent implements OnInit {


  searchForm: UntypedFormGroup;
  formError = {};
  formData: any = {};
  vehicle: any;
  isLoadingList: boolean = false;
  isPaging: boolean = false;
  vehicleList = [];
  noMoreRec = false;
  vSubscription: Subscription;
  total = 0;
  page: any = 1;
  limit = 10;
  selectedFilter = {};
  sort = 'asc';
  currentTotal = 0;
  sortBy = 'new_price';
  notFoundHeading = 'Please choose a filter.';
  notFoundDescription = '';
  filterState = {
    submit: false,
    reset: false,
    initialState: false,
    data: {},
    error: false,
    loader: true
  };
  type = "calculator";

  constructor(
    private fb: UntypedFormBuilder,
    private commonService: CommonService,
    private vehicleService: VehicleService,
    public roleCheckService: RoleCheckerService,
    private filterName: FilterName,
    private dataStore: DataStoreService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formData = data;
    //console.log('data', data)
    this.vehicle = data.vehicle ? data.vehicle : {};
    this.formData = data;
    this.searchForm = this.fb.group(
      {
        VFactsGroups: [''],
        Make: [''],
        Family: [''],
        Body_Type: [''],
        Fuel_Type: [''],
        Transmission: [''],
        Cylinders: [''],
        Vehicle_Level: ['']
      }
    )
  }

  ngOnInit() {

  }


  /*
  * @name toggleLoader
  * @param None
  * @description toggle loader(pagination and other loader)
  * @return none
  */
  toggleLoader(show = false, paging = false) {
    if (paging == false) {
      this.isLoadingList = show
    } else {
      this.isPaging = show;
    }
  }

  searchResponse(response) {
    //console.log(response)
    //console.log(this.filterState, '---------------filter state')
    this.filterState = response
    if (this.filterState) {
      let filterStateChanges = this.filterState;
      //console.log(filterStateChanges, '-------------filterStateChanges')
      if (filterStateChanges) {//value == false
        let filterVal = filterStateChanges//.currentValue
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          //this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.resetPaginationRecords()
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please correct the errors.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
        }
      } else {
        let filterVal = filterStateChanges//.currentValue
        if (filterVal.error == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.isLoadingList = false;
        }
        if (filterVal.submit == true) {
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          //this.vehicleList = []
          //this.disableScroll = true;
          //this.page = 1
          //this.limit = 10;
          this.resetPaginationRecords()
          this.selectedFilter = this.filterState['data']//this.commonService.getInitialFilterApiParams(this.filterState['data'])
          this.getVehicleList()
        }
        if (filterVal.reset == true) {
          this.notFoundHeading = 'Please choose a filter.'
          this.notFoundDescription = ''
          // cancel exiting request
          if (this.vSubscription != null) {
            // console.log('unsubscribe')
            this.vSubscription.unsubscribe();
          }
          this.resetPaginationRecords()
        }
      }
    }
  }

  /*
  * @name onScroll
  * @param newList:None
  * @description paginate on scroll
  * @return none
  */
  onScroll() {
    /*if (this.disableScroll === true) {
      return false;
    }*/
    if (this.isLoadingList === true || this.isPaging === true) {
      return false;
    }
    // console.log(typeof (this.page), 'this.page')
    let page = parseInt(this.page);
    this.page = page + 1;
    // console.log(typeof (this.page), 'this.page')
    if (this.currentTotal > 10) {
      //  console.log('onScroll request')
      this.getVehicleList(true)
    }
  }

  /*
  * @name getVehicleList
  * @param None
  * @description Api call for fetching the vehicle list
  * @return none
  */
  getVehicleList(paging = false) {
    this.toggleLoader(true, paging)
    let apiParams = {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
      sortBy: this.sortBy,
      type: 'bi',
      exId: this.formData['vehicleId'] ? this.formData['vehicleId'] : ''
    }
    apiParams = Object.assign(apiParams, this.selectedFilter)
    apiParams['list_type'] = 'rec_list'
    if (this.vSubscription != null) {
      this.vSubscription.unsubscribe();
    }
    this.vSubscription = this.vehicleService.otherVehicleListing(apiParams, 'post').subscribe(
      (result: any) => {
        try {
          let response = result;
          let vehicleData = response.data;
          if (this.page == 1) {
            this.total = vehicleData.total
          }
          //this.total = vehicleData.total
          this.currentTotal = vehicleData.total
          this.limit = vehicleData.limit
          this.page = vehicleData.page
          if (this.currentTotal == 0 && paging === true) {
            this.noMoreRec = true;
          }
          else {
            this.noMoreRec = false;
          }
          if (vehicleData.records.length > 0) {
            this.appendVehicleListing(vehicleData.records)
          } else {
            if (this.page == 1) {
              this.notFoundHeading = 'Hum.. Looks like no record(s) found for this filter'
              this.notFoundDescription = 'Please update your filter above.'
            }
          }
        } catch (e) {

        }
      },
      (error) => {
        try {
          let response = error;
          //console.log(response)
          if (response['status'] == 504) {
            this.notFoundHeading = ''
            this.notFoundDescription = 'An error occurred while processing your request. Please try again later.'
          }
          this.toggleLoader(false, paging)
          if (response.message) {
            this.handleServerFormError(response.errors)
            this.commonService.showSnakeBar(response.message);
          } else {
            this.commonService.commonSnakeBar();
          }
          if (this.page != 1) {
            this.page -= 1
          }
        } catch (e) {

        }
      },
      () => {
        this.toggleLoader(false, paging)
      }
    )
  }

  /*
  * @name appendVehicleListing
  * @param newList:Array
  * @description paginate over vehicle listing                   
  * @return none
  */
  appendVehicleListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.vehicleList.length > 0) {
        newState = this.vehicleList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.vehicleList = newState
    }

  }

  /*
 * @name handleSearchForm
 * @param searchFormObj:FormGroup
 * @description handle the search form
 * @return none
 */
  handleSearchForm(searchFormObj: UntypedFormGroup) {
    //console.log(searchFormObj, searchFormObj.valid)
    if (searchFormObj.valid) {
      let VFactsGroups = searchFormObj.value.VFactsGroups
      let Make = searchFormObj.value.Make
      if (!VFactsGroups && !Make) {
        if (!VFactsGroups) {
          return this.handleServerFormError({
            'VFactsGroups': 'Please choose VFacts Groups.'
          })
        }
        if (!Make) {
          return this.handleServerFormError({
            'Make': 'Please choose Make.'
          })
        }
      }
      this.resetPaginationRecords()
      this.getVehicleList();
    } else {
      this.formValidate(searchFormObj);
    }
  }

  /*
  * @name handleServerFormError
  * @param Form
  * @description handle server side errors                  
  * @return none
  */
  handleServerFormError(errors) {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key) => {
        console.log(key, this.searchForm.contains(key))
        if (this.searchForm.contains(key)) {
          let control = this.searchForm.get(key);
          control.markAsTouched({ onlySelf: true });
          control.setErrors({ 'incorrect': true })
          this.formError[key] = errors[key];
          console.log(this.formError, control)
        }
      });
    }
  }

  /*
    * @name formValidate
    * @param  Form
    * @description validate form                       
    * @return none
    */
  formValidate(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.formValidate(control);
      }
    });
  }

  /*
  * @name resetPaginationRecords
  * @param None
  * @description reset pagination, records and selected filter
  * @return none
  */
  resetPaginationRecords() {
    this.page = 1
    this.limit = 10
    this.currentTotal = 0;
    this.total = 0
    this.vehicleList = [];
    this.sort = 'asc'
    this.sortBy = 'new_price'
    //this.disableScroll = true;
    this.noMoreRec = false;
    this.isLoadingList = false;
    this.isPaging = false;
  }

  /*
  * @name resetFilters
  * @param none
  * @description reset the vehicle filter form
  * @return none
  */
  resetFilters() {
    this.searchForm.setValue(this.filterName.filter)
    this.formError = {}
    this.vehicleList = [];
    this.dataStore.setSelectedFilters(this.filterName.filter)
    this.dataStore.resetVehicleFilterList()
  }
}
