import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from './../shared/shared.module';
import { MaterialComponentsModule } from './../material-components.module';

import { ApCommonModule } from '../ap-common/ap-common.module';
import { AddUpdateUserComponent } from './add-update-user/add-update-user.component';
import { AdminUserDetailComponent } from './admin-user-detail/admin-user-detail.component';


import { AdminUsersService } from '../services/admin-users.service';
import { AdminUsersComponent } from './admin-users/admin-users.component';

@NgModule({
    declarations: [AddUpdateUserComponent, AdminUserDetailComponent, AdminUsersComponent],
    imports: [
        CommonModule,
        MaterialComponentsModule,
        SharedModule,
        RouterModule,
        ApCommonModule
    ],
    providers: [AdminUsersService]
})
export class UsersModule { }
