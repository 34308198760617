<div class="add_rv_link" *ngIf="!isRvValues && !commonService.toggleIndex(vehicle,'toggle');else otherSec">
  <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
    <!-- <div class="col_heading">Accept Vehicle & Add Values</div> -->
    <p>No {{roleCheckService.isSuperAdmin()? 'Datium Values' : 'RV'}} Available, you can add here.</p>
    <div class="add_btns d-flex">
      <button mat-flat-button color="primary" (click)="addRvValues(vehicle)">
        Accept & Add
      </button>
    </div>
  </ng-container>
</div>
<ng-template #otherSec>
  <ng-container *ngIf="isRvValues; else elseAddValues">
    <!-- <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
      <div class="col_heading" *ngIf="!linkedVehicle">Change
        {{roleCheckService.isSuperAdmin()? 'Datium' : 'RV'}} values</div>
      <div class="col_heading" *ngIf="linkedVehicle">Linking Adjustments</div>
    </ng-container> -->
    <div *ngIf="isRvValues">
      <vehicle-rv-values [isRvValues]="isRvValues" [vehicleId]="vehicleId" [vehicle]="vehicle"
        *appHasPermission="['used_edit_rv','new_edit_rv']"></vehicle-rv-values>
      <ng-container *ngIf="commonService.toggleIndex(vehicle,'toggle')">
        <div class="border-bottom my-4" *appHasPermission="['used_edit_rv','new_edit_rv']"></div>
        <vehicle-rv-history [vehicleId]="vehicleId" [vehicle]="vehicle"></vehicle-rv-history>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #elseAddValues>
    <div class="d-flex flex-column align-items-center justify-content-center" style="min-height:300px;">
      <p>No RV Available</p>
      <button mat-flat-button color="primary" (click)="addRvValues(vehicle)">
        Add RV Values
      </button>
    </div>

  </ng-template>
</ng-template>
<!-- <div class="add_rv_link">
    <div class="add_btns d-flex">
      <button class="btn btn-primary" mat-button (click)="openLinkedVehicle()">
        <svg width="18px" height="10px" class="mr-1">
          <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M5.400,5.833 L12.600,5.833 L12.600,4.167 L5.400,4.167 L5.400,5.833 ZM1.710,5.000 C1.710,3.083 3.330,1.583 5.400,1.583 L8.100,1.583 L8.100,-0.000 L5.400,-0.000 C2.430,-0.000 -0.000,2.250 -0.000,5.000 C-0.000,7.750 2.430,10.000 5.400,10.000 L8.100,10.000 L8.100,8.416 L5.400,8.416 C3.330,8.416 1.710,6.916 1.710,5.000 ZM12.600,-0.000 L9.900,-0.000 L9.900,1.583 L12.600,1.583 C14.670,1.583 16.290,3.083 16.290,5.000 C16.290,6.916 14.670,8.416 12.600,8.416 L9.900,8.416 L9.900,10.000 L12.600,10.000 C15.570,10.000 18.000,7.750 18.000,5.000 C18.000,2.250 15.570,-0.000 12.600,-0.000 Z" />
        </svg> Accept & Link
      </button>
    </div>
  </div> -->