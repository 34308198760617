<div class="table_row_detail ap-vertical-accordion">

  <div class="vehicle-chart-wrapper ap-accordion-panel" [ngClass]="{'opened': activePanel==1}">
    <div class="ap-accordion-header" (click)="openPanel(1)">
      <div class="heading-text">Residual Values vs. Sold Prices</div>
    </div>
    <div class="ap-accordion-content">
      <vehicle-chart class="w-100" [vehicle]="vehicle" [vehicleId]="vehicleId"
        (isChartDataLoading)="isChartDataLoading($event)"></vehicle-chart>
    </div>
  </div>

  <div class="linking-adjustment-wrapper ap-accordion-panel" [ngClass]="{'opened': activePanel==2}">
    <div class="ap-accordion-header" (click)="isChartDataLoaded && openPanel(2)"
      [ngClass]="{'disabled': !isChartDataLoaded}">
      <div class="heading-text" *ngIf="!isRvValues && !commonService.toggleIndex(vehicle,'toggle')">
        Accept Vehicle & Add
        Values</div>
      <div class="heading-text" *ngIf="isRvValues; else elseAddRv">
        <ng-container *ngIf="!linkedVehicle">
          Change {{roleCheckService.isSuperAdmin()? 'Datium' : 'RV'}} values
        </ng-container>
        <ng-container *ngIf="linkedVehicle">
          Linking Adjustments
        </ng-container>

      </div>

      <ng-template #elseAddRv>
        <div class="heading-text">Add RV Values</div>
      </ng-template>

    </div>
    <div class="ap-accordion-content">
      <vehicle-rv-wrapper [linkedVehicle]="linkedVehicle" [isRvValues]="isRvValues || vehicle?.diValues"
        [vehicle]="vehicle" [vehicleId]="vehicleId"></vehicle-rv-wrapper>
    </div>
  </div>

  <div class="linked-vehicle-details ap-accordion-panel" [ngClass]="{'opened': activePanel==3}">
    <div class="ap-accordion-header" (click)="isChartDataLoaded && openPanel(3)"
      [ngClass]="{'disabled': !isChartDataLoaded}">
      <div class="heading-text">
        <!-- <ng-container *ngIf="!linkedVehicle && isRvValues && !roleCheckService.isSuperAdmin()">Linked
          Vehicle</ng-container> -->
        <ng-container *ngIf="!linkedVehicle && !isRvValues && !roleCheckService.isSuperAdmin(); else elseLinkedTitle">
          {{roleCheckService.isSuperAdmin()? 'Datium' : 'RV'}} Value
        </ng-container>
        <ng-template #elseLinkedTitle>Linked Vehicle</ng-template>
      </div>

    </div>
    <div class="ap-accordion-content">

      <vehicle-link-wrapper *ngIf="linkedVehicle" [linkedVehicle]="linkedVehicle"
        [isAdmin]="roleCheckService.isSuperAdmin()" [isLinkedVehicleTabOpened]="activePanel == 3 ? true : false"
        [vehicleId]="vehicleId" [vehicle]="vehicle"
        [linkedVehicleId]="linkedVehicle?.DatiumAssetKey"></vehicle-link-wrapper>
      <ng-container *appHasPermission="['used_edit_rv','new_edit_rv']">
        <div class="add_rv_link" *ngIf="!linkedVehicle && isRvValues && !roleCheckService.isSuperAdmin()">
          <div class="col_heading">Linked Vehicle</div>
          <p>No vehicle is linked with this vehicle, you can link vehicle here.</p>
          <div class="add_btns d-flex" *ngIf="roleCheckService.isWriteAccess()">
            <button mat-flat-button color="primary" (click)="openLinkedVehicle()">
              <span class="material-symbols-outlined">link</span>
              {{ !commonService.toggleIndex(vehicle, 'toggle') ? 'Accept & Link':'Link Vehicle'}}
            </button>
            <ng-container *ngIf="vehicle?.NewLink && commonService.toggleIndex(vehicle, 'toggle')">
              <div class="or"><span>OR</span></div>
              <button class="btn btn-outline-warning" mat-stroked-button
                (click)="openNewAdminLinking(vehicle?.NewLink)">
                New Link
              </button>
            </ng-container>
          </div>
        </div>
        <div class="add_rv_link" *ngIf="!linkedVehicle && !isRvValues && !roleCheckService.isSuperAdmin()">
          <div class="col_heading">{{roleCheckService.isSuperAdmin()? 'Datium' : 'RV'}} Value</div>
          <p>
            No {{roleCheckService.isSuperAdmin()? 'Datium' : 'RV'}} Value added for this vehicle. you can add
            custom
            values or link a vehicle with this.
          </p>
          <div class="add_btns d-flex" *ngIf="roleCheckService.isWriteAccess()">
            <button mat-flat-button color="primary" (click)="openLinkedVehicle()">
              <span class="material-symbols-outlined">link</span> {{ !commonService.toggleIndex(vehicle, 'toggle') ?
              'Accept & Link':'Link Vehicle'}}
            </button>
            <div class="or"><span>OR</span></div>
            <button color="primary" mat-stroked-button (click)="addRvValues(vehicle)">
              <ng-container *ngIf="!commonService.toggleIndex(vehicle, 'toggle');else elseAddLink">
                Accept & Add
              </ng-container>
              <ng-template #elseAddLink>
                Add {{roleCheckService.isSuperAdmin() ? 'Datium' : 'RV'}} Values
              </ng-template>
            </button>
            <ng-container *ngIf="vehicle?.NewLink && commonService.toggleIndex(vehicle, 'toggle')">
              <div class="or"></div>
              <button color="warn" mat-stroked-button
                *ngIf="vehicle?.NewLink && commonService.toggleIndex(vehicle, 'toggle')"
                (click)="openNewAdminLinking(vehicle?.NewLink)">
                New Link
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <link-vehicle-super-admin *ngIf="!linkedVehicle && roleCheckService.isSuperAdmin()" [vehicleId]="vehicleId"
        [vehicle]="vehicle"></link-vehicle-super-admin>

    </div>
  </div>
</div>