<div class="vehicle_listing_table">
  <ul class="table_heading" *ngIf="!isLoadingList && vehicleList.length > 0">
    <li>
      <div class="row align-items-center">
        <div class="col col-md-4 col-lg-4">
          <div class="d-flex align-items-center">
            <div class="select_col thbox">
              &nbsp;
              <!-- <mat-checkbox></mat-checkbox> -->
            </div>
            <div class="thbox vehicle_details">Vehicle Details({{total}})</div>
            <div class="thbox pointer d-flex" (click)="orderBy('units',(sort == 'asc' ? 'desc':'asc'))">Units
              <svg width="7px" height="11px" class="ml-2">
                <path fill-rule="evenodd" fill="rgb(143, 143, 151)"
                  d="M3.500,0.500 L6.500,4.500 L0.500,4.500 L3.500,0.500 ZM3.500,10.500 L0.500,6.500 L6.500,6.500 L3.500,10.500 Z" />
              </svg>
            </div>
            <div class="thbox pointer d-flex" (click)="orderBy('new_price',(sort == 'asc' ? 'desc':'asc'))">New Price
              <svg width="7px" height="11px" class="ml-2">
                <path fill-rule="evenodd" fill="rgb(143, 143, 151)"
                  d="M3.500,0.500 L6.500,4.500 L0.500,4.500 L3.500,0.500 ZM3.500,10.500 L0.500,6.500 L6.500,6.500 L3.500,10.500 Z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="col col-md-8 col-lg-8">
          <div class="d-flex align-items-center">
            <div class="thbox double_col">Index</div>
            <div class="thbox double_col">12M (30,000KM)</div>
            <div class="thbox double_col">24M (60,000KM)</div>
            <div class="thbox double_col">36M (90,000KM)</div>
            <div class="thbox double_col">48M (120,000KM)</div>
            <div class="thbox double_col">60M (150,000KM)</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0" [notFoundHeading]="'No record(s) found'"
    [cStyle]="{'height':'300px'}"></no-record-found>
  <ul class="table_body" *ngIf="isLoadingList">
    <vehicle-list-loading></vehicle-list-loading>
  </ul>
  <!--ul class="table_body" infinite-scroll [infiniteScrollDistance]="scrollDistance" [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle" (scrolled)="onScroll()" *ngIf="vehicleList.length > 0 && !isLoadingList">
    <ng-container *ngFor="let vehicleObj of vehicleList">
      <li>
      <vehicle-detail-wrapper [vehicle]="vehicleObj" [vehicleId]="vehicleObj?.DatiumAssetKey" [isRvValues]="vehicleObj?.rvValues"
        [linkedVehicle]="vehicleObj?.linkedVehicle"></vehicle-detail-wrapper>
        </li>
    </ng-container>
    <app-small-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-small-loader>
    <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
      [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  </ul--->
  <ul class="table_body" *ngIf="vehicleList.length > 0 && !isLoadingList">
    <ng-container *ngFor="let vehicleObj of vehicleList">
      <li>
        <vehicle-detail-wrapper [vehicle]="vehicleObj" [vehicleId]="vehicleObj?.DatiumAssetKey"
          [isRvValues]="vehicleObj?.rvValues" [linkedVehicle]="vehicleObj?.linkedVehicle" [preventClose]="preventClose">
        </vehicle-detail-wrapper>
      </li>
    </ng-container>
    <div class="d-flex justify-content-center p-4"
      *ngIf="!isPaging && currentTotal > 10 && !isLoadingList && !noMoreRec">
      <button class="btn btn-primary" (click)="onScroll()" mat-button>Load More</button>
    </div>
    <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
    <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
      [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  </ul>
</div>

<div class="screen_loading">
  <div class="msg_loader_data">
    <div>
      <mat-spinner></mat-spinner>
    </div>
    <div class="loading_messages">
      <ul>
        <li>Saving datium values...</li>
        <li>Updating product chart...</li>
        <li>Updating datium history...</li>
        <li>Updating datium index...</li>
      </ul>
    </div>
  </div>
</div>
