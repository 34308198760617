import { Component, OnInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { DataStoreService } from 'src/app/services/data-store.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'new-vehicle-detail',
  templateUrl: './new-vehicle-detail.component.html',
  styleUrls: ['./new-vehicle-detail.component.scss']
})
export class NewVehicleDetailComponent implements OnInit {

  @Input() vehicleId;
  @Input() vehicle;

  vehicleObj
  selectedVehicle = null
  getSelectedCarDataSignal: Subscription;
  selectedVehicles: Array<any> = [];
  constructor(public dialog: MatDialog, private dataStore: DataStoreService, private commonService: CommonService) {
    // this.getSelectedCarDataSignal = this.dataStore.getSelectedNewVehicle().subscribe(data => {
    //   this.selectedVehicles = data;
    // })
  }

  ngOnInit() {
    this.vehicleObj = this.vehicle
  }

  acceptValues(DatiumAssetKey): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to accept this vehicle?'
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        //console.log(DatiumAssetKey, 'DatiumAssetKey')
        //this.selectedVehicle = DatiumAssetKey
        this.dataStore.setAcceptVehicle({ DatiumAssetKey: DatiumAssetKey, accept: 1 })
      }
    });
  }

  rejectValues(DatiumAssetKey): void {
    const confirmDialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        title: 'Are you sure you want to reject this vehicle?'
      }
    });
    confirmDialogRef.afterClosed().subscribe((result: any) => {
      if (result == 'yes') {
        //console.log(DatiumAssetKey, 'DatiumAssetKey')
        //this.selectedVehicle = DatiumAssetKey
        this.dataStore.setAcceptVehicle({ DatiumAssetKey: DatiumAssetKey, accept: 2 })
      }
    });
  }

  toggleAcceptReject(event): void {
    //console.log(event, 'event')
    if (this.vehicle.accepted != 0) {

    } else {
      this.dataStore.setSelectedNewVehicle(this.vehicleId);
    }
    // if (this.vehicleObj.accepted == 0) {
    //   const index = this.selectedVehicles.findIndex(vehicleId => vehicleId == this.vehicleId);
    //   console.log('index', index)
    //   if (index != -1) {
    //     this.selectedVehicles.splice(index, 1);
    //   }
    //   else {
    //     console.log('push', this.vehicleId)
    //     this.selectedVehicles.push(this.vehicleId);
    //   }
    //   console.log('length', this.selectedVehicles.length)
    //   if (this.selectedVehicles.length > 10) {
    //     this.selectedVehicles.pop();
    //     event.preventDefault();
    //     this.commonService.showSnakeBar("Maximum limit of selection reached");
    //   }
    //   else {
    //     console.log('selectedVehicles', this.selectedVehicles)
    //     this.dataStore.setSelectedNewVehicle(this.selectedVehicles);
    //   }
    // }
  }

  ngOnDestroy() {
    if (this.getSelectedCarDataSignal) {
      this.getSelectedCarDataSignal.unsubscribe()
    }
  }
}