import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-insta-api-key',
  templateUrl: './insta-api-key.component.html',
  styleUrls: ['./insta-api-key.component.scss']
})
export class InstaApiKeyComponent implements OnInit {

  companyDetails: any
  constructor(public dialogRef: MatDialogRef<InstaApiKeyComponent>, @Inject(MAT_DIALOG_DATA) public data: InstaApiKeyComponent, private commonService: CommonService) {
    this.companyDetails = this.data.companyDetails
    //console.log(JSON.stringify(this.companyDetails))
  }

  ngOnInit() {
  }

  triggerAction(action = 'No') {
    this.dialogRef.close(action);
  }

  copiedMsg(from: string = '') {
    this.commonService.showSnakeBar(`${from} has been copied successfully.`)
  }

  copiedError() {
    this.commonService.showSnakeBar('An error occurred while coping. Please use Ctrl+C for copy.')
  }
}
