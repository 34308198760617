<mat-card class="vehicle_breif_component">
  <div class="d-flex justify-content-between align-items-start">
    <div class="vehicle_info">
      <div class="d-flex align-items-center">
        <mat-chip-list>
          <mat-chip color="primary" matTooltip="Click to copy Datium Asset Key" ngxClipboard
            [cbContent]="vehicle?.DatiumAssetKey" *ngxClipboardIfSupported
            (cbOnSuccess)="copiedMsg('Copied to clipboard')" class="pointer" selected>
            <app-text-format [data]="vehicle?.DatiumAssetKey"></app-text-format>
          </mat-chip>
        </mat-chip-list>
        <div class="brand_modal">
          <app-text-format [data]="vehicle?.vehicleDetails?.make"></app-text-format>
          <span class="dot"></span>
          <app-text-format [data]="vehicle?.vehicleDetails?.model"></app-text-format>
          <span class="dot"></span>
          <app-text-format [data]="vehicle?.vehicleDetails?.year_group"></app-text-format>
        </div>
      </div>
      <p class="mt-1 mb-1">
        <app-text-format [data]="vehicle?.vehicleDetails?.description"></app-text-format>
        <ng-container *ngIf="type=='in_calculator'">
          <ng-container *appHasPermission="'calculator'">
            <div class="ms-2 d-inline-block" *ngIf="!roleCheckService.isSuperAdmin()"
              (click)="$event.stopPropagation()">
              <a [routerLink]="[]" matTooltip="Capture RV" (click)="captureCalValues(vehicle?.DatiumAssetKey)">
                <span class="material-symbols-outlined white">photo_camera</span>
              </a>
            </div>
          </ng-container>
        </ng-container>
      </p>
      <a href="javascript:void(0)" (click)="rvHidden=!rvHidden"
        *ngIf="type !='l-tree' && type != 'calculator' && type != 'in_calculator' && rvIncluded">{{rvHidden
        ?
        'Hide' : 'Show'}}
        {{roleCheckService.isSuperAdmin() ? 'DI' : 'RV'}}</a>
    </div>
    <div>
      <ng-container *ngIf="type != 'calculator' && type != 'in_calculator';else otherCase">
        <a href="javascript:void(0)" (click)="removeRecommendation(vehicle, $event)"
          *ngIf="rvIncluded && isRecommended && isSuperAdmin" class="text-danger">
          {{(removingThis!=undefined && removingThis == vehicle.DatiumAssetKey) ? 'Please Wait...' : 'Remove'}}</a>
        <div matTooltip="Click to copy Datium Asset Key" ngxClipboard [cbContent]="linkedId"
          (cbOnSuccess)="copiedMsg('Copied to clipboard')" class="linked_id pointer d-flex align-items-center"
          *ngIf="type=='l-tree' && linkedId">
          <span class="material-symbols-outlined white me-2">link</span> {{linkedId}}
        </div>
        <button
          *ngIf="type=='linking' && parentVehicle.vehicleId != vehicle.DatiumAssetKey && vehicle['DatiumAssetKey'] != linkedId && rvIncluded"
          mat-stroked-button color="primary" (click)="selectedLink(vehicle['DatiumAssetKey'])"
          [disabled]="datiumLoader == vehicle.DatiumAssetKey">
          <span class="material-symbols-outlined blue me-2">link</span>
          {{ datiumLoader == vehicle.DatiumAssetKey ? 'Linking..' : 'Link'}}
        </button>
        <button (click)="addRecommendation(vehicle, $event)"
          *ngIf="rvIncluded && type=='recommended' && !isRecommended && parentVehicle.vehicleId != vehicle.DatiumAssetKey"
          mat-stroked-button color="primary" [disabled]="getDisabledStatus(addedThese)">
          {{(addingThis!=undefined && addingThis == vehicle.DatiumAssetKey) ? 'Please Wait...' :
          (getDisabledStatus(addedThese)) ? 'Added' : 'Select'}}
        </button>
      </ng-container>
      <ng-template #otherCase>
        <button (click)="openRVCal(vehicle)"
          *ngIf="rvIncluded && type == 'calculator' && vehicle?.vehicleDetails?.new_price" color="primary"
          mat-stroked-button>
          Calculate {{roleCheckService.isSuperAdmin() ? 'DI': 'RV'}}
        </button>
        <div class="linked_id" *ngIf="linkedId && type =='in_calculator'">
          <span class="material-symbols-outlined white">link</span> {{linkedId}}
        </div>
      </ng-template>
    </div>
  </div>
  <div
    *ngIf="(showRvDi() || rvHidden) && type !='l-tree' && type != 'calculator' && type !='in_calculator' && rvIncluded"
    class="vehicleBriefRv">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>12M (30,000KM)</th>
          <th>24M (60,000KM)</th>
          <th>36M (90,000KM)</th>
          <th>48M (120,000KM)</th>
          <th>60M (150,000KM)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span
              [ngClass]="roleCheckService.isSuperAdmin() ? 'text-danger':'text-primary'">{{roleCheckService.isSuperAdmin()
              ? 'D.MODEL' : 'RV'}}</span></td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.rvValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.dmValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.rvValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.dmValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.rvValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.dmValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.rvValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.dmValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="!roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.rvValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.rvValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td *ngIf="roleCheckService.isSuperAdmin()">
            <app-text-format [data]="vehicle?.dmValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.dmValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
        </tr>
        <tr>
          <td><span class="text-uppercase"
              [ngClass]="roleCheckService.isSuperAdmin() ? 'text-primary ':'text-danger'">{{roleCheckService.isSuperAdmin()
              ? 'D.INDEX' : 'DATIUM'}}</span></td>
          <td>
            <app-text-format [data]="vehicle?.diValues?.dollarValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['12_30000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <app-text-format [data]="vehicle?.diValues?.dollarValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['24_60000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <app-text-format [data]="vehicle?.diValues?.dollarValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['36_90000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <app-text-format [data]="vehicle?.diValues?.dollarValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['48_120000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
          <td>
            <app-text-format [data]="vehicle?.diValues?.dollarValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'dollar'"></app-text-format>
            <app-text-format [data]="vehicle?.diValues?.percentageValue['60_150000']['formatted']" [defaultNoFormat]="0"
              [formatType]="'percentage'"></app-text-format>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-card>