<svg width="7px" height="12px" class="ml-2" *ngIf="sortBy==sortingFor">
  <path fill-rule="evenodd" *ngIf="sort == 'asc'" fill="rgb(145, 144, 149)" d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001 " />
  <path fill-rule="evenodd" *ngIf="sort == 'asc'" fill="#ddd" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />

  <path fill-rule="evenodd" *ngIf="sort == 'desc'" fill="#ddd" d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001 " />
  <path fill-rule="evenodd" *ngIf="sort == 'desc'" fill="rgb(145, 144, 149)" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
</svg>
<svg width="7px" height="12px" class="ml-2" *ngIf="sortBy!=sortingFor">
  <path fill-rule="evenodd" fill="rgb(143, 143, 151)" d="M3.500,0.500 L6.500,4.500 L0.500,4.500 L3.500,0.500 ZM3.500,10.500 L0.500,6.500 L6.500,6.500 L3.500,10.500 Z" />
</svg>
