<div class="main-admin-inner">
    <div class="main-admin-heading">
        <div class="row">
            <div class="col-12 align-self-center">
                <h1 class="page-title">Usage</h1>
            </div>
        </div>
    </div>
    <div class="admin-top-tab-link">
        <ul>
            <li>
                <a (click)="populateData(true)" href="javascript:void(0);">
          AutoPredict
        </a>
            </li>
            <li>
                <a (click)="populateData(false)" href="javascript:void(0);">
          Instant Valuation
        </a>
            </li>
        </ul>
    </div>

    <div>
        <ng-container>
            <div id="container"></div>
        </ng-container>
    </div>
    <!-- <div class="admin_graph_area usage-instant-graph common-inner-box">
      <div class="top-graph-area d-flex justify-content-between">
        <h2>200 <span>Total Requests</span></h2>
        <div class="graph_right_area">
          <label>Date</label>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-secondary active">All Time</button>
            <button type="button" class="btn btn-secondary">Today</button>
            <button type="button" class="btn btn-secondary">Yesterday</button>
            <button type="button" class="btn btn-secondary">This week</button>
            <button type="button" class="btn btn-secondary">This Month</button>
            <button type="button" class="btn btn-secondary">Select period</button>
          </div>
        </div>
      </div>
    </div>
  
    <div class="table-inner-box usage-instant-list">
      <h2 class="admin_sub_heading">Usage list</h2>
  
      <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="{{column.id}}" *ngFor="let column of columnNames">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.value}} </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="{username:column.value=='User'}">
              {{element[column.id]}}</mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
    </div> -->
</div>