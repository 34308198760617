/*
* @ngdoc Component
* @name app.vehicle.vehicle-list.VehicleDetailComponent
* @description
* This component is act as a wrapper over selected vehicle for showing the Chart, RV and DI history, linked vehicle
* */

import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RvValuesComponent } from '../../../shared/calculator/rv-values/rv-values.component';
import { LinkVehicleComponent } from '../../../shared/link-vehicle/link-vehicle.component';
import { DataStoreService } from '../../../services/data-store.service';
import { RoleCheckerService } from '../../../services/role-checker.service';
import { AddRvValuesComponent } from '../../../shared/add-rv-values/add-rv-values.component';
import { NewAdminLinkComponent } from '../../new-admin-link/new-admin-link.component';
import { CommonService } from '../../../services/common.service';
import { GaEventsService } from '../../../services/ga-events.service';
@Component({
  selector: 'vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehicleDetailComponent implements OnInit, OnChanges {

  @Input() isRvValues: string;
  @Input() linkedVehicle;
  @Input() vehicleId;
  @Input() isAdmin: boolean;
  @Input() vehicle
  @Input() showVehicle: boolean
  @Input() preventClose: boolean
  public isChartDataLoaded: boolean = false;
  activePanel: number = 1;

  constructor(
    public dialog: MatDialog,
    private dataStore: DataStoreService,
    public roleCheckService: RoleCheckerService,
    public commonService: CommonService,
    private gaEvent: GaEventsService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    /*if (this.preventClose == this.vehicleId) {
      //console.log('matched...')
      return false;
    } else {
      //console.log('no')
    }*/
    /*if (!changes.showVehicle) {
      return false;
    }*/
    if (changes.showVehicle.currentValue === true) {
      //this.dataStore.resetVehicleDetailsReload(this.vehicleId, true, true)
      setTimeout(() => {
        this.dataStore.resetRVHistoryListDatiumAssetKey(this.vehicleId, true, true)
      }, 10)
    } else {
      setTimeout(() => {
        this.dataStore.resetRVHistoryListDatiumAssetKey(this.vehicleId, true, true)
      }, 10)
    }
  }

  valueCalculator(vehicle, type = 'list'): void {
    let gaData = {
      'event': 'valueCalculator',
      'value': this.vehicleId
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(RvValuesComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        vehicle: this.vehicle,
        type: type
      }
    });
  }

  openLinkedVehicle(): void {
    let gaData = {
      'event': 'linkVehicle',
      'value': this.vehicleId
    }
    this.gaEvent.sendGaTagConfigData(gaData)
    const dialogRef = this.dialog.open(LinkVehicleComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        linkedVehicle: this.linkedVehicle,
        vehicle: this.vehicle,
        vehicleId: this.vehicleId
      }
    });
  }


  openNewAdminLinking(NewLink) {
    let linkedVehicleId = null;
    let linkedVehicle = null;
    if (this.linkedVehicle) {
      linkedVehicle = this.linkedVehicle
      if ('DatiumAssetKey' in this.linkedVehicle) {
        linkedVehicleId = this.linkedVehicle['DatiumAssetKey']
      }
    }
    let popupD = {
      linkedVehicle: linkedVehicle,
      linkedVehicleId: linkedVehicleId,
      vehicle: this.vehicle,
      vehicleId: this.vehicleId,
      NewLink: NewLink
    }
    //console.log(popupD, 'popupD')
    const dialogRef = this.dialog.open(NewAdminLinkComponent, {
      width: 'auto',
      height: 'auto',
      data: popupD
    });
  }

  addRvValues(vehicle = {}): void {
    const dialogRef = this.dialog.open(AddRvValuesComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        vehicle: vehicle
      }
    });
  }

  openPanel(e) {
    this.activePanel = e;
  }

  isChartDataLoading(isChartDataLoading: string) {
    if(isChartDataLoading === 'done') {
      setTimeout(() => {
        this.isChartDataLoaded = true;
      }, 1000);
    } else {
      this.isChartDataLoaded = false;
    }
  }

}
