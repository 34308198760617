import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ApCommonModule } from "../ap-common/ap-common.module";

import { MaterialComponentsModule } from "../material-components.module";

import { FormsModule } from "@angular/forms";

import { SharedModule } from "../shared/shared.module";

import { AddProductComponent } from "./portal/add-product/add-product.component";
import { DynamicFormComponent } from "./portal/dynamic-form/dynamic-form.component";
import { ProductComponent } from "./product/product.component";
import { EditProductComponent } from "./edit-product/edit-product.component";
import { UsageComponent } from "./usage/usage.component";
import { InstaApiKeyComponent } from "./insta-api-key/insta-api-key.component";

import { ClipboardModule } from "ngx-clipboard";
import { ProductOfferComponent } from "./product-offer/product-offer.component";
import { OfferListingComponent } from "./portal/offer-listing/offer-listing.component";
import { AddProductV2Component } from "./portal/add-product-v2/add-product-v2.component";
import { SubscriptionListComponent } from "./subscription/subscription-list/subscription-list.component";
import { RenewSubscriptionComponent } from "./subscription/renew-subscription/renew-subscription.component";
import { SubscriptionService } from "../services/subscription.service";
import { AddOfferComponent } from "./subscription/add-offer/add-offer.component";
@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        FormsModule,
        SharedModule,
        ApCommonModule,
        ClipboardModule,
        RouterModule
    ],
    declarations: [
        AddProductComponent,
        DynamicFormComponent,
        ProductComponent,
        EditProductComponent,
        UsageComponent,
        InstaApiKeyComponent,
        ProductOfferComponent,
        OfferListingComponent,
        AddProductV2Component,
        SubscriptionListComponent,
        RenewSubscriptionComponent,
        AddOfferComponent
    ],
    exports: [],
    providers: [DatePipe, SubscriptionService]
})
export class CompaniesModule { }
