<h2 mat-dialog-title class="d-flex justify-content-between align-items-center">
  Vehicle Linkage
  <button mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography rv_calculator rv_value_popup">

  <vehicle-brief [isLinked]="childV?.linkedVehicle" [linkVehicle]="childV?.linkedVehicle"
    [linkedId]="childV?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="childV" [type]="'l-tree'" [rvHidden]="false"
    class="bg-primary text-white" [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>
  <div class="vehicle_linkage">

    <h3 class="mt-3">Linkage</h3>

    <p class="font-weight-bold mb-3">Donor Vehicle</p>
    <vehicle-brief [isLinked]="parentV?.linkedVehicle" [linkVehicle]="parentV?.linkedVehicle"
      [linkedId]="parentV?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="parentV" [type]="'l-tree'"
      [rvHidden]="false" [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>

    <app-loader [ShowLoader]="isLoading" [CStyle]="{'min-height':'150px'}"></app-loader>

    <p class="font-weight-bold mb-3 mt-4" *ngIf="treeDetails?.sibling && treeDetails.sibling.length > 0">Share same
      link with</p>
    <ng-container *ngIf="treeDetails?.sibling">
      <vehicle-brief *ngFor="let vehicleSib of treeDetails.sibling" [isLinked]="vehicleSib?.linkedVehicle"
        [linkVehicle]="vehicleSib?.linkedVehicle" [linkedId]="vehicleSib?.linkedVehicle?.DatiumAssetKey"
        [vehicleDetails]="vehicleSib" [type]="'l-tree'" [rvHidden]="false"
        [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>
    </ng-container>

    <p class="font-weight-bold mb-3 mt-4" *ngIf="treeDetails?.child  && treeDetails.child.length > 0">Used as donor
      vehicle for</p>
    <ng-container *ngIf="treeDetails?.child">
      <vehicle-brief *ngFor="let vehicleChild of treeDetails.child" [isLinked]="vehicleChild?.linkedVehicle"
        [linkVehicle]="vehicleChild?.linkedVehicle" [linkedId]="vehicleChild?.linkedVehicle?.DatiumAssetKey"
        [vehicleDetails]="vehicleChild" [type]="'l-tree'" [rvHidden]="false"
        [isSuperAdmin]="roleCheckService.isSuperAdmin()"></vehicle-brief>
    </ng-container>


  </div>

</mat-dialog-content>