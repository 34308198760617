<li>
  <div class="table_row_wrapper loading">
    <div class="row table_row">
      <div class="col col-lg-5">
        <div class="d-flex h-100">
          <div class="select_col tbox">
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox vehicle_details d-block">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox d-block">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox align-items-center d-block">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
        </div>
      </div><!-- .col-lg-5 ends here -->
      <div class="col col-lg-7">
        <div class="d-flex">
          <div class="tbox double_col border-left">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox double_col">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox double_col">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox double_col">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox double_col">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
          <div class="tbox double_col">
            <div class="animatingLayer first"></div>
            <div class="animatingLayer"></div>
          </div>
        </div>
      </div><!-- .col-lg-7 ends here -->
    </div>
  </div>
</li>