<div class="d-flex align-items-center title-with-dropdown mb-5">

  <page-title [title]="pageTitle"></page-title>

  <div class="di-input-wrapper ms-4">
    <mat-form-field *ngIf="exportDataOptions.length" appearance="fill" floatLabel="never" class="mb-0">
      <mat-select [(ngModel)]="selectedExportPage" placeholder="Select">
        <mat-option *ngFor="let record of exportDataOptions" value="{{record.value}}">{{record.key}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button *ngIf="totalRecords !== 0" (click)="exportRecords()"
    [disabled]="isExporting || isSearchFinished || (exportDataOptions.length > 0 && selectedExportPage==='')"
    class="ms-4" mat-flat-button color="primary">{{isExporting ? 'Exporting' : 'Export'}} {{isSuperAdmin ?
    'Datium' : 'RV'}}{{isExporting?'...':''}}</button>

</div>

<div>
  <app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'listing'"
    [isExportingResults]="isExporting" (searchResponse)="searchResponse($event)"
    [filterClass]="'col-md-2 mt-3'"></app-vehicle-filter-search>
</div>
<app-bi-vehicle-list [routeName]="routeName" [filterState]="filterState" (listState)="vehicleListState($event)"
  (totalRecords)="totalRecordsReturned($event)" (exportFinishEvent)="exportFinishEvent($event)"
  (searchFinishEvent)="searchFinshedEvent($event)"></app-bi-vehicle-list>

<app-sel-compare-vehicle></app-sel-compare-vehicle>