<div class="di-input-wrapper">
  <!-- <label class="custom-label">{{controlName == 'recentlyAdded' ? recentAddedPlace : controlPlaceholder}}</label> -->
  <mat-form-field [ngClass]="field_class" [formGroup]="parentForm" appearance="fill">
    <mat-label>{{controlName == 'recentlyAdded' ? recentAddedPlace : controlPlaceholder}}</mat-label>
    <ng-container
      *ngIf="controlName == 'recentlyAdded' || controlName == 'Datium_Values' || (controlName == 'Ind_NewVehicle');else otherFilters">
      <mat-select [formControlName]="controlName" [placeholder]="Select" [multiple]="false"
        (selectionChange)="selectionChange($event.value)">
        <mat-option *ngFor="let filterObj of filterList" [value]="filterObj.key">{{filterObj.title}}</mat-option>
      </mat-select>
    </ng-container>
    <ng-template #otherFilters>
      <mat-select [formControlName]="controlName" placeholder="{{controlPlaceholder}}" [multiple]="isMultiple"
        (selectionChange)="selectionChange($event.value)">
        <mat-checkbox *ngIf="controlName != 'VFacts_Class'" class="mat-option" (checkAll)="checkAll($event)"
          (change)="toggleSelection($event)" [formControlName]="controlName+'_check'">
          All
        </mat-checkbox>
        <!-- <app-select-check-all [form]="frmControl" [controlName]="controlName+'_check'" [controlObj]="parentForm.controls[controlName+'_check']"
        [currentControlName]="controlName" [values]="filterList" [allFilterList]="allFilterList" (checkAll)="checkAll($event)"></app-select-check-all> -->

        <mat-option *ngFor="let filterObj of filterList" [value]="filterObj.filter">{{filterObj.filter}}</mat-option>

      </mat-select>
    </ng-template>
    <!-- <mat-error *ngIf="formError[controlName] && parentForm.controls[controlName].invalid">
      <span>{{formError[controlName]}}</span>
    </mat-error> -->
    <mat-error>
      <span>{{formError[controlName]}}</span>
    </mat-error>
  </mat-form-field>
</div>