import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MaterialComponentsModule } from './../material-components.module';

import { ApCommonModule } from '../ap-common/ap-common.module';

// Core components
import { HeaderComponent } from './header/header.component';
import { ProfileComponent } from './profile/profile.component';
import { SearchComponent } from './search/search.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { MasterComponent } from './master/master.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ProductsDropdownComponent } from './products-dropdown/products-dropdown.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
    RouterModule,
    HttpClientModule,
    ApCommonModule
  ],
  declarations: [
    HeaderComponent,
    ProfileComponent,
    MenuComponent,
    FooterComponent,
    SearchComponent,
    MasterComponent,
    PageNotFoundComponent,
    ProductsDropdownComponent,
    MobileMenuComponent
  ],
  exports: [
    HttpClientModule,
    HeaderComponent,
    SearchComponent,
    ProductsDropdownComponent
  ],
})
export class CoreModule { }
