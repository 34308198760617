import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialComponentsModule } from './material-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//Common modules
import { CoreModule } from './core/core.module';
import { ApCommonModule } from './ap-common/ap-common.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { RoutingModule } from "./app-routing.module";
import { VehicleModule } from './vehicle/vehicle.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CompaniesModule } from './companies/companies.module';
import { MyAccountModule } from './my-account/my-account.module';
import { UiKitModule } from './uikit/uikit.module';
import { ErrorHandlerInterceptor } from "./services/http-interceptor";
import { ApiUrls } from "./configs/api-urls";
import { AppHeadings } from "./configs/app-headings";
import { AppSettings } from "./configs/app-settings";
import { FilterName } from "./configs/filter-names";
import { DataStoreService } from './services/data-store.service';
import { HighChartService } from './services/highcharts.service';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { HelpModule } from './help/help.module';

import { DateFormat } from './configs/date-format';
import { MatDatepickerModule } from '@angular/material/datepicker';


import { GaConfig } from './configs/ga-configs';
import { UsersModule } from './users/users.module';
import { HttpTokenInterceptor } from './services/http-token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MaterialComponentsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RoutingModule,
    CoreModule,
    ApCommonModule,
    SharedModule,
    VehicleModule,
    DashboardModule,
    UiKitModule,
    CompaniesModule,
    MyAccountModule,
    UsersModule,
    HelpModule,
    BrowserAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    ApiUrls,
    GaConfig,
    AppHeadings,
    AppSettings,
    DataStoreService,
    FilterName,
    HighChartService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: true } },
    { provide: DateAdapter, useClass: DateFormat },
  ]
})

export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }
}
