<button class="di-product-dropdown" [matMenuTriggerFor]="products"><span
        class="material-symbols-outlined">manage_search</span>
    {{ isLoading ? 'Loading...' : 'AutoPredict' }}</button>
<mat-menu #products="matMenu" class="product-dropdown-wrapper">
    <div (click)="$event.stopPropagation()" class="category-wrapper" *ngFor="let item of datiumProducts">
        <h5>{{item.category}}</h5>
        <button *ngFor="let product of item.products" [ngClass]="{'active': product.url ==='/' }"
            (click)="redirect(product.url)" mat-menu-item><span
                class="material-symbols-outlined">{{product.icon}}</span>
            {{product.name}}</button>
    </div>
    <div (click)="$event.stopPropagation()" class="category-wrapper">
        <h5>WEB PORTAL</h5>
        <button (click)="redirect(portalUrl)" mat-menu-item><span class="material-symbols-outlined">domain</span>
            Web Portal Home</button>
    </div>
</mat-menu>