<div class="row">
  <div class="col-8">
    <page-title [title]="title"></page-title>
  </div>
  <div class="col-4" *ngIf="!isLoading">
    <button class="btn btn-primary ml-4" mat-button (click)="addSubscription()">Add Subscription</button>
  </div>
</div>
<div class="row" *ngIf="isLoading">
  <app-loader [ShowLoader]="true" [CStyle]="{'min-height':'100px'}"></app-loader>
</div>
<div class="row" *ngIf="!isLoading">
  <div class="company_row p-4 d-flex align-items-center justify-content-between">
    <div class="company_info d-flex align-items-center">
      <div class="company_thumb me-4">
        <img style="border-radius:100%" width="65px" height="65px"
          *ngIf="CompanyDetails?.Thumbnail && CompanyDetails.Thumbnail!= null && CompanyDetails.Thumbnail !=''"
          src="{{commonService.azureURL + CompanyDetails.Thumbnail}}" alt="Company Thumb">
        <div
          *ngIf="CompanyDetails?.Thumbnail == undefined || CompanyDetails.Thumbnail==null || CompanyDetails.Thumbnail==''"
          class="thumbPlaceholder">
          <app-svg-icon [type]="'cmp_profile'"></app-svg-icon>
        </div>
      </div>
      <div class="company_name">
        <h2 class="font-weight-bold">
          {{CompanyDetails.CompanyName}}
        </h2>
        <div class="author d-flex">
          <div *ngIf="CompanyDetails.UserName">
            <span>Author:</span> {{CompanyDetails?.UserName}}
          </div>
          <span *ngIf="CompanyDetails.Email" class="dot"></span>
          <div class="company_email" *ngIf="CompanyDetails.Email">
            <span>Email:</span> {{CompanyDetails?.Email}}
          </div>
          <span *ngIf="CompanyDetails.PhoneNo" class="dot"></span>
          <div *ngIf="CompanyDetails.PhoneNo">
            <span>Phone:</span> {{CompanyDetails?.PhoneNo}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!isLoading">
  <div class="col-12" *ngIf="isSubLoading">
    <app-loader [ShowLoader]="true" [text]="'Loading subscriptions...'" [CStyle]="{'min-height':'100px'}">
    </app-loader>
  </div>
  <div class="col-12">
    <no-record-found *ngIf="listData.length <= 0 && !isSubLoading"
      [notFoundHeading]="'No subscription found. Please add a subscription'" [cStyle]="{'height':'300px'}">
    </no-record-found>
    <div class="example-container" *ngIf="listData.length > 0">

      <div class="example-table-container" *ngIf="!isLoadingSub">
        <table mat-table [dataSource]="listData">

          <!-- Position Column -->
          <ng-container matColumnDef="PlanName">
            <th mat-header-cell *matHeaderCellDef>
              <div>Offer Name</div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.PlanName}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="ProductName">
            <th mat-header-cell *matHeaderCellDef>
              <div class="des">Product Name</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-chip-list class="me-2">
                <mat-chip color="primary" selected>
                  {{element.ProductName}}
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef>
              <div class="des">Start Date</div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.StartDate | date:timeLineDateFormat}} </td>
          </ng-container>
          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef>
              <div class="des">End Date</div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.EndDate | date:timeLineDateFormat}} </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef>
              <div class="des">Status</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IsActive==1;else eStatus">
                <ng-container [ngSwitch]="element.Status">
                  <div *ngSwitchCase="1" class="text-success">Active</div>
                  <div *ngSwitchCase="2" class="text-primary">Upcoming</div>
                  <div *ngSwitchCase="3" class="text-warning">Expired</div>
                </ng-container>
              </ng-container>
              <ng-template #eStatus>
                <div class="text-danger">Inactive</div>
              </ng-template>
            </td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="Action">
            <th class="text-right" mat-header-cell *matHeaderCellDef> Action </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <button (click)="renewSubscription(element)" class="btn btn-outline-primary ml-3" mat-stroked-button>Renew
              </button>
              <button (click)="confirmDeactivation(element)" [disabled]="element.Status==3||element.IsActive==0"
                class="btn btn-outline-danger ml-3" mat-stroked-button>Deactivate
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>