<div class="vehicle_listing_table mt-4">
  <ul class="table_heading" *ngIf="!isLoadingList && vehicleList.length > 0"
    [ngClass]="{'nw-vehicle-heading': type=='new_vehicles'}">
    <li>
      <div class="row align-items-center">
        <div class="col col-md-5 col-lg-5">
          <div class="d-flex align-items-center">
            <div class="select_col thbox">
              &nbsp;
              <mat-checkbox *ngIf="type == 'new_vehicles' && isSuperAdmin" [(ngModel)]="selectAllCheckbox"
                (change)="selectAllForAdmin($event)"></mat-checkbox>
            </div>
            <div class="thbox vehicle_details" *ngIf="type != 'new_vehicles'">Vehicle Details({{total}})</div>
            <div class="thbox vehicle_details" *ngIf="type == 'new_vehicles'">Vehicle Details</div>
            <div class="thbox pointer d-flex justify-content-center"
              (click)="orderBy('units',(sort == 'asc' ? 'desc':'asc'))">Units
              <ng-container *ngIf="!fromBasket">
                <ng-container *ngIf="sortBy=='units'; else uAsc">
                  <svg width="7px" height="12px" class="ms-2" *ngIf="sort == 'asc'">
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001" />
                    <path fill-rule="evenodd" fill="#ddd" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                  <svg width="7px" height="12px" class="ms-2" *ngIf="sort == 'desc'">
                    <path fill-rule="evenodd" fill="#eee" d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001 " />
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                </ng-container>
                <ng-template #uAsc>
                  <svg width="7px" height="12px" class="ms-2">
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001" />
                    <path fill-rule="evenodd" fill="#ddd" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                </ng-template>
              </ng-container>
            </div>
            <div class="thbox pointer d-flex justify-content-end"
              (click)="orderBy('new_price',(sort == 'asc' ? 'desc':'asc'))">New Price
              <ng-container *ngIf="!fromBasket">
                <ng-container *ngIf="sortBy=='new_price'; else pAsc">
                  <svg width="7px" height="12px" class="ms-2" *ngIf="sort == 'asc'">
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001" />
                    <path fill-rule="evenodd" fill="#ddd" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                  <svg width="7px" height="12px" class="ms-2" *ngIf="sort == 'desc'">
                    <path fill-rule="evenodd" fill="#eee" d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001 " />
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                </ng-container>
                <ng-template #pAsc>
                  <svg width="7px" height="12px" class="ms-2">
                    <path fill-rule="evenodd" fill="rgb(145, 144, 149)"
                      d="M3.500,0.001 L0.001,4.999 L6.999,4.999 L3.500,0.001" />
                    <path fill-rule="evenodd" fill="#ddd" d="M3.500,11.999 L0.001,7.001 L6.999,7.001 L3.500,11.999 " />
                  </svg>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col col-md-7 col-lg-7" [ngClass]="{'new_vehicles_list': type == 'new_vehicles'}">
          <div class="d-flex align-items-center">
            <div class="thbox double_col text-center">Index</div>
            <div class="thbox double_col text-right">12M<br>30,000KM</div>
            <div class="thbox double_col text-right">24M<br>60,000KM</div>
            <div class="thbox double_col text-right">36M<br>90,000KM</div>
            <div class="thbox double_col text-right">48M<br>120,000KM</div>
            <div class="thbox double_col text-right">60M<br>150,000KM</div>
            <div class="action-btns" *ngIf="type == 'new_vehicles'">&nbsp;</div>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0" [notFoundHeading]="notFoundHeading"
    [cStyle]="{'height':'300px'}" [notFoundDescription]="notFoundDescription"></no-record-found>
  <ul class="table_body" *ngIf="isLoadingList">
    <vehicle-list-loading></vehicle-list-loading>
    <vehicle-list-loading></vehicle-list-loading>
    <vehicle-list-loading></vehicle-list-loading>
  </ul>
  <ul class="table_body" *ngIf="vehicleList.length > 0 && !isLoadingList">
    <ng-container *ngFor="let vehicleObj of vehicleList">
      <li>
        <vehicle-detail-wrapper [vehicle]="vehicleObj" [vehicleId]="vehicleObj?.DatiumAssetKey"
          [isRvValues]="vehicleObj?.rvValues" [linkedVehicle]="vehicleObj?.linkedVehicle" [preventClose]="preventClose"
          [preventToggle]="preventToggle" [newScreen]="newScreen"></vehicle-detail-wrapper>
      </li>
    </ng-container>
    <div class="d-flex justify-content-center p-4"
      *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
      <button color="primary" (click)="onScroll()" mat-flat-button>Load More</button>
    </div>
    <div class="pb-5">
      <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
    </div>
    <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging" [notFoundHeading]="'No more record(s) found'"
      [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>
  </ul>
</div>
<!--
<div class="screen_loading">
  <div class="msg_loader_data">
    <div>
      <mat-spinner></mat-spinner>
    </div>
    <div class="loading_messages">
      <ul>
        <li>Saving datium values...</li>
        <li>Updating product chart...</li>
        <li>Updating datium history...</li>
        <li>Updating datium index...</li>
      </ul>
    </div>
  </div>
</div> -->