<header class="di-app-header">
  <div class="container-xxl">
    <div class="row align-items-center">

      <div class="col" *ngIf="innerWidth < 768">
        <button class="toggleMobileMenu" (click)="showMobileMenu = true">
          <span class="material-symbols-outlined">menu</span>
        </button>
      </div>

      <div class="col">

        <div class="di-logo-nav-wrapper">

          <a class="di-logo" (click)="handlePortalUrl(portalUrl)"><img src="assets/images/datium-logo.svg"
              alt="Datium Insights"></a>

          <products-dropdown *ngIf="innerWidth >= 768"></products-dropdown>

          <app-menu *ngIf="innerWidth >= 768"></app-menu>

        </div><!-- .di-logo-nav-wrapper ends here -->

      </div>

      <div class="col-md-3">
        <div class="di-product-user-dropdown">

          <div class="action-icons">
            <a matTooltip="Quick Search" href="javascript:void(0)" *ngIf="isQuickSearch"
              [routerLink]="[ '/quick-search' ]"><span class="material-symbols-outlined">search</span></a>
            <ng-container *appHasPermission="'calculator'">
              <a (click)="openCalculator()" matTooltip="{{roleCheckService.isSuperAdmin() ? 'Datium Calculator' :
                'RV Calculator'}}" href="javascript:void(0)"><span
                  class="material-symbols-outlined">calculate</span></a>
            </ng-container>
            <!-- <a matTooltip="Export to excel" href="javascript:void(0)"><app-svg-icon [type]="'excel'"></app-svg-icon></a> -->
            <a *ngIf="!roleCheckService.isSuperAdmin()" [routerLink]="[ '/my-account/reports' ]" matTooltip="Snapshots"
              href="javascript:void(0)"><span class="material-symbols-outlined">photo_camera</span></a>
            <a *ngIf="hasBasketAccess" [routerLink]="[ '/basket' ]" matTooltip="Basket" href="javascript:void(0)"><span
                class="material-symbols-outlined">shopping_basket</span></a>
            <!-- <a [routerLink]="[ '/help-support' ]" matTooltip="Help" href="javascript:void(0)"><app-svg-icon
                [type]="'help'"></app-svg-icon></a> -->
          </div>

          <app-profile [userName]="userName" [userEmail]="userEmail"></app-profile>

        </div><!-- .di-product-user-dropdown ends here -->
      </div>

    </div>

  </div>
</header>

<!-- <mobile-menu [userName]="userName" [userEmail]="userEmail" *ngIf="innerWidth < 768 && showMobileMenu"
  (menuStatus)="chkStatus($event)"></mobile-menu> -->