import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InviteUserComponent } from '../invite-user/invite-user.component';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import { DataStoreService } from 'src/app/services/data-store.service';
import { JwtService } from 'src/app/services/jwt.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})

export class ManageUsersComponent implements OnInit {

  public pagingParams: any;
  public getUserListSignal: Subscription;
  public isProcessing: boolean = false;
  public userList: any[] = [];
  public isPaging: boolean = false;
  public totalRecords: number = 0;
  public totalAllowed: number = 0;
  public noUsers: number = 0;
  public listCompleted: boolean = false;
  noMoreRec = false
  sub_status: number
  SubscriptionsDetails: any = null
  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private commonService: CommonService,
    private dataStore: DataStoreService,
    private jwtService: JwtService
  ) {
    this.getUserListSignal = this.dataStore.getUserListSignal().subscribe((res: any) => {
      this.pagingParams = this.getPagingParams();
      this.userList = [];
      this.listCompleted = false;
      this.getUserListing(false);
    })
  }

  ngOnInit() {
    this.pagingParams = this.getPagingParams();
    this.getUserListing(false);
  }

  ngOnDestroy() {
    if (this.getUserListSignal) {
      this.getUserListSignal.unsubscribe();
    }
  }

  inviteNewUser(): void {
    const dialogRef = this.dialog.open(InviteUserComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
        companyId: this.jwtService.getCompanyId(),
        isEdit: false
      }
    });
    dialogRef.componentInstance.onInviteSubmit.subscribe(() => {
      this.dataStore.sendUserListSignal();
    })
  }

  getPagingParams() {
    return {
      page: 1,
      limit: 10,
      sort: 'asc',
      companyId: this.jwtService.getCompanyId()
    }
  }

  getUserListing(isForPaging) {
    this.toggleLoader(isForPaging);
    this.userService.getCompanyUserListing(this.pagingParams).subscribe((res: any) => {
      try {
        const response = res;
        this.appendCompanyListing(response.data.records);
        this.totalRecords = response.data.total;
        this.totalAllowed = response.data.totalAllowed;
        this.noUsers = response.data.noUsers;
        this.sub_status = response.data.sub_status
        this.SubscriptionsDetails = response.data.SubscriptionsDetails
        if (this.noUsers == 0 && isForPaging === true) {
          this.noMoreRec = true;
        } else {
          this.noMoreRec = false;
        }
        //console.log(response.data, '---prin the total records here');
      }
      catch (e) {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    }, (err) => {
      let response = err;
      if (response.message) {
        this.commonService.showSnakeBar(response.message);
      } else {
        this.commonService.commonSnakeBar();
      }
      this.toggleLoader(isForPaging);
    })
  }

  toggleLoader(isForPaging) {
    if (isForPaging) {
      this.isPaging = !this.isPaging;
    }
    else {
      this.isProcessing = !this.isProcessing;
    }
  }

  loadMore() {
    if (this.isProcessing === true || this.isPaging === true) {
      return false;
    }
    this.pagingParams.page++;
    if (this.totalRecords > 10) {
      this.getUserListing(true)
    }
  }

  appendCompanyListing(newList = []) {
    if (newList.length > 0) {
      let newState = [];
      if (this.userList.length > 0) {
        newState = this.userList.slice();
      }
      newList.forEach(element => {
        newState.push(element)
      });
      this.userList = newState;
      if (this.userList.length == this.totalRecords) {
        this.listCompleted = true;
      }
    }
  }

}
