<app-loader [ShowLoader]="isLoading && !isFilter" [CStyle]="{'min-height':'300px'}" [type]="'normal'"></app-loader>
<form [formGroup]="searchForm" (ngSubmit)="handleSearchForm(searchForm)" autocomplete="false" novalidate
  [ngClass]="cssClass" *ngIf="!isLoading">
  <ng-container *ngIf="type == 'search'">
    <div class="step-heading"><span class="material-symbols-outlined">search</span> Search Vehicle</div>
    <div class="row">
      <div [ngClass]="filterClass" *ngFor="let filterObj of filterControls">
        <app-vehicle-filter (sendFilterSelect)="sendFilterSelect($event)" [isMultiple]="isMultiple"
          [filterList]="allFilterList[filterObj['name']]" [field_class]="'button_type'" [form]="searchForm"
          [formError]="formError" [controlName]="filterObj['name']"
          [frmControl]="searchForm.controls[filterObj['name']]" [controlPlaceholder]="filterObj['title']"
          [toggleLoader]="isFilter || isLoading" [allFilterList]="allFilterList"
          *ngIf="filterObj['name']=='Vehicle_Level';else otherFilter"
          matTooltip="Use the Vehicle Level filter to restrict your query to just the Base variants within each model range, making it easier to compare different models.">
        </app-vehicle-filter>
        <ng-template #otherFilter>
          <app-vehicle-filter (sendFilterSelect)="sendFilterSelect($event)" [isMultiple]="isMultiple"
            [filterList]="allFilterList[filterObj['name']]" [field_class]="'button_type'" [form]="searchForm"
            [formError]="formError" [controlName]="filterObj['name']"
            [frmControl]="searchForm.controls[filterObj['name']]" [controlPlaceholder]="filterObj['title']"
            [toggleLoader]="isFilter || isLoading" [allFilterList]="allFilterList"></app-vehicle-filter>
        </ng-template>
      </div>
    </div>
    <div class="footer-buttons">
      <button mat-flat-button class="me-3" color="primary" [ngClass]="btnClass" type="submit"
        [disabled]="isFilter">Search</button>
      <button mat-stroked-button color="primary" [ngClass]="btnClass" type="button" (click)="resetFilters()"
        [disabled]="isFilter">Clear</button>
    </div>
  </ng-container>
  <ng-container *ngIf="type == 'list' || type == 'listing' || type =='popup'">
    <div [ngClass]="filterClass" *ngFor="let filterObj of filterControls">
      <app-vehicle-filter (sendFilterSelect)="sendFilterSelect($event)" [isMultiple]="isMultiple"
        [filterList]="allFilterList[filterObj['name']]" [field_class]="'button_type'" [form]="searchForm"
        [formError]="formError" [controlName]="filterObj['name']" [frmControl]="searchForm.controls[filterObj['name']]"
        [controlPlaceholder]="filterObj['title']" [toggleLoader]="isFilter || isLoading" [allFilterList]="allFilterList"
        *ngIf="filterObj['name']=='Vehicle_Level';else otherListFilter"
        matTooltip="Use the Vehicle Level filter to restrict your query to just the Base variants within each model range, making it easier to compare different models.">
      </app-vehicle-filter>
      <ng-template #otherListFilter>
        <app-vehicle-filter (sendFilterSelect)="sendFilterSelect($event)" [isMultiple]="isMultiple"
          [filterList]="allFilterList[filterObj['name']]" [field_class]="'button_type'" [form]="searchForm"
          [formError]="formError" [controlName]="filterObj['name']"
          [frmControl]="searchForm.controls[filterObj['name']]" [controlPlaceholder]="filterObj['title']"
          [toggleLoader]="isFilter || isLoading" [allFilterList]="allFilterList"></app-vehicle-filter>
      </ng-template>
    </div>
    <div class="col-md-3 mt-3">
      <button mat-flat-button color="primary" class="me-3" [ngClass]="btnClass" type="submit"
        [disabled]="isFilter || isExportingResults">Search</button>
      <button mat-stroked-button color="primary" [ngClass]="btnClass" type="button" (click)="resetFilters()"
        [disabled]="isFilter || isExportingResults">Clear</button>
    </div>
  </ng-container>
</form>