<page-title [title]="'Manage Users'"></page-title>
<div class="row">
  <div class="col col-4 col-xl-3">
    <dashboard-sidebar [sidebarClass]="'d-flex dashboard_sidebar_wrapper myprofile'" [for]="'profile'">
    </dashboard-sidebar>
  </div>

  <div class="col col-8 col-xl-9">
    <div class="user_list_container p-4">
      <div class="row justify-content-between align-items-center mb-4">
        <div class="col-md-6" *ngIf="!isProcessing">
          <ng-container *ngIf="SubscriptionsDetails !=null">
            <ng-container *ngFor="let subscription of SubscriptionsDetails">
              <ng-container *ngIf="subscription.isSub==true">
                <h2 class="mb-0"><strong>{{subscription.ProductName}}:&nbsp;</strong> <span
                    class="font-weight-normal">{{subscription.ActiveUsers}}/{{subscription.TotalUsers}}</span></h2>
                <!-- <span *ngIf="subscription.IsActiveTrial!=1" class="font-weight-normal">
                        {{subscription.ActiveUsers}}/{{subscription.TotalUsers}}</span>
                      <span *ngIf="subscription.IsActiveTrial==1" class="text-warning font-weight-normal">On Trial</span> -->
                <!-- <ng-template #noActiveSub><span class="text-warning font-weight-normal">No active subscription</span>
                    </ng-template> -->
              </ng-container>
              &nbsp;
            </ng-container>
          </ng-container>
        </div>
        <div class="col-md-6 text-right" *ngIf="!isProcessing">
          <!-- <h2 *ngIf="userList.length > 0 && sub_status!=0">{{noUsers}} of {{totalAllowed}} user accounts used.</h2> -->
          <button class="btn btn-primary btn-lg" mat-button (click)="inviteNewUser()" [disabled]="isProcessing">Invite
            User</button>
        </div>
      </div>
      <div class="users_list" *ngFor="let user of userList">
        <single-user [User]="user"></single-user>
      </div>

      <app-loader [ShowLoader]="isProcessing || isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>

      <no-record-found *ngIf="pagingParams.page==1 && !isProcessing && userList.length <= 0"
        [notFoundHeading]="'No record(s) found'" [cStyle]="{'height':'300px'}"></no-record-found>
      <no-record-found *ngIf="noMoreRec && !isProcessing && !isPaging" [notFoundHeading]="'No more record(s) found'"
        [noRecord]="true" [cStyle]="{'height':'100px'}"></no-record-found>

      <div class="d-flex justify-content-center p-4"
        *ngIf="!isProcessing && !isPaging && totalRecords > 10 && !listCompleted">
        <button class="btn btn-primary" (click)="loadMore()" mat-button>Load More</button>
      </div>
    </div>
  </div>
</div>
