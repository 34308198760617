<h2 mat-dialog-title>
  Link Vehicle
  <button mat-dialog-close>
    <span class="material-symbols-outlined">close</span>
  </button>
</h2>

<mat-dialog-content class="mat-typography link_vehicle_popup">
  <div class="popup_content">
    <div class="linked_vehicle_detail" *ngIf="formData?.linkedVehicle?.DatiumAssetKey">
      <link-vehicle-detail [linkedVehicleId]="formData?.linkedVehicle?.DatiumAssetKey"
        [linkedVehicle]="formData?.linkedVehicle" [rvHidden]="false" (printChart)="printChartData($event)"
        [vehicleId]="formData?.vehicleId" [isDataLoaded]="true"></link-vehicle-detail>
      <ng-container *ngIf="!processingLinkedData">
        <vehicle-chart [vehicle]="processedLinkedData"
          [vehicleId]="formData.linkedVehicle.DatiumAssetKey"></vehicle-chart>
      </ng-container>
    </div>

    <div class="search_results mt-0">
      <mat-tab-group>
        <mat-tab label="Recommended" *ngIf="commonService.toggleIndex(formData.vehicle, 'toggle')">
          <app-loader [ShowLoader]="isGettingRecommendVehicles" [CStyle]="{'min-height':'200px'}"></app-loader>
          <no-record-found *ngIf="recommendedVehicles.length==0 && !isGettingRecommendVehicles"
            [notFoundHeading]="'No recommendations found'" [cStyle]="{'height':'300px'}"></no-record-found>
          <ve-loader [ShowLoader]="loaderRecData.loading" [text]="loaderRecData.text"></ve-loader>
          <ng-container *ngFor="let vehicleObj of recommendedVehicles">
            <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
              [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
              [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj" [type]="'linking'"
              [rvHidden]="false" [parentVehicle]="formData" (changeVehicle)="changeVehicle($event,true)">
            </vehicle-brief>
          </ng-container>
        </mat-tab>
        <mat-tab label="Search Vehicles">
          <div class="search_filters mt-3 mb-4">
            <app-vehicle-filter-search [listingType]="type" [cssClass]="'row'" [type]="'popup'"
              (searchResponse)="searchResponse($event)" [filterClass]="'col-md-4 mt-2'"></app-vehicle-filter-search>
            <!-- <form [formGroup]="searchForm" class="row m-0 d-flex align-items-center flex-wrap" (ngSubmit)="handleSearchForm(searchForm)"> -->

            <!-- <vfacts-group-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm" [formError]="formError"></vfacts-group-filter>

                            <vehicle-make-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm" [formError]="formError"></vehicle-make-filter>

                            <vehicle-family-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm" [formError]="formError"></vehicle-family-filter>

                            <fuel-type-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm" [formError]="formError"></fuel-type-filter> -->

            <!-- <vehicle-transmission-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm" [formError]="formError"></vehicle-transmission-filter> -->

            <!-- <vehicle-cylinders-filter [isMultiple]="false" [field_class]="'button_type'" class="col col-lg-3" [form]="searchForm"
                [formError]="formError"></vehicle-cylinders-filter> -->
            <!-- <button mat-button class="btn btn-primary mat-button ml-3" type="submit">Search</button>
                            <button mat-button class="btn btn-dark mat-button ml-3" type="button" (click)="resetFilters()">Clear
                Filters</button> -->
            <!-- </form> -->
          </div>
          <ng-container>
            <no-record-found *ngIf="page==1 && !isLoadingList && vehicleList.length <= 0"
              [notFoundHeading]="notFoundHeading" [notFoundDescription]="notFoundDescription"
              [cStyle]="{'height':'300px'}"></no-record-found>
            <app-loader [ShowLoader]="isLoadingList" [CStyle]="{'min-height':'200px'}"></app-loader>
            <ve-loader [ShowLoader]="loaderData.loading" [text]="loaderData.text"></ve-loader>
            <!-- <no-record-found *ngIf="!searchForm.value.VFactsGroups" [notFoundHeading]="'Please choose any filter.'"
              [cStyle]="{'height':'300px'}"></no-record-found> -->
            <ng-container *ngFor="let vehicleObj of vehicleList">
              <vehicle-brief [isLinked]="vehicleObj?.linkedVehicle" [linkVehicle]="vehicleObj?.linkedVehicle"
                [rvIncluded]="vehicleObj?.rvValues || vehicleObj?.dmValues"
                [linkedId]="vehicleObj?.linkedVehicle?.DatiumAssetKey" [vehicleDetails]="vehicleObj" [type]="'linking'"
                [rvHidden]="false" [parentVehicle]="formData"
                (changeVehicle)="changeVehicle($event,false)"></vehicle-brief>
            </ng-container>
            <div class="d-flex justify-content-center p-4"
              *ngIf="!isPaging && currentTotal > 10 && !noMoreRec && !isLoadingList">
              <button color="primary" (click)="onScroll()" mat-flat-button>Load More</button>
            </div>
            <app-loader [ShowLoader]="isPaging" [CStyle]="{'min-height':'100px'}"></app-loader>
            <no-record-found *ngIf="noMoreRec && !isLoadingList && !isPaging"
              [notFoundHeading]="'No more record(s) found'" [noRecord]="true"
              [cStyle]="{'height':'100px'}"></no-record-found>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
      <button class="ms-3 link-manage-rec" color="primary" mat-stroked-button (click)="linkingRecommendations()"
        *ngIf="roleCheckService.isSuperAdmin() && commonService.toggleIndex(this.formData.vehicle, 'toggle')">Manage
        Recommendations</button>
    </div>
  </div>
</mat-dialog-content>