import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';



import { MaterialComponentsModule } from '../material-components.module';

import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { DashboardModule } from '../dashboard/dashboard.module';

import { ManageUsersComponent } from './manage-users/manage-users.component';
import { SystemConfigurationsComponent } from './system-configurations/system-configurations.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { HelpComponent } from './help/help.component';
import { SingleUserComponent } from './single-user/single-user.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { ApCommonModule } from '../ap-common/ap-common.module';
import { VehicleReportsComponent } from './vehicle-reports/vehicle-reports.component';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsModule,
        FormsModule,
        SharedModule,
        DashboardModule,
        ApCommonModule,
        ClipboardModule
    ],
    declarations: [
        ManageUsersComponent,
        SystemConfigurationsComponent,
        SubscriptionsComponent,
        HelpComponent,
        SingleUserComponent,
        InviteUserComponent,
        VehicleReportsComponent
    ],
    exports: [],
    providers: [
        DatePipe
    ]
})
export class MyAccountModule { }
