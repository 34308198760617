<mat-form-field [ngClass]="field_class" [formGroup]="parentForm">
  <mat-select [formControlName]="'Fuel_Type'" name="Fuel_Type" placeholder="Fuel Type" [multiple]="isMultiple"
    [attr.disabled]="isLoading" (selectionChange)="selectionChange($event.value)" [(value)]="selectedFuelType">
    <app-select-check-all *ngIf="isMultiple" [form]="parentForm.controls.Fuel_Type" [values]="fuelTypeList" (checkAll)="checkAll($event)"></app-select-check-all>
    <mat-option *ngIf="!isMultiple" [value]="'All'">All</mat-option>
    <mat-option *ngFor="let fuelTypeObj of fuelTypeList" [value]="fuelTypeObj.filter">{{fuelTypeObj.filter}}</mat-option>
  </mat-select>
  <mat-error *ngIf="parentForm.controls.Fuel_Type.invalid && (parentForm.controls.Fuel_Type.dirty || parentForm.controls.Fuel_Type.touched)">
    <span *ngIf="parentForm.controls.Fuel_Type.errors.required || parentForm.controls.Fuel_Type.errors.Fuel_Type">
      Please choose Fuel Type
    </span>
    <span *ngIf="formError?.Fuel_Type">{{formError.Fuel_Type}}</span>
  </mat-error>
</mat-form-field>
